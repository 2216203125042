import MultiContext from 'components/utils/MultiContext'
import { AssetModificationContext, IAssetModificationContext } from 'contexts/assetModification'
import { LoaderFunction, RouteObject, useLoaderData } from 'react-router-dom'
import { getAsset, getAssetAttributeSettings } from 'stores/asset'
import getParams from 'utils/getParams'
import EditApartmentPage from './_edit_apt'
import EditMHPHomePage from './_edit_home'
import EditLotPage from './_edit_lot'
import { useAsset } from './_root'

type LoaderData = {
  assetModificationContext: IAssetModificationContext
}

const EditAssetPageComponent = () => {
  const { asset } = useAsset()
  const { assetModificationContext } = useLoaderData() as LoaderData

  const renderAssetType = (): JSX.Element => {
    switch (asset.type) {
      case 'mhp_lot': return <EditLotPage />
      case 'mhp_home': return <EditMHPHomePage />
      case 'apartment': return <EditApartmentPage />
    }

    return <div>Unknown asset type.</div>
  }

  const contexts = [
   { context: AssetModificationContext, value: assetModificationContext }
  ]

  return (
    <MultiContext contexts={contexts}>
      {renderAssetType()}
    </MultiContext>
  )
}

const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { assetId } = getParams(args)

  // TODO: remove this fetch
  const asset = await getAsset({ assetId })

  const attributeSettings = await getAssetAttributeSettings({
    assetType: asset.type,
    propertyId: asset.propertyId
  })

  const assetModificationContext: IAssetModificationContext = {
    assetAttributeSettings: {
      [asset.type]: attributeSettings
    }
  }

  return {
    assetModificationContext
  }
}

const route: RouteObject = {
  path: 'edit',
  element: <EditAssetPageComponent />,
  loader
}

export default route
