import classNames from 'classnames'
import { uniq } from 'lodash'
import React from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import PageUtils from 'utils/pages'

interface Props {
  page: PageUtils
  getPageHref: (page: number) => string
}

export default function Pagination(props: Props): JSX.Element {
  const {
    page: pageUtil,
    getPageHref
  } = props

  const [ searchParams ] = useSearchParams()

  const preserveQueryParams = (targetUrl: string): string => {
    const [ origUrl, origParams ] = targetUrl.split('?')


    const currentParams = new URLSearchParams(searchParams)
    const targetParams = new URLSearchParams(origParams || '')

    // We need to copy current params to target params
    // so that we don't lose any query params
    currentParams.forEach((value, key) => {
      if (targetParams.has(key)) {
        // do not override existing params
        return
      }

      targetParams.set(key, value)
    })

    return origUrl + '?' + targetParams.toString()
  }

  const renderPrevious = (): JSX.Element => {
    const url: string = preserveQueryParams(getPageHref(pageUtil.current() - 1))

    return (
      <div className='-mt-px w-0 flex-1 flex'>
          <Link to={url} className='pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700'>
          <svg className='mr-3 h-5 w-5 text-gray-400' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' >
            <path fillRule='evenodd' d='M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z' clipRule='evenodd' />
          </svg>
          Previous
        </Link>
      </div>
    )
  }

  const renderNext = (): JSX.Element => {
    const url: string = preserveQueryParams(getPageHref(pageUtil.current() + 1))

    return (
      <div className='-mt-px w-0 flex-1 flex justify-end'>
        <Link to={url} className='pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700'>
          Next
          <svg className='ml-3 h-5 w-5 text-gray-400' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
            <path fillRule='evenodd' d='M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z' clipRule='evenodd' />
          </svg>
        </Link>
      </div>
    )
  }

  const renderNumber = (pageNum: number, index: number, pages: number[]): JSX.Element => {
    const isSequential = index === 0
      ? true
      : pages[index - 1] === pageNum - 1

    const styles = {
      common: 'px-4 inline-flex items-center text-sm font-medium',
      hover: 'hover:text-gray-700',
      current: 'text-indigo-600',
      default: 'text-gray-500 hover:text-gray-700'
    }

    const classes = classNames({
      [styles.common]: true,
      [styles.hover]: true,
      [styles.current]: pageNum === pageUtil.current(),
      [styles.default]: pageNum !== pageUtil.current()
    })

    const url = preserveQueryParams(getPageHref(pageNum))
    const link = <Link to={url} className={classes}>{pageNum}</Link>

    if (isSequential) {
      return (
        <React.Fragment key={pageNum}>{link}</React.Fragment>
      )
    }

    return (
      <React.Fragment key={pageNum}>
        <span className='text-gray-500 px-4 inline-flex items-center text-sm font-medium'>
          ...
        </span>
        {link}
      </React.Fragment>
    )
   
  }


  const pageSeries = new Array(5)
    .fill(null)
    .map((_, index: number) => (pageUtil.current() - 2) + index)
    .filter(p => p > 1)
    .filter(p => p < pageUtil.last())

  const pages = uniq([
    1,
    ...pageSeries,
    pageUtil.last()
  ])

  return (
    <nav className='px-4 flex items-center justify-between sm:px-0'>
      {
        pageUtil.current() !== 1
        ? renderPrevious()
        : <div className='flex-1'></div>
      }
      <div className='hidden md:-mt-px md:flex'>
        {
          pages.length > 1
          ? pages.map(renderNumber)
          : <span className='text-gray-500 text-sm font-medium'>No more results.</span>
        }
      </div>

      {
        pageUtil.current() !== pageUtil.last()
        ? renderNext()
        : <div className='flex-1'></div>
      }
    </nav>
  )
}
