import schemas from '@shared/schemas'
import { Comment, CommentsWithKnownTarget, CommentWithKnownTarget } from '@shared/schemas/comment'
import { Users } from '@shared/schemas/user'
import classNames from 'classnames'
import Avatar from 'components/elements/Avatar'
import Button from 'components/elements/Button'
import { Card, CardBody, CardFooter, CardHeader } from 'components/elements/Card'
import { UserContext } from 'contexts/user'
import { keyBy } from 'lodash'
import { postApi } from 'modules/api'
import progress from 'modules/progress'
import { getTypedApi } from 'modules/typedApi'
import moment from 'moment'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BarLoader } from 'react-spinners'
import useAsyncEffect from 'use-async-effect'
import * as z from 'zod'

interface User {
  id: string
  firstName: string
  lastName: string
  avatarUrl: string|null
}

interface Props {
  title?: string
  nouns?: string[]
  placeholder?: string
  submitText?: string
  targetType: Comment['targetType']
  targetId: string
}

export default function Comments(props: Props): JSX.Element {
  const {
    nouns = [ 'comment', 'comments' ],
    targetType,
    targetId
  } = props

  const [
    nounSingular,
    nounPlural
  ] = nouns.map(noun => noun.toLowerCase())

  const placeholder: string = props.placeholder || `Add a ${nounSingular}...`

  const title: string = props.title || [
    nounPlural.slice(0, 1).toUpperCase(),
    nounPlural.slice(1)
  ].join('')

  const submitText: string = nounSingular === 'comment'
    ? 'Comment'
    : `Add ${nounSingular}`

  const [ comments, setComments ] = useState<CommentsWithKnownTarget>([])
  const [ users, setUsers ] = useState<Users>([])

  const [ inputText, setInputText ] = useState('')

  const [ isLoading, setIsLoading ] = useState(true)
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const [ isDeleting, setIsDeleting ] = useState(false)

  const [ lastRefreshed, setLastRefreshed ] = useState(Date.now())
  const [ confirmDeleteCommentId, setConfirmDeleteCommentId ] = useState('')
  
  const self = useContext(UserContext)

  useAsyncEffect(async isMounted => {
    const { users, comments } = await getTypedApi({
      url: '/GetComments',
      schema: z.object({
        comments: schemas.comment.commentsWithKnownTarget,
        users: schemas.user.users
      }),
      query: { targetType, targetId }
    })

    if (!isMounted()) return

    setUsers(users)
    setComments(comments)
    setIsLoading(false)
  }, [ targetType, targetId, lastRefreshed ])

  /**
   * Automatically return the 'Are you sure?' delete button to normal after a
   * few seconds
   */
  useAsyncEffect(
    async () => {
      if (confirmDeleteCommentId === '') return
      return setTimeout(() => setConfirmDeleteCommentId(''), 3000)
    },
    (timeout) => {
      // Clear the timeout if we are unmounted
      if (timeout) clearTimeout(timeout)
    }
  )

  useEffect(() => {
    setTimeout(() => {
      // TODO: ???? what is this here for
    })
  }, [ confirmDeleteCommentId ])

  const usersById = keyBy(users, 'id')

  const onSubmit = async (evt: ChangeEvent<HTMLFormElement>) => {
    evt.preventDefault()

    setIsSubmitting(true)
    progress.start('submit_comment')

    await postApi('/CreateComment',
      { comment: inputText },
      { targetId, targetType }
    )

    progress.done('submit_comment')
    setIsSubmitting(false)
    setInputText('')
    setLastRefreshed(Date.now())
  }

  const onDeleteComment = (commentId: string) => {
    const isLoadingDelete = (state: boolean) => {
      setIsDeleting(state)
      progress.setLoading('delete comment', state)
    }
    
    return async () => {
      if (commentId === confirmDeleteCommentId) {
        isLoadingDelete(true)
        const result = await postApi('/DeleteComment', { commentId })

        if (result._.statusCode === 200) {
          // Remove the comment from the screen
          setComments(comments.filter(comment => comment.id !== commentId))
        }

        isLoadingDelete(false)
      } else {
        setConfirmDeleteCommentId(commentId)
      }
    }
  }

  const renderAvatar = (user: User): JSX.Element => {
    const { firstName, lastName, avatarUrl } = user
    const initials: string = [
      firstName.slice(0, 1),
      lastName.slice(0, 1)
    ].join('')

    return <Avatar src={avatarUrl} initials={initials} />
  }
  
  const renderComment = (c: CommentWithKnownTarget): JSX.Element => {
    const { id, userId, comment, createdAt } = c
    const user = usersById[userId]
    const { firstName, lastName } = user

    const isMine = self.id === userId
    const isConfirmingDelete = confirmDeleteCommentId === id
    const isDeletingThisItem = isConfirmingDelete && isDeleting

    const renderDelete = (): JSX.Element => {
      let label: string = 'Delete'

      if (isConfirmingDelete) label = 'Are you sure?'
      if (isDeletingThisItem) label = 'Deleting...'

      const type: 'button' = 'button'

      const props = {
        onClick: onDeleteComment(id),
        type,
        className: classNames(
          { 'text-red-500': isConfirmingDelete },
          { 'text-gray-900': !isConfirmingDelete }
        )
      }

      return (
        <button {...props}>{label}</button>
      )
    }

    return (
      <li key={id} className={isDeletingThisItem ? 'opacity-50' : ''}>
        <div className='flex space-x-3'>
          <div className='flex-shrink-0'>
            {renderAvatar(user)}
          </div>
          <div>
            <div className='text-sm'>
              <Link to='/' className='font-medium text-gray-900'>{firstName} {lastName}</Link> 
            </div>
            <div className='mt-1 text-sm text-gray-700'>
              {comment}
            </div>
            <div className='mt-2 text-sm space-x-2'>
              <span
                className='text-gray-500 font-medium'
                title={moment(createdAt).format('lll')}
              >{moment(createdAt).fromNow()}</span>
              {
                isMine
                ? <>
                    <span className='text-gray-500 font-medium'>&middot;</span>
                    {renderDelete()}
                  </>
                : null
              }
            </div>
          </div>
        </div>
      </li>
    )
  }

  const renderComments = (): JSX.Element => {
    if (comments.length < 1) {
      return <div />
    }

    return (
      <CardBody type='custom' className='px-4 py-6 sm:px-6'>
        <ul className='space-y-8'>
          {comments.map(renderComment)}
        </ul>
      </CardBody>
    )
  }

  const renderInput = (): JSX.Element => {
    return (
      <div className='flex space-x-3'>
        <div className='flex-shrink-0'>
          {renderAvatar(self)}
        </div>
        <div className='min-w-0 flex-1'>
          <form onSubmit={onSubmit}>
            <div>
              <textarea
                id='comment'
                className='shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md'
                rows={3}
                value={inputText}
                placeholder={placeholder}
                onChange={evt => setInputText(evt.target.value)}
              />
            </div>
            <div className='mt-3 flex items-center justify-between'>
              <button type='button' className='group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900'>
                <svg className='flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                </svg>
                <span>Some Markdown is okay.</span>
              </button>
              <Button
                type='primary'
                disabled={isLoading || isSubmitting}
                onClick='submit'
              >
                {isSubmitting ? 'One sec...' : submitText}
              </Button>
            </div>
          </form>
        </div>
      </div>
    )
  }

  const renderLoading = (): JSX.Element => {
    return (
      <CardBody type='custom' className='px-4 py-6 sm:px-6'>
        <div className='h-24 w-full flex items-center justify-center'>
          <BarLoader />
        </div>
      </CardBody>
    )
  }

  const getSubtitle = (): string => {
    if (isLoading) return 'Just a sec...'
    if (comments.length < 1) return `No ${nounPlural} yet.`
    if (comments.length === 1) return `There is one ${nounSingular}.`
    return `There are ${comments.length} ${nounPlural}.`
  }

  return (
    <Card>
      <CardHeader
        title={title}
        subtitle={getSubtitle()}
        divider={comments.length > 0}
      />
      {
        isLoading
        ? renderLoading()
        : renderComments()
      }
      <CardFooter>
        {renderInput()}
      </CardFooter>
    </Card>
  )
}
