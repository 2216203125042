import schemas from '@shared/schemas'
import { Form } from 'components/elements/Form'
import { Checkbox } from 'components/elements/forms/inputs/Checkbox'
import FieldSet from 'components/elements/forms/inputs/FieldSet'
import Section from 'components/elements/forms/inputs/Section'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { postApi } from 'modules/api'
import notifications from 'modules/notifications'
import progress from 'modules/progress'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import createSchema from 'utils/createSchema'
import { withNamespace } from 'utils/forms'
import namespaces from 'utils/namespaces'
import AddressForm from '../elements/forms/Address'
import HomeAssetForm from '../elements/forms/HomeAsset'
import Submit from '../elements/forms/inputs/Submit'
import TextInput from '../elements/forms/inputs/Text'

interface Props {
  updateParentState: ({ newAssetId }: { newAssetId: string }) => void
  updateParentFormResetRef: (ref: any) => void
}

export default function CreateLot(props: Props): JSX.Element {
  const { updateParentState, updateParentFormResetRef } = props
  const [ hasHome, setHasHome ] = useState(false)
  const { propertyId } = useParams<any>()

  const namespace = withNamespace(namespaces.lot)

  const schema = createSchema()
    .with(namespaces.lot, schemas.asset.lot)
    .with(namespaces.address, schemas.address.address)
    .with(namespaces.home, schemas.asset.home, hasHome)

  const onSubmit = async (values: any, frmk: FormikHelpers<any>) => {
    progress.start('create lot')

    const data = schema.filter(values)

    try {
      const response = await postApi('/CreateAsset', data, {
        type: 'mhp_lot',
        propertyId
      })

      if (response._.statusCode !== 200) {
        throw new Error(response.message)
      }

      updateParentState({ newAssetId: response.assetId })
    } catch (e: any) {
      notifications.error({ message: e.message })
    }

    progress.done('create lot')
    frmk.setSubmitting(false)
  }

  const initialValues = {
    [namespaces.lot]: schemas.asset.blanks.lot,
    [namespaces.address]: schemas.address.blanks.address,
    [namespaces.home]: schemas.asset.blanks.home
  }

  const renderForm = (formikProps: FormikProps<typeof initialValues>): JSX.Element => {
    updateParentFormResetRef(() => {
      setHasHome(false)
      formikProps.resetForm()
    })

    return (
      <Form onSubmit={formikProps.handleSubmit}>
        <Section>
          <TextInput
            label='Lot #'
            className='col-span-6 sm:col-span-3'
            name={namespace('displayId')}
          />

          <TextInput
            label='Base rate'
            help='The standard monthly rent for this asset.'
            placeholder='50.00'
            className='col-span-6 sm:col-span-3'
            name={namespace('baseRate')}
          />
        </Section>

        <AddressForm
          subtitle='Use the physical address of the lot when possible. Otherwise, use the address where lot tenants can receive mail.'
          namespace={namespaces.address}
          visible
        />

        <Section gridCols={0}>
          <FieldSet title='Options'>
            <Checkbox
              label='Include home'
              help='This lot has a park-owned home on it.'
              name='hasHome'
              onChange={evt => setHasHome(evt.target.checked)}
              checked={hasHome}
            />
          </FieldSet>
        </Section>

        <HomeAssetForm
          namespace={namespaces.home}
          visible={hasHome}
          propertyId={propertyId as string}
        />

        <Submit />
      </Form>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={schema.validate}
      onSubmit={onSubmit}
    >
      {renderForm}
    </Formik>
  )
}
