import { ReactElement } from 'react'
import { NotificationProps } from '../components/elements/Notification'

const ref = Symbol()
const didInit = Symbol()
const init = Symbol()

interface NotificationTemplateOptions {
  title?: string|ReactElement
  message: string|ReactElement
}

class Notifications {
  private [ref]: any = null
  private [didInit]: boolean = false

  private [init] = () => {
    window.addEventListener('unhandledrejection', (evt) => {
      console.warn('UNHANDLED PROMISE REJECTION', evt)
      this.post({
        type: 'error',
        title: 'Something went wrong',
        message: 'Sorry about that, the error has been recorded.'
      })
    })
  }

  public captureRef = (_ref: any) => {
    this[ref] = _ref

    if (this[didInit] === false) {
      this[init]()
    }
  }

  public post = (notification: NotificationProps): void => {
    if (this[ref] === null) {
      console.warn('notifications: cannot post until control ref is passed')
      return
    }

    this[ref].post(notification)
  }

  public info = (options: NotificationTemplateOptions): void => {
    const {
      title = 'Heads up!',
      message
    } = options

    this.post({
      type: 'info',
      title,
      message
    })
  }

  public success = (options: NotificationTemplateOptions): void => {
    const {
      title = 'Success!',
      message
    } = options

    this.post({
      type: 'success',
      title,
      message
    })
  }

  public warn = (options: NotificationTemplateOptions): void => {
    const {
      title = 'Hmm, that didn\'t work.',
      message
    } = options

    this.post({
      type: 'warning',
      title,
      message
    })
  }

  public error = (options: NotificationTemplateOptions): void => {
    const {
      title = 'Hmm, something went wrong.',
      message
    } = options

    this.post({
      type: 'error',
      title,
      message
    })
  }
}

const notifications = new Notifications()

export default notifications
