import { ExtendedAddress } from '@shared/schemas/address'
import { ExtendedAsset } from '@shared/schemas/asset'
import Page from 'components/app/Page'
import Shell from 'components/app/Shell'
import MultiContext from 'components/utils/MultiContext'
import { AssetContext } from 'contexts/asset'
import { PropertyContext } from 'contexts/property'
import { useContext } from 'react'
import { LoaderFunction, Outlet, RouteObject, useLoaderData, useRouteLoaderData } from 'react-router-dom'
import { getAddress } from 'stores/address'
import { getAsset } from 'stores/asset'
import { assetTypeToReadable } from 'utils/assetTypes'
import getParams from 'utils/getParams'
import AssetHeader from './_header'
import AssetNav from './_nav'

type LoaderData = {
  asset: ExtendedAsset
  address: ExtendedAddress | null
}

const AssetPageRootComponent: React.FC = () => {
  const { asset } = useLoaderData() as LoaderData
  const property = useContext(PropertyContext)

  const breadcrumbs = () => {
    const base = {
      label: property.displayName,
      href: `/p/${property.id}`
    }

    const assets = {
      label: assetTypeToReadable(asset.type, true),
      href: `/p/${property.id}/assets/${asset.type}`
    }

    const thisAsset = {
      label: asset.displayId || 'Mobile Home',
      href: `/p/${property.id}/asset/${asset.id}`
    }

    return [
      base,
      assets,
      thisAsset
    ]
  }

  const contexts = [
    { context: AssetContext, value: asset  }
  ]

  return (
    <MultiContext contexts={contexts}>
      <Shell active={asset.type.toUpperCase()} breadcrumbs={breadcrumbs()}>
        <AssetHeader />
        <Page>
          <div className='grid grid-cols-3 gap-6'>
            <div className='col-span-2'>
              <Outlet />
            </div>
            <div className='col-span-1'>
              <AssetNav />
            </div>
          </div>
        </Page>
      </Shell>
    </MultiContext>
  )
}

const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  // TODO: if asset is not found, render 404
  const { assetId } = getParams(args)
  const asset = await getAsset({ assetId })

  if (asset && asset.addressId) {
    const address = await getAddress({ id: asset.addressId })
    return { asset, address }
  }

  return { asset, address: null }
}


const route: RouteObject = {
  id: 'asset/assetId',
  path: 'asset/:assetId',
  element: <AssetPageRootComponent />,
  loader
}

export const useAsset = (): LoaderData => {
  const { asset, address } = useRouteLoaderData(route.id as string) as LoaderData
  return { asset, address}
}


export default route
