import React, { InputHTMLAttributes, ReactElement } from 'react'
import classNames from 'classnames'
import { kebabCase, omit } from 'lodash'
import { useField } from 'formik'

interface BaseProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  help?: string|ReactElement
  fieldsetIndex?: number
}

interface BasePropsFormik extends BaseProps {
  name: string
  value: string
}

type AllProps = BaseProps & {
  formik?: any
}

function StandardRadioInput(props: AllProps): JSX.Element {
  const {
    fieldsetIndex = 0,
    name,
    label,
    help,
    value,
    formik = []
  } = props

  const [
    field = {},
    meta = null
  ] = formik
  
  const hasError = meta !== null && !!meta.error
  const checked = meta !== null 
    ? meta.value === value
    : props.checked

  const classes = classNames(
    'flex items-center select-none',
    { 'mt-4': fieldsetIndex !== 0 }
  )

  const inputId = kebabCase(`ipr-${fieldsetIndex}-${name}-${label}`)
  const inputSpread = {
    ...field,
    ...omit(props, [
      'label',
      'fieldsetIndex',
      'help'
    ]),
    type: 'radio',
    id: inputId,
    checked
  }

  return (
    <>
      {
        hasError && fieldsetIndex === 0
        ? <div className='mb-4'>
            <p className='text-sm text-red-600'>{meta.error}</p>
          </div>
        : null
      }
      <div className={classes}>
        <input
          {...inputSpread}
          className='form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out'
        />
        <label className='ml-3 text-sm leading-5 cursor-pointer' htmlFor={inputId}>
          <span className='block font-medium text-gray-700'>
            {label}
          </span>
          { help !== null ? <p className='text-gray-500'>{help}</p> : null }
        </label>
      </div>
    </>
  )
}

function FormikRadioInput(props: BasePropsFormik): JSX.Element {
  const formik = useField(props)
  return <StandardRadioInput {...props} formik={formik} />
}

export default function Radio(props: BaseProps): JSX.Element {
  if (typeof props.name !== 'undefined' && props.name !== '') {
    return <FormikRadioInput {...props as BasePropsFormik} />
  }

  return <StandardRadioInput {...props} />
}
