import { ExtendedAsset } from '@shared/schemas/asset'
import Button from 'components/elements/Button'
import { Card, CardBody, CardHeader } from 'components/elements/Card'
import Link from 'components/elements/Link'
import DeleteModal from 'components/elements/modals/DeleteModal'
import TextInputModal from 'components/elements/modals/TextInputModal'
import Table from 'components/elements/Table'
import { keyBy } from 'lodash'
import { postApi } from 'modules/api'
import notifications from 'modules/notifications'
import progress from 'modules/progress'
import { ReactElement, useState } from 'react'
import { BiSubdirectoryRight } from 'react-icons/bi'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { VscTypeHierarchySub } from 'react-icons/vsc'
import { LoaderFunction, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import { getAsset, getAssetHierarchy } from 'stores/asset'
import { assetTypeToReadable } from 'utils/assetTypes'
import getParams from 'utils/getParams'
import { useAsset } from './_root'

type LoaderData = {
  assets: ExtendedAsset[]
  paths: string[]
  root: string
}

const MODALS = Object.freeze({
  REMOVE_PARENT: 'REMOVE_PARENT',
  ADD_CHILD: 'ADD_CHILD',
  NONE: 'NONE'
})

const BLANK_TARGET = Object.freeze({
  assetId: '',
  parentAssetId: ''
})

const AssetHierarchyPage: React.FC = () => {
  const [ targetRelationship, setTargetRelationShip ] = useState<{
    assetId: string
    parentAssetId: string|null
  }>(BLANK_TARGET)
  const [ visibleModal, setVisibleModal ] = useState<keyof typeof MODALS>(MODALS.NONE)
  const [ isLoading, setIsLoading ] = useState(false) 
  const { asset } = useAsset()
  const { assets, paths, root } = useLoaderData() as LoaderData
  const navigate = useNavigate()

  const assetsById = {
    ...keyBy(assets, 'id'),
    ...{ [asset.id]: asset }
  }

  const updateRelationship = async (): Promise<void> => {
    const done = (): void => {
      setIsLoading(false)
      setVisibleModal(MODALS.NONE)
      setTargetRelationShip(BLANK_TARGET)
      progress.done('update relationship')
    }

    setIsLoading(true)
    progress.start('update relationship')

    try {
      const result = await postApi('/UpdateAssetHierarchy',
        { parentAssetId: targetRelationship.parentAssetId },
        { assetId: targetRelationship.assetId }
      )

      if (result._.statusCode !== 200) {
        throw Error(result.message)
      }
    } catch (e: any) {
      console.error(e)
      notifications.error({ message: e.message })
      return done()
    }


    done()
    navigate(0)
  }

  const renderUpdateModal = (): ReactElement => {
    const onChange = (text: string) => setTargetRelationShip({
      assetId: text,
      parentAssetId: targetRelationship.parentAssetId
    })

    const renderMessage = (): ReactElement => {
      if (!assetsById.hasOwnProperty(targetRelationship.parentAssetId || '')) {
        return <></>
      }

      const asset = assetsById[targetRelationship.parentAssetId as string]

      return (
        <span>
          Provide an asset ID, and it will be linked under
          <span className='font-medium mx-1'>{assetTypeToReadable(asset.type)} {asset.displayId}</span>
          in the hierarchy.
        </span>
      )
    }

    return <TextInputModal
      visible={visibleModal === MODALS.ADD_CHILD}
      isLoading={isLoading}
      action={updateRelationship}
      close={() => setVisibleModal(MODALS.NONE)}
      inputValue={targetRelationship.assetId}
      inputOnChange={onChange}
      icon={<VscTypeHierarchySub className='text-white' />}
      title='Add child asset'
      message={renderMessage()}
      placeholder='Asset ID'
      label='Link'
    />
  }

  const renderRemoveModal = (): ReactElement => {
    let message: any = ''

    /**
     * We are rendering this modal on the page regardless of its display state,
     * so we need to make sure that the message is only rendered if the assets 
     * needed are in the asset cache on this page, or we will get an error
     * (for example, while we're putting in an asset ID in the add child modal)
     */
    if (assetsById.hasOwnProperty(targetRelationship.assetId)) {
      const asset = assetsById[targetRelationship.assetId]

      if (assetsById.hasOwnProperty(asset.parentAssetId || '')) {
        const parentAsset = assetsById[asset.parentAssetId as string]
        message = (
          <span>
            <span className='font-medium'>{assetTypeToReadable(asset.type)} {asset.displayId}</span>
            <span className='mx-1'>will no longer be a child of</span>
            <span className='font-medium'>{assetTypeToReadable(parentAsset.type)} {parentAsset.displayId}.</span>
          </span>
        )
      }
    }

    const spreadProps: any = {
      label: 'Unlink',
      title: 'Unlink parent?',
      message,
      isLoading,
      visible: visibleModal === MODALS.REMOVE_PARENT,
      action: updateRelationship,
      close: () => setVisibleModal(MODALS.NONE)
    }

    return <DeleteModal {...spreadProps} />
  }

  const renderTable = (): ReactElement => {
    const columns = [
      { key: 'name', label: 'Asset', type: 'primary' },
      { key: 'controls', label: '', rowClasses: 'w-20'  }
    ]
  
    const rows = paths.map(path => {
      const assetIds = path.split('.')
      const depth = assetIds.length - 1

      const [ assetId ] = assetIds.slice(-1)
      const { id, type, displayId } =  assetsById[assetId]
      const displayName = `${assetTypeToReadable(type)} ${displayId}`

      const isCurrentAsset = assetId === asset.id // Are we on this asset's page?
      const isRoot = assetId === root

      const paddingLeft = depth > 1
        ? (depth - 1) * 20
        : 0

      const icon = depth > 0
        ? <BiSubdirectoryRight style={{marginRight: '10px'}}/>
        : <></>

      const styles = {
        paddingLeft
      }

      const name = (
        <span style={styles} className='flex items-center'>
          {icon}
          <Link to={`/asset/${id}`} relative='property'>
            {displayName}
          </Link>
          {
            isCurrentAsset
            ? <span className='font-medium text-gray-400 ml-1'>(current asset)</span>
            : <></>
          }
        </span>
      )

      const removeParent = (): void => {
        setTargetRelationShip({
          assetId: id,
          parentAssetId: null
        })

        setVisibleModal(MODALS.REMOVE_PARENT)
      }

      const addChild = (): void => {
        setTargetRelationShip({
          assetId: '',
          parentAssetId: id
        })

        setVisibleModal(MODALS.ADD_CHILD)
      }

      const controls = (
        <span className='flex items-center justify-end'>
          {// you cannot remove parents from root assets
            !isRoot
            ? <Button
                onClick={removeParent}
                size='small'
                type='danger'
                className='mr-1'
              >
                <FaMinus />
              </Button>
            : <></>
          }
          <Button
            onClick={addChild}
            size='small'
            type='primary'
          >
            <FaPlus />
          </Button>
        </span>
      )

      return {
        name,
        controls
      }
    })

    return (
      <Card>
        <CardHeader title='Asset Hierarchy' />
        <CardBody type='table'>
          <Table inline columns={columns} rows={rows} />
        </CardBody>
      </Card>
    )
  }


  return (
    <>
      {renderUpdateModal()}
      {renderRemoveModal()}
      {renderTable()}
    </>
  )
}

const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { assetId } = getParams(args)
  const { root, members, paths } = await getAssetHierarchy({ assetId })

  const apiCalls = members
    .filter(memberId => memberId !== assetId)
    .map(memberId => getAsset({ assetId: memberId }))

  const assets = await Promise.all(apiCalls)

  return {
    assets,
    paths,
    root
  }
}

const route: RouteObject = {
  path: 'hierarchy',
  element: <AssetHierarchyPage />,
  loader
}

export default route
