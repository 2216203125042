import Index from '.'
import Root from './_root'
import Edit from './edit'
import Hierarchy from './hierarchy'
import Leases from './leases'

Root.children = [
  Index,
  Hierarchy,
  Leases,
  Edit
]

export default Root
