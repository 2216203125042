import { ReactElement, useState } from 'react'
import CenteredWideButtonsModal from './CenteredWideButtonsModal'
import { FaExclamationTriangle } from 'react-icons/fa'
import { buttonStyles } from '../Button'
import TextInput from '../forms/inputs/Text'

interface Props {
  visible: boolean
  action: any
  close: any
  isDeleting?: boolean
  label?: string
  title?: string
  message?: ReactElement|string
  confirmMessage?: string
  confirmStatement?: string
}

/**
 * This is just a small utility wrapper to make delete confirmation modals
 * take less markup
 */
export default function DeleteModal(props: Props): JSX.Element {
  const {
    isDeleting = false,
    visible,
    action,
    close,
    confirmMessage = '',
    confirmStatement = '',
    label = 'Delete',
    title = 'Are you sure?',
    message = 'This cannot be undone.'
  } = props

  const [ typedStatement, setTypedStatement ] = useState('')

  const cancel = {
    label: 'Cancel',
    action: close,
    className: buttonStyles({ color: 'white', disabled: isDeleting }),
    disabled: isDeleting
  }

  const confirm = {
    label: isDeleting ? 'Please wait...' : label,
    action,
    className: buttonStyles({ color: 'red', disabled: isDeleting }),
    disabled: isDeleting
  }

  const requiresConfirmation = confirmStatement !== ''
  const confirmMatches = typedStatement === confirmStatement

  if (requiresConfirmation && !confirmMatches) {
    confirm.disabled = true
    confirm.className = buttonStyles({ color: 'red', disabled: true })
  }

  const buttons = { cancel, confirm }

  const renderMessage = (): JSX.Element => {
    if (confirmStatement !== '') {
      return (
        <div className='-mb-3'>
          <p className='text-sm leading-5 text-gray-500'>{message}</p>
          <div className='w-full mt-2'>
            <p className='text-xs leading-5 text-gray-500'>
              {
                confirmMessage !== ''
                ? confirmMessage
                : <>Please type <span className='font-medium'>{confirmStatement}</span> to continue.</>
              }
            </p>
            <TextInput
              className='w-full mt-1'
              placeholder={confirmStatement}
              value={typedStatement}
              onChange={(evt) => setTypedStatement(evt.target.value)}
            />
          </div>
        </div>
      )
    }

    return (
      <p className='text-sm leading-5 text-gray-500'>{message}</p>
    )
  }

  return (
    <CenteredWideButtonsModal
      visible={visible}
      icon={<FaExclamationTriangle className='text-red-600' />}
      iconBg='bg-red-100'
      title={title}
      message={renderMessage()}
      buttons={buttons}
    />
  )
}
