import { PlusCircleIcon } from '@heroicons/react/24/solid'
import HeaderAvatar from 'components/app/HeaderAvatar'
import Page from 'components/app/Page'
import Shell from 'components/app/Shell'
import Address from 'components/elements/Address'
import Button from 'components/elements/Button'
import Link from 'components/elements/Link'
import { ReactElement } from 'react'
import { FaBuilding } from 'react-icons/fa'
import { IoCog, IoHome, IoLocation, IoPeople } from 'react-icons/io5'
import { LoaderFunction, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import { getProperties, PropertyWithMetadata } from 'stores/property'

type LoaderData = {
  properties: PropertyWithMetadata[]
}

const loader: LoaderFunction = async (): Promise<LoaderData> => {
  const properties = await getProperties()
  return { properties }
}

const GlobalDashboardPageElement = (): ReactElement => {
  const { properties } = useLoaderData() as LoaderData
  const navigate = useNavigate()
  
  const renderProperty = (property: PropertyWithMetadata): JSX.Element => {
    const { id, displayName, address, metadata } = property
    const { assets: numAssets, people: numPeople } = metadata
    const status: string = 'Active'

    return (
      <li key={id}>
          <div className='px-4 py-4 sm:px-6'>
            <div className='flex items-center'>
              <Link to={`/p/${id}`} className='flex-1'>
                <div className='flex-1'>
                  <div className='flex items-center justify-between'>
                    <p className='text-sm font-medium text-indigo-600 truncate'>
                      {displayName}
                    </p>
                    <div className='ml-2 flex-shrink-0 flex'>
                      <p className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800'>
                        {status}
                      </p>
                    </div>
                  </div>
                  <div className='mt-1 sm:flex sm:justify-between'>
                    {/* left side */}
                    <div className='sm:flex'>
                      <p className='flex items-center text-sm text-gray-500'>
                        <IoLocation size={18} className='mr-1' />
                        <Address address={address} type='one-line' />
                      </p>

                      <p className='mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6'>
                        <IoPeople size={18} className='mr-1' />
                        {numPeople} people
                      </p>
                    </div>
                    {/* right side */}
                    <div className='mt-2 flex items-center text-sm text-gray-500 sm:mt-0'>
                      <IoHome size={15} className='mr-1' />
                      <p>{numAssets} assets</p>
                    </div>
                  </div>
                </div>
              </Link>
              <div className='pl-4 flex items-center'>
                <Link to={`/p/${property.id}/settings`}>
                  <IoCog size={22} />
                </Link>
              </div>
            </div>
          </div>
      </li>
    )
  }

  const renderProperties = (properties: PropertyWithMetadata[]): JSX.Element => {
    return (
      <div className='bg-white shadow overflow-hidden sm:rounded-md'>
        <ul className='divide-y divide-gray-200'>
          {properties.map(renderProperty)}
        </ul>
      </div>
    )
  }

  const headerActions = [
    <Button type='secondary' onClick={() => navigate('properties/create')}>
      <span>Property</span><PlusCircleIcon className='w-4 ml-2' />
    </Button>
  ]

  const breadcrumbs = [
    { label: 'Dashboard', href: '/' }
  ]

  return (
    <Shell active='DASHBOARD' hideSearch breadcrumbs={breadcrumbs}>
      <HeaderAvatar
        title='Properties'
        subtitle={`${properties.length} properties`}
        avatarIcon={<FaBuilding />}
        actions={headerActions}
      />
      <Page>
        {renderProperties(properties)}
      </Page>
    </Shell>
  )
}

const DashboardPage: RouteObject = {
  path: '',
  loader: loader,
  element: <GlobalDashboardPageElement />
}

export default DashboardPage
