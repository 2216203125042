import { CheckIcon, PlusCircleIcon } from '@heroicons/react/24/solid'
import { ExtendedAddress } from '@shared/schemas/address'
import { AssetTypes, ExtendedAsset } from '@shared/schemas/asset'
import { PersonStub } from '@shared/schemas/person'
import HeaderAvatar from 'components/app/HeaderAvatar'
import { Card, CardBody, CardFooter } from 'components/elements/Card'
import Dropdown from 'components/elements/Dropdown'
import Empty from 'components/elements/Empty'
import Pagination from 'components/elements/Pagination'
import { keyBy } from 'lodash'
import React, { Fragment, ReactElement } from 'react'
import { LoaderFunctionArgs, RouteObject, useLoaderData, useNavigate, useParams } from 'react-router-dom'
import { getAddresses } from 'stores/address'
import { ListAssetOptions, listAssets } from 'stores/asset'
import { getPeopleByAssetIds } from 'stores/person'
import getParams from 'utils/getParams'
import { getSearchParams } from 'utils/getQuery'
import PageUtils from 'utils/pages'
import Page from '../../components/app/Page'
import Shell from '../../components/app/Shell'
import Badge from '../../components/elements/Badge'
import Button from '../../components/elements/Button'
import Table from '../../components/elements/Table'
import { assetTypeIcon, assetTypeToReadable } from '../../utils/assetTypes'
import { useProperty } from './_root'

interface LoaderData {
  assets: ExtendedAsset[]
  people: PersonStub[]
  page: PageUtils
  filters: {
    vacant: boolean|null
  }
  addressesById: Record<string, ExtendedAddress|null>
}

const AssetsPageComponent = (): ReactElement => {
  const { assetType = 'mhp_home' } = useParams<{ assetType: AssetTypes }>()
  const property = useProperty()
  const { assets, people, page, filters, addressesById } = useLoaderData() as LoaderData
  const navigate = useNavigate()


  const peopleByAssetId: Record<string, PersonStub[]> = {}

  const title = assetTypeToReadable(assetType, true)
  const subtitle = page.count() !== 0
    ? `Viewing ${page.range()} of ${page.count()} ${title.toLowerCase()}.`
    : 'Nothing here yet.'

  const renderPeople = (people: any): string => people
    .map((p: any) => `${p.firstName} ${p.lastName}`)
    .join(', ')

  const renderEmptyText = (text: string): JSX.Element => {
    return (
      <span className='text-gray-200 italic'>{text}</span>
    )
  }
  
  for (const person of people) {
    const { assetIds = [] } = person
    for (const id of assetIds) {
      if (!peopleByAssetId.hasOwnProperty(id)) peopleByAssetId[id] = []
      peopleByAssetId[id].push(person)
    }
  }

  const addAction = () =>  navigate(`/p/${property.id}/assets/create?type=${assetType}`)

  const renderFilterMenu = (): ReactElement => {
    const { vacant = null } = filters

    const options: Record<string, string> = {
      'null': 'All',
      'true': 'Vacant',
      'false': 'Occupied'
    }

    const items = Object.entries(options).map(([key, label]) => {
      return {
        label,
        icon: key === String(vacant) ? <CheckIcon /> : <Fragment />,
        action: () => {
          if (key === 'null') {
            return navigate(`/p/${property.id}/assets/${assetType}`)
          }
          
          return navigate(`/p/${property.id}/assets/${assetType}?vacant=${key}`)
        }
      }
    })

    const label = options[String(vacant)]

    return <Dropdown menu={[ items ]} label={label} />
  }

  const headerActions = [
    renderFilterMenu(),
    <Button type='secondary' onClick={addAction}>
      <span>{assetTypeToReadable(assetType)}</span><PlusCircleIcon className='w-4 ml-2' />
    </Button>
  ]

  const renderHomes = (): JSX.Element => {
    const columns = [
      { key: 'unit', label: 'Unit', type: 'primary' },
      { key: 'yearmakemodel', label: '' },
      { key: 'people', label: 'People' },
      { key: 'sqft', label: 'Sq Ft' },
      { key: 'bedrooms', label: 'Bedrooms' },
      { key: 'bathrooms', label: 'Bathrooms' }
    ]

    const rows = assets.map((asset: ExtendedAsset) => {
      const {
        id,
        attributes = {}
      } = asset

      const people = peopleByAssetId[id]  || []
        
      const yearmakemodel = attributes && [
        attributes['mfr_year'],
        attributes['mfr_make'],
        attributes['mfr_model']
      ].join(' ')

      const sqft = attributes && attributes['sq_ft']
      const bedrooms = attributes && attributes['bedrooms']
      const bathrooms = attributes && attributes['bathrooms']

      const config = {
        onClick: () => navigate(`/p/${property.id}/asset/${id}`)
      }

      return {
        unit: asset.displayId ? asset.displayId : <span className='text-gray-400 font-normal'>N/A</span>,
        yearmakemodel,
        people: renderPeople(people) || renderEmptyText('Empty'),
        sqft,
        bedrooms,
        bathrooms,
        config
      }
      
    })

    return (
      <Table columns={columns} rows={rows} inline />
    )
  }

  const renderApartments = (): ReactElement => {
    const columns = [
      { key: 'unit', label: 'Unit', type: 'primary' },
      { key: 'people', label: 'People' },
      { key: 'bedbath', label: 'Beds / Baths' },
      { key: 'sqft', label: 'Sq Ft' },
      { key: 'status', label: '', rowClasses: 'text-right' }
    ]

    const rows = assets.map((asset: ExtendedAsset) => {
      const {
        id,
        attributes = {}
      } = asset

      const people = peopleByAssetId[id]  || []
      const sqft = attributes && attributes['sq_ft']
      const bedrooms = attributes && attributes['bedrooms']
      const bathrooms = attributes && attributes['bathrooms']

      const config = {
        onClick: () => navigate(`/p/${property.id}/asset/${id}`)
      }

      const badge = people.length > 0
        ? <Badge label='Occupied' color='green' />
        : <Badge label='Vacant' color='yellow' />

      const renderBedBath = (): ReactElement => {
        if (bedrooms && bathrooms) {
          return (<span>{bedrooms} bd / {bathrooms} ba</span>)
        }

        if (bedrooms) return (<span>{bedrooms} bd</span>)
        if (bathrooms) return (<span>{bathrooms} ba</span>)
        
        return renderEmptyText('Unknown')
      }
      
      return {
        unit: asset.displayId,
        bedbath: renderBedBath(),
        sqft: sqft ? sqft : renderEmptyText('Unknown'),
        people: renderPeople(people) || renderEmptyText('Empty'),
        status: badge,
        config
      }
    })

    return (
      <Table columns={columns} rows={rows} inline />
    )
  }

  const renderAllOtherAssets = (): JSX.Element => {
    const columns = [
      { key: 'unit', label: 'Unit', type: 'primary' },
      { key: 'address', label: 'Address' },
      { key: 'people', label: 'People' },
      { key: 'status', label: '', rowClasses: 'text-right' }
    ]

    const rows = assets.map((asset: ExtendedAsset) => {
      const {
        addressId,
        id,
        propertyId
      } = asset

      const people = peopleByAssetId.hasOwnProperty(id)
        ? peopleByAssetId[id]
        : []

      const address = !!addressId && addressesById.hasOwnProperty(addressId)
        ? addressesById[addressId]
        : null

      const renderAddress = address ? `${address.street1} ${address.street2}` : ''

      const badge = people.length > 0
        ? <Badge label='Occupied' color='green' />
        : <Badge label='Vacant' color='yellow' />

      const config = {
        onClick: () => navigate(`/p/${propertyId}/asset/${id}`)
      }

      return {
        unit: asset.displayId,
        address: renderAddress,
        people: renderPeople(people),
        status: badge,
        config
      }
    })

    return (
      <Table columns={columns} rows={rows} inline />
    )
  }

  const renderPages = (): JSX.Element => {
    if (page.last() === 1) {
      // There is only a single page
      return <React.Fragment />
    }

    return (
      <CardFooter>
        <Pagination
          page={page}
          getPageHref={page => `/p/${property.id}/assets/${assetType}?p=${page}`}
        />
      </CardFooter>
    )
  }
  
  const pageDecorator = (table: JSX.Element): JSX.Element => {
    if (assets.length === 0) {
      return (
        <Card>
          <CardBody>
            <Empty
              icon={assetTypeIcon(assetType)}
              message={`Hmm, there aren't any ${assetTypeToReadable(assetType, true).toLowerCase()} yet.`}
              height='200px'
            />
          </CardBody>
        </Card>
      )
    }

    return (
      <Card>
        <CardBody type='table'>
          {table}
        </CardBody>
        {renderPages()}
      </Card>
    )
  }

  const renderAssets = (): JSX.Element => {
    switch (assetType) {
      case 'mhp_home': return renderHomes()
      case 'apartment': return renderApartments()
    }

    return renderAllOtherAssets()
  }

  const crumbs = [
    { label: property.displayName, href: `/p/${property.id}` },
    { label: title, href: `/p/${property.id}/assets/${assetType}` }
  ]

  return (
    <Shell active={assetType.toUpperCase()} breadcrumbs={crumbs}>
      <HeaderAvatar
        title={title}
        subtitle={subtitle}
        avatarIcon={assetTypeIcon(assetType)}
        actions={headerActions}
      />
      <Page>
        {pageDecorator(renderAssets())}
      </Page>
    </Shell>
  )
}

const loader = async (args: LoaderFunctionArgs): Promise<LoaderData> => {
  const {
    propertyId,
    assetType,
  } = getParams(args)

  const {
    p: pageNumAsStr = '1',
    vacant: _vacant = 'null'
  } = getSearchParams(args)

  const vacant = _vacant !== 'null'
    ? Boolean(_vacant)
    : null

  const pageUtils = new PageUtils({
    currentPage: Number(pageNumAsStr)
  })

  const listAssetsQuery: ListAssetOptions = Object.assign(
    vacant !== null ? { vacant } : {},
    {
      propertyId,
      type: assetType as any,
      limit: pageUtils.limit(),
      offset: pageUtils.offset()
    }
  )
  
  const { assets, page }  = await listAssets(listAssetsQuery)
  const assetIds: string[] = assets.map(asset => asset.id)
  const people = await getPeopleByAssetIds({ assetIds })

  const addressIds = assets
    .map(asset => asset.addressId)
    .filter(address => !!address) as string[]

  const addresses = await getAddresses({ ids: addressIds })
  const addressesById = keyBy(addresses, 'id')

  pageUtils.count(page.count)

  return {
    assets,
    addressesById,
    people,
    page: pageUtils,
    filters: {
      vacant
    }
  }
}

const route: RouteObject = {
  path: 'assets/:assetType',
  element: <AssetsPageComponent />,
  loader
}

export default route
