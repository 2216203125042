import HeaderAvatar from 'components/app/HeaderAvatar'
import { ReactElement } from 'react'
import { FaMoneyCheckAlt } from 'react-icons/fa'

interface Props {
  title?: string
  subtitle?: string
  actions?: ReactElement[]
}

export default function LeaseHeader(props: Props): JSX.Element {
  const {
    title = '',
    subtitle = '',
    actions = []
  } = props

  const headerProps: any = {
    title,
    subtitle,
    actions,
    avatarIcon: <FaMoneyCheckAlt />,
  }

  return (<HeaderAvatar {...headerProps} />)
}
