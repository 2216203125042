import { get, omit, set } from 'lodash'
import { json } from 'react-router-dom'
import { z } from 'zod'
import { APICacheOptions, getApi, getApiCache, postApi } from './api'

type Z = z.Schema<any>
type Query = Record<string, any>

interface TypedAPI<T> {
  url: string
  schema: T
  query?: Query
  body?: any
  cache?: boolean|APICacheOptions
}

interface TypedAPIWithPaths<T> extends TypedAPI<T> {
  paths: string[]
}

interface TypedAPIWithPath<T> extends TypedAPI<T> {
  path: string
}

export async function getTypedApi<S extends Z>(options: TypedAPIWithPath<S>): Promise<z.infer<S>>;
export async function getTypedApi<S extends Z>(options: TypedAPIWithPaths<S>): Promise<z.infer<S>>;
export async function getTypedApi<S extends Z>(options: TypedAPI<S>): Promise<z.infer<S>>;
export async function getTypedApi<S extends Z>(options: any): Promise<z.infer<S>> {
  const {
    url,
    schema,
    path = '',
    paths = [],
    query = {},
    body = null,
    cache = false
  } = options

  const cacheOptions: APICacheOptions = typeof cache === 'boolean'
    ? {} // true or false, use defaults
    : cache // otherwise, use provided options

  const bothPathAndPaths = path !== '' && paths.length > 0
  const eitherPathAndPaths = path !== '' || paths.length > 0

  if (bothPathAndPaths) {
    throw Error('cannot specify both path and paths, pick one')
  }

  // Send a post if we are given a body
  const request = body !== null
    ? await postApi(url, body, query)
    : cache === false
      ? await getApi(url, query)
      : await getApiCache(url, query, cacheOptions)

  const picked = path !== ''
    ? get(request, path)
    : {}

  if (paths.length > 0) {
    for (const path of paths) {
      const ref = get(request, path, null)
      set(picked, path, ref)
    }
  }

  const bodyToBeChecked = eitherPathAndPaths === false
    ? omit(request, ['_'])
    : picked

  // If neither path or paths is set, then just parse the entire request
  try {
    const checked = schema.parse(bodyToBeChecked)
    
    return checked
  } catch (e: any) {
    throw json(
      { message: e.message },
      { status: request._.statusCode }
    )
    
    /**
    enhancedError.message = [
      `Typed API response validation error:`,
      `Status: ${request._.statusCode}`,
      `URL: ${url}`,
      `Path: ${path}`,
      `Query: ${JSON.stringify(query)}`,
      `Body: ${JSON.stringify(body)}`,
      `Response:`,
      JSON.stringify(request),
      `Error: ${enhancedError.message}`
    ].join('\n')

    throw enhancedError
    */
  }
}
