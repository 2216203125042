import schemas from '@shared/schemas'
import { ExtendedLease, LeaseActivity, LeaseMetadata, LineItem, States } from '@shared/schemas/lease'
import { getTypedApi } from 'modules/typedApi'
import * as z from 'zod'

interface StandardLeaseOptions {
  status?: States|'any'
}

interface GetLeaseOptions {
  leaseId: string
}

export async function getLease(options: GetLeaseOptions): Promise<ExtendedLease> {
  const { leaseId } = options

  return await getTypedApi({
    url: '/GetLease',
    query: { leaseId },
    schema: schemas.lease.extendedLease,
    path: 'lease'
  })
}

export async function getLeaseWithLineItems(options: GetLeaseOptions): Promise<{
  lease: ExtendedLease
  lineItems: LineItem[]
}> {
  const { leaseId } = options

  return await getTypedApi({
    url: '/GetLease',
    query: { leaseId },
    schema: z.object({ 
      lease: schemas.lease.extendedLease,
      lineItems: schemas.lease.lineItems
    })
  })
}

interface GetAssetLeasesOptions extends StandardLeaseOptions {
  assetId: string
}

export async function getAssetLeases(
  options: GetAssetLeasesOptions
): Promise<ExtendedLease[]> {
  const { assetId, status = 'any' } = options

  return await getTypedApi({
    url: '/GetAssetLeases',
    schema: schemas.lease.extendedLeases,
    path: 'leases',
    query: { assetId, status }
  })
}

export async function getCurrentLease(assetId: string): Promise<ExtendedLease|null> {
  const schema = z.union([
    schemas.lease.extendedLease,
    z.null()
  ])

  return await getTypedApi({
    url: '/GetAssetCurrentLease',
    schema,
    path: 'lease',
    query: { assetId }
  })
}

interface GetPersonLeasesOptions extends StandardLeaseOptions {
  personId: string
}

export async function getPersonLeases(
  options: GetPersonLeasesOptions
): Promise<ExtendedLease[]> {
  const { personId, status = 'any' } = options

  return await getTypedApi({
    url: '/GetPersonLeases',
    schema: schemas.lease.extendedLeases,
    query: { personId, status },
    path: 'leases'
  })
}

export async function getLeaseMetadata(
  options: GetLeaseOptions
): Promise<LeaseMetadata> {
  const { leaseId } = options

  return await getTypedApi({
    url: '/GetLeaseMeta',
    query: { leaseId },
    schema: schemas.lease.metadata
  })
}

export async function getLeaseActivity(
  options: GetLeaseOptions
): Promise<LeaseActivity> {
  const { leaseId } = options

  return await getTypedApi({
    url: '/GetLeaseActivity',
    query: { leaseId },
    schema: schemas.lease.activity,
    path: 'activity'
  })
}

export async function getLeasesByPropertyId(
  { propertyId, status, limit, offset }:
  { propertyId: string, status?: States, limit?: number, offset?: number }
) {
  return await getTypedApi({
    url: '/GetLeasesByPropertyId',
    query: { propertyId, status, limit, offset },
    schema: z.object({
      leases: schemas.lease.extendedLeases,
      page: schemas.page.page
    })
  })
}
