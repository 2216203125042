import Activity from './activity'
import Asset from './asset/_routes'
import Assets from './assets'
import CreateAsset from './create_asset'
import CreateLease from './create_lease'
import CreatePerson from './create_person'
import Dashboard from './dashboard'
import Invoices from './invoices'
import Lease from './lease/_routes'
import Leases from './leases'
import People from './people'
import Person from './person/_routes'
import Settings from './settings'
import Root from './_root'

Root.children = [
  Dashboard,
  People,
  Person,
  Activity,
  Assets,
  CreateAsset,
  Asset,
  CreateLease,
  CreatePerson,
  Lease,
  Invoices,
  Leases,
  Settings
]

export default Root
