import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid'
import { ExtendedAddress } from '@shared/schemas/address'
import { ExtendedAsset } from '@shared/schemas/asset'
import { LeaseMetadata } from '@shared/schemas/lease'
import { ExtendedPerson } from '@shared/schemas/person'
import classNames from 'classnames'
import Address from 'components/elements/Address'
import Alert from 'components/elements/Alert'
import Badge from 'components/elements/Badge'
import Button from 'components/elements/Button'
import DescriptionList from 'components/elements/DescriptionList'
import Dropdown from 'components/elements/Dropdown'
import Link from 'components/elements/Link'
import DeleteModal from 'components/elements/modals/DeleteModal'
import Spacer from 'components/elements/Spacer'
import Stats from 'components/elements/Stats'
import Comments from 'components/modules/Comments'
import Documents from 'components/modules/Documents'
import LeaseStatusBadge from 'components/utils/LeaseStatusBadge'
import { compact } from 'lodash'
import { DateTime } from 'luxon'
import { postApi } from 'modules/api'
import notifications from 'modules/notifications'
import progress from 'modules/progress'
import { useState } from 'react'
import { FaDollarSign, FaRegClock } from 'react-icons/fa'
import { LoaderFunction, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import { getAddress } from 'stores/address'
import { getLeaseAssets } from 'stores/asset'
import { getLease, getLeaseMetadata } from 'stores/lease'
import { getLeasePeople } from 'stores/person'
import { assetTypeToReadable } from 'utils/assetTypes'
import getParams from 'utils/getParams'
import { formatMoney } from 'utils/transforms'
import { useProperty } from '../_root'
import { useLease } from './_root'
import LeasePageShell from './_shell'

type LoaderData = {
  balances: LeaseMetadata['balances']
  upcomingInvoiceId: string
  people: ExtendedPerson[]
  assets: ExtendedAsset[]
  address: ExtendedAddress | null
}

const LeaseIndexPageComponent: React.FC = () => {
  const { lease } = useLease()
  const property = useProperty()
  const {
    balances,
    people,
    assets,
    address
  } = useLoaderData() as LoaderData
  
  const navigate = useNavigate()
  const [ visibleModal, setVisibleModal ] = useState('')
  const [ isDeleting, setIsDeleting ] = useState(false)

  const deleteLease = async (): Promise<void> => {
    const done = (): void => {
      setIsDeleting(false)
      setVisibleModal('')
      progress.done('delete lease')
    }

    setIsDeleting(true)
    progress.start('delete lease')

    try {
      const result = await postApi('/DeleteLease', { leaseId: lease.id })
      if (result._.statusCode !== 200) throw new Error(result.message)
    } catch (e: any) {
      notifications.error({ message: e.message })
      return done()
    }

    done()

    // Navigate away
    navigate(`/p/${property.id}/asset/${lease.assetId}`, { replace: true }) 
  }

  const renderStats = (): JSX.Element => {
    const { balance, upcoming } = balances
    const stats = [
      {
        label: 'Balance',
        value: Number(balance) > 0
          ? formatMoney(balance)
          : <span className='text-green-500'>{formatMoney(balance, { format: 'accounting' })}</span>,
        icon: <FaDollarSign />,
        linkLabel: 'View all activity',
        href: 'activity'
      },
      {
        label: 'Upcoming Charges',
        value: formatMoney(upcoming),
        icon: <FaRegClock />,
        linkLabel: 'Peek next invoice',
        href: 'upcoming-invoice'
      }
    ]

    return (
      <Stats data={stats} />
    )
  }

  const renderPerson = (person: any, index: number): JSX.Element => {
    const {
      id,
      firstName,
      lastName
    } = person

    return (
      <div key={id} className={index !== 0 ? 'mt-1' : ''}>
        <Link
          to={`/person/${id}`}
          relative='property'
        >{firstName} {lastName}</Link>
      </div>
    )
  }

  const renderAsset = (asset: ExtendedAsset, index: number): JSX.Element => {
    const to = `/property/${property.id}/asset/${asset.id}`
    const label = compact([
      assetTypeToReadable(asset.type),
      asset.displayId
    ]).join(' ')

    const isPrimary = asset.id === lease.assetId
    const classes = classNames({
      'flex': true,
      'mt-1': index !== 0
    })

    return (
      <Link to={to} relative='property' key={index} className={classes}>
        <span className='flex-1'>{label}</span>
        {
          isPrimary
          ? <Badge label='Primary' color='gray' />
          : ''
        }
      </Link>
    )
  }

  const renderDetails = (): JSX.Element => {
    /**
     * lease.termStart and lease.termEnd are both sent down from the server in
     * UTC time. getTypedAPI converts them to a local date time object, and 
     * if we display that date, it will show offset. So, we use Luxon to 
     * display what the time/date is in UTC
     */
    const termStart = DateTime
      .fromJSDate(lease.termStart, { zone: 'UTC' })
      .toLocaleString(DateTime.DATE_SHORT)
    
      const termEnd = DateTime
      .fromJSDate(lease.termEnd, { zone: 'UTC' })
      .toLocaleString(DateTime.DATE_SHORT)

    const lastAutomaticInvoiceDueDate = lease.lastAutomaticInvoiceDue !== null
      ? DateTime
          .fromJSDate(lease.lastAutomaticInvoiceDue, { zone: 'UTC' })
          .toLocaleString(DateTime.DATE_SHORT)
      : null

    const renderStatus = () => {
      const go = (): void => navigate('update/status')

      return (
        <div className='flex flex-1 items-center'>
          <div className='flex-1'>
            <LeaseStatusBadge status={lease.status} />
          </div>
          <div className=''>
            <Button
              type='secondary'
              size='small'
              onClick={go}
            >Update</Button>
          </div>
        </div>
      )
    }

    const assetData = [
      { label: 'Status', value: renderStatus() },
      { label: 'Lessees', value: <>{people.map(renderPerson)}</> },
      { label: 'Term', value: `${termStart} - ${termEnd}` },
      { label: 'Primary Address', value: <Address address={address} type='full' /> },
      { label: 'Assets', value: <>{assets.map(renderAsset)}</> },
      { label: 'Last Automatic Invoice', value: lastAutomaticInvoiceDueDate || 'N/A' }
    ]

    return <DescriptionList title='Overview' data={assetData} />
  }

  const renderDeleteModal = (): JSX.Element => {
    const spreadProps: any = {
      isDeleting,
      visible: visibleModal === 'deleteLease',
      action: deleteLease,
      close: () => setVisibleModal('')
    }

    return <DeleteModal
      {...spreadProps}
      message='This cannot be undone. You will need to remove any activity on this lease (payments, invoices, etc) before you can delete it.'
    />
  }

  const actions = () => {
    const edit = {
      label: 'Edit lease',
      icon: <PencilIcon />,
      action: () => navigate('edit')
    }

    const remove = { 
      label: 'Delete lease',
      icon: <TrashIcon className='text-red-500' />,
      action: () => setVisibleModal('deleteLease')
    }
    
    return <Dropdown menu={[ edit, remove ]} label='Options' />
  }

  const renderStatusBanner = (): JSX.Element => {
    if (lease.status === 'expired') {
      return (
        <Alert
          display='linkOnRight'
          type='warn'
          message='This lease has expired, please update the status.'
          actions={[
            { title: 'Update', onClick: () => navigate('status') }
          ]}
        />
      )
    }
    
    return <></>
  }

  return (
    <LeasePageShell actions={[ actions() ]}>
      {renderDeleteModal()}
      <Spacer>
        {renderStats()}
        {renderStatusBanner()}
        {renderDetails()}
        <Documents targetType='lease' targetId={lease.id} />

        <Comments
          nouns={[ 'note', 'notes' ]}
          targetId={lease.id}
          targetType='lease'
        />
      </Spacer>
    </LeasePageShell>
  )
}

const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { leaseId } = getParams(args)

  // TODO: we only need this for the leased asset ID, which we can get from
  // the other calls below
  const lease = await getLease({ leaseId })

  const [
    metadata,
    people,
    assets
  ] = await Promise.all([
    getLeaseMetadata({ leaseId }),
    getLeasePeople({ leaseId }),
    getLeaseAssets({ leaseId })
  ])

  assets.sort((a, b) => {
    // Primary asset should always be first
    if (a.id === lease.assetId) return -1
    if (b.id === lease.assetId) return 1

    // Keep assets grouped by type
    if (a.type === b.type) return 0

    // Then sort by display ID
    return String(a.displayId).localeCompare(String(b.displayId))
  })

  let address = null
  const primaryAsset = assets.find((a) => a.id === lease.assetId)

  if (primaryAsset && primaryAsset.addressId) {
    address = await getAddress({ id: primaryAsset.addressId })
  }

  return {
    ...metadata,
    people,
    assets,
    address
  }
}

const route: RouteObject = {
  path: '',
  element: <LeaseIndexPageComponent />,
  loader
}

export default route
