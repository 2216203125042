import classNames from 'classnames'
import { ReactNode } from 'react'

interface CardProps {
  className?: string
  children?: ReactNode
  allowOverflow?: boolean
}

export function Card(props: CardProps): JSX.Element {
  const {
    className = '',
    children = null,
    allowOverflow = false
  } = props

  const classes = classNames({
    'bg-white shadow sm:rounded-lg': true,
    'overflow-hidden': allowOverflow === false,
    [className]: className !== ''
  })

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

interface CardHeaderProps extends CardProps {
  divider?: boolean
  title?: ReactNode
  subtitle?: ReactNode
  small?: boolean
}

export function CardHeader(props: CardHeaderProps): JSX.Element {
  const {
    divider = true,
    title = '',
    subtitle = '',
    children = null,
    small = false
  } = props

  const classes = classNames({
    'px-4 py-5 sm:px-6 flex': true,
    'border-b border-gray-200': divider
  })

  if (small) {
    const classes = classNames(
      'px-6 py-3 border-b border-gray-200 bg-gray-50 text-xs',
      'leading-4 font-medium text-gray-500 uppercase tracking-wider'
    )

    return (
      <div className={classes}>
        {title}
      </div>
    )
  }

  return (
    <div className={classes}>
      <div className='flex-1 flex flex-col justify-center'>
      {
        title !== ''
        ? <h3 className='text-lg leading-6 font-medium text-gray-900'>
            {title}
          </h3>
        : ''
      }

      {
          subtitle !== ''
          ? <p className='mt-1 max-w-2xl text-sm text-gray-500'>
              {subtitle}
            </p>
          : ''
        }
      </div>
      {children}
    </div>
  )
}

interface CardBodyProps extends CardProps {
  type?: 'table'|'padded'|'list'|'custom'
}

export function CardBody(props: CardBodyProps): JSX.Element {
  const {
    type = 'padded',
    children = null,
    className = ''
  } = props

  const tableClasses = 'overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'
  const paddedClasses = 'px-4 py-5 sm:p-6'
  const listClasses = 'px-4 py-5 sm:p-0'

  const classes = classNames(
    { [tableClasses]: type === 'table' },
    { [paddedClasses]: type === 'padded' },
    { [listClasses]: type === 'list' },
    { [className]: className !== '' }
  )

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export function CardFooter(props: CardProps): JSX.Element {
  const { children = null } = props

  return (
    <div className='bg-gray-50 px-4 py-4 sm:px-6 border-t border-gray-200'>
      {children}
    </div>
  )
}

