import * as z from 'zod'
import { zFlexNumber, zFlexBool } from './util'

export const types = z.enum([
  'string',
  'number',
  'boolean'
])

const baseAttribute = z.object({
  id: z.string(),
  name: z.string().optional().nullable()
})

export const stringAttribute = baseAttribute.merge(z.object({
  type: z.enum([ 'string' ]),
  value: z.string().min(1, { message: 'cannot be blank' }),
}))

export const numberAttribute = baseAttribute.merge(z.object({
  type: z.enum([ 'number' ]),
  value: zFlexNumber()
}))

export const booleanAttribute = baseAttribute.merge(z.object({
  type: z.enum([ 'boolean' ]),
  value: zFlexBool()
}))

export const attribute = z.union([
  stringAttribute,
  numberAttribute,
  booleanAttribute
])

export const attributePreset = baseAttribute.merge(z.object({
  type: types
}))

export const attributePresets = z.array(attributePreset)

export const attributes = z.array(attribute)

export type AttributePreset = z.infer<typeof attributePreset>
export type AttributePresets = z.infer<typeof attributePresets>
export type Attribute = z.infer<typeof attribute>
export type Attributes = z.infer<typeof attributes>
