import classNames from 'classnames'
import { ReactElement } from 'react'

type ButtonSize = 'small'|'regular'

interface ButtonOptions {
  color: 'white'|'indigo'|'red',
  disabled?: boolean
  size?: ButtonSize
}

export interface ButtonProps {
  children: string|ReactElement|ReactElement[]
  type?: 'primary'|'secondary'|'danger'
  className?: string
  disabled?: boolean
  onClick?: any
  size?: ButtonSize
}


export function buttonStyles(options: ButtonOptions): string {
  const {
    color,
    size = 'regular',
    disabled = false
  } = options

  const smallSizeStyles: string = classNames(
    'px-2.5 py-1.5',
    'text-xs',
    'leading-4'
  )

  const regularSizeStyles: string = classNames(
    'py-2 px-4',
    'text-sm',
    'leading-5'
  )

  const sizeStyles = classNames({
    [smallSizeStyles]: size === 'small',
    [regularSizeStyles]: size === 'regular'
  })

  const generalStyles: string = classNames(
    'inline-flex',
    'justify-center',
    'items-center',
    'shadow-sm',
    'rounded-md',
    'border',
    'font-medium'
  )

  const transitionStyles: string = 'transition duration-150 ease-in-out'
  const disabledStyles: string = 'opacity-50 cursor-not-allowed'
  const activeStyles: string = 'transform active:scale-95'

  // Add these styles if the button is NOT disabled
  const nonDisabledStyles = classNames({
    'hover:text-gray-500': color === 'white',
    'hover:bg-indigo-500': color === 'indigo',
    'hover:bg-red-500': color === 'red'
  })

  // White uses different colors for text and focus states
  const whiteColor: string = [
    'bg-white text-gray-700 border-gray-300 focus:border-blue-300',
    'focus:shadow-outline-blue'
  ].join(' ')

  const colorSpecificStyles = classNames({
    'bg-indigo-600 focus:border-indigo-700 focus:shadow-outline-indigo': color === 'indigo',
    'bg-red-600 focus:border-red-700 focus:shadow-outline-red': color === 'red',
    'bg-white text-gray-700 border-gray-300 focus:border-blue-300 focus:shadow-outline-blue': color === 'white',
  })

  const allOtherColors: string = [
    `text-white border-transparent`,
    colorSpecificStyles
  ].join(' ')

  return classNames(
    generalStyles,
    sizeStyles,
    transitionStyles,
    {
      [whiteColor]: color === 'white',
      [allOtherColors]: color !== 'white',
      [nonDisabledStyles]: disabled === false,
      [disabledStyles]: disabled === true,
      [activeStyles]: disabled === false
    }
  )
}

export default function Button(props: ButtonProps): JSX.Element {
  const {
    children,
    type = 'primary',
    className = '',
    disabled = false,
    onClick = null,
    size = 'regular'
  } = props

  const buttonType = onClick === 'submit'
    ? 'submit'
    : 'button'

  const color = {
    primary: 'indigo',
    secondary: 'white',
    danger: 'red'
  }

  const colorStyle = buttonStyles({
    color: color[type] as any,
    disabled,
    size
  })
  
  const spreadProps: any = {}

  if (onClick !== null && disabled === false && buttonType !== 'submit') {
    spreadProps.onClick = onClick
  }

  return (
    <button
      type={buttonType}
      className={`${colorStyle} ${className}`}
      disabled={disabled}
      {...spreadProps}
    >{children}</button>
  )
}
