import classNames from 'classnames'
import React, { ReactElement } from 'react'
import { Card, CardBody, CardHeader } from './Card'

export interface DescListItem {
  label: string|ReactElement|ReactElement[]
  value: string|ReactElement|ReactElement[]
  wide?: boolean
}

interface Props {
  title?: string
  subtitle?: string
  data: DescListItem[]
  className?: string
  inline?: boolean
  twoColumn?: boolean
  smallTitle?: boolean
}

function DescriptionListTwoColumn(props: Props): JSX.Element {
  const {
    data,
    className = '',
    inline = false
  } = props

  const c = {
    dl: classNames(
      className,
      { 'p-6': inline === true },
      'grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'
    ),
    dt: 'text-sm font-medium text-gray-500',
    dd: 'mt-1 text-sm text-gray-900'
  }

  const renderItem =  (item: DescListItem, index: number): JSX.Element => {
    const {
      label,
      value,
      wide = false
    } = item

    const divClass = wide === false
      ? 'sm:col-span-1'
      : 'sm:col-span-2'

    return (
      <div className={divClass} key={index}>
        <dt className={c.dt}>{label}</dt>
        <dd className={c.dd}>{value}</dd>
      </div>
    )
  }

  return (
    <dl className={c.dl}>
      {data.map(renderItem)}
    </dl>
  )
}

function DescriptionListOneColumn(props: Props): JSX.Element {
  const { data, className = '' } = props
  const c = {
    item: 'py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 ' + className,
    dl: 'sm:divide-y sm:divide-gray-200',
    dt: 'text-sm font-medium text-gray-500',
    dd: 'mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'
  }

  const renderItem = (item: DescListItem, index: number): JSX.Element => {
    const { label, value } = item

    return (
      <div className={c.item} key={index}>
        <dt className={c.dt}>{label}</dt>
        <dd className={c.dd}>{value}</dd>
      </div>
    )
  }

  return (
    <dl className={c.dl}>
      {data.map(renderItem)}
    </dl>
  )
}

interface DLItemListItem {
  icon: ReactElement
  label: string
  action: VoidFunction
}

interface DLItemListProps {
  items: DLItemListItem[]
}

export function DLItemList(props: DLItemListProps): JSX.Element {
  const { items } = props

  const renderItem = (item: DLItemListItem, index: number) => {
    const {
      icon,
      label,
      action
    } = item

    return (
      <li
        className='pl-3 pr-4 py-3 flex items-center justify-between text-sm'
        onClick={action}
        key={index}
      >
        <div className='w-0 flex-1 flex items-center'>
          <div className='flex-shrink-0 h-5 w-5 text-gray-400 flex items-center'>
            {icon}
          </div>
          <span className='ml-2 flex-1 w-0 truncate'>
            {label}
          </span>
        </div>
        <div className='ml-4 flex-shrink-0'>
          <button type='button' className='font-medium text-blue-600 hover:text-blue-500'>
            View
          </button>
        </div>
      </li>
    )
  }

  return (
    <ul className='mt-2 border border-gray-200 rounded-md divide-y divide-gray-200'>
      {items.map(renderItem)}
    </ul>
  )
}

export default function DescriptionList(props: Props): JSX.Element {
  const {
    title = '',
    subtitle = '',
    className = '',
    inline = false,
    twoColumn = false,
    smallTitle = false
  } = props

  const component = twoColumn
    ? <DescriptionListTwoColumn {...props} />
    : <DescriptionListOneColumn {...props} />

  if (inline) {
    return component
  }

  const hasTitles: boolean = title !== '' || subtitle !== ''

  const cardBodyClasses = classNames({
    'px-4 py-5': true,
    'sm:px-6': twoColumn,
    'sm:p-0': !twoColumn
  })
  
  return (
    <Card className={className}>
      {
        hasTitles
        ? <CardHeader title={title} subtitle={subtitle} small={smallTitle} />
        : <React.Fragment />
      }
      <CardBody type='custom' className={cardBodyClasses}>
        {component}
      </CardBody>
    </Card>
  )
}
