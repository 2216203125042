import { ArrowRightCircleIcon } from '@heroicons/react/24/solid'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'

export interface StatItem {
  label: string
  value: string|ReactElement
  icon: ReactElement
  iconBg?: string
  onClick?: VoidFunction
  linkLabel?: string
  href?: string
}

interface Props {
  className?: string
  title?: string
  data: StatItem[]
}

export default function Stats(props: Props): JSX.Element {
  const {
    title = null,
    data,
    className = ''
  } = props

  const gridSize = data.length

  const renderLink = (stat: StatItem): JSX.Element => {
    const { href = '', linkLabel = '' } = stat

    return (
      <div className='bg-gray-50 px-4 py-4 sm:px-6'>
        <div className='text-sm leading-5'>
          <Link to={href} className='flex items-center justify-end font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150'>
            {linkLabel} <ArrowRightCircleIcon className='w-5 ml-1' />
          </Link>
        </div>
      </div>
    )
  }

  function renderStat(stat: StatItem): JSX.Element {
    const { label, value, icon, iconBg = 'bg-indigo-500', href = '', onClick } = stat

    return (
      <div className='bg-white overflow-hidden shadow sm:rounded-lg'  key={label}>
        <div className={`px-4 py-5 sm:p-6  ${onClick && 'cursor-pointer'}`} onClick={onClick}>
          <div className='flex items-center'>
            <div className={`flex-shrink-0 rounded-md p-3 ${iconBg}`}>
              <div className='text-white flex items-center justify-center'>
                {icon}
              </div>
            </div>
            <div className='ml-5 w-0 flex-1'>
              <dl>
                <dt className='text-sm leading-5 font-medium text-gray-500 truncate'>
                  {label}
                </dt>
                <dd className='flex items-baseline'>
                  <div className='text-2xl leading-8 font-semibold text-gray-900'>
                    {value}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>

        {
          href !== ''
          ? renderLink(stat)
          : null
        }
      </div>
    )
  }

  return (
    <div className={className}>
      {
        title !== null
        ? <h3 className='text-lg leading-6 font-medium text-gray-900'>{title}</h3>
        : null
      }
      <div className={`${title !== null ? 'mt-5' : ''} grid grid-cols-1 gap-5 sm:grid-cols-${gridSize}`}>
        {data.map(renderStat)}
      </div>
    </div>
  )
}
