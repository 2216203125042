import React, { InputHTMLAttributes } from 'react'
import { useField } from 'formik'
import Label from './Label'
import { omit } from 'lodash'
import classNames from 'classnames'

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  hint?: string
  help?: string
  name: string
  rows?: number
}

export default function TextArea(props: Props): JSX.Element {
  const [ field, meta ] = useField(props)
  
  const {
    label = '',
    hint = '',
    help = '',
    rows = 3,
    className = ''
  } = props

  const hasLabel = label !== ''
  const hasError = !!(meta.touched && meta.error)
  const error = hasError ? meta.error : ''
  const textareaStyles = classNames(
    'w-full shadow-sm block w-full sm:text-sm rounded-md leading-5',
    {
      'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300': !error,
      'order-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500': error
    }
  )

  const inputProps = omit(props, [
    'label',
    'hint',
    'help',
    'className'
  ])

  return (
    <div className={className}>
      { hasLabel ? <Label label={label} hint={hint} /> : ''}

      <div className={`${hasLabel ? 'mt-1' : ''}`}>
        <textarea
          className={textareaStyles}
          {...inputProps}
          {...field}
          rows={rows}
        />
      </div>

      {
        hasError
        ? <p className='mt-2 text-sm text-red-600'>{error}</p>
        : null
      }

      {
        help !== '' && !hasError
        ? <p className='mt-2 text-sm text-gray-500'>{help}</p>
        : null
      }
    </div>
  )
}
