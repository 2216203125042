import schemas from '@shared/schemas'
import HeaderAvatar from 'components/app/HeaderAvatar'
import Page from 'components/app/Page'
import Shell from 'components/app/Shell'
import { Form } from 'components/elements/Form'
import AddressForm from 'components/elements/forms/Address'
import PersonForm from 'components/elements/forms/Person'
import Submit from 'components/elements/forms/inputs/Submit'
import CenteredWideButtonsModal from 'components/elements/modals/CenteredWideButtonsModal'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { postApi } from 'modules/api'
import nProgress from 'nprogress'
import { useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import { IoPerson } from 'react-icons/io5'
import { RouteObject, useNavigate, useParams } from 'react-router-dom'
import createSchema from 'utils/createSchema'
import namespaces from 'utils/namespaces'

const CreatePersonPageComponent: React.FC = () => {
  const { propertyId } = useParams<any>()
  const [ hasAddress, setHasAddress ] = useState(false)
  const [ newPersonId, setNewPersonId ] = useState('')
  const [ newPersonName, setNewPersonName ] = useState('')
  const navigate = useNavigate()

  const initialValues = {
    [namespaces.person]: schemas.person.blanks.person,
    [namespaces.address]: schemas.address.blanks.address
  }

  const schema = createSchema()
    .with(namespaces.person, schemas.person.person)
    .with(namespaces.address, schemas.address.address, hasAddress)

  const onSubmit = async (values: any, frmk: FormikHelpers<any>) => {
    const done = () => {
      nProgress.done()
      frmk.setSubmitting(false)
    }

    const data = schema.filter(values)
    nProgress.start()

    
    try {
      const response = await postApi('/CreatePerson', data, {
        propertyId
      })
  
      if (response._.statusCode === 200) {
        setNewPersonId(response.personId)
        setNewPersonName(`${data.person.firstName} ${data.person.lastName}`)
      }
    } catch (e) {
      done()
      throw e
    }
    
    done()
  }

  let resetForm: null|(() => void) = null

  const renderForm = (
    formikProps: FormikProps<any>
  ): JSX.Element => {
    // Pass the reference to resetting the form up so we can use it in the modal
    resetForm = formikProps.resetForm

    return (
      <Form onSubmit={formikProps.handleSubmit}>
        <PersonForm
          title='Identity'
          subtitle='Make sure to double check that all of this information is correct.'
          namespace={namespaces.person}
          hasAddress={hasAddress}
          setHasAddress={setHasAddress}
        />

        <AddressForm
          namespace={namespaces.address}
          subtitle='Use a permanent address where this person can receive mail. '
          visible={hasAddress}
        />

        <Submit />
      </Form>
    )
  }

  const buttons = {
    confirm: {
      label: 'Go to profile',
      action: () => {
        navigate(`/p/${propertyId}/person/${newPersonId}`)
      }
    },
    cancel: {
      label: 'Add another person',
      action: () => {
        setNewPersonId('')

        // We do not reset the name here because it will cause the modal to flash.
        if (resetForm !== null) {
          resetForm()
        }
      }
    }
  }

  return (
    <Shell active='PEOPLE'>
      <CenteredWideButtonsModal
        visible={newPersonId !== ''}
        icon={<FaCheck className='text-white' />}
        iconBg='bg-green-400'
        title={`${newPersonName} added!`}
        message='Would you like to go to their profile or add another person?'
        buttons={buttons}
      />
      <HeaderAvatar
        title='Add a person'
        avatarIcon={<IoPerson />}
      />
      <Page>
        <Formik
          initialValues={initialValues}
          validate={schema.validate}
          onSubmit={onSubmit}
        >{renderForm}</Formik>
      </Page>
    </Shell>
  )
}

const route: RouteObject = {
  path: 'people/create',
  element: <CreatePersonPageComponent />
}

export default route
