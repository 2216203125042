import { useState, useEffect, useRef } from 'react'
import Notification, { NotificationProps } from './Notification'
import { TransitionGroup } from 'react-transition-group'
import NotificationTransition from './transitions/Notification'
import { v4 as uuid } from 'uuid'

interface Props {
  controlRef?: any
}

export default function Notifications(props: Props): JSX.Element {
  const { controlRef } = props
  const [ notifications, setNotifications ] = useState<NotificationProps[]>([])
  const notificationRef = useRef<NotificationProps[]>([])

  useEffect(() => {
    notificationRef.current = notifications
  }, [ notifications ])

  useEffect(() => {
    if (controlRef) controlRef({ post })
    return () => controlRef(null)
  }, [ controlRef ])

  const post = (notification: NotificationProps): void => {
    if (!notification.id) {
      notification.id = uuid()
    }

    setNotifications([
      notification,
      ...notificationRef.current
    ])
  }

  const renderNotification = (notification: NotificationProps, index: number): JSX.Element => {
    const { id,  onClose } = notification

    const handleClose = () => {
      setNotifications(notifications.filter(i => i.id !== id))
      if (onClose) onClose()
    }

    return (
      <NotificationTransition key={id}>
        <Notification
          {...notification}
          index={index}
          onClose={handleClose}
        />
      </NotificationTransition>
    )
  }

  return (
    <div className='fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end z-50'>
      <TransitionGroup className='flex flex-col items-end max-w-sm w-full'>
        {notifications.map(renderNotification)}
      </TransitionGroup>
    </div>
  )
}
