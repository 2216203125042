import { BanknotesIcon } from '@heroicons/react/24/solid'
import VerticalNav from 'components/elements/VerticalNav'
import { ReactElement } from 'react'
import { IoFileTrayFull, IoStatsChartOutline } from 'react-icons/io5'
import { useMatch } from 'react-router-dom'
import { useProperty } from '../_root'
import { useLease } from './_root'

type Props = {
  active?: string
}

export default function Nav(props: Props): ReactElement {
  const { active: specifiedActive = '' } = props
  const match = useMatch('/p/:propertyId/lease/:leaseId/:page/*')
  
  const active = match?.params?.page 
    ? [ match.params.page, match.params.page + 's' ]
    : 'index'

  const property = useProperty()
  const { lease } = useLease()
  const href = `/p/${property.id}/lease/${lease.id}`
    
  const dashboard = {
    id: 'index',
    label: 'Details',
    icon: <IoStatsChartOutline />,
    href: href
  }

  const invoices = {
    id: 'invoices',
    label: 'Invoices',
    icon: <IoFileTrayFull />,
    href: href + '/invoices'
  }

  const payments = {
    id: 'payments',
    label: 'Payments',
    icon: <BanknotesIcon />,
    href: href + '/payments'
  }

  const items = [
    dashboard,
    invoices,
    payments
  ]

  return (
    <VerticalNav
      items={items}
      className='w-full'
      active={specifiedActive !== '' ? specifiedActive : active}
    />
  )
}
