import {
  PencilIcon,
  PlusCircleIcon,
  TrashIcon
} from '@heroicons/react/24/solid'
import {
  BillingInvoice,
  BillingPayment,
  BillingPaymentDistribution
} from '@shared/schemas/billing'
import Button from 'components/elements/Button'
import { Card, CardBody, CardHeader } from 'components/elements/Card'
import Dropdown from 'components/elements/Dropdown'
import Empty from 'components/elements/Empty'
import Link from 'components/elements/Link'
import DeleteModal from 'components/elements/modals/DeleteModal'
import Spacer from 'components/elements/Spacer'
import Table from 'components/elements/Table'
import { DateTime } from 'luxon'
import { postApi } from 'modules/api'
import notifications from 'modules/notifications'
import progress from 'modules/progress'
import { ReactElement, useState } from 'react'
import { MdMoneyOff } from 'react-icons/md'
import { LoaderFunction, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import {
  deleteBillingPaymentDistribution,
  getBillingInvoice,
  getBillingInvoicePayments,
  getBillingPayment
} from 'stores/billing'
import getParams from 'utils/getParams'
import { formatMoney } from 'utils/transforms'
import { renderPayload, renderStats } from './_invoices'
import LeasePageShell from './_shell'

type LoaderData = {
  invoice: BillingInvoice
  payments: {
    payment: BillingPayment
    distributions: BillingPaymentDistribution[]
  }[]
}

const LeaseInvoicePage: React.FC = () => {
  const { invoice, payments } = useLoaderData() as LoaderData
  const [ removePaymentDistributionId, setRemovePaymentDistributionId ] = useState('')
  const [ visibleModal, setVisibleModal ] = useState('')
  const [ isDeleting, setIsDeleting ] = useState(false)
  const navigate = useNavigate()

  const deleteInvoice = async (): Promise<void> => {
    setIsDeleting(true)
    progress.start('delete invoice')

    const result = await postApi('/DeleteBillingInvoice', { id: invoice.id })

    progress.done('delete invoice')

    if (result._.statusCode !== 200) {
      setIsDeleting(false)
      setVisibleModal('')

      notifications.error({ message: result.message })
      return
    }
    
    navigate('../invoices', { replace: true })

    notifications.success({
      message: 'The invoice has been deleted. Any allocated payments have been released.'
    })
  }

  const deletePaymentDistribution = async (): Promise<void> => {
    setIsDeleting(true)
    progress.start('delete payment distribution')

    await deleteBillingPaymentDistribution({
      paymentId: removePaymentDistributionId,
      invoiceId: invoice.id
    })

    progress.done('delete payment distribution')
    
    setIsDeleting(false)
    setVisibleModal('')

    notifications.success({
      message: 'The payment has been removed from the invoice.'
    })

    navigate(0)
  }

  const renderPayments = (): ReactElement => {
    const addPaymentButton = (
      <Button type='secondary' size='small' onClick={() => navigate(`../payments/create?invoice=${invoice.id}`)}>
        <span>Payment</span><PlusCircleIcon className='w-4 ml-2' />
      </Button>
    )

    const header = (
      <CardHeader title='Payments'>
        {
          invoice.amount !== invoice.paid
          ? addPaymentButton
          : null
        }
      </CardHeader>
    )


    if (payments.length === 0) {
      return (
        <Card>
          {header}
          <CardBody>
            <Empty
              icon={<MdMoneyOff />}
              message='There are no payments on this invoice.'
              height='100px'
            />
          </CardBody>
        </Card>
      )
    }

    const columns = [
      { key: 'date', label: 'Date' },
      { key: 'amount', label: 'Amount' },
      { key: 'actions', label: '', rowClasses: 'text-right' }
    ]

    const rows = payments.map(i => {
      const { payment, distributions } = i
      const date = DateTime
        .fromJSDate(payment.paidAt, { zone: 'UTC' })
        .toLocaleString(DateTime.DATETIME_SHORT)

      const [ appliedToThisInvoice ] = distributions
        .filter(i => i.invoiceId === invoice.id)

      const removePayment = () => {
        setRemovePaymentDistributionId(payment.id)
        setVisibleModal('deletePaymentDistribution')
      }

      const actions = (
        <button onClick={removePayment} className='hover:text-red-500'>
          <TrashIcon width={15} />
        </button> 
      )

      return {
        id: payment.id,
        date: <Link to={`/lease/${invoice.leaseId}/payment/${payment.id}`} relative='property'>{date}</Link>,
        amount: formatMoney(appliedToThisInvoice.amount),
        actions
      }
    })

    return (
      <Card>
        {header}
        <CardBody type='table'>
          <Table inline rows={rows} columns={columns} />
        </CardBody>
      </Card>
    )
  }

  const actions = (): ReactElement[] => {
    const edit = {
      label: 'Edit invoice',
      icon: <PencilIcon />,
      action: () => navigate('edit')
    }

    const remove = { 
      label: 'Delete invoice',
      icon: <TrashIcon className='text-red-500' />,
      action: () => setVisibleModal('deleteInvoice')
    }
    
    return [
      <Dropdown menu={[ edit, remove ]} label='Options' />
    ]
  }

  const renderDeleteModal = (): JSX.Element => {
    const spreadProps: any = {
      isDeleting,
      visible: visibleModal === 'deleteInvoice',
      action: deleteInvoice,
      close: () => setVisibleModal('')
    }

    return <DeleteModal {...spreadProps} />
  }

  const renderDeletePaymentDistributionModal = (): ReactElement => {
    const props: any = {
      isDeleting,
      visible: visibleModal === 'deletePaymentDistribution',
      action: deletePaymentDistribution,
      close: () => setVisibleModal(''),
      message: 'You are not deleting the payment, just the distribution to this invoice.'
    }

    return <DeleteModal {...props} />
  }

  return (
    <LeasePageShell title='Invoice' actions={actions()}>
      {renderDeleteModal()}
      {renderDeletePaymentDistributionModal()}
      <Spacer>
        {renderStats(invoice)}
        {renderPayload(invoice)}
        {renderPayments()}
      </Spacer>
    </LeasePageShell>
  )
}

const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { invoiceId } = getParams(args)

  const [
    invoice,
    paymentsByInvoiceId
  ] = await Promise.all([
    getBillingInvoice({ invoiceId }),
    getBillingInvoicePayments({ invoiceId })
  ])

  const payments = await Promise.all(paymentsByInvoiceId.map(payment => {
    return getBillingPayment({ paymentId: payment.paymentId })
  }))

  return {
    invoice,
    payments
  }
}

const route: RouteObject = {
  path: 'invoice/:invoiceId',
  element: <LeaseInvoicePage />,
  loader
}

export default route
