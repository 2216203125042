import { ExtendedAsset } from '@shared/schemas/asset'
import { ExtendedLease } from '@shared/schemas/lease'
import { LoaderFunction, Outlet, RouteObject, useRouteLoaderData } from 'react-router-dom'
import { getAsset } from 'stores/asset'
import { getLease } from 'stores/lease'
import getParams from 'utils/getParams'

type LoaderData = {
  lease: ExtendedLease
  asset: ExtendedAsset
}

const LeaseRootPageComponent: React.FC = () => {
  return (
    <>
      <Outlet />
    </>
  )
}
 
const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { leaseId } = getParams(args)
  const lease = await getLease({ leaseId })
  const asset = await getAsset({ assetId: lease.assetId })

  return {
    lease,
    asset
  }
}

const route: RouteObject = {
  id: 'lease/leaseId',
  path: 'lease/:leaseId',
  element: <LeaseRootPageComponent />,
  loader
}

export const useLease = (): LoaderData => {
  return useRouteLoaderData(route.id as string) as LoaderData
}

export default route
