/**
 * namespaces simply returns an object that, for any key accessed, returns the
 * string version of the key
 */
interface Namespace {
  [key: string]: string
}

const handler: ProxyHandler<Namespace> = {
  get: (target: Namespace, prop: string, recv: any): string => {
    return String(prop)
  }
}

const blank: Namespace = {}
const proxy = new Proxy(blank, handler)

export default proxy
