import { User } from '@shared/schemas/user'
import MultiContext from 'components/utils/MultiContext'
import { UserContext } from 'contexts/user'
import progress from 'modules/progress'
import { isRouteErrorResponse, LoaderFunction, Outlet, RouteObject, useLoaderData, useRouteError } from 'react-router-dom'
import { getSelfUser } from 'stores/user'
import AuthPage from './global/auth'
import NotFoundPage from './system/not_found'

// TODO: Handle unauthenticated users
// TODO: Show loader when first loading
type LoaderData = {
  user: User
}

const AppRoot: React.FC = () => {
  const { user } = useLoaderData() as LoaderData

  const contexts = [
    { context: UserContext, value: user }
  ]
  
  return (
    <MultiContext contexts={contexts}>
      <Outlet />
    </MultiContext>
  )
}

const AppError: React.FC = () => {
  const error = useRouteError()

  // If we threw an error, there is probably something stuck loading
  progress.clear()

  if (isRouteErrorResponse(error)) {
    switch(error.status) {
      case 401: return <AuthPage />
      case 403: return <div>You can't do that</div>
      case 404: return <NotFoundPage />
    }

    console.log('this is a route error')
  }

  return (
    <div>Error</div>
  )
}

const loader: LoaderFunction = async (): Promise<LoaderData> => {
  const user = await getSelfUser()
  return { user }
}


const route: RouteObject = {
  path: '/',
  loader,
  element: <AppRoot />,
  errorElement: <AppError />,
}

export default route
