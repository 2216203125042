import Activity from './activity'
import Edit from './edit'
import Leases from './leases'
import Overview from './overview'
import Root from './_root'

Root.children = [
  Overview,
  Activity,
  Leases,
  Edit
]

export default Root
