import { FaCheck } from 'react-icons/fa'
import { buttonStyles } from '../Button'
import Modal, { ModalButtonProps, ModalProps } from '../Modal'

interface Props extends ModalProps {
  buttons: {
    confirm: ModalButtonProps
    cancel: ModalButtonProps
  }
}

export default function CenteredWideButtonsModal(props: Props): JSX.Element {
  const {
    visible,
    icon = <FaCheck className='text-green-600' />,
    iconBg = 'bg-green-100',
    title,
    message,
    buttons,
    onAfterClose = () => {}
  } = props

  const renderButtons = (): JSX.Element => {
    const {
      confirm,
      cancel 
    } = buttons

    const buttonBaseStyles: string = [
      'inline-flex justify-center w-full rounded-md border px-4 py-2 text-base',
      'leading-6 font-medium shadow-sm focus:outline-none transition',
      'ease-in-out duration-150 sm:text-sm sm:leading-5'
    ].join(' ')

    const {
      className: confirmClassName = buttonStyles({
        color: 'indigo',
        disabled: confirm.disabled
      })
    } = confirm

    const {
      className: cancelClassName = buttonStyles({
        color: 'white',
        disabled: cancel.disabled
      })
    } = cancel

    return (
      <>
        <span className='flex w-full rounded-md shadow-sm sm:col-start-2'>
          <button
            type='button'
            onClick={confirm.action}
            disabled={confirm.disabled}
            className={`${confirmClassName} ${buttonBaseStyles}`}
          >
            {confirm.label}
          </button>
        </span>
        <span className='mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1'>
          <button
            type='button'
            onClick={cancel.action}
            disabled={cancel.disabled}
            className={`${cancelClassName} ${buttonBaseStyles}`}
          >
            {cancel.label}
          </button>
        </span>
      </>
    )
  }

  const renderTitle = (): JSX.Element => {
    if (typeof title === 'string') {
      return (
        <h3 className='text-lg leading-6 font-medium text-gray-900'>{title}</h3>
      )
    }

    return title
  }

  const renderMessage = (): JSX.Element => {
    if (typeof message === 'string') {
      return (
        <p className='text-sm leading-5 text-gray-500'>{message}</p>
      )
    }

    return message
  }

  return (
    <Modal visible={visible} onAfterClose={onAfterClose}>
      <div>
        <div className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full ${iconBg}`}>
          {icon}
        </div>
        <div className='mt-3 text-center sm:mt-5'>
          {renderTitle()}
          <div className='mt-2'>
            {renderMessage()}
          </div>
        </div>
      </div>
      <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
        {renderButtons()}
      </div>
    </Modal>
  )
}
