import React, { ChangeEvent, useState, useEffect, useMemo } from 'react'
import { FaSearch } from 'react-icons/fa'
import { debounce, pick } from 'lodash'
import { getApi } from '../../../../modules/api'
import useAsyncEffect from 'use-async-effect'
import FadeUpFast from '../../transitions/FadeUpFast'
import { defaultInputStyles } from './Defaults'
import classNames from 'classnames'

interface Props {
  propertyId: string
  label?: string
  onSelect: Function
  className?: string
  error?: string|null
}

export default function PeopleSelect(props: Props): JSX.Element {
  const {
    propertyId,
    label = 'People',
    onSelect,
    className = '',
    error = ''
  } = props

  const [ inputValue, setInputValue ] = useState<string>('')
  const [ searchValue, setSearchValue ] = useState<string>('')
  const [ isSearching, setIsSearching ] = useState<boolean>(false)
  const [ searchResults, setSearchResults ] = useState<any[]>([])
  const [ isFocused, setIsFocused ] = useState<boolean>(false)
  const [ highlightedItemId, setHighlightedItemId ] = useState<string>('')

  const search = useMemo(() => debounce((query: string) => {
    setSearchValue(query)
  }, 200), [])

  // Update the search value, but fire the debounce
  useEffect(() => search(inputValue), [ inputValue, search ])

  useAsyncEffect(async (isMounted) => {
    if (searchValue.trim() === '') {
      setIsSearching(false)
      setSearchResults([])
    }

    if (searchValue.trim() !== '') {
      setIsSearching(true)

      const { results } = await getApi('/SearchPeople', {
        query: searchValue,
        propertyId
      })

      if (!isMounted()) {
        return
      }

      setIsSearching(false)
      setSearchResults(results)
    }
  }, [ searchValue ])

  const showPopover: boolean = searchResults.length > 0

  function itemIsPresentInResults(itemId: string): boolean {
    return searchResults
      .filter(item => item.id === itemId)
      .length > 0
  }

  function getSelectedItemIndex(): number {
    let selectedIndex: number = 0

    if (highlightedItemId !== '' && itemIsPresentInResults(highlightedItemId)) {
      searchResults.map((item, index) => {
        if (item.id === highlightedItemId) selectedIndex = index
        return null
      })
    }

    return selectedIndex
  }

  function highlightNextItem(): void {
    const selectedIndex = getSelectedItemIndex()

    let nextIndex = selectedIndex + 1

    if (nextIndex >= searchResults.length) {
      nextIndex = 0
    }

    const nextItem = searchResults[nextIndex]

    if (!nextItem) {
      return
    }

    setHighlightedItemId(nextItem.id)
  }

  function highlightPrevItem(): void {
    const selectedIndex = getSelectedItemIndex()

    let nextIndex = selectedIndex - 1

    if (nextIndex < 0 || nextIndex >= searchResults.length) {
      nextIndex = Math.max(searchResults.length - 1, 0)
    }

    const nextItem = searchResults[nextIndex]

    if (!nextItem) {
      return
    }

    setHighlightedItemId(nextItem.id)
  }

  function selectHighlightedItem(): void {
    let [ item ] = searchResults.filter(item => item.id === highlightedItemId)

    if (!item) {
      // We have only psuedo-selected an item
      item = searchResults[0]
    }

    if (!item) {
      // There was still no item selected
      console.warn('PeopleSelect: no item selected')
      return
    }

    handleSelect(item)
  }

  function handleSelect(item: object) {
    const selected = pick(item, [ 'id', 'firstName', 'lastName' ])

    onSelect(selected)
    setInputValue('')

    setTimeout(() => {
      // This could potentially fire after being unmounted
      setHighlightedItemId('')
    }, 500)
  }

  function renderItem(item: any, index: number): JSX.Element {
    const {
      id,
      firstName,
      lastName
    } = item

    let isHighlighted = false

    if (highlightedItemId === '') {
      /**
       * There is no selected item, so we are going to highlight the first 
       * result
       */
      isHighlighted = index === 0
    } else {
      if (itemIsPresentInResults(highlightedItemId)) {
        isHighlighted = id === highlightedItemId
      } else {
        // There is a selected item, but it is not present in the search results
        isHighlighted = index === 0
      }
    }

    const liClasses = {
      base: 'cursor-pointer select-none relative py-2 pl-3 pr-9',
      highlighted: 'text-white bg-indigo-600',
      inactive: 'text-gray-900'
    }

    const spanClasses = {
      base: 'block truncate',
      highlighted: 'font-semibold',
      inactive: 'font-normal'
    }

    return (
      <li onClick={() => handleSelect(item)} className={[ liClasses.base, isHighlighted ? liClasses.highlighted : liClasses.inactive ].join(' ')} key={id}>
        <span className={[ spanClasses.base, isHighlighted ? spanClasses.highlighted : spanClasses.inactive ].join(' ')}>
          {firstName} {lastName}
        </span>
      </li>
    )
  }

  function renderPopover(): JSX.Element {
    return (
      <FadeUpFast in={showPopover && isFocused} className='absolute always-top w-full'>
        <div className='rounded-md bg-white shadow-lg'>
          <ul className='max-h-60 rounded-md py-1 text-base leading-6 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm sm:leading-5'>
            {searchResults.map(renderItem)}
          </ul>
        </div>
      </FadeUpFast>
    )
  }

  function onChangeText(e: ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value)
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const ARROW_DOWN = 40
    const ARROW_UP = 38
    const ENTER = 13

    if (e.keyCode === ARROW_DOWN) {
      highlightNextItem()
      return
    }

    if (e.keyCode === ARROW_UP) {
      highlightPrevItem()
      return
    }

    if (e.keyCode === ENTER) {
      selectHighlightedItem()
      return
    }
  }

  const inputClasses = classNames(
    defaultInputStyles({ error: !!error }),
    'pl-10'
  )

  return (
    <div className={className !== '' ? className : 'space-y-1 col-span-6 sm:col-span-3'}>
      <label className='block text-sm leading-5 font-medium text-gray-700'>
        {label}
      </label>
      <div className='relative'>
        <div className='relative inline-block w-full rounded-md shadow-sm'>
          <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
            <svg className='h-5 w-5 text-gray-400' viewBox='0 0 20 20' fill='currentColor'>
              <path d='M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z' />
            </svg>
          </div>
          <input
            type='text'
            className={inputClasses}
            onChange={onChangeText}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyDown={onKeyDown}
            value={inputValue}
            placeholder='Start typing...'
          />
          <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>    
            <div className='h-5 w-5 text-gray-400'>
              <FaSearch style={{ marginTop: '1px' }} className={'transform transition-all ' + (isSearching === true ? 'search-icon-animation' : '')} />
            </div>
          </div>
        </div>

        {
          error !== ''
          ? <p className='mt-2 text-sm text-red-600'>{error}</p>
          : null
        }

        {renderPopover()}
      </div>
    </div>
  )
}
