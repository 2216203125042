import React, {
  ReactElement,
  Children,
  isValidElement,
  cloneElement
} from 'react'

interface Props {
  title?: string
  subtitle?: string
  children: ReactElement|ReactElement[]
  className?: string
}

export default function FieldSet(props: Props): JSX.Element {
  const {
    title = '',
    subtitle = '',
    children,
    className = ''
  } = props

  const childrenWithProps = Children.map(children, (child, index) => {
    if (isValidElement(child)) {
      return cloneElement(child, { fieldsetIndex: index } as any)
    }

    return child
  })

  const hasNoHeader = title === '' && subtitle === ''

  return (
    <fieldset className={className}>
      {
        title !== ''
        ? <legend className='text-base leading-6 font-medium text-gray-900'>{title}</legend>
        : null
      }
      {
        subtitle !== ''
        ? <p className='text-sm leading-5 text-gray-500'>{subtitle}</p>
        : null
      }
      <div className={hasNoHeader ? '' : 'mt-4'}>
        {childrenWithProps}
      </div>
    </fieldset>
  )
}
