import Badge from 'components/elements/Badge'
import { ReactElement } from 'react'

interface Props {
  status: string
}

export default function LeaseStatusBadge(props: Props): ReactElement {
  const { status } = props

  switch (status) {
    case 'active': return <Badge label='Active' color='green' />
    case 'expired': return <Badge label='Expired' color='yellow' />
    case 'terminated': return <Badge label='Terminated' color='red' />
    case 'month_to_month': return <Badge label='Month-to-month' color='blue' />
    case 'complete': return <Badge label='Complete' color='blue' />
  }

  return <Badge label='Unknown' color='gray' />
}
