import React from 'react'

interface Props {
  htmlFor?: string
  label?: string
  hint?: string
}

export default function Label(props: Props) {
  const {
    htmlFor = '',
    label,
    hint = ''
  } = props

  const optProps: any = {}

  if (htmlFor !== '') {
    optProps.htmlFor = htmlFor
  }

  return (
    <div className='flex justify-between'>
      <label
        {...optProps}
        className='block text-sm font-medium leading-5 text-gray-700'
      >{label}</label>

      {
        hint !== ''
        ? <p className='text-sm leading-5 text-gray-500'>{hint}</p>
        : null
      }
    </div>
  )
}
