import React, { useContext } from 'react'
import { useFormikContext } from 'formik'
import { withNamespace } from '../../../utils/forms'
import AttributesInput from './inputs/Attributes'
import TextInput from './inputs/Text'
import Section from './inputs/Section'
import { AssetModificationContext } from 'contexts/assetModification'

interface Props {
  visible: boolean
  propertyId: string
  namespace?: string
  index?: number
}

export default function MHPHomeForm(props: Props) {
  const { assetAttributeSettings } = useContext(AssetModificationContext)
  const attributeSettings = assetAttributeSettings['mhp_home']

  const {
    visible,
    index = 0,
    namespace = null
  } = props

  const formik = useFormikContext()
  const ns = withNamespace(namespace)

  return (
    <Section
      title='Home details'
      subtitle='Try to add as much detail as possible, it will make tracking these easier in the future.'
      index={index}
      visible={visible}
    >
      <TextInput
        label='Base rate'
        placeholder='200.00'
        help='The monthly charge for the home. This will be in addition to any lot fees.'
        className='col-span-6 sm:col-span-3'
        {...formik.getFieldProps(ns('baseRate'))}
      />

      <AttributesInput
        label='Attributes'
        name={ns('attributes')}
        mode='settings'
        settings={attributeSettings}
      />
    </Section>
  )
}
