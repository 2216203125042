import { RocketLaunchIcon } from '@heroicons/react/24/solid'
import HeaderAvatar from 'components/app/HeaderAvatar'
import Page from 'components/app/Page'
import Shell from 'components/app/Shell'
import Activity, { ActivityProps, loadActivityItemsByPropertyId } from 'components/modules/Activity'
import { ReactElement } from 'react'
import { LoaderFunctionArgs, RouteObject, useLoaderData } from 'react-router-dom'
import getParams from 'utils/getParams'
import { useProperty } from './_root'

interface LoaderData {
  activity: ActivityProps
}

const ActivityPageComponent = (): ReactElement => {
  const property = useProperty()
  const { activity } = useLoaderData() as LoaderData

  const crumbs = [
    { label: property.displayName, href: `/p/${property.id}` },
    { label: 'Activity', href: `/p/${property.id}/activity` }
  ]

  return (
    <Shell active='activity' breadcrumbs={crumbs}>
      <HeaderAvatar
        title='Activity'
        avatarIcon={<RocketLaunchIcon className='w-6' />}
      />
      <Page>
        <Activity {...activity} noFooter absoluteTime />
      </Page>
    </Shell>
  )
}

const loader  = async (args: LoaderFunctionArgs): Promise<LoaderData> => {
  const { propertyId } = getParams(args)
  const activity = await loadActivityItemsByPropertyId({
    propertyId,
    limit: 20
  })

  return {
    activity
  }
}

const route: RouteObject = {
  path: 'activity',
  element: <ActivityPageComponent />,
  loader
}

export default route
