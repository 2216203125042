import React from 'react'

interface Props {
  address: any
  type?: 'full'|'first-line'|'one-line'
  className?: string
}

export default function Address(props: Props): JSX.Element {
  const {
    address = null,
    type = 'full',
    className = ''
  } = props

  if (address === null) {
    return (
      <span className='text-gray-400'>Not specified</span>
    )
  }

  const {
    street1,
    street2,
    city,
    state,
    zip
  } = address

  const streetAddress = [ street1, street2 ]
    .filter(i => !!i)
    .join(' ')

  const renderFull = (): JSX.Element => {
    return (
      <span className={`${className} block`}>
        <span className='block'>{streetAddress}</span>
        <span className='block text-gray-500'>{city}, {state} {zip}</span>
      </span>
    )
  }

  const renderFirstLine = (): JSX.Element => {
    return (
      <span className={className}>{streetAddress}</span>
    )
  }

  const renderOneLine = (): JSX.Element => {
    return (
      <span className={className}>{streetAddress}, {city}, {state} {zip}</span>
    )
  }

  switch(type) {
    case 'full': return renderFull()
    case 'first-line': return renderFirstLine()
    case 'one-line': return renderOneLine()
    default: throw Error('unknown address render type')
  }
}
