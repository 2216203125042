import { ExtendedAsset } from '@shared/schemas/asset'
import { ExtendedLease } from '@shared/schemas/lease'
import Leases from 'components/modules/Leases'
import { PropertyContext } from 'contexts/property'
import { uniq } from 'lodash'
import { useContext } from 'react'
import { LoaderFunction, RouteObject, useLoaderData } from 'react-router-dom'
import { getAssets } from 'stores/asset'
import { getAssetLeases } from 'stores/lease'
import getParams from 'utils/getParams'
import { useAsset } from './_root'

type LoaderData = {
  assets: ExtendedAsset[]
  leases: ExtendedLease[]
}

const AssetLeasesPageComponent: React.FC = () => {
  const property = useContext(PropertyContext)
  const { asset } = useAsset()
  const { assets, leases } = useLoaderData() as LoaderData

  return (
    <Leases
      assetId={asset.id}
      property={property}
      assets={assets}
      leases={leases}
    />
  )
}


const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { assetId } = getParams(args)
  const leases = await getAssetLeases({ assetId, status: 'any' })
  const assetIds = uniq([
    assetId,
    ...leases.map(l => l.assetId)
  ])

  const assets = await getAssets({ assetIds })

  return {
    leases,
    assets
  }
}

const route: RouteObject = {
  path: 'leases',
  element: <AssetLeasesPageComponent />,
  loader
}

export default route
