import { CheckIcon, ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/solid'
import React, { ReactElement, useEffect } from 'react'

export interface NotificationProps {
  id?: string
  index?: number
  timeout?: number
  icon?: ReactElement
  title: ReactElement|string
  message: ReactElement|string
  type?: 'success'|'error'|'warning'|'info'
  onClose?: () => any
}

export default function Notification(props: NotificationProps): JSX.Element {
  const {
    index = 0,
    timeout = 4000,
    onClose,
    icon,
    title,
    message,
    type = 'info'
  } = props

  // Automatically close the notification if the timeout has passed
  // TODO: We should not close the notification if the mouse is over it
  useEffect(() => {
    if (timeout === Infinity) {
      return
    }

    const closeTimeout = setTimeout(() => {
      if (onClose) onClose()
    }, timeout)
    return () => clearTimeout(closeTimeout)
  }, [ timeout, onClose ])

  const renderCloseIcon = (): JSX.Element => {
    return (
      <svg className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
        <path
          fillRule='evenodd'
          d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
          clipRule='evenodd'
        />
      </svg>
    )
  }


  const ICONS: any = {
    error: <ExclamationTriangleIcon className='text-white w-5' />,
    warning: <ExclamationCircleIcon className='text-white w-5' />,
    info: <InformationCircleIcon className='text-white w-5' />,
    success: <CheckIcon className='text-white w-5' />
  }

  const renderIcon = (): JSX.Element => {
    if (typeof icon !== 'string' && icon !== undefined) {
      return icon
    }

    if (ICONS.hasOwnProperty(type)) {
      return ICONS[type]
    }

    console.warn('renderIcon: unknown string given')
    return <React.Fragment />
  }

  const bgColors = {
    success: 'bg-green-600',
    warning: 'bg-yellow-600',
    error: 'bg-red-600',
    info: 'bg-blue-600'
  }

  const bgColor = bgColors[type]
  
  const renderAlert = (): JSX.Element => {
    return (
      <div className={`${index !== 0 ? 'mt-2' : '' } max-w-sm w-full ${bgColor} shadow-lg rounded-lg pointer-events-auto`}>
        <div className='rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
          <div className='p-4'>
            <div className='flex items-start'>
              <div className='flex-shrink-0 pt-0.5'>
                {renderIcon()}
              </div>

              <div className='ml-3 w-0 flex-1'>
                <p className='text-sm leading-5 font-medium text-white'>
                  {title}
                </p>
                <p className='mt-1 text-sm leading-5 text-white'>
                  {message}
                </p>
              </div>

              <div className='ml-4 flex-shrink-0 flex'>
                <button
                  onClick={onClose}
                  className='inline-flex text-white focus:outline-none focus:text-gray-400 transition ease-in-out duration-150'
                >
                  {renderCloseIcon()}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return renderAlert()
}
