import * as z from 'zod'

export const address = z.object({
  street1: z.string().min(1, { message: 'cannot be blank' }),
  street2: z.string().optional(),
  city: z.string().min(1, { message: 'cannot be blank' }),
  state: z.string().length(2),
  zip: z.string().length(5)
})

export const extendedAddress = address.merge(z.object({
  id: z.string().uuid()
}))

export type Address = z.infer<typeof address>
export type ExtendedAddress = z.infer<typeof extendedAddress>

export const blanks = Object.freeze({
  address: {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: ''
  }
})
