import schemas from '@shared/schemas'
import { ExtendedAsset } from '@shared/schemas/asset'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import nProgress from 'nprogress'
import { LoaderFunction, LoaderFunctionArgs, RouteObject, useLoaderData, useNavigate, useParams } from 'react-router-dom'
import { getAsset } from 'stores/asset'
import Header from '../../components/app/Header'
import Page from '../../components/app/Page'
import Shell from '../../components/app/Shell'
import { Form } from '../../components/elements/Form'
import LeaseForm from '../../components/elements/forms/Lease'
import Submit from '../../components/elements/forms/inputs/Submit'
import { postApi } from '../../modules/api'
import { assetTypeToReadable } from '../../utils/assetTypes'
import createSchema from '../../utils/createSchema'
import { getSearchParams } from '../../utils/getQuery'
import namespaces from '../../utils/namespaces'

type LoaderData = {
  asset: ExtendedAsset|null
}

const CreateLeasePageComponent: React.FC = () => {
  const { propertyId = '' } = useParams()
  const { asset = null } = useLoaderData() as LoaderData
  const navigate = useNavigate()

  const assetName = asset !== null
    ? `${assetTypeToReadable(asset.type)} ${asset.displayId}`
    : ''

  const subtitle = `You are adding a lease for ${assetName}.`

  const schema = createSchema()
    .with(namespaces.lease, schemas.lease.lease)
    .with(namespaces.lineItems, schemas.attribute.attributes)

  const initialValues = {
    [namespaces.lease]: schemas.lease.blanks.lease,
    [namespaces.lineItems]: []
  }

  const onSubmit = async (values: any, frmk: FormikHelpers<any>) => {
    const done = () => {
      nProgress.done()
      frmk.setSubmitting(false)
    }
    
    nProgress.start()

    try {
      const opts: any = {}
      
      if (asset) {
        opts.assetId = asset.id
      }

      const response = await postApi('/CreateLease', values, opts)

      if (response._.statusCode === 200) {
        // Redirect to the new lease
        const { leaseId } = response
        navigate(`/p/${propertyId}/lease/${leaseId}`, { replace: true })
        return
      }
    } catch (e) {
      done()
      throw e
    }

    done()
  }

  const renderForm = (formikProps: FormikProps<typeof initialValues>): JSX.Element => {
    return (
      <Form onSubmit={formikProps.handleSubmit}>
        <LeaseForm
          namespace={namespaces.lease}
          visible
          propertyId={propertyId as string}
          subtitle={subtitle}
        />
        <Submit />
      </Form>
    )
  }

  return (
    <Shell>
      <Header title='Create a lease' />
      <Page>
        <Formik
          initialValues={initialValues}
          validate={schema.validate}
          onSubmit={onSubmit}
        >{renderForm}</Formik>
      </Page>
    </Shell>
  )
}

const loader: LoaderFunction = async (args: LoaderFunctionArgs): Promise<LoaderData> => {
  const { assetId = null } = getSearchParams(args)

  const asset = assetId !== null
    ? await getAsset({ assetId })
    : null

  return { asset }
}

const route: RouteObject = {
  path: 'leases/create',
  element: <CreateLeasePageComponent />,
  loader
}

export default route
