import { BriefcaseIcon, CheckIcon } from '@heroicons/react/24/solid'
import schemas from '@shared/schemas'
import { ExtendedAsset } from '@shared/schemas/asset'
import { ExtendedLease, States } from '@shared/schemas/lease'
import { PersonStub } from '@shared/schemas/person'
import HeaderAvatar from 'components/app/HeaderAvatar'
import Page from 'components/app/Page'
import Shell from 'components/app/Shell'
import { Card, CardBody, CardFooter } from 'components/elements/Card'
import Dropdown from 'components/elements/Dropdown'
import Link from 'components/elements/Link'
import Pagination from 'components/elements/Pagination'
import Table from 'components/elements/Table'
import { renderLeaseStatusBadge } from 'components/modules/Leases'
import { flatten } from 'lodash'
import { DateTime } from 'luxon'
import { Fragment, ReactElement } from 'react'
import { LoaderFunction, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import { getAsset } from 'stores/asset'
import { getLeasesByPropertyId } from 'stores/lease'
import { getLeasePeople } from 'stores/person'
import { assetTypeToReadable } from 'utils/assetTypes'
import getParams from 'utils/getParams'
import { getSearchParams } from 'utils/getQuery'
import PageUtils from 'utils/pages'
import { useProperty } from './_root'

type LoaderData = {
  leases: ExtendedLease[]
  page: PageUtils
  peopleByLeaseId: Record<string, PersonStub[]>
  assetsById: Record<string, ExtendedAsset>
  filters: {
    status: States
  }
}

const LeasesPage: React.FC = () => {
  const property = useProperty()
  const { leases, peopleByLeaseId, assetsById, page, filters } = useLoaderData() as LoaderData
  const navigate = useNavigate()

  const crumbs = [
    { label: property.displayName, href: `/p/${property.id}` },
    { label: 'Leases', href: `/p/${property.id}/leases` }
  ]

  const renderTable = (): ReactElement => {
    const columns = [
      { key: 'asset', label: 'Asset' },
      { key: 'tenants', label: 'Tenants' },
      { key: 'term', label: 'Term' },
      { key: 'status', label: '', headerClasses: 'text-right', rowClasses: 'text-right' }
    ]

    const rows = leases.map((lease: ExtendedLease) => {
      const {
        id,
        assetId,
        status,
        termStart,
        termEnd
      } = lease

      const asset = assetsById[assetId]
      const people = peopleByLeaseId[id]

      const config = {
        onClick: () => navigate(`../lease/${id}`)
      }

      const tenants = people.map((person: PersonStub, i: number) => {
        const name = [
          person.firstName,
          person.lastName
        ].join(' ')

        return (
          <span key={person.id}>
            <Link to={`/person/${person.id}`} relative='property'>{name}</Link>
            <span color=''></span>
            { i < people.length - 1 && <span>, </span> }
          </span>
        )
      })

      const term = [
        DateTime.fromJSDate(termStart).toLocaleString(),
        DateTime.fromJSDate(termEnd).toLocaleString()
      ].join(' - ')

      const assetName = [
        assetTypeToReadable(asset.type),
        asset.displayId
      ].join(' ')

      return {
        asset: <Link to={`/asset/${asset.id}`} className='font-medium' relative='property'>{assetName}</Link>,
        tenants,
        term,
        status: renderLeaseStatusBadge(status),
        config
      }
    })

    return <Table columns={columns} rows={rows} inline />
  }

  const renderPages = (): ReactElement => {
    if (page.last() <= 1) {
      return <Fragment />
    }

    return (
      <CardFooter>
        <Pagination
          page={page}
          getPageHref={(page: number) => `/p/${property.id}/leases/?p=${page}`}
        />
      </CardFooter>
    )
  }

  const renderFilterMenu = (): ReactElement => {
    const { status } = filters

    const statusToLabel: Record<States, string> = {
      'any': 'All',
      'active': 'Active',
      'complete': 'Complete',
      'expired': 'Expired',
      'month_to_month': 'Month to Month',
      'terminated': 'Terminated'
    }

    const items = Object.entries(statusToLabel).map(([ key, value ]) => {
      return {
        label: value,
        icon: status === key ? <CheckIcon /> : <Fragment />,
        action: () => navigate(`./?status=${key}`)
      }
    })
    
    const label = statusToLabel[status]

    return <Dropdown menu={[ items ]} label={label} />
  }

  const subtitle = page.count() !== 0
    ? `Viewing ${page.range()} of ${page.count()} leases.`
    : 'Nothing here yet.'

  return (
    <Shell active='leases' breadcrumbs={crumbs}>
      <HeaderAvatar
        title='Leases'
        subtitle={subtitle}
        avatarIcon={<BriefcaseIcon className='w-6' />}
        actions={[ renderFilterMenu() ]}
      />
      <Page>
        <Card>
          <CardBody type='table'>
            {renderTable()}
          </CardBody>
          {renderPages()}
        </Card>
      </Page>
    </Shell>
  )
}

const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { propertyId } = getParams(args)

  const { 
    p: pageStr = '1',
    status: unfilteredStatus = 'any'
  } = getSearchParams(args)

  const parsedStatusFilter = schemas.lease.states.safeParse(unfilteredStatus)

  const statusFilter: States = parsedStatusFilter.success
    ? parsedStatusFilter.data
    : 'any'

  const page = new PageUtils({ currentPage: Number(pageStr) })

  const [
    data
  ] = await Promise.all([
    getLeasesByPropertyId({
      propertyId,
      offset: page.offset(),
      limit: page.limit(),
      status: statusFilter
    })
  ])

  const { leases, page: pageResults } = data
  page.count(pageResults.count)

  const peopleByLeaseId: Record<string, PersonStub[]> = {}
  const assetsById: Record<string, ExtendedAsset> = {}

  /**
   * We have the lease information, but now we need to get the underlying asset
   * and tenant information for each lease.
   */
  const getPeople = async (leaseId: string): Promise<void> => {
    peopleByLeaseId[leaseId] = await getLeasePeople({ leaseId })
  }

  const getAssets = async (assetId: string): Promise<void> => {
    if (assetsById.hasOwnProperty(assetId)) {
      // We already fetched this asset
      return
    }

    const asset = await getAsset({ assetId })

    if (asset !== null) {
      assetsById[asset.id] = asset
    }
  }

  await Promise.all(
    flatten(
      leases.map(lease => {
        return [ getPeople(lease.id), getAssets(lease.assetId) ]
      })
    )
  )

  return {
    leases,
    peopleByLeaseId,
    assetsById,
    page,
    filters: {
      status: statusFilter
    }
  }
}

const route: RouteObject = {
  path: 'leases',
  element: <LeasesPage />,
  loader
}

export default route
