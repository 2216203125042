import classNames from 'classnames'
import { ReactElement } from 'react'
import ReactModal from 'react-modal'
import {
  ModalBackgroundTransition,
  ModalPanelTransition
} from './transitions/Modal'

ReactModal.setAppElement('#root')
ReactModal.defaultStyles = {}

export const MODAL_CLOSE_DURATION = 200

interface Props {
  visible: boolean
  children: ReactElement|ReactElement[]
  onAfterClose?: (() => void)|(() => Promise<void>)
  noPadding?: boolean
}

export interface ModalButtonProps {
  label: string
  action: AnyVoidFunction
  className?: string
  disabled?: boolean
}

export interface ModalProps {
  visible: boolean
  icon?: ReactElement
  iconBg?: string
  title: ReactElement|string
  message: ReactElement|string
  onAfterClose?: (() => void)|(() => Promise<void>)
}

export default function Modal(props: Props): JSX.Element {
  const {
    visible,
    children,
    onAfterClose = () => {},
    noPadding = false
  } = props

  const modalPanelStyles = classNames({
    'relative inline-block align-bottom bg-white rounded-lg  text-left overflow-hidden shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full': true,
    'px-4 pt-5 pb-4 sm:p-6': !noPadding
  })

  return (
    <ReactModal
      isOpen={visible}
      closeTimeoutMS={MODAL_CLOSE_DURATION}
      onAfterClose={onAfterClose}
    >
      <div className='fixed z-10 inset-0 overflow-y-auto'>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <ModalBackgroundTransition in={visible} className='fixed inset-0'>
            <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
          </ModalBackgroundTransition>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='hidden sm:inline-block sm:align-middle sm:h-screen' />&#8203;
          
          <ModalPanelTransition in={visible} className={modalPanelStyles}>
            {children}
          </ModalPanelTransition>
        </div>
      </div>
    </ReactModal>
  )
}
