import { Context, ReactElement } from 'react'

interface ContextItem {
  context: Context<any>
  value: any
}

interface Props {
  children: ReactElement|ReactElement[]
  contexts: ContextItem[]
}

export default function MultiContext(props: Props): JSX.Element {
  const { contexts, children } = props

  const renderContextProvider = (index: number): JSX.Element => {
    if (typeof contexts[index] === 'undefined') {
      return <>{children}</>
    }

    const { context, value } = contexts[index]
    const child = renderContextProvider(index + 1)

    return (
      <context.Provider value={value}>
        {child}
      </context.Provider>
    )
  }

  return renderContextProvider(0)
}
