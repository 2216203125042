import Version from 'components/app/Version'
import Notifications from 'components/elements/Notifications'
import notifications from 'modules/notifications'
import routes from 'pages/_routes'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

const App: React.FC = () => {
  const router = createBrowserRouter([ routes ])
  return (
    <>
      <Version />
      <Notifications controlRef={notifications.captureRef} />
      <RouterProvider router={router} />
    </>
  )
}

export default App
