import { late, z } from 'zod'
import { zFlexBool, zFlexCurrency, zFlexDate, zFlexNumber } from './util'

export const invoiceItem = z.object({
  name: z.string(),
  description: z.string().optional(),
  qty: zFlexNumber(),
  amount: zFlexCurrency(),
  ref: z.string().optional()
})

export const invoicePayload = z.object({
  ver: z.enum([ 'v1' ]),
  items: z.array(invoiceItem).min(1)
})

export const invoice = z.object({
  id: z.string().uuid(),
  leaseId: z.string().uuid(),
  amount: zFlexCurrency(),
  due: zFlexDate(),
  payload: invoicePayload,
  paid: zFlexCurrency(),
  createdAt: zFlexDate(),
  updatedAt: zFlexDate(),
  automatic: zFlexBool(),
  late: zFlexBool()
})

export const paymentProviders = z.enum([
  'stripe',
  'manual'
])

export const payment = z.object({
  id: z.string().uuid(),
  leaseId: z.string().uuid(),
  personId: z.string().uuid().nullable().optional(),
  provider: paymentProviders,
  metadata: z.record(z.string()).nullable().optional(),
  amount: zFlexCurrency(),
  allocated: zFlexCurrency(),
  paidAt: zFlexDate()
})

export const paymentDistribution = z.object({
  paymentId: z.string().uuid(),
  invoiceId: z.string().uuid(),
  amount: zFlexCurrency()
})

export const paymentDistributionAdjustment = z.object({
  previousAmount: zFlexCurrency(),
  newAmount: zFlexCurrency(),
  paymentId: z.string().uuid()
})

export const invoicePaymentsAdjustmentsResult = z.object({
  previous: z.object({
    balance: zFlexCurrency(),
    total: zFlexCurrency()
  }),
  adjusted:z.object({
    balance: zFlexCurrency(),
    total: zFlexCurrency()
  }),
  adjustments: z.array(paymentDistributionAdjustment),
  distributions: z.array(paymentDistribution)
})

export type BillingInvoiceItem = z.infer<typeof invoiceItem>
export type BillingInvoicePayload = z.infer<typeof invoicePayload>
export type BillingInvoice = z.infer<typeof invoice>
export type BillingPaymentProviders = z.infer<typeof paymentProviders>
export type BillingPayment = z.infer<typeof payment>
export type BillingPaymentDistribution = z.infer<typeof paymentDistribution>
export type BillingPaymentDistributionAdjustment = z.infer<typeof paymentDistributionAdjustment>
export type BillingInvoicePaymentsAdjustmentsResult = z.infer<typeof invoicePaymentsAdjustmentsResult>
