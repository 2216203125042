import { useEffect, useState } from 'react'
import useAsyncEffect from 'use-async-effect'

const Version: React.FC = () => {
  const [ version, setVersion ] = useState('')
  const [ lastRefreshed, setLastRefreshed ] = useState(Date.now())
  const [ updateAvailable, setUpdateAvailable ] = useState(false)

  useAsyncEffect(async mounted => {
    let appVersion: string = ''

    try {
      const response = await fetch('/assets/version.txt')
      appVersion = await response.text()
      appVersion = appVersion.trim()
    } catch (e) {
      // if there was an error fetching the version, just move on
      return
    }
    
    if (!mounted()) {
      return
    }

    // on first load, set the version
    if (version === '') {
      console.log('app version:', appVersion)
      setVersion(appVersion)
      return
    }

    // if the version has changed, show a notification
    if (version !== appVersion) {
      setUpdateAvailable(true)
    }
  }, [ lastRefreshed ])

  // check the version every minute
  useEffect(() => {
    const interval = setInterval(() => {
      setLastRefreshed(Date.now())
    }, 1000 * 60 * 5)

    return () => clearInterval(interval)
  })

  if (!updateAvailable) {
    return (<></>)
  }

  const onClick = (e: any) => {
    e.preventDefault()
    window.location.reload()
  }

  return (
    <div className='flex items-center justify-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 h-10'>
      <p className='text-sm leading-6 text-white'>
        <a href='/' onClick={onClick}>
          <strong className='font-semibold'>Update available</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          Please refresh the page to update&nbsp;<span aria-hidden='true'>&rarr;</span>
        </a>
      </p>
    </div>
  )
}

export default Version
