import { z } from 'zod'
import { zFlexDate, zFlexNumber } from './util'

export const targetTypes = z.enum([
  'asset',
  'person',
  'lease'
])

export const document = z.object({
  id: z.string().uuid(),
  tenantId: z.string().uuid(),
  userId: z.string().uuid(),
  targetType: targetTypes,
  targetId: z.string().uuid(),
  createdAt: zFlexDate(),
  uri: z.string(),
  name: z.string(),
  size: zFlexNumber(),
  visible: z.boolean(),
  propertyId: z.string().uuid()
})

export const documentWithKnownTarget = document.omit({
  tenantId: true,
  targetType: true,
  targetId: true,
  visible: true,
  propertyId: true
})

export const documentsWithKnownTarget = z.array(documentWithKnownTarget)

export type Document = z.infer<typeof document>
export type DocumentWithKnownTarget = z.infer<typeof documentWithKnownTarget>
