import React from 'react'

const colors = {
  gray: 'bg-gray-100 text-gray-800',
  red: 'bg-red-100 text-red-800',
  orange: 'bg-orange-100 text-orange-800',
  yellow: 'bg-yellow-100 text-yellow-800',
  green: 'bg-green-100 text-green-800',
  teal: 'bg-teal-100 text-teal-800',
  blue: 'bg-blue-100 text-blue-800',
  indigo: 'bg-indigo-100 text-indigo-800',
  purple: 'bg-purple-100 text-purple-800',
  pink: 'bg-pink-100 text-pink-800'
}

export interface BadgeProps {
  label: string
  color?: keyof typeof colors
  className?: string
  size?: 'small'|'large'
  onRemove?: Function
}

export default function Badge(props: BadgeProps): JSX.Element {
  const {
    label,
    color = 'gray',
    className = '',
    size = 'small',
    onRemove
  } = props

  const baseStyles: Record<string, string> = {
    small: 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 text-center',
    large: 'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 text-center'
  }

  const selectedStyles = colors[color] ? colors[color] : colors.gray

  const renderRemoveButton = (): JSX.Element => {
    const { onRemove } = props

    return (
      <button
        type='button'
        className={`flex-shrink-0 -mr-0.5 ml-1.5 inline-flex focus:outline-none ${selectedStyles}`}
        aria-label='Remove large badge'
        onClick={onRemove as any}
      >
        <svg className='h-2 w-2' stroke='currentColor' fill='none' viewBox='0 0 8 8'>
          <path strokeLinecap='round' strokeWidth='1.5' d='M1 1l6 6m0-6L1 7' />
        </svg>
      </button>
    ) 
  }

  return (
    <span className={`${baseStyles[size]} ${selectedStyles} ${className}`}>
      {label}
      {onRemove ? renderRemoveButton() : null}
    </span>
  )
}
