import { z } from 'zod'

/**
 * We want all currency values to be strings while they're being passed around
 */
export type Currency = string

function stringIsDate(value: string): boolean {
  return !isNaN(Date.parse(value))
}

function stringIsCurrency(value: string): boolean {
  // This is a really hacky way of ensuring only 2 decimal points of precision
  if (value.includes('.')) {
    const [ , cents ] = value.split('.')

    if (cents.length > 2) {
      return false
    }
  }

  return !isNaN(
    Number(value
      .replace('$', '')
      .replace(/,/g, '')
    )
  )
}

export function zJsonDate() {
  const transform = (val: string): Date => new Date(val)

  return z
    .string()
    .refine(stringIsDate)
    .transform(transform)
}

export function zFlexDate() {
  return z.union([
    z.date(),
    zJsonDate()
  ])
}

/**
 * zFlexCurrency() takes a number (sans $) or a string (with $) representing a
 * currency amount and returns the result always as a string
 */
export function zFlexCurrency() {
  const stringInput = z
    .string()
    .refine(stringIsCurrency, { message: 'Must be a number.' })
    .transform((value: string): string => {
      return value
        .replace('$', '')
        .replace(/,/g, '')
    })

  const numberInput = z
    .number()
    .transform((value: number): string => String(value))
    .refine(stringIsCurrency, { message: 'Must be a number.' })

  return z.union([
    stringInput,
    numberInput
  ])
}

/**
 * zFlexBool() is a type that will accept either an actual boolean, or the
 * strings 'true' or 'false'
 */
export function zFlexBool() {
  const refiner = (value: string) => ['true', 'false'].includes(value)

  const transformed = z.string()
    .refine(refiner, { message: `Must be 'true' or 'false'.`})
    .transform((value: string): boolean => value === 'true')

  return z.union([
    z.boolean(),
    transformed
  ])
}

export function zStringNumber(numberSchema: z.ZodNumber = z.number()) {
  const refiner = (value: string) => !isNaN(Number(value))

  const transformed = z
    .string()
    .min(1)
    .refine(refiner, { message: 'Must be a number.' })
    .transform((value: string): number => Number(value))
    .refine(data => numberSchema.safeParse(data).success)

  return transformed
}

/**
 * zFlexNumber() is a type that allows for either an actual number type, or a 
 * string that will translate directly to a number
 */
export function zFlexNumber(numberSchema: z.ZodNumber = z.number()) {
  // https://github.com/colinhacks/zod/issues/668 doesn't work right now
  return z.union([
    numberSchema,
    zStringNumber(numberSchema)
  ])
}

/**
 * zCSV() parses a comma-delimited string into an array, and validates it
 * against the provided arraySchema
 * @param arraySchema 
 */
export function zCSV(
  arraySchema: z.ZodArray<z.ZodTypeAny> = z.array(z.string())
) {
  return z
    .string()
    .transform((value: string): string[] => value.split(','))
    .refine(data => arraySchema.safeParse(data).success)
}
