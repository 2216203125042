import schemas from '@shared/schemas'
import { Form } from 'components/elements/Form'
import Section from 'components/elements/forms/inputs/Section'
import Select from 'components/elements/forms/inputs/Select'
import Submit from 'components/elements/forms/inputs/Submit'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { postApi } from 'modules/api'
import notifications from 'modules/notifications'
import progress from 'modules/progress'
import { ReactElement } from 'react'
import { RouteObject, useNavigate } from 'react-router-dom'
import createSchema from 'utils/createSchema'
import { withNamespace } from 'utils/forms'
import namespaces from 'utils/namespaces'
import { z } from 'zod'
import { useLease } from './_root'
import LeasePageShell from './_shell'

const UPDATE_SCHEMA = z.object({
  status: schemas.lease.states
})

const LeaseUpdateStatus: React.FC = () => {
  const { lease } = useLease()
  const navigate = useNavigate()

  const ns = withNamespace(namespaces.lease)
  const schema = createSchema()
    .with(namespaces.lease, UPDATE_SCHEMA)

  const initialValues = {
    [namespaces.lease]: { status: lease.status }
  }

  const onSubmit = (values: any, frmk: FormikHelpers<any>) => {
    asyncOnSubmit(values, frmk)
  }

  const asyncOnSubmit = async (values: any, frmk: FormikHelpers<any>) => {
    const done = () => {
      progress.done('update lease state')
      frmk.setSubmitting(false)
    }

    progress.start('update lease state')
    frmk.setSubmitting(true)

    const { lease: formData } = schema.filter(values)

    try {
      const response = await postApi('/UpdateLeaseState', {
        leaseId: lease.id,
        ...formData
      })

      if (response._.statusCode !== 200) {
        throw Error(response.message)
      }
    } catch (e: any) {
      console.error(e)
      notifications.error({ message: e.message })
      return done()
    }

    progress.done('update lease state')
    navigate('../')
  }

  const renderTooltip = (state: string): string => {
    let message = ''

    switch (state) {
      case 'active':
        message = [
          'This lease is active. This should only be set if the lease term is',
          'still underway, or has been updated.'
        ].join(' ');
        break;

      case 'complete':
        message = 'This lease is complete and is now closed.';
        break;
    
      case 'expired':
        message = [
          'This lease was active but the term has ended. No more statements',
          'will be issued until the status is changed.'
        ].join(' ');
        break;

      case 'terminated':
        message = 'This lease has been terminated by us, and is now closed.';
        break;

      case 'month_to_month': 
        message = [
          'The current leasee(s) will continue to occupy the assets.',
          'Statements will still be issued until the lease state is changed.'
        ].join(' ');
        break;

    }

    return message
  }

  const renderForm = (
    formikProps: FormikProps<typeof initialValues>
  ): ReactElement => {
    const states: Record<string, string> = {
      'active': 'Active',
      'expired': 'Expired',
      'terminated': 'Terminated',
      'complete': 'Complete',
      'month_to_month': 'Month-to-month'
    }

    const renderItem = (state: string) => {
      return (
        <option key={state} value={state}>{states[state]}</option>
      )
    }
    
    return (
      <Form onSubmit={formikProps.handleSubmit}>
        <Section fullWidth>
          <Select
            label='New status'
            className='col-span-6 sm:col-span-3'
            name={ns('status')}
            help={renderTooltip(formikProps.values.lease.status)}  
          >
            {Object.keys(states).map(renderItem)}
          </Select>
        </Section>

        <Submit
          fullWidth
          className='mt-6'
        />
      </Form>
    )
  }

  return (
    <LeasePageShell title='Update status' active='overview'>
      <Formik
        initialValues={initialValues}
        validate={schema.validate}
        onSubmit={onSubmit}
      >{renderForm}</Formik>
    </LeasePageShell>
  )
}

const route: RouteObject = {
  path: 'update/status',
  element: <LeaseUpdateStatus />
}

export default route

