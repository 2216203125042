import schemas from '@shared/schemas'
import { Attribute } from '@shared/schemas/attribute'
import { ExtendedLease, LineItem } from '@shared/schemas/lease'
import { ExtendedPerson } from '@shared/schemas/person'
import { Form } from 'components/elements/Form'
import Submit from 'components/elements/forms/inputs/Submit'
import LeaseForm from 'components/elements/forms/Lease'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { pick } from 'lodash'
import { postApi } from 'modules/api'
import notifications from 'modules/notifications'
import nProgress from 'nprogress'
import { LoaderFunction, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import { getLeaseWithLineItems } from 'stores/lease'
import { getLeasePeople } from 'stores/person'
import createSchema from 'utils/createSchema'
import getParams from 'utils/getParams'
import namespaces from 'utils/namespaces'
import { formatMoney } from 'utils/transforms'
import { z } from 'zod'
import { useProperty } from '../_root'
import LeasePageShell from './_shell'

type LoaderData = {
  lease: ExtendedLease
  lineItems: LineItem[]
  people: ExtendedPerson[]
}

const LIToAttr = (
  lineItems: LineItem[]
): Attribute[] => lineItems.map(item => {
  // Remove the currency, and form
  const value: any = formatMoney(item.amount).replace('$', '')

  const attr: Attribute = {
    id: 'custom_' + item.id, 
    name: item.description as string,
    type: 'number',
    value
  }

  return attr
})

const attrToLI= (
  attrs: Attribute[],
  leaseId: string
): LineItem[] => attrs.map(attr => {
  const lineItem: LineItem = {
    id: attr.id.replace('custom_', ''),
    leaseId,
    description: String(attr.name),
    amount: String(attr.value)
  }
  
  return lineItem
})

const EditLeasePageComponent: React.FC = () => {
  const property = useProperty()
  const { lease, people, lineItems } = useLoaderData() as LoaderData
  const navigate = useNavigate()

  const schema = createSchema()
    .with(namespaces.lease, schemas.lease.lease)
    .with(namespaces.lineItems, z.array(schemas.attribute.numberAttribute))

  const tenants = people.map(
    person => pick(person, [ 'id', 'firstName', 'lastName' ])
  )

  const leaseWithTenants = { ...lease, tenants }
  const { lease: filteredLeaseData } = schema.filter({ lease: leaseWithTenants })

  const initialValues = {
    [namespaces.lease]: filteredLeaseData,
    [namespaces.lineItems]: LIToAttr(lineItems)
  }

  const _onSubmit = async (values: any, frmk: FormikHelpers<any>) => {
    const done = () => {
      nProgress.done()
      frmk.setSubmitting(false)
    }

    const data = schema.filter(values)

    // We need to convert the attributes input back to line items
    data.lineItems = attrToLI(data.lineItems, lease.id)

    nProgress.start()

    const response = await postApi('/UpdateLease', data, {
      leaseId: lease.id
    })

    if (response._.statusCode !== 200) {
      notifications.error({
        message: 'Failed to update lease. Try again in a few minutes.'
      })
      
      return done()
    }

    const { leaseId } = response
    navigate(`/p/${property.id}/lease/${leaseId}`, { replace: true })
    return done()
  }

  const onSubmit = (values: any, frmk: FormikHelpers<any>) => {
    _onSubmit(values, frmk)
  }

  const renderForm = (formikProps: FormikProps<typeof initialValues>): JSX.Element => {
    return (
      <Form onSubmit={formikProps.handleSubmit}>
        <LeaseForm
          namespace={namespaces.lease}
          propertyId={property.id}
          visible
        />
        <Submit />
      </Form>
    )
  }

  return (
    <LeasePageShell title='Edit Lease' empty>
      <Formik
        initialValues={initialValues}
        validate={schema.validate}
        onSubmit={onSubmit}
      >{renderForm}</Formik>
    </LeasePageShell>
  )
}


const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { leaseId } = getParams(args)

  const [
    { lease, lineItems },
    people
  ] = await Promise.all([
    getLeaseWithLineItems({ leaseId }),
    getLeasePeople({ leaseId })
  ])

  return {
    lease,
    lineItems,
    people
  }
}

const route: RouteObject = {
  path: 'edit',
  loader,
  element: <EditLeasePageComponent />
}

export default route
