import * as z from 'zod'

export const user = z.object({
  id: z.string().uuid(),
  firstName: z.string(),
  lastName: z.string(),
  avatarUrl: z.string().nullable()
})

export const createUser = user.omit({
  id: true
})

export const users = z.array(user)

export type User = z.infer<typeof user>
export type CreateUser = z.infer<typeof createUser>
export type Users = z.infer<typeof users>
