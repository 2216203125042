import React, {
  Children,
  InputHTMLAttributes,
  isValidElement,
  cloneElement,
  ReactNode
} from 'react'
import { kebabCase, omit } from 'lodash'

interface FormProps {
  onSubmit?: any
  children: ReactNode
}

export interface BaseProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  help?: string
  error?: string
}

export function generateId(id?: string): string {
  if (!id) return ''
  return 'form-id-' + kebabCase(id)
}

export function Form(props: FormProps): JSX.Element {
  const {
    onSubmit,
    children
  } = props

  /**
   * We want to add the index of the item onto the child element so that we can
   * use it to render page-position-specific styles
   */
  const childrenWithProps = Children.map(children, (child, index) => {
    if (isValidElement(child)) {
      return cloneElement(child, { index } as any)
    }

    return child
  })

  return (
    <form onSubmit={onSubmit}>
      <div>
        {childrenWithProps}
      </div>
    </form>
  )
}

export function removeCustomProps(props: any): any {
  return omit(props, [
    'label',
    'help',
    'mask',
    'className',
    'children',
    'formik',
    'error',
    'fieldsetIndex',
    'children',
    'noFormik'
  ])
}

