import schemas from '@shared/schemas'
import { Property } from '@shared/schemas/property'
import { getTypedApi } from 'modules/typedApi'
import { z } from 'zod'

export const propertyWithMetadata = schemas.property.property.extend({
  metadata: z.object({
    assets: z.number(),
    people: z.number()
  })
})

export type PropertyWithMetadata = z.infer<typeof propertyWithMetadata>

export async function getProperty(
  { propertyId }:
  { propertyId: string }
): Promise<Property> {
  return await getTypedApi({
    url: '/GetProperty',
    path: 'property',
    query: { propertyId },
    schema: schemas.property.property,
    cache: true
  })
}

export async function getProperties(): Promise<PropertyWithMetadata[]> {
  // no args because getProperties is based on the logged in user
  return await getTypedApi({
    url: '/GetProperties',
    schema: z.array(propertyWithMetadata),
    path: 'properties'
  })
}

export interface HealthStats {
  overdueInvoices: number
  expiredLeases: number
  vacantAssets: Record<string, number>
}

export async function getPropertyHealth(
  { propertyId }:
  { propertyId: string }
): Promise<HealthStats> {
  return await getTypedApi({
    url: '/GetPropertyHealth',
    schema: z.object({
      overdueInvoices: z.number(),
      expiredLeases: z.number(),
      vacantAssets: z.record(
        schemas.asset.types,
        z.number()
      )
    }),
    query: { propertyId },
    path: 'health'
  })
}
