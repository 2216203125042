import VerticalNav from 'components/elements/VerticalNav'
import { IoCash, IoStatsChartOutline, IoWalk } from 'react-icons/io5'
import { useMatch } from 'react-router-dom'

export default function PersonNav() {
  const data = useMatch('/p/:propertyId/person/:personId/:page')
  const active = data?.params?.page || 'overview'

  const dashboard = {
    id: 'overview',
    label: 'Overview',
    icon: <IoStatsChartOutline />,
    href: ''
  }

  const activity = {
    id: 'activity',
    label: 'Activity',
    icon: <IoWalk />,
    href: 'activity'
  }

  const leases = {
    id: 'leases',
    label: 'Leases',
    icon: <IoCash />,
    href: 'leases'
  }

  const items = [
    dashboard,
    activity,
    leases
  ]

  return (
    <VerticalNav
      items={items}
      className='w-full'
      active={active}
    />
  )
}

