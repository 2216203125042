import schemas from '@shared/schemas'
import HeaderAvatar from 'components/app/HeaderAvatar'
import Page from 'components/app/Page'
import Shell from 'components/app/Shell'
import { Form } from 'components/elements/Form'
import AddressForm from 'components/elements/forms/Address'
import { Checkbox } from 'components/elements/forms/inputs/Checkbox'
import FieldSet from 'components/elements/forms/inputs/FieldSet'
import Section from 'components/elements/forms/inputs/Section'
import Submit from 'components/elements/forms/inputs/Submit'
import TextInput from 'components/elements/forms/inputs/Text'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { uniq } from 'lodash'
import { postApi } from 'modules/api'
import notifications from 'modules/notifications'
import progress from 'modules/progress'
import { ChangeEvent, ReactElement, useState } from 'react'
import { FaBuilding } from 'react-icons/fa'
import { RouteObject, useNavigate } from 'react-router-dom'
import createSchema from 'utils/createSchema'
import { withNamespace } from 'utils/forms'
import namespaces from 'utils/namespaces'


const CreatePropertyPage: React.FC = () => { 
  const [ assetTypes, setAssetTypes ] = useState<string[]>([])
  const navigate = useNavigate()

  const ns = withNamespace(namespaces.property)
  const schema = createSchema()
    .with(namespaces.property, schemas.property.createProperty)
    .with(namespaces.address, schemas.address.address)
  
  const initialValues = {
    [namespaces.property]: schemas.property.blanks.createProperty,
    [namespaces.address]: schemas.address.blanks.address
  }

  const asyncOnSubmit = async (values: any, frmk: FormikHelpers<any>) => {
    const done = () => {
      progress.done('add property')
      frmk.setSubmitting(false)
    }

    const data = schema.filter(values)
    data.property.assetTypes = assetTypes

    progress.start('add property')

    try {
      const response = await postApi('/CreateProperty', data)
      
      if (response._.statusCode !== 200) {
        throw new Error(response.message)
      }
    } catch (e: any) {
      notifications.error({ message: e.message })
      return done()
    }
    
    notifications.success({
      message: 'Property has been created!'
    })

    progress.done('add property')
    navigate('/')
  }

  const onSubmit = (values: any, frmk: FormikHelpers<any>) => {
    asyncOnSubmit(values, frmk)
  }

  const handleAssetTypeSelect = (types: string[]) => {
    return (evt: ChangeEvent<HTMLInputElement>) => {
      const checked = evt.target.checked
      const updated = checked
        ? uniq([ ...assetTypes, ...types ])
        : assetTypes.filter((i: string) => !types.includes(i))

      setAssetTypes(updated)
    }
  }

  const renderForm = (formikProps: FormikProps<any>): ReactElement => {
    const handleReset = () => {
      // todo: go back
    }

    return (
      <Form onSubmit={formikProps.handleSubmit}>
        <Section
          title='Details'
          subtitle='General information about the property.'
        >
          <TextInput
            label='Property name'
            help='Either the actual name of the property, or something that makes it easy to identify.'
            className='col-span-6 sm:col-span-3'
            name={ns('displayName')}
          />
        </Section>


        <Section
          gridCols={0}
          title='Asset types'
          subtitle='What types of assets will this property have? This can be adjusted at any time.'
        >
          <FieldSet title='Choose assets'>
            <Checkbox
              label='Mobiles homes and lots'
              onChange={handleAssetTypeSelect([ 'mhp_home', 'mhp_lot' ])}
              checked={assetTypes.includes('mhp_home')}
              name='hasMhp'
            />

            <Checkbox
              label='Apartments'
              onChange={handleAssetTypeSelect([ 'apartment' ])}
              checked={assetTypes.includes('apartment')}
              name='hasApartments'
            />

            <Checkbox
              label='Parking spaces'
              onChange={handleAssetTypeSelect([ 'parking' ])}
              checked={assetTypes.includes('parking')}
              name='hasParking'
            />
            
            <Checkbox
              label='Storage Units'
              onChange={handleAssetTypeSelect([ 'storage_unit' ])}
              checked={assetTypes.includes('storage_unit')}
              name='hasStorage'
            />
          </FieldSet>
        </Section>

        <AddressForm namespace={namespaces.address} visible />

        <Submit handleReset={handleReset} />
      </Form>
    )
  }

  return (
    <Shell active='DASHBOARD' hideSearch>
      <HeaderAvatar
        title='Add a property'
        avatarIcon={<FaBuilding />}
      />

      <Page>
        <Formik
          initialValues={initialValues}
          validate={schema.validate}
          onSubmit={onSubmit}
        >{renderForm}</Formik>
      </Page>
    </Shell>
  )
}

const route: RouteObject = {
  path: 'properties/create',
  element: <CreatePropertyPage />
}

export default route
