import Page from 'components/app/Page'
import Shell from 'components/app/Shell'
import { ReactElement } from 'react'
import { assetTypeToReadable } from 'utils/assetTypes'
import { useProperty } from '../_root'
import Header from './_header'
import Nav from './_nav'
import { useLease } from './_root'

type Props = {
  title?: string
  subtitle?: string
  actions?: ReactElement[]
  children?: ReactElement | ReactElement[]
  empty?: boolean
  active?: string
}

const LeasePageShell = (props: Props): ReactElement => {
  const property = useProperty()
  const { lease, asset: primaryAsset } = useLease()

  const {
    title = 'Lease',
    subtitle = '',
    actions = [],
    children,
    empty = false,
    active = ''
  } = props

  const crumbs = () => {
    return [
      {
        label: property.displayName,
        href: `/p/${property.id}`
      },
      {
        label: assetTypeToReadable(primaryAsset.type, true),
        href: `/p/${property.id}/assets/${primaryAsset.type}`
      },
      {
        label: primaryAsset.displayId || assetTypeToReadable(primaryAsset.type),
        href: `/p/${property.id}/asset/${primaryAsset.id}`
      },
      {
        label: 'Lease',
        href: `/p/${property.id}/lease/${lease.id}`
      }
    ]
  }

  return (
    <Shell active='LEASES' breadcrumbs={crumbs()}>
      <Header
        title={title}
        subtitle={subtitle || ''}
        actions={actions}
      />
      <Page>
        {
          empty === false
          ? <div className='grid grid-cols-3 gap-6'>
              <div className='col-span-2'>
                {children}
              </div>
              <div className='col-span-1'>
                <Nav active={active} />
              </div>
            </div>
          : <>{children}</>
        }
      </Page>
    </Shell>
  )
}

export default LeasePageShell
