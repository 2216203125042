import React from 'react'
import GenericTransition from './Generic'
import styles from './FadeUp.module.css'

export default function FadeUp(props: any): JSX.Element {
  const _props = {
    appear: true,
    ...props
  }

  return (
    <GenericTransition {..._props} styles={styles}>
      {props.children}
    </GenericTransition>
  )
}
