import { useEffect } from 'react'

export default function useOutsideClick(
  ref: React.MutableRefObject<any>,
  func: any
): void {
  useEffect(() => {
    const handleClicks = (evt: any) => {
      if (ref.current && !ref.current.contains(evt.target)) {
        func()
      }
    }

    document.addEventListener('mousedown', handleClicks)
    
    return () => {
      document.removeEventListener('mousedown', handleClicks)
    }
  }, [ ref, func ]) 
}
