import { CubeTransparentIcon } from '@heroicons/react/20/solid'
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline'
import { RocketLaunchIcon } from '@heroicons/react/24/solid'
import { ExtendedActivityItem, ExtendedActivityItemWithPayload } from '@shared/schemas/activity'
import { User } from '@shared/schemas/user'
import Avatar from 'components/elements/Avatar'
import { Card, CardFooter, CardHeader } from 'components/elements/Card'
import DescriptionList from 'components/elements/DescriptionList'
import Empty from 'components/elements/Empty'
import Link from 'components/elements/Link'
import { MODAL_CLOSE_DURATION } from 'components/elements/Modal'
import SimpleModal from 'components/elements/modals/SimpleModal'
import Table, { Column, Row } from 'components/elements/Table'
import { DateTime } from 'luxon'
import progress from 'modules/progress'
import { ReactElement, useState } from 'react'
import { ExtendedActivityData, getActivity, getActivityByPropertyId } from 'stores/activity'
import { getUser } from 'stores/user'
import { formatMoney } from 'utils/transforms'

export interface ActivityProps {
  items: ExtendedActivityItem[]
  users: User[]
  noFooter?: boolean
  absoluteTime?: boolean
}

export async function loadActivityItemsByPropertyId(
  { propertyId, limit = 10, offset = 0 }:
  { propertyId: string, limit?: number, offset?: number }
): Promise<ActivityProps> {
  const items = await getActivityByPropertyId({
    propertyId,
    limit,
    offset
  })

  const uniqueUserIds = [...new Set(
    items.map(item => item.userId).filter(Boolean)
  )] as string[]

  const users = await Promise.all(uniqueUserIds.map(userId => {
    return getUser({ userId })
  }))

  return {
    items,
    users
  }
}

export default function Activity(props: ActivityProps): ReactElement {
  const {
    items,
    users,
    noFooter = false,
    absoluteTime = false
  } = props
  
  const [ modalVisible, setModalVisible ] = useState<boolean>(false)
  const [ modalItem, setModalItem ] = useState<ExtendedActivityData|null>(null)

  const renderUserDisplayName = (userId?: string|null, user?: User|null): string => {
    if (!userId) {
      // no user id means this is the system
      return 'System'
    }

    if (user) {
      return `${user.firstName} ${user.lastName}`
    }

    return 'Unknown'
  }

  const renderUserAvatar = (item: ExtendedActivityItem): ReactElement => {
    const { userId } = item

    if (!userId) {
      return <Avatar icon={<CubeTransparentIcon className='w-4' />} size={6} />
    }

    const user = users.find(user => user.id === userId)

    if (!user) {
      return <Avatar initials={'?'} size={6} />
    }

    const initials: string = [
      user.firstName.slice(0, 1).toUpperCase(),
      user.lastName.slice(0, 1).toUpperCase()
    ].join('')

    return (
      <Avatar
        initials={initials}
        size={6}
        src={user.avatarUrl}
      />
    )
  }

  const renderMessage = (item: ExtendedActivityItemWithPayload): ReactElement => {
    const { type, payload } = item
    
    const renderHighlight = (str: string): ReactElement => {
      return (<span className='text-indigo-600'>{str}</span>)
    }

    switch (type) {
      case 'asset.created.v1': return <span>created {payload.assetName}</span>
      case 'asset.updated.v1': return <span>updated {payload.assetName}</span>
      case 'asset.deleted.v1': return <span>deleted {payload.assetName}</span>
      case 'billing.invoice.created.v1': return <span>created an invoice for {formatMoney(payload.amount)}</span>
      case 'billing.invoice.deleted.v1': return <span>deleted an invoice</span>
      case 'billing.invoice.updated.v1': return <span>updated an invoice</span>
      case 'billing.payment.created.v1': return <span>created a payment for {formatMoney(payload.amount)}</span>
      case 'billing.payment.deleted.v1': return <span>deleted a payment</span>
      case 'billing.payment.updated.v1': return <span>updated a payment</span>
      case 'lease.created.v1': return <span>created a lease for {payload.assetName}</span>
      case 'lease.deleted.v1': return <span>deleted a lease for {payload.assetName}</span>
      case 'lease.state_changed.v1': return <span>changed the state of a lease</span>
      case 'person.created.v1': return <span>added a person: {payload.personName}</span>
      case 'person.deleted.v1': return <span>deleted a person: {payload.personName}</span>
      case 'person.updated.v1': return <span>updated a person: {payload.personName}</span>
      case 'document.created.v1': return <span>added {renderHighlight(payload.documentName)}</span>
      case 'document.deleted.v1': return <span>deleted {payload.documentName}</span>
      case 'document.read.v1': return <span>read {payload.documentName}</span>
      case 'comment.created.v1': return <span>added a comment</span>
      case 'comment.deleted.v1': return <span>deleted a comment</span>
      case 'comment.updated.v1': return <span>updated a comment</span>
      default:
        const _exhaustiveCheck: never = item
        return _exhaustiveCheck
    }
  }

  const renderItem = (item: ExtendedActivityItemWithPayload): ReactElement => {
    const { userId } = item
    const user = users.find(user => user.id === userId)
    const name = renderUserDisplayName(userId, user)
      

    return (
      <>
        <span className='font-medium'>{name}</span>&nbsp;
        {renderMessage(item)}
      </>
    )
  }

  const loadActivity = async (activityId: string) => {
    progress.start('loading activity')
    const data = await getActivity({ id: activityId })

    setModalItem(data)
    setModalVisible(true)
    
    progress.done('loading activity')
  }

  const renderRow = (item: ExtendedActivityItem): Row => {
    const time = absoluteTime
      ? DateTime
          .fromJSDate(item.createdAt, { zone: 'UTC' })
          .toLocaleString(DateTime.DATETIME_MED)
      : DateTime
          .fromJSDate(item.createdAt, { zone: 'UTC' })
          .toRelative()

    const onClick =  () => loadActivity(item.id)

    return {
      user: renderUserAvatar(item),
      activity: renderItem(item),
      time,
      config: {
        onClick
      }
    }
  }

  const renderDetailModal = (): ReactElement => {
    const onClose = () => {
      setModalVisible(false)
      setTimeout(() => setModalItem(null), MODAL_CLOSE_DURATION)
    }

    if (!modalItem) {
      // this should be unreachable
      return <></>
    }

    const user = modalItem.activity.userId
      ? users.find(user => user.id === modalItem.activity.userId)
      : null

    const data = [
      { label: 'Type', value: modalItem.activity.type  },
      { label: 'Time', value: DateTime.fromJSDate(modalItem.activity.createdAt).toLocaleString(DateTime.DATETIME_MED) },
      { label: 'User', value: renderUserDisplayName(modalItem.activity.userId, user) },
      { label: 'Payload', value: <span className='font-mono whitespace-pre-wrap block overflow-auto bg-gray-100'>{JSON.stringify(modalItem.activity.payload, null, 2)}</span> }
    ]

    if (modalItem.link) {
      data.push({ label: 'Link', value: <Link className='flex items-center' to={modalItem.link}>View this item <ArrowRightCircleIcon className='w-4 ml-1' /> </Link> })
    }

    if (modalItem.parentLink) {
      data.push({ label: 'Parent', value: <Link className='flex items-center' to={modalItem.parentLink}>View where this item appears <ArrowRightCircleIcon className='w-4 ml-1' /> </Link>  })
    }

    return (
      <SimpleModal 
        title='Activity Details'
        subtitle=''
        divider
        onClose={onClose}
        open={modalVisible}
      >
        <DescriptionList 
          inline
          data={data}
        />
      </SimpleModal>
    )
  }

  const renderTable = (): ReactElement => {
    const columns: Column[] = [
      { label: '', key: 'user', rowClasses: 'w-5 pr-0' },
      { label: '', key: 'activity' },
      { label: '', key: 'time', rowClasses: 'text-right'}
    ]

    const rows: Row[] = items.map(renderRow)
    const empty = (
      <Empty
        className='py-12'
        icon={<RocketLaunchIcon className='w-20' />}
        message='No activity has been recorded yet.'
      />
    )

    return (
      <Table
        columns={columns}
        rows={rows}
        inline
        empty={empty}
      />
    )
  }

  const renderFooter = (): ReactElement => {
    return (
      <CardFooter>
        <Link
          to={`/activity`}
          relative='property'
          className='flex text-sm justify-center font-medium text-gray-500 hover:text-gray-700 sm:rounded-b-lg'
        >
          <span className='flex items-center text-center'>
            View all activity <ArrowRightCircleIcon className='w-4 inline-block ml-1' />
          </span>
        </Link>
      </CardFooter>
    )
  }

  return (
    <Card>
      {renderDetailModal()}
      <CardHeader
        title='Activity'
        small
      />
      {renderTable()}
      {!!noFooter ? null : renderFooter()}
    </Card>
  )
}
