import * as z from 'zod'
import { extendedAddress } from './address'
import { zFlexDate } from './util'

const contactMethods = z.object({
  id: z.enum([ 'phone', 'email' ]),
  name: z.string().optional(),
  value: z.string()
})

export const person = z.object({
  type: z.enum([ 'tenant' ]),
  firstName: z.string().max(30).min(1, { message: 'cannot be blank' }),
  lastName: z.string().max(30).min(1, { message: 'cannot be blank' }),
  birthDate: zFlexDate().nullable().optional(),
  contactMethods: z.array(contactMethods).nullable().optional(),
  notes: z.string().optional()
})

export const extendedPerson = person.merge(z.object({
  id: z.string().uuid(),
  propertyId: z.string().uuid(),
  createdAt: zFlexDate(),
  address: extendedAddress.nullable().optional()
}))

export const extendedPeople = z.array(extendedPerson)

export const personStub = extendedPerson
  .pick({
    id: true,
    firstName: true,
    lastName: true,
    address: true // address is already optional
  })
  .extend({
    propertyId: extendedPerson.shape.propertyId.optional(),
    assetId: z.string().uuid().optional(),
    assetIds: z.array(z.string().uuid()).optional()
  })

export const peopleStubs = z.array(personStub)

export type Person = z.infer<typeof person>
export type ExtendedPerson = z.infer<typeof extendedPerson>
export type PersonStub = z.infer<typeof personStub>
export type PeopleStubs = z.infer<typeof peopleStubs>

const blankPerson: Person = {
  type: 'tenant',
  firstName: '',
  lastName: '',
  birthDate: null,
  contactMethods: null,
  notes: ''
}

const extendedBlankPerson: ExtendedPerson = {
  ...blankPerson,
  id: '_blank',
  propertyId: '_blank',
  createdAt: new Date(),
  address: null
}

export const blanks = Object.freeze({
  person: blankPerson,
  extendedPerson: extendedBlankPerson
})
