import { User } from '@shared/schemas/user'
import { createContext } from 'react'

const GUEST_USER: User = {
  id: '_guest',
  firstName: 'Guest',
  lastName: 'User',
  avatarUrl: null
}

export const UserContext = createContext<User>(GUEST_USER)
