import React, { ReactElement } from 'react'
import classNames from 'classnames'

interface Props {
  icon?: ReactElement
  message?: string|ReactElement
  className?: string
  height?: string
}

interface PropsWithActions extends Props {
  action: () => void
  actionMessage: string|ReactElement
}

export default function Empty(props: PropsWithActions): JSX.Element;
export default function Empty(props: Props): JSX.Element;
export default function Empty(props: any): JSX.Element {
  const {
    icon = null,
    message = null,
    height = '',
    action = null,
    actionMessage = null,
    className = ''
  } = props
    
  const renderIcon = (): JSX.Element => {
    if (icon === null) {
      return <React.Fragment />
    }

    const iconStyle = {
      fontSize: '40px'
    }

    const clonedIcon = React.cloneElement(icon, {
      style: iconStyle
    })

    return (
      <div className='text-gray-300'>
        {clonedIcon}
      </div>
    )
  }

  const renderMessage = (): JSX.Element => {
    if (message === null) {
      return <React.Fragment />
    }

    const classes = classNames({
      'mt-2': icon !== null
    })
      
    return (
      <div className={classes}>
        <span className='text-gray-400'>{message}</span>
      </div>
    )
  }

  const renderAction = (): JSX.Element => {
    if (actionMessage === null) {
      return <React.Fragment />
    }

    const classes: string = classNames(
      'text-indigo-500 hover:text-indigo-700 text-xs font-medium mt-1'
    )

    return (
      <button className={classes} onClick={action}>
        {actionMessage}
      </button>
    )
  }

  const classes: string = classNames({
    'flex-1': height === '',
    'flex items-center justify-center flex-col select-none': true,
    [className]: className !== ''
  })
  
  const styles: React.CSSProperties = {}

  if (height !== '') {
    styles.height = height
  }

  return (
    <div className={classes} style={styles}>
      {renderIcon()}
      {renderMessage()}
      {renderAction()}
    </div>
  )
}
