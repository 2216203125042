import * as z from 'zod'
import { zFlexCurrency, zFlexDate, zFlexNumber } from './util'

export const states = z.enum([
  'active',
  'expired',
  'terminated',
  'complete',
  'month_to_month',
  'any' // this is not actually a valid state, but will make our lives easier
])

/**
 * We don't actually care about getting the names of the tenants, but due to
 * the nature of the way the form is rendered, it's easier just to pass them up
 */
const tenant = z.object({
  id: z.string().uuid(),
  firstName: z.string().optional(),
  lastName: z.string().optional()
})

export const lease = z.object({
  tenants: z.array(tenant).min(1),
  termStart: zFlexDate(),
  termEnd: zFlexDate(),
  dueDay: zFlexNumber(z.number().int().min(1).max(31)),
  lateAfterDays: zFlexNumber(z.number().int().min(1).max(21))
})

export const extendedLease = lease.omit({
  tenants: true
}).merge(z.object({
  id: z.string().uuid(),
  assetId: z.string().uuid(),
  status: states,
  createdAt: zFlexDate(),
  lastAutomaticInvoiceDue: zFlexDate().nullable()
}))

export const extendedLeases = z.array(extendedLease)

export const metadata = z.object({
  balances: z.object({ 
    total: zFlexCurrency(),
    paid: zFlexCurrency(),
    balance: zFlexCurrency(),
    upcoming: zFlexCurrency()
  }),
  upcomingInvoiceId: z.string()
})

// Activity items are used to render the activity feed for leases
export const activityItem = z.object({
  id: z.string().uuid(),
  type: z.enum([ 'statement', 'payment' ]),
  date: zFlexDate(),
  amount: zFlexCurrency()
})

export const activity = z.array(activityItem)

export const lineItem = z.object({
  id: z.string().uuid(),
  leaseId: z.string().uuid(),
  description: z.string().nullable(),
  amount: zFlexCurrency()
})

export const lineItems = z.array(lineItem)

export type States = z.infer<typeof states>
export type Lease = z.infer<typeof lease>
export type ExtendedLease = z.infer<typeof extendedLease>
export type LineItem = z.infer<typeof lineItem>
export type LeaseActivityItem = z.infer<typeof activityItem>
export type LeaseActivity = z.infer<typeof activity>
export type LeaseMetadata = z.infer<typeof metadata>

const currentDate = new Date()
const blankLease: Lease = {
  tenants: [],
  termStart: currentDate,
  termEnd: currentDate,
  dueDay: 1,
  lateAfterDays: 5
}

export const blanks = Object.freeze({
  lease: blankLease
})
