import { Card, CardBody, CardHeader } from 'components/elements/Card'
import Empty from 'components/elements/Empty'
import { ReactElement } from 'react'
import { IoWalk } from 'react-icons/io5'
import { RouteObject } from 'react-router-dom'


const PersonActivityPageComponent = (): ReactElement => {
  return (
    <>
        <Card>
          <CardHeader title='Activity' />
          <CardBody>
            <Empty
              icon={<IoWalk />}
              message={`Not implemented yet.`}
              height='200px'
            />
          </CardBody>
        </Card>
      </>
  )
}

const route: RouteObject = {
  path: 'activity',
  element: <PersonActivityPageComponent />
}

export default route
