import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { useNavigate } from 'react-router-dom'
import Button from '../../Button'

interface Props {
  handleReset?: () => void
  fullWidth?: boolean
  className?: string
  submitLabel?: string
  submittingLabel?: string
}

export default function Submit(props: Props): JSX.Element {
  const navigate = useNavigate()

  const formik = useFormikContext()
  const {
    handleReset,
    fullWidth = false,
    className = 'mt-6',
    submitLabel = 'Submit',
    submittingLabel = 'Submitting...'
  } = props

  const { isSubmitting, submitForm } = formik

  const styles = classNames({
    'flex justify-end': true,
    'col-span-2 pr-2': !fullWidth,
    'col-span-3': fullWidth
  })

  return (
    <div className={className}>
      <div className='grid grid-cols-3'>
        <div className={styles}>
          <Button
            type='secondary'
            className='mr-2'
            disabled={isSubmitting}
            onClick={handleReset ? handleReset : () => navigate(-1) }
          >Cancel</Button>
          <Button
            type='primary'
            onClick={submitForm}
            disabled={isSubmitting}
          >{isSubmitting ? submittingLabel : submitLabel }</Button>
        </div>
      </div>
    </div>
  )
}
