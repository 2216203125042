import { ReactElement } from 'react'
import HeaderAvatar from './HeaderAvatar'

interface HeaderMetadataItem {
  label: string|ReactElement
  icon: ReactElement
}

interface Props {
  title: string|ReactElement
  actions?: ReactElement[]
  metadata?: HeaderMetadataItem[]
}

export default function Header(props: Props): JSX.Element {
  return <HeaderAvatar {...props} />
}
