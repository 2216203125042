import { BillingInvoice, paymentProviders } from '@shared/schemas/billing'
import { ExtendedPerson } from '@shared/schemas/person'
import { zFlexCurrency, zFlexDate } from '@shared/schemas/util'
import { ActionPanelWithToggle } from 'components/elements/ActionPanel'
import Alert from 'components/elements/Alert'
import { Form } from 'components/elements/Form'
import ComboboxInput from 'components/elements/forms/inputs/Combobox'
import DateInput from 'components/elements/forms/inputs/Date'
import Submit from 'components/elements/forms/inputs/Submit'
import TextInput from 'components/elements/forms/inputs/Text'
import Decimal from 'decimal.js'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { DateTime } from 'luxon'
import { postApi } from 'modules/api'
import notifications from 'modules/notifications'
import progress from 'modules/progress'
import { ReactElement, useState } from 'react'
import { LoaderFunction, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import { getBillingInvoice } from 'stores/billing'
import { getLeasePeople } from 'stores/person'
import createSchema from 'utils/createSchema'
import { withNamespace } from 'utils/forms'
import getParams from 'utils/getParams'
import { getSearchParams } from 'utils/getQuery'
import { decimalFromMoney } from 'utils/money'
import namespaces from 'utils/namespaces'
import { z } from 'zod'
import { useProperty } from '../_root'
import { useLease } from './_root'
import LeasePageShell from './_shell'

type LoaderData = {
  people: ExtendedPerson[]
  invoice: BillingInvoice|null
}

const PAYMENT_SCHEMA = z.object({
  paidAt: zFlexDate(),
  amount: zFlexCurrency(),
  leaseId: z.string().uuid(),
  personId: z.union([ z.enum([ 'null' ]), z.string().uuid().nullable() ]),
  provider: paymentProviders
})

const LeaseAddPaymentPage: React.FC = () => {
  const { lease } = useLease()
  const { people, invoice } = useLoaderData() as LoaderData
  const property = useProperty()
  const navigate = useNavigate()

  // We want to remove the late fee by default, if eligible.
  const [ removeLateFee, setRemoveLateFee ] = useState(false)
  const [ firstPerson ] = people

  const remainingBalance: Decimal = invoice !== null
    ? decimalFromMoney(invoice.amount).sub(
        decimalFromMoney(invoice.paid)
      )
    : new Decimal(0)

  const BLANK_PAYMENT: Readonly<z.infer<typeof PAYMENT_SCHEMA>> = {
    paidAt: new Date(),
    amount: remainingBalance.toFixed(2),
    personId: firstPerson.id,
    leaseId: lease.id,
    provider: 'manual'
  }

  const ns = withNamespace(namespaces.payment)
  
  const schema = createSchema()
    .with(namespaces.payment, PAYMENT_SCHEMA)

  const initialValues = {
    [namespaces.payment]: {
      ...BLANK_PAYMENT,
      leaseId: lease.id
    }
  }

  const onSubmit = (values: any, frmk: FormikHelpers<any>) => {
    asyncOnSubmit(values, frmk)
  }

  const asyncOnSubmit = async (values: any, frmk: FormikHelpers<any>) => {
    const done = () => {
      progress.done('add payment')
      frmk.setSubmitting(false)
    }

    const { payment }: { payment: z.infer<typeof PAYMENT_SCHEMA> } = schema.filter(values)

    progress.start('add payment')
    frmk.setSubmitting(true)

    try {
      // Create the payment
      let _r = await postApi('/CreateBillingPayment', {
        payment
      })

      if (_r._.statusCode !== 200) throw Error(_r.message)


      const { paymentId } = _r

      if (invoice !== null) {
        notifications.info({ message: 'Payment created...' })
        
        /**
         * The invoice was not null, so we are attaching this payment to it.
         * However, the payment can be more than the invoice balance, so we need
         * to calculate the correct amount to apply to the invoice.
         */
        const $ = {
          paymentAmount: decimalFromMoney(payment.amount),
          invoiceAmount: decimalFromMoney(invoice.amount),
          invoicePaid: decimalFromMoney(invoice.paid)
        }

        const invoiceBalance: Decimal = $.invoiceAmount.sub($.invoicePaid)
        const assignedAmount: Decimal = $.paymentAmount.lessThan(invoiceBalance)
          ? $.paymentAmount
          : invoiceBalance

        // Add a payment distribution to the invoice
        _r = await postApi('/UpdateBillingPaymentDistribution', {
          paymentId,
          invoiceId: invoice.id,
          amount: assignedAmount.toFixed(2)
        })

        if (_r._.statusCode !== 200) throw Error(_r.message)

        notifications.info({ message: 'Payment assigned to invoice...' })

        if (removeLateFee) {
          _r = await postApi('/UpdateBillingInvoiceRemoveLateFee', {
            invoiceId: invoice.id
          })

          if (_r._.statusCode !== 200) throw Error(_r.message)

          notifications.info({ message: 'Late fee removed.' })
        }
      }

      notifications.success({ message: 'Payment created successfully.' }) 
      progress.done('add payment')
      navigate(`/p/${property.id}/lease/${lease.id}/payment/${paymentId}`)
    } catch (e: any) {
      console.error(e)
      notifications.error({ message: e.message })
      return done()
    }
  }

  const renderInvoice = (): ReactElement => {
    if (!invoice) return <></>
    
    const due = DateTime
      .fromJSDate(invoice.due, { zone: 'UTC' })
      .toLocaleString(DateTime.DATE_FULL)

    const message = (
      <span>
        You are assigning this payment directly to the invoice due on
        <span className='ml-1 font-medium'>{due}</span>.
      </span>
    )
    
    return (
      <Alert
        type='info'
        title={`Payment to Invoice #${invoice.id.slice(-6).toUpperCase()}`}
        message={message}
        className='mb-6'
      />
    )
  }

  const renderForm = (formikProps: FormikProps<typeof initialValues>): ReactElement => {
    const renderLateFeeAlert = (): ReactElement => {
      if (invoice === null) return <></>
      if (invoice.late === false) return <></>
      
      let forceEnable = false

      // grace period is 5 days
      const gracePeriodEnds = DateTime
        .fromJSDate(invoice.due, { zone: 'UTC' })
        .plus({ days: 5 })

      const paidDate = DateTime
        .fromJSDate(formikProps.values[namespaces.payment].paidAt, { zone: 'UTC' })


      if (paidDate < gracePeriodEnds) {
        forceEnable = true
      }

      const enabled = forceEnable
        ? true
        : removeLateFee

      return (
        <ActionPanelWithToggle
          enabled={enabled}
          setEnabled={setRemoveLateFee}
          className='mb-6'
          title='Remove late fee?'
          message='This invoice is marked late, would you like to remove the late fee? If the payment falls within the grace period, this will be enabled automatically.'
        />
      )
    }

    const renderWhoInput = () => {
      return (
        <ComboboxInput
          label='Paid by'
          className='mt-2'
          selected={formikProps.values[namespaces.payment].personId}
          setSelected={(value: string) => {
            formikProps.setFieldValue(ns('personId'), value)
          }}
          displayValue={(key: string) => {
            const person = people.find(person => person.id === key)
            
            if (!person) return 'Other'

            return `${person.firstName} ${person.lastName}`
          }}
          options={(filter: string) => {
            const withNullOption = [ ...people, null ]

            return withNullOption
              .filter(person => {
                if (person === null) {
                  // this is the other option
                  return "other".includes(filter.toLowerCase())
                }

                const name = `${person.firstName} ${person.lastName}`
                return name.includes(filter.toLowerCase())
              })
          }}
          getKey={(option: ExtendedPerson) => {
            if (option === null) return 'null'
            return option.id
          }}
        />
      )
    }
    
    return (
      <Form onSubmit={formikProps.handleSubmit}>
        {renderInvoice()}
        {renderLateFeeAlert()}

        <div className='grid grid-cols-2 gap-4'>
          <div className='col-span-1'>
            <DateInput
              label='Date paid'
              className='col-span-6 sm:col-span-3'
              name={ns('paidAt')}
              inline
            />
          </div>

          <div className='col-span-1'>
            <TextInput
              displayType='inline'
              className='col-span-6 sm:col-span-3'
              inputClassName='text-3xl py-2'
              label='Amount'
              clearOnFocus='0'
              name={ns('amount')}
            />

            {renderWhoInput()}

            <Submit
              fullWidth
              className='mt-4'
            />
          </div>
        </div>
        
      </Form>
    )
  }

  return (
    <LeasePageShell title='Add payment'>
      <Formik
        initialValues={initialValues}
        validate={schema.validate}
        onSubmit={onSubmit}
      >{renderForm}</Formik>
    </LeasePageShell>
  )
}

const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { leaseId } = getParams(args)
  const { invoice: invoiceId = '' } = getSearchParams(args)

  let invoice: BillingInvoice|null = null

  if (invoiceId !== '') {
    invoice = await getBillingInvoice({ invoiceId })
  }

  // TODO: get lease people should probably just return the IDs of people
  const people = await getLeasePeople({ leaseId })

  return {
    people,
    invoice
  }
}

const route: RouteObject = {
  path: 'payments/create',
  loader,
  element: <LeaseAddPaymentPage />
}

export default route
