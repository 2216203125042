import * as Attributes from '@shared/schemas/attribute'
import { useFormikContext } from 'formik'
import { isBoolean } from 'lodash'
import { withNamespace } from '../../../utils/forms'
import AttributesInput from './inputs/Attributes'
import { Checkbox } from './inputs/Checkbox'
import DateInput from './inputs/Date'
import FieldSet from './inputs/FieldSet'
import Radio from './inputs/Radio'
import Section from './inputs/Section'
import TextInput from './inputs/Text'
import TextArea from './inputs/TextArea'

type SetHasAddressFunc<T> = (hasAddress: T) => void

interface Props<T> {
  visible?: boolean
  title?: string
  subtitle?: string
  index?: number
  namespace?: string
  hasAddress: T
  setHasAddress: SetHasAddressFunc<T>
  fullWidth?: boolean
}

const CONTACT_ATTRIBUTES: Attributes.Attributes = [
  { id: 'email', name: 'Email', type: 'string', value: '' },
  { id: 'phone', name: 'Phone', type: 'string', value: '' }
]

export default function PersonForm<T extends string|boolean>(props: Props<T>): JSX.Element {
  const {
    visible = true,
    title = 'Person',
    subtitle = '',
    index = 0,
    namespace: ns = null,
    hasAddress,
    setHasAddress,
    fullWidth = false
  } = props
  
  const formik = useFormikContext()
  const namespace = withNamespace(ns)

  const renderAddressOptions = (): JSX.Element => {
    if (isBoolean(hasAddress)) {
      // If has address is a boolean, then setHasAddress will accept a boolean
      return (
        <FieldSet title='Options' className='col-span-6'>
          <Checkbox
            label='Use a custom address'
            help='This person has/will have an address different from any of their (potential) leased assets.'
            name='hasAddress'
            onChange={evt => setHasAddress(evt.target.checked as any)}
            checked={hasAddress}
          />
        </FieldSet>
      )
    }

    return (
      <FieldSet
        title='Address'
        className='col-span-6'
      >
        <Radio
          label='Use asset address'
          help='Use the address of one of their leased assets.'
          onChange={evt => setHasAddress('asset' as any)}
          checked={hasAddress === 'asset'}
        />

        <Radio
          label='Use custom address'
          help='Specify an address for this person.'
          onChange={evt => setHasAddress('custom' as any)}
          checked={hasAddress === 'custom'}
        />

        <Radio
          label='No address'
          onChange={evt => setHasAddress('none' as any)}
          checked={hasAddress === 'none'}
        />
      </FieldSet>
    )
  }

  return (
    <>
      <Section
        title={title}
        subtitle={subtitle}
        index={index}
        visible={visible}
        fullWidth={fullWidth}
      >
        <TextInput
          label='First name'
          className='col-span-6 sm:col-span-3'
          name={namespace('firstName')}
        />

        <TextInput
          label='Last name'
          className='col-span-6 sm:col-span-3'
          name={namespace('lastName')}
        />

        <DateInput
          label='Date of birth'
          hint='optional'
          className='col-span-6 sm:col-span-3'
          name={namespace('birthDate')}
        />

        <TextArea
          className='col-span-6'
          label='Notes'
          hint='optional'
          name={namespace('notes')}
          placeholder={`No notes yet.`}
        />

        {renderAddressOptions()}
      </Section>

      <Section
        index={1}
        title={fullWidth ? '' : 'Contact methods'}
        subtitle={fullWidth ? '' : 'Add as many ways as possible that this person can be reached.' }
        fullWidth={fullWidth}
      >
        <AttributesInput
          {...formik.getFieldProps(namespace('contactMethods'))}
          mode='props'
          buttonLabel='Add item'
          presets={CONTACT_ATTRIBUTES}
        />
      </Section>
    </>
  )
}
