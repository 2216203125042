import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { ArrowDownIcon, ArrowUpIcon, HandThumbUpIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid'
import schemas from '@shared/schemas'
import { BillingInvoice, BillingInvoicePaymentsAdjustmentsResult, BillingPayment } from '@shared/schemas/billing'
import { zFlexNumber } from '@shared/schemas/util'
import Alert from 'components/elements/Alert'
import Button from 'components/elements/Button'
import { Card, CardBody, CardFooter } from 'components/elements/Card'
import Empty from 'components/elements/Empty'
import { Form } from 'components/elements/Form'
import { Checkbox } from 'components/elements/forms/inputs/Checkbox'
import DateInput from 'components/elements/forms/inputs/Date'
import FieldSet from 'components/elements/forms/inputs/FieldSet'
import Submit from 'components/elements/forms/inputs/Submit'
import TextInput from 'components/elements/forms/inputs/Text'
import SimpleModal from 'components/elements/modals/SimpleModal'
import Table from 'components/elements/Table'
import Decimal from 'decimal.js'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { DateTime } from 'luxon'
import { postApi } from 'modules/api'
import progress from 'modules/progress'
import { ReactElement, useState } from 'react'
import { LoaderFunction, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import { SyncLoader } from 'react-spinners'
import {
  getBillingInvoice, getBillingInvoicePaymentsAutoAdjustmentPreview,
  getBillingPayment
} from 'stores/billing'
import createSchema from 'utils/createSchema'
import { withNamespace } from 'utils/forms'
import getParams from 'utils/getParams'
import namespaces from 'utils/namespaces'
import { formatMoney } from 'utils/transforms'
import { z } from 'zod'
import { useLease } from './_root'
import LeasePageShell from './_shell'

type LoaderData = {
  invoice: BillingInvoice|null
}

const ITEMS_SCHEMA = z.array(
  z.object({
    name: z.string().min(1),
    qty: zFlexNumber(z.number().min(1)),
    amount: zFlexNumber()
  })
)

const INVOICE_SCHEMA = schemas.billing.invoice.pick({
  due: true
})

const BLANK_ITEM = Object.freeze({
  name: '',
  qty: '',
  amount: ''
})

const BLANK_INVOICE: z.infer<typeof INVOICE_SCHEMA> = Object.freeze({
  due: new Date()
})

const LeaseEditInvoicePage: React.FC = () => {
  const { invoice } = useLoaderData() as LoaderData 
  const { lease } = useLease()
  const [ adjustPayments, setAdjustPayments ] = useState(true)
  const [ isPreviewing, setIsPreviewing ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(true)
  const [ previewDetails, setPreviewDetails ] = useState<BillingInvoicePaymentsAdjustmentsResult|null>(null)
  const [ previewPayments, setPreviewPayments ] = useState<BillingPayment[]>([])
  const navigate = useNavigate()

  const title = invoice !== null
    ? 'Edit invoice'
    : 'Create invoice'


  const schema = createSchema()
    .with(namespaces.items, ITEMS_SCHEMA)
    .with(namespaces.invoice, INVOICE_SCHEMA)

  const initialValues = schema.filter({
    [namespaces.invoice]: invoice ? invoice : BLANK_INVOICE,
    [namespaces.items]: invoice ? invoice.payload.items : []
  })

  const calculateTotal = (items: any[]): Decimal => {
    try {
      const current = items.reduce(
        (prev, curr) => prev.add(new Decimal(curr.amount).mul(curr.qty)),
        new Decimal(0)
      )

      return current
    } catch (e) {
      return new Decimal(0)
    }
  }

  const autoAdjustSettings = ({ values }: { values: typeof initialValues }): { disabled: boolean, willAdjust: boolean } => {
    const newTotal: Decimal = calculateTotal(values[namespaces.items])

    // If the new balance would be negative, auto adjustment is REQUIRED
    const disabled = !!invoice
      ? newTotal.sub(invoice.paid).lessThan(0)
      : false

    return {
      disabled,
      willAdjust: disabled === false
        ? adjustPayments
        : true
    }
  }

  const onAddItem = (formikProps: FormikProps<typeof initialValues>) => {
    return (): void => {
      const currentInvoiceItems = formikProps.values[namespaces.items]
      const updatedItems = [ ...currentInvoiceItems, { ...BLANK_ITEM } ]
      const newIdx = updatedItems.length - 1

      const newValues = {
        ...formikProps.values,
        [namespaces.items]: updatedItems
      }

      formikProps.setValues(newValues)

      setTimeout(() => {
        // focus the newly created element
        const found = document.getElementById(`formik.${namespaces.items}.${newIdx}`)
        if (found) found.focus()
      }, 100)
    }
  }

  const _onSubmit = async (values: any, frmk: FormikHelpers<any>): Promise<void> => {
    setIsLoading(true)

    const { willAdjust } = autoAdjustSettings({ values })

    let invoiceId = invoice ? invoice.id : 'new'
    
    const data = { ...schema.filter(values) }

    if (invoice) {
      data.invoice.id = invoice.id
    }

    if (willAdjust && isPreviewing === false) {
      setIsPreviewing(true)
      frmk.setSubmitting(false)

      const preview = await getBillingInvoicePaymentsAutoAdjustmentPreview({
        invoiceId,
        leaseId: lease.id,
        amount: calculateTotal(data.items).toFixed(2)
      })

      let payments = []

      if (preview !== null) {
        const { adjustments } = preview

        payments = await Promise.all(
          adjustments.map(d => getBillingPayment({ paymentId: d.paymentId }))
        )
        
        payments = payments.map(data => data.payment)

        setPreviewPayments(payments)
      } else {
        setPreviewPayments([])
      }

      setPreviewDetails(preview)
      setIsLoading(false)
      return 
    }

    progress.start('update invoice')

    try {
      if (invoiceId === 'new') {
        // For a new invoice, the lease ID has to be attached
        data.invoice.leaseId = lease.id

        const result = await postApi('/CreateBillingInvoice', data, {
          adjust: willAdjust
        })

        invoiceId = result.invoiceId
      } else {
        await postApi('/UpdateBillingInvoice', data, {
          adjust: willAdjust
        })
      }
    } catch (e) {
      progress.done('update invoice')
      frmk.setSubmitting(false)
      throw e
    }

    progress.done('update invoice')
    navigate(`../invoice/${invoiceId}`)
  }

  const onSubmit = (values: any, frmk: FormikHelpers<any>): void => {
    _onSubmit(values, frmk)
  }

  const renderForm = (formikProps: FormikProps<typeof initialValues>): ReactElement => {
    const { willAdjust } = autoAdjustSettings(formikProps)
    const namespace = withNamespace(namespaces.invoice)

    const renderPreviewModal = (): ReactElement => {
      const onClose = () => {
        setIsPreviewing(false)
      }
  
      const renderLoading = (): ReactElement => {
        return (
          <div className='flex flex-1 items-center justify-center text-indigo-600 h-32'>
            <SyncLoader size={15} color='#4F46E5' />
          </div>
        )
      }
  
      const renderPreview = (): ReactElement => {
        if (previewDetails === null) {
          return (
            <Empty
              className='p-4'
              icon={<CheckCircleIcon className='text-green-500 w-20' />}
              message='No changes are being made to the payments on this invoice.'
            />
          )
        }
  
        const columns = [
          { key: 'item', label: '' },
          { key: 'previous', label: 'Was' },
          { key: 'current', label: 'Now' }
        ]
  
        const renderRow = (previousAmount: string, currentAmount: string) => {
          const prev = new Decimal(previousAmount)
          const current = new Decimal(currentAmount)
  
          const returnObj = {
            previous: formatMoney(prev.toFixed(2)),
            current: (<span>{formatMoney(current.toFixed(2))}</span>)
          }
  
          if (current.greaterThan(prev)) {
            returnObj.current = (
              <span className='text-red-400 flex'>{formatMoney(current.toFixed(2))} <ArrowUpIcon className='w-3 text-red-400' /></span>
            )
          }
  
          if (current.lessThan(prev)) {
            returnObj.current = (
              <span className='text-green-600 flex'>{formatMoney(current.toFixed(2))} <ArrowDownIcon className='w-3 text-green-600'/></span>
            )
          }
  
          return returnObj
        }
  
        const rows: any[] = previewDetails.adjustments.map(item => {
          const payment = previewPayments.find(i => i.id === item.paymentId)
          const displayname: string = payment
            ? DateTime.fromJSDate(payment.paidAt, { zone: 'UTC' }).toLocaleString(DateTime.DATE_SHORT)
            : item.paymentId.slice(0, 5)
  
          return {
            item: 'Payment allocation from ' + displayname,
            ...renderRow(item.previousAmount, item.newAmount)
          }
        })
  
        rows.push({
          item: (<span className='font-medium text-black'>Balance</span>),
          ...renderRow(previewDetails.previous.balance, previewDetails.adjusted.balance)
        })
  
        rows.push({
          item: (<span className='font-medium text-black'>Total</span>),
          ...renderRow(previewDetails.previous.total, previewDetails.adjusted.total)
        })
  
        return (
          <Table
            columns={columns}
            rows={rows}
            inline
          />
        )
      }
  
      const renderButtons = (): ReactElement => {
        return (
          <>
            <Button onClick={formikProps.submitForm} disabled={formikProps.isSubmitting}>
              {formikProps.isSubmitting ? 'Saving...' : 'Save' }
            </Button>
            <Button type='secondary' className='mr-2' onClick={onClose}>Cancel</Button>
          </>
        )
      }
  
      return (
        <SimpleModal
          title='Preview invoice changes'
          subtitle='These are the changes that will be made to the invoice balance, total, and payment allocations.'
          open={isPreviewing}
          onClose={onClose}
          icon={<HandThumbUpIcon className='text-indigo-600 h-6 w-6' />}
          iconBg='bg-indigo-100'
          buttons={renderButtons()}
        >
          <div className='overflow-y-auto flex' style={{maxHeight: '200px'}}>
            {
              isLoading
              ? renderLoading()
              : renderPreview()
            }
          </div>
        </SimpleModal>
      )
    }

    const renderAutoAdjust = (): ReactElement => {
      const { disabled, willAdjust } = autoAdjustSettings(formikProps)
  
      const helpText = !disabled
       ? 'Allow the system to automatically adjust the payments made to this invoice to cover any unpaid balance.'
       : 'This invoice already has payments, and since the modified total is lower than the previous total, the payments will be auto adjusted. If you do not want this, please first remove the payments made to this invoice before continuing.'
       
      return (
        <Checkbox
          label='Automatically adjust payments'
          help={helpText}
          name='adjustPayments'
          onChange={evt => setAdjustPayments(evt.target.checked)}
          checked={willAdjust}
          disabled={disabled}
        />
      )
    }

    const renderAutoGeneratedWarning = (): ReactElement => {
      if (!invoice) return <></>
      if (!invoice.automatic) return <></>

      const formDueDate = DateTime.fromJSDate(formikProps.values.invoice.due, { zone: 'UTC' })
      const invoiceDueDate = DateTime.fromJSDate(invoice.due, { zone: 'UTC' })

      if (+formDueDate === +invoiceDueDate) {
        return <></>
      }

      let message: string = ''

      if (formDueDate > invoiceDueDate) {
        // we've adjusted the date forward
        message = [
          'If the due date of this invoice is adjusted into the future, it will',
          'prevent any new automatic invoices from being generated until after the',
          'adjusted due date. This should not be a problem unless this due date is later',
          'than when the next normal invoice would be issued.'
        ].join(' ')
      }

      if (formDueDate < invoiceDueDate) {
        // we've adjusted the date backwards
        message = [
          'If the due date of this invoice is adjusted to an earlier date, the',
          'system may issue a new, duplicate invoice for the normal due date of the lease.',
          'This will only happen if this is the latest automatic invoice on this lease.'
        ].join(' ')
      }
      
      return (
        <Alert
          type='warn'
          title='Adjusting the due date of an automatic invoice can cause unexpected behavior.'
          message={message}
          className='mt-4'
        />
      )
    }

    const renderPayload = (): ReactElement => {
      const items = formikProps.values.items as z.infer<typeof ITEMS_SCHEMA>
  
      const columns = [
        { key: 'item', label: 'Item' },
        { key: 'amount', label: 'Amount' },
        { key: 'qty', label: 'QTY' },
        { key: 'total', label: 'Total' },
        { key: 'actions', label: '' }
      ]
  
      const rows = items.map((item: any, index: number) => {
        const namespace = withNamespace(`${namespaces.items}.${index}`)
        const { amount, qty } = item
  
        const total: string = !isNaN(amount) && !isNaN(qty)
          ? new Decimal(Number(amount)).mul(Number(qty)).toFixed(2)
          : '0'
  
        const onDelete = (): void => {
          formikProps.setFieldValue(namespaces.items, items.filter((val, idx) => {
            return idx !== index
          }))
        }
  
        const actions = (
          <Button type='danger' size='small' onClick={onDelete} disabled={formikProps.isSubmitting}>
            <TrashIcon className='w-3' />
          </Button>
        )
  
        return {
          item: <TextInput name={namespace('name')} id={`formik.${namespaces.items}.${index}`} />,
          qty: <TextInput name={namespace('qty')} />,
          amount: <TextInput name={namespace('amount')} />,
          total: formatMoney(total),
          actions
        }
      })
  
      return (
        <Card>
          <Table rows={rows} columns={columns} inline />
          <CardFooter className='text-center'>
            <button
              type='button'
              className='flex w-full items-center justify-center text-sm font-medium text-indigo-600 text-center hover:text-indigo-800 sm:rounded-b-lg'
              onClick={onAddItem(formikProps)}
              disabled={formikProps.isSubmitting}
            >
              <PlusIcon className='w-4 inline-block mr-1' />
              Add an item
            </button>
          </CardFooter>
        </Card>
      )
    }
  
    return (
      <Form onSubmit={formikProps.handleSubmit}>
        {renderPreviewModal()}
        {renderPayload()}

        <Card className='mt-6' allowOverflow>
          <CardBody>
            <DateInput
              label='Due'
              className='col-span-6 sm:col-span-2'
              name={namespace('due')}
            />
            {renderAutoGeneratedWarning()}
          </CardBody>
        </Card>

        <Card className='mt-6'>
          <CardBody>
            <FieldSet title='Options'>
              {renderAutoAdjust()}
            </FieldSet>
          </CardBody>
        </Card>

        <Submit
          submitLabel={willAdjust ? 'Preview' : 'Save'}
          submittingLabel={'Saving...'}
          fullWidth
        />
      </Form>
    )
  }

  return (
    <LeasePageShell title={title} active='invoices'>
      <Formik
        initialValues={initialValues}
        validate={schema.validate}
        onSubmit={onSubmit}
      >{renderForm}</Formik>
    </LeasePageShell>
  )
}

const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { invoiceId } = getParams(args)
  const invoice = invoiceId !== 'new'
    ? await getBillingInvoice({ invoiceId })
    : null

  return {
    invoice
  }
}

const route: RouteObject = {
  path: 'invoice/:invoiceId/edit',
  element: <LeaseEditInvoicePage />,
  loader
}

export default route
