import schemas from '@shared/schemas'
import { Form } from 'components/elements/Form'
import Submit from 'components/elements/forms/inputs/Submit'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { postApi } from 'modules/api'
import notifications from 'modules/notifications'
import progress from 'modules/progress'
import { useParams } from 'react-router'
import createSchema from 'utils/createSchema'
import namespaces from 'utils/namespaces'
import HomeAssetForm from '../elements/forms/HomeAsset'

interface Props {
  updateParentState: ({ newAssetId }: { newAssetId: string }) => void
  updateParentFormResetRef: (ref: any) => void
}

export default function CreateHome(props: Props): JSX.Element {
  const {
    updateParentState,
    updateParentFormResetRef
  } = props

  const { propertyId } = useParams<any>()
  
  const schema = createSchema()
    .with(namespaces.home, schemas.asset.home)

  const initialValues = {
    [namespaces.home]: schemas.asset.blanks.home
  }

  const submit = async (values: any, frmk: FormikHelpers<any>) => {
    const data = schema.filter(values)

    progress.start('create home')

    try {
      const response = await postApi('/CreateAsset', data, {
        type: 'mhp_home',
        propertyId
      })

      if (response._.statusCode !== 200) {
        throw new Error(response.message)
      }

      updateParentState({ newAssetId: response.assetId })
    } catch (e: any) {
      notifications.error({ message: e.message })
    }

    progress.done('create home')
    frmk.setSubmitting(false)
  }

  const onSubmit = (values: any, frmk: FormikHelpers<any>) => {
    submit(values, frmk)
  }

  const renderForm = (formikProps: FormikProps<typeof initialValues>): JSX.Element => {
    updateParentFormResetRef(() => formikProps.resetForm())

    return (
      <Form>
        <HomeAssetForm
          namespace={namespaces.home}
          visible
          propertyId={propertyId as string}
        />
        <Submit />
      </Form>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={schema.validate}
      onSubmit={onSubmit}
    >{renderForm}</Formik>
  )
}

