import * as z from 'zod'
import { zFlexDate } from './util'

export const targetTypes = z.enum([
  'asset',
  'person',
  'lease',
  'billing.payment'
])

export const comment = z.object({
  id: z.string().uuid(),
  userId: z.string().uuid(),
  targetType: targetTypes,
  targetId: z.string().uuid(),
  createdAt: zFlexDate(),
  updatedAt: zFlexDate().nullable(),
  comment: z.string()
})

export const commentWithKnownTarget = comment.omit({
  targetType: true,
  targetId: true
})

export const commentsWithKnownTarget = z.array(commentWithKnownTarget)

export type Comment = z.infer<typeof comment>
export type CommentWithKnownTarget = z.infer<typeof commentWithKnownTarget>
export type CommentsWithKnownTarget = z.infer<typeof commentsWithKnownTarget>
