import React, { ReactElement, useState, useRef } from 'react'
import useOutsideClick from '../../utils/useOutsideClick'
import FadeUpFast from './transitions/FadeUpFast'

export interface MenuButton {
  label: string|ReactElement
  icon: ReactElement
  action: any
}

export type MenuItemGroup = MenuButton[]
export type MenuItem = MenuButton | MenuItemGroup

interface Props {
  label: string
  menu: MenuItem[]
}

export default function Dropdown(props: Props): JSX.Element {
  const [ menuVisible, setMenuVisible ] = useState<boolean>(false)
  const containerRef = useRef(null)

  useOutsideClick(containerRef, () => {
    setMenuVisible(false)
  })

  const {
    label,
    menu
  } = props

  const renderBox: JSX.Element = (
    <div onClick={() => setMenuVisible(!menuVisible)}>
      <span className='rounded-md shadow-sm'>
        <button type='button' className='inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150'>
          {label}
          <svg className='-mr-1 ml-2 h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
            <path
              fillRule='evenodd'
              d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
              clipRule='evenodd'
            />
          </svg>
        </button>
      </span>
    </div>
  )

  const renderMenuButton = (item: MenuButton, index: number): JSX.Element => {
    const {
      label,
      icon,
      action
    } = item
    
    const doAction = () => {
      action()
      setMenuVisible(false)
    }

    return (
      <button
        key={index}
        onClick={doAction}
        type='button'
        className='w-full group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900'
      >
        <div className='mr-2 h-5 w-5 flex items-center text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500'>
          {icon}
        </div>

        {label}
      </button>
    )
  }

  const renderMenuItem = (item: MenuItem, index: number): JSX.Element => {
    // Array either way, baby
    const items = Array.isArray(item)
      ? item
      : [ item ]

    return (
      <React.Fragment key={index}>
        {
          index !== 0
          ? <div className='border-t border-gray-100' />
          : null
        }
        <div className='py-1'>
          {items.map(renderMenuButton)}
        </div>
      </React.Fragment>
    )
  }

  const renderList: JSX.Element = (
    <FadeUpFast in={menuVisible}>
      <div className='origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg'>
        <div className='rounded-md bg-white ring-1 ring-black ring-opacity-5'>
          {menu.map(renderMenuItem)}
        </div>
      </div>
    </FadeUpFast>
  )

  return (
    <div className='relative inline-block text-left' ref={containerRef}>
      {renderBox}
      {renderList}
    </div>
  )
}
