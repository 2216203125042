import { CogIcon } from '@heroicons/react/24/solid'
import HeaderAvatar from 'components/app/HeaderAvatar'
import Page from 'components/app/Page'
import Shell from 'components/app/Shell'
import DescriptionList from 'components/elements/DescriptionList'
import { ReactElement } from 'react'
import { RouteObject } from 'react-router-dom'
import { assetTypeToReadable } from 'utils/assetTypes'
import { useProperty } from './_root'

const PropertySettingsPage: React.FC = () => {
  const property = useProperty()

  const breadcrumbs = [
    { label: property.displayName, href: `/p/${property.id}`, options: {} },
    { label: 'Settings', href: `/p/${property.id}/settings` }
  ]

  const renderInlineAction = (
    { label, actionLabel = 'Update', action }:
    { label: string|ReactElement, actionLabel?: string, action: () => void }
  ): ReactElement => {
    return (
      <span className='flex'>
        <span className='flex-grow'>{label}</span>
        <span className='ml-4 flex-shrink-0'>
          <button type='button' className='rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500' onClick={action}>
            {actionLabel}
          </button>
        </span>
      </span>
    )
  }
  
  
  const renderAssetTypes = () => {
    const display = (
      <div>
        {property.assetTypes.map((assetType) => {
          return (
            <div key={assetType}>
              {assetTypeToReadable(assetType, true)}
            </div>
          )
        })}
      </div>
    )

    return renderInlineAction({ label: display, action: () => {} })
  }

  return (
      <Shell active='DASHBOARD' breadcrumbs={breadcrumbs}>
        <HeaderAvatar
          title='Property Settings'
          subtitle={property.displayName}
          avatarIcon={<CogIcon />}
        />
        <Page>
          <DescriptionList
            data={[
              { label: 'Name', value: renderInlineAction({ label: property.displayName, action: () => {} }) },
              { label: 'Assets', value: renderAssetTypes() }
            ]}
          />
        </Page>
      </Shell>
  )
}

const route: RouteObject = {
  path: 'settings',
  element: <PropertySettingsPage />,
}

export default route
