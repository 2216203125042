import classNames from 'classnames'
import { useField } from 'formik'
import { DateTime } from 'luxon'
import { InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import { FaCalendar } from 'react-icons/fa'
import useOutsideClick from '../../../../utils/useOutsideClick'
import FadeUp from '../../transitions/FadeUp'
import { defaultInputStyles } from './Defaults'
import FormLabel from './Label'
import TextInput from './Text'

const DAYS: string[] = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat'
]

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  hint?: string
  help?: string
  inline?: boolean
  name: string
}

/**
 * Styled after the excellent component available at:
 * https://tailwindcomponents.com/component/datepicker-with-tailwindcss-and-alpinejs
 */
export default function DateInput(props: Props): JSX.Element {
  const {
    className = '',
    label = '',
    hint = '',
    inline = false
  } = props

  const [ field, meta, helpers ] = useField(props)

  const initialDateTime = !!field.value
    ? DateTime.fromJSDate(field.value, { zone: 'UTC' })
    : DateTime.now()

  const initialInputValue: string = !!field.value
    ? initialDateTime.toLocaleString()
    : ''

  const [ visibleDate, setVisibleDate ] = useState([
    initialDateTime.year,
    initialDateTime.month
  ])
  
  const [ calendarVisible, setCalendarVisible ] = useState(false)

  const [ inputValue, setInputValue ] = useState<string>(initialInputValue)
  const containerRef = useRef(null)

  const getInputDate = (): [ number|null, number|null, string|null ] => {
    const [
      month,
      day,
      year
    ] = String(inputValue).split('/')

    return [
      Number(month) || null,
      Number(day) || null,
      year ? String(year) : null
    ]
  }

  const assumedYear = (inputYear: string|null): number => {
    let newYear = DateTime.now().year

    if (inputYear === null) {
      return DateTime.now().year
    }

    const inputYearNum = Number(inputYear)
    const currentYear = DateTime.now().year
    const currentDecade = Number(String(currentYear).slice(0, 2))

    if (inputYear.length === 2) {
      if ((inputYearNum + (currentDecade * 100)) > currentYear + 15) {
        /**
         * This date would be almost 15 years in advance of now, so we're
         * probably talking about a prior year
         */
        newYear = ((currentDecade - 1) * 100) + inputYearNum
      } else {
        newYear = (currentDecade * 100) + inputYearNum
      }
    }

    if (inputYear.length === 4) {
      newYear = inputYearNum
    }

    return newYear
  }

  useOutsideClick(containerRef, () => {
    setCalendarVisible(false)
  })

  // This effect is how we're setting the underlying field value to a date object
  useEffect(() => {
    let [ month, day, yearStr ] = getInputDate()

    if (month === null || day === null) {
      return
    }

    const year = assumedYear(yearStr)
    const chosenDate = DateTime.utc(year, month, day, 0, 0, 0, 0)

    helpers.setValue(chosenDate.toJSDate())
  }, getInputDate()) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * When the user types a valid date into the text input, we will update what
   * the calendar is showing to match
   */
  useEffect(() => {
    // There is no calendar visible, so do nothing
    if (calendarVisible === false && inline === false) return

    const [ inputMonth, , inputYear ] = getInputDate()
    const [ visibleYear, visibleMonth ] = visibleDate

    const nonZeroIndexedMonth = Number(inputMonth) 

    let newMonth: number|null = null
    let newYear: number|null = null

    if (
      inputMonth !== null &&
      nonZeroIndexedMonth !== visibleMonth &&
      Number.isInteger(nonZeroIndexedMonth) &&
      nonZeroIndexedMonth >= 1 &&
      nonZeroIndexedMonth <= 12
    ) {
      newMonth = nonZeroIndexedMonth
    }

    /**
     * We do not have to set "visible day" since it doesn't affect how the
     * calendar renders
     */
    if (
      inputYear !== null &&
      Number.isInteger(Number(inputYear))
    ) {
      newYear = assumedYear(inputYear)
    }

    // If there is no input year, reset the display year to the current year
    const currentYear = DateTime.now().year

    if (inputYear === null && currentYear !== visibleYear) {
      newYear = currentYear
    }

    // Batch the updates, if any, together
    if (newMonth !== null || newYear !== null) {
      setVisibleDate([
        newYear !== null ? newYear : visibleYear,
        newMonth !== null ? newMonth : visibleMonth
      ])
    }
  }, [ inputValue, calendarVisible ]) // eslint-disable-line react-hooks/exhaustive-deps

  const calendarDate = DateTime.utc(visibleDate[0], visibleDate[1])
  const isErrored = !!(meta.touched && meta.error)

  const renderHeader = (): JSX.Element => {
    const nextMonth = (evt: any) => {
      evt.preventDefault()
      const next = calendarDate.plus({ months: 1 })
      setVisibleDate([ next.year, next.month ])
    }

    const prevMonth = (evt: any) => {
      evt.preventDefault()
      const prev = calendarDate.minus({ months: 1 })
      setVisibleDate([ prev.year, prev.month ])
    }

    return (
      <div className='flex justify-between items-center mb-2'>
        <div>
          <span className='text-lg font-bold text-gray-800'>{calendarDate.toFormat('MMMM')}</span>
          <span className='ml-1 text-lg text-gray-500 font-normal'>{calendarDate.toFormat('yyyy')}</span>
        </div>
        <div>
          <button
            type='button'
            onClick={prevMonth}
            className='transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full'
          >
            <svg className='h-6 w-6 text-gray-400 inline-flex' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M15 19l-7-7 7-7'></path>
            </svg>
          </button>

          <button
            type='button'
            onClick={nextMonth}
            className='transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full'
          >
            <svg className='h-6 w-6 text-gray-400 inline-flex' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M9 5l7 7-7 7'></path>
            </svg>									  
          </button>
        </div>
      </div>
    )
  }

  const renderDayHeaders = (): JSX.Element => {
    return (
      <div className='flex flex-wrap mb-3 -mx-1'>
        {DAYS.map(day => {
          return (
            <div key={day} style={{width: '14.26%'}} className='px-1'>
              <div className='text-gray-800 font-medium text-center text-xs'>{day}</div>
            </div>
          )
        })}
      </div>
    )
  }

  const renderBlankDay = (index: number): JSX.Element => {
    return (
      <div key={index} className='text-center border p-1 border-transparent text-sm' style={{width: '14.28%'}} />
    )
  }

  const renderDay = (day: number, index: number): JSX.Element => {
    const styles = {
      selected: 'bg-blue-500 text-white',
      normal: 'text-gray-700 hover:bg-gray-200'
    }

    const [ visibleYear, visibleMonth ] = visibleDate
    const [ inputMonth, inputDay, inputYear ] = getInputDate()

    const isActive: boolean = (
      visibleYear === assumedYear(inputYear) &&
      visibleMonth === inputMonth &&
      inputDay === day
    )

    const now = DateTime.now()

    const isToday: boolean = (
      visibleYear === now.year &&
      visibleMonth === now.month &&
      day === now.day
    )

    const shouldHighlightToday = isToday && inputDay === null
    const isSelected = isActive || shouldHighlightToday

    const onClick = (evt: any) => {
      evt.preventDefault()

      const [ year, month ] = visibleDate
      const chosenDate = DateTime.utc(year, month, day, 0, 0, 0, 0)

      setInputValue(chosenDate.toLocaleString())
      helpers.setValue(chosenDate.toJSDate())
      
      setCalendarVisible(false)
    }

    const classes = classNames(
      'cursor-pointer text-center text-sm rounded-full leading-loose transition ease-in-out duration-100',
      {
        [styles.selected]: isSelected,
        [styles.normal]: !isSelected
      }
    )

    return (
      <div className='px-1 mb-1' style={{width: '14.28%'}} key={index}>
        <div onClick={onClick} className={classes}>
          {day}
        </div>
      </div>
    )
  }

  const renderDays = (): JSX.Element => {
    const firstDayOfMonth = calendarDate.startOf('month')
    const firstDayOfMonthDayOfWeek: number = firstDayOfMonth.weekday
    const daysInThisMonth: number = calendarDate.daysInMonth || 0

    const days = [
      ...Array(firstDayOfMonthDayOfWeek).fill(null),
      ...Array(daysInThisMonth).fill(null).map((i, index) => (index + 1))
    ]

    return (
      <div className='flex flex-wrap -mx-1'>
        {days.map((day: any, index: number) => {
          if (day === null) return renderBlankDay(index)
          return renderDay(day, index)
        })}
      </div>
    )
  }

  const toggleCalendar = (evt: any) => {
    evt.preventDefault()
    
    if (inline) {
      // we are inline, so have the button set today instead
      const now = DateTime.now()

      setInputValue(now.toLocaleString())
      // always return to the current month, even if the date does not change
      setVisibleDate([ now.year, now.month ])
      return
    }
    
    setCalendarVisible(!calendarVisible)
  }

  const renderInput = (
    { inline }: { inline?: boolean } = { inline: false }
  ): JSX.Element => {
    const inputStyles = defaultInputStyles({
      error: isErrored,
      noErrorIcon: true,
      overrideBase: 'block w-full rounded-none rounded-l-md sm:leading-5 sm:text-sm'
    })

    if (inline) {
      return (
        <TextInput
          placeholder='MM/DD/YYYY'
          onChange={(evt) => setInputValue(evt.target.value)}
          value={inputValue}
          type='text'
          label={label}
          displayType='inline'
        />
      )
    }
    
    return (
      <>
        <div className='mt-1 flex rounded-md'>
          <div className='relative flex-grow focus-within:z-10'>
            <input
              className={inputStyles}
              placeholder='MM/DD/YYYY'
              onChange={(evt) => setInputValue(evt.target.value)}
              value={inputValue}
              type='text'
            />
          </div>
          <button
            type='button'
            onClick={toggleCalendar}
            className='-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150'>
            <FaCalendar />
          </button>
        </div>
      </>
    )
  }

  const renderCalendar = (): JSX.Element => {
    return (
      <FadeUp in={calendarVisible} className='relative z-50'>
        <div className='absolute right-0'>
          <div className='mt-2 bg-white rounded-lg shadow p-4' style={{width: '17rem'}}>
            {renderHeader()}
            {renderDayHeaders()}
            {renderDays()}
          </div>
        </div>
      </FadeUp>
    )
  }

  const renderCalendarInline = (): JSX.Element => {
    return (
      <div className='relative'>
        <div className='bg-white mb-2 p-4 shadow-sm border-gray-300 rounded-md' style={{"borderWidth": "1px"}}>
          {renderHeader()}
          {renderDayHeaders()}
          {renderDays()}
        </div>
      </div>
    )
  }

  const render = (): JSX.Element => {
    return (
      <>
      {
        label !== ''
        ? <FormLabel label={label} hint={hint} />
        : null
      }
      {renderInput()}
      {renderCalendar()}
      {
        isErrored
        ? <p className='mt-2 text-sm text-red-600'>{meta.error}</p>
        : ''
      }
      </>
    )
  }

  const renderInline = (): JSX.Element => {
    return (
      <>
        {renderCalendarInline()}
        {renderInput({ inline: true })}
      </>
    )
  }

  return (
    <div className={`${className} relative`} ref={containerRef}>
      {
        inline
        ? renderInline()
        : render()
      }
    </div>
  )
}
