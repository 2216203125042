import { BanknotesIcon, CheckCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid'
import { BillingPayment } from '@shared/schemas/billing'
import Button from 'components/elements/Button'
import Empty from 'components/elements/Empty'
import Table from 'components/elements/Table'
import Decimal from 'decimal.js'
import { DateTime } from 'luxon'
import { ReactElement } from 'react'
import { LoaderFunction, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import { getBillingPaymentsByLeaseId } from 'stores/billing'
import getParams from 'utils/getParams'
import { formatMoney } from 'utils/transforms'
import LeasePageShell from './_shell'

type LoaderData = {
  payments: BillingPayment[]
}

const LeasePaymentsPage: React.FC = () => {
  const { payments } = useLoaderData() as LoaderData
  const navigate = useNavigate()

  const createOnClick = () => navigate('create')

  const renderEmpty = (): ReactElement => {
    return (
      <Empty
        className='bg-white py-12'
        icon={<BanknotesIcon className='w-14 mb-2 text-gray-400' />}
        message='No payments have been recorded.'
        action={createOnClick}
        actionMessage='Add a payment'
      />
    )
  }

  const renderPayments = (payments: BillingPayment[]): ReactElement => {
    const columns = [
      { key: 'date', label: 'Date', type: 'primary'  },
      { key: 'amount', label: 'Amount' },
      { key: 'allocated', label: 'Allocated' },
      { key: 'icon', label: '' }
    ]
    
    const rows = payments.map(payment => {
      const date = DateTime
        .fromJSDate(payment.paidAt, { zone: 'UTC' })
        .toLocaleString(DateTime.DATE_SHORT)

      const onClick = (): void => navigate(`../payment/${payment.id}`)

      const allocationPercent = new Decimal(payment.allocated)
        .div(payment.amount)
        .mul(100)

      const icon = allocationPercent.eq(100)
        ? <CheckCircleIcon className='w-5 text-green-400 float-right' />
        : null

      return {
        amount: formatMoney(payment.amount),
        date,
        allocated: formatMoney(payment.allocated),
        icon,
        config: { onClick }
      }
    })

    return <Table rows={rows} columns={columns} empty={renderEmpty()} />
  }

  const actions = (): ReactElement[] => {
    return [
      <Button type='secondary' onClick={createOnClick}>
        <span>Payment</span><PlusCircleIcon className='w-4 ml-2' />
      </Button>
    ]
  }

  return (
    <LeasePageShell title='Payments' actions={actions()}>
      {renderPayments(payments)}
    </LeasePageShell>
  )
}

const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { leaseId } = getParams(args)
  const payments = await getBillingPaymentsByLeaseId({ leaseId })

  return { payments }
}

const route: RouteObject = {
  path: 'payments',
  element: <LeasePaymentsPage />,
  loader
}

export default route
