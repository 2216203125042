import Decimal from 'decimal.js'

function removeFormatting(money: string): string {
  return money
    .replace('$', '')
    .replaceAll(',', '')
    .trim()
}

export function decimalFromMoney(money: string): Decimal {
  return new Decimal(Number(removeFormatting(money)))
}

export function moneyIsNaN(money: string): boolean {
  return isNaN(Number(removeFormatting(money)))
}
