import { Property } from '@shared/schemas/property'
import { createContext } from 'react'

const BLANK_PROPERTY: Property = {
  id: '_blank',
  displayName: 'Blank Property',
  assetTypes: [],
  address: {
    street1: 'N/A',
    street2: '',
    city: 'N/A',
    state: 'NA',
    zip: '00000'
  },
  tenantId: ''
}

export const PropertyContext = createContext<Property>(BLANK_PROPERTY)
