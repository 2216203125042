import { LoaderFunctionArgs } from "react-router-dom"

export default function getQuery<T extends Record<string, string|undefined>>(search: string): T {
  const parameters = new URLSearchParams(search)
  const searchObj: Record<string, string|undefined> = {}

  for (const [key, value] of parameters) {
    searchObj[key] = value
  }

  return searchObj as T
}

export function getSearchParams<T extends Record<string, string|undefined>>(args: LoaderFunctionArgs): T {
  const obj = new URL(args.request.url)
  const parameters = obj.searchParams
  const searchObj: Record<string, string|undefined> = {}

  for (const [key, value] of parameters) {
    searchObj[key] = value
  }

  return searchObj as T
}
