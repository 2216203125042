import schemas from '@shared/schemas'
import { Page } from '@shared/schemas/page'
import { ExtendedPerson, PersonStub } from '@shared/schemas/person'
import { getTypedApi } from 'modules/typedApi'
import * as z from 'zod'

export async function getPerson(
  { personId } :
  { personId: string }
): Promise<ExtendedPerson> {
  return await getTypedApi({
    url: '/GetPerson',
    schema: schemas.person.extendedPerson,
    query: { personId },
    path: 'person'
  })
}

export async function getPeopleByAssetIds(
  { assetIds }:
  { assetIds: string[] }
): Promise<PersonStub[]> {
  return await getTypedApi({
    url: '/GetPeopleByAssetIds',
    schema: z.array(schemas.person.personStub),
    path: 'people',
    body: { assetIds }
  })
}

export async function getPeople(
  { propertyId, offset, limit }:
  { propertyId: string, offset: number, limit: number }
): Promise<{ people: PersonStub[], page: Page }> {
  return await getTypedApi({
    url: '/GetPeople',
    schema: z.object({
      people: schemas.person.peopleStubs,
      page: schemas.page.page
    }),
    query: { propertyId, offset, limit }
  })
}

export async function getLeasePeople(
  options: { leaseId: string }
): Promise<ExtendedPerson[]> {
  const { leaseId } = options
  
  return await getTypedApi({
    url: '/GetLeasePeople',
    query: { leaseId },
    schema: schemas.person.extendedPeople,
    path: 'people'
  })
}
