import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, ReactElement } from 'react'

interface SimpleModalProps {
  title?: string
  subtitle?: string
  open: boolean
  onClose: () => void
  children: ReactElement|ReactElement[]
  icon?: ReactElement
  iconBg?: string
  buttons?: ReactElement|ReactElement[]
  divider?: boolean
}

export default function SimpleModal(props: SimpleModalProps) {
  const {
    onClose,
    open,
    title,
    subtitle = '',
    children,
    buttons = null,
    divider = false
  } = props

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl'>
                <div className='absolute top-0 right-0 hidden pt-4 pr-4 sm:block'>
                  <button
                    type='button'
                    className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    onClick={onClose}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div className='sm:flex sm:items-start'>
                  <div className='text-center sm:text-left w-full'>
                    <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900 px-6'>
                      {title}
                    </Dialog.Title>
                    {
                      subtitle !== ''
                      ? <div className='px-6 text-sm text-gray-500'>
                          {subtitle}
                        </div>
                      : ''
                    }
                    <div className={`mt-4 ${divider && 'border-t border-gray-200'}`}>
                      {children}
                    </div>
                  </div>
                </div>
                {
                  buttons !== null
                  ? <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse px-4'>
                      {buttons}
                    </div>
                  : <></>
                }
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
