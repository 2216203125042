import schemas from '@shared/schemas'
import { ExtendedAddress } from '@shared/schemas/address'
import { ExtendedAsset } from '@shared/schemas/asset'
import { ExtendedLease } from '@shared/schemas/lease'
import Address from 'components/elements/Address'
import { Form } from 'components/elements/Form'
import AddressForm from 'components/elements/forms/Address'
import FieldSet from 'components/elements/forms/inputs/FieldSet'
import Radio from 'components/elements/forms/inputs/Radio'
import Section from 'components/elements/forms/inputs/Section'
import Submit from 'components/elements/forms/inputs/Submit'
import PersonForm from 'components/elements/forms/Person'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { keyBy, uniq } from 'lodash'
import { postApi } from 'modules/api'
import nProgress from 'nprogress'
import React, { useState } from 'react'
import { LoaderFunction, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import { getAddresses } from 'stores/address'
import { getAsset } from 'stores/asset'
import { getPersonLeases } from 'stores/lease'
import { assetTypeToReadable } from 'utils/assetTypes'
import createSchema from 'utils/createSchema'
import { withNamespace } from 'utils/forms'
import getParams from 'utils/getParams'
import namespaces from 'utils/namespaces'
import * as z from 'zod'
import { usePerson } from './_root'

type LoaderData = {
  leases: ExtendedLease[]
  assets: ExtendedAsset[]
  addressesById: Record<string, ExtendedAddress>
}

const EditPersonPage: React.FC = () => {
  const { assets, leases, addressesById } = useLoaderData() as LoaderData
  const person = usePerson()
  const navigate = useNavigate()

  const assetsByAddressId = keyBy(assets, 'address.id')
  const assetsByAssetId = keyBy(assets, 'id')

  const personHasAddress = !!person.address
  const personAddressIsAssetAddress = personHasAddress &&
    assetsByAddressId.hasOwnProperty(person?.address?.id || 'unknown')

  const initialAddress = personHasAddress
    ? personAddressIsAssetAddress ? 'asset' : 'custom'
    : 'none'

  const [ hasAddress, setHasAddress ] = useState(initialAddress)

  const schema = createSchema()
    .with(namespaces.person, schemas.person.person)
    .with(namespaces.address, schemas.address.address, hasAddress === 'custom')
    .with(namespaces.addressId, z.object({ id: z.string().uuid() }), hasAddress === 'asset')

  const initialValues = schema.filter({
    person,
    address: person.address,
    addressId: { id: person?.address?.id }
  })

  const addressId = withNamespace(namespaces.addressId)

  const renderAvailableAssets = (): JSX.Element => {
    // Check to make sure at least one asset has an address that can be used
    const hasAssetWithAddress = leases.reduce(
      (acc: boolean, lease): boolean => {
        if (acc === true) {
          return true
        }

        if (assetsByAssetId[lease.id] && assetsByAssetId[lease.id].addressId) {
          return true
        }

        return false
    }, false)

    if (!hasAssetWithAddress) {
      return <div className='col-span-6 text-sm text-gray-400'>None of the assets associated with this person have addresses.</div>
    }

    return (
      <FieldSet className='col-span-6'> 
        {leases.map(lease => {
          const asset = assetsByAssetId[lease.assetId]
          const label = `${assetTypeToReadable(asset.type)} ${asset.displayId}`

          if (!asset.addressId) {
            // This asset does not have an address, we can't select it
            return <React.Fragment key={lease.id} />
          }

          if (!addressesById.hasOwnProperty(asset.addressId)) {
            // This asset has an address, but we don't have it in our store
            return <React.Fragment key={lease.id} />
          }

          const thisAddress = addressesById[asset.addressId]
          const address = <Address address={thisAddress} />

          return (
            <Radio
              key={lease.id}
              name={addressId('id')}
              label={label}
              help={address}
              value={asset.addressId}
            />
          )
        })}
      </FieldSet>
    )
  }

  const renderForm = (formikProps: FormikProps<typeof initialValues>): JSX.Element => {
    return (
      <Form onSubmit={formikProps.handleSubmit}>
        <PersonForm
          //title='Identity'
          //subtitle='Make sure to double check that all of this information is correct.'
          namespace={namespaces.person}
          hasAddress={hasAddress}
          setHasAddress={setHasAddress}
          fullWidth
        />

        <AddressForm
          namespace={namespaces.address}
          visible={hasAddress === 'custom'}
          fullWidth
        />

        <Section
          //title='Address'
          //subtitle={`Use an address from an existing leased asset as this person's mailing address.`}
          visible={hasAddress === 'asset'}
        >
          {renderAvailableAssets()}
        </Section>

        <Submit fullWidth/>
      </Form>
    )
  }

  const onSubmit = (values: any, frmk: FormikHelpers<any>): void => {
    const done = () => {
      nProgress.done()
      frmk.setSubmitting(false)
    }

    const data = schema.filter(values)
    nProgress.start();


    (async () => {
      try {
        const response = await postApi('/UpdatePerson', data, {
          personId: person.id
        })

        if (response._.statusCode === 200) {
          navigate('../')
          return
        }
      } catch (e) {
        done()
        throw e
      }

      done()
    })()
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={schema.validate}
      onSubmit={onSubmit}
    >{renderForm}</Formik>
  )       
}

const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { personId } = getParams(args)

  const [
    leases
  ] = await Promise.all([
    getPersonLeases({ personId })
  ])
  
  const assets: ExtendedAsset[] = await Promise.all(
    leases.map(l => getAsset({ assetId: l.assetId }))
  )

  const addressIds: string[] = uniq(assets.map(a => a.addressId).filter(Boolean)) as string[]  
  const addresses = await getAddresses({ ids: addressIds })
  const addressesById = keyBy(addresses, 'id')
  
  return {
    leases,
    assets,
    addressesById
  }
}

const route: RouteObject = {
  path: 'edit',
  loader,
  element: <EditPersonPage />
}

export default route
