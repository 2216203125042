import { HomeIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'

export interface Breadcrumb {
  label: string
  href: string|null
}

interface Props {
  breadcrumbs: Breadcrumb[]
}

export default function Breadcrumbs(props: Props): JSX.Element {
  const { breadcrumbs } = props

  const renderBreadcrumb = (breadcrumb: Breadcrumb, index: number): JSX.Element => {
    const {
      label,
      href
    } = breadcrumb

    return (
      <li key={index}>
        <div className='flex items-center'>
          <svg
            className='flex-shrink-0 h-5 w-5 text-gray-300'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            viewBox='0 0 20 20'
            aria-hidden='true'
          >
            <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z'/>
          </svg>
          {
            href !== null
            ? <Link
                to={href}
                className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
              >
                {label}
              </Link>
            : <span className='ml-4 text-sm font-medium text-gray-500'>{label}</span>
          }
        </div>
      </li>
    )
  }

  return (
    <div className='hidden sm:block'>
      <ol className='flex items-center space-x-4'>
        <li>
          <div>
            <Link to='/' className='text-gray-400 hover:text-gray-500'>
              <div className='flex-shrink-0 h-5 w-5 flex items-center'>
                <HomeIcon className='text-gray-400 hover:text-gray-500 w-4' />
              </div>
              <span className='sr-only'>Home</span>
            </Link>
          </div>
        </li>
        {breadcrumbs.map(renderBreadcrumb)}
      </ol>
    </div>
  )
}
