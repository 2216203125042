import schemas from '@shared/schemas'
import { ExtendedAddress } from '@shared/schemas/address'
import { uniq } from 'lodash'
import { postApi } from 'modules/api'
import { z } from 'zod'

export async function getAddress({ id }: { id: string }): Promise<ExtendedAddress|null> {
  const addresses = await getAddresses({ ids: [ id ] })
  const address = addresses.find(address => address.id === id)

  return address
    ? address
    : null
}

export async function getAddresses({ ids }: { ids: string[] }): Promise<ExtendedAddress[]> {
  const addressIds = uniq(ids)

  // fetch nothing if no ids were actually passed
  if (addressIds.length === 0) return []

  const api = await postApi('/GetAddresses', { ids: addressIds })
  const response = z
    .array(schemas.address.extendedAddress)
    .parse(api.addresses)

  return response
}
