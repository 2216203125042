const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})

interface FormatMoneyOptions {
  format?: 'normal'|'accounting'
}

export function formatMoney(amount: number|string, options: FormatMoneyOptions = {}): string {
  const { format = 'normal' } = options
  const num = Number(amount)

  if (format === 'accounting') {
    if (num < 0) {
      return `(${moneyFormatter.format(num).slice(1)})`
    }
  }

  return moneyFormatter.format(num)
}
