import { ArrowRightCircleIcon } from '@heroicons/react/24/solid'
import schemas from '@shared/schemas'
import { DocumentWithKnownTarget } from '@shared/schemas/document'
import { Users } from '@shared/schemas/user'
import Button from 'components/elements/Button'
import { Card, CardBody, CardFooter, CardHeader } from 'components/elements/Card'
import Empty from 'components/elements/Empty'
import Link from 'components/elements/Link'
import DeleteModal from 'components/elements/modals/DeleteModal'
import Table from 'components/elements/Table'
import { UserContext } from 'contexts/user'
import { postApi, uploadApi } from 'modules/api'
import notifications from 'modules/notifications'
import progress from 'modules/progress'
import { getTypedApi } from 'modules/typedApi'
import moment from 'moment'
import React, { createRef, ReactElement, useContext, useState } from 'react'
import { FaFrown } from 'react-icons/fa'
import { IoTrash } from 'react-icons/io5'
import { BarLoader } from 'react-spinners'
import useAsyncEffect from 'use-async-effect'
import { z } from 'zod'


interface Props {
  targetType: string
  targetId: string
}

export default function Documents(props: Props): ReactElement {
  const { targetType, targetId } = props
  const [ deleteFile, setDeleteFile ] = useState('')
  const [ documents, setDocuments ] = useState<DocumentWithKnownTarget[]>([])
  const [ , setUsers ] = useState<Users>([])
  const [ isLoading, setIsLoading ] = useState(true)
  const [ lastRefreshed, setLastRefreshed ] = useState(Date.now())
  const [ uploadProgress, setUploadProgress ] = useState<null|number>(null)
  const [ uploadDisabled, setUploadDisabled ] = useState(false)
  const self = useContext(UserContext)

  const filePickerRef = createRef<HTMLInputElement>()

  useAsyncEffect(async isMounted => {
    const { users, documents } = await getTypedApi({
      url: '/GetDocuments',
      schema: z.object({
        documents: schemas.document.documentsWithKnownTarget,
        users: schemas.user.users
      }),
      query: { targetType, targetId }
    })

    if (!isMounted()) return

    setUsers(users)
    setDocuments(documents)
    setIsLoading(false)
  }, [ targetType, targetId, lastRefreshed ])
  
  const columns = [
    { key: 'name', label: 'Name', type: 'primary' },
    { key: 'createdAt', label: 'Uploaded' },
    { key: 'actions', label: '' }
  ]

  const doDeleteFile = async (): Promise<void> => {
    setIsLoading(true)
    progress.start('delete file')
    
    const result = await postApi('/DeleteDocument', { id: deleteFile })

    if (result._.statusCode !== 200) {
      notifications.error({ message: 'Failed to delete file. Try again in a minute.' })
      setIsLoading(false)
      progress.done('delete file')
      setDeleteFile('')
      return
    }

    notifications.success({ message: 'File deleted.' })
    setDeleteFile('')
    setLastRefreshed(Date.now())
    
    progress.done('delete file')
  }

  const renderDeleteModal = (): ReactElement => {
    const file = documents.find(doc => doc.id === deleteFile)
    const message = file
      ? <Link to={file.uri}>{file.name} <ArrowRightCircleIcon className='w-5 inline-block mb-1' /></Link>
      : 'One moment, fetching file details.'

    return (
      <DeleteModal
        title='Delete file?'
        message={message}
        visible={deleteFile !== ''}
        action={doDeleteFile}
        isDeleting={isLoading}
        close={() => setDeleteFile('')}
      />
    )
  }

  const rows = documents.map(doc => {
    const deleteButton = (
      <button onClick={() => setDeleteFile(doc.id)}>
        <IoTrash />
      </button>
    )
    
    const actions = (
      <div className='flex items-center justify-end'>
        {
          doc.userId === self.id 
          ? deleteButton
          : null
        }
      </div>
    )

    return {
      name: <Link to={doc.uri}>{doc.name}</Link>,
      createdAt: moment(doc.createdAt).format('lll'),
      actions
    }
  })

  const getSubtitle = (): string => {
    if (isLoading) return 'Just a sec...'
    if (documents.length < 1) return `No documents yet.`
    if (documents.length === 1) return `There is one document.`
    return `There are ${documents.length} documents.`
  }

  const renderLoading = (): JSX.Element => {
    return (
      <CardBody type='custom' className='px-4 py-6 sm:px-6'>
        <div className='h-24 w-full flex items-center justify-center'>
          <BarLoader />
        </div>
      </CardBody>
    )
  }

  const renderDocuments = (): ReactElement => {
    return (
      <CardBody type='table'>
        <>
          <Table inline={true} columns={columns} rows={rows} />
          {
            documents.length === 0
            ? <Empty icon={<FaFrown />} message='No documents uploaded.' height='200px' />
            : null
          }
        </>
      </CardBody>
    )
  }

  const onClickUpload = () => {
    if (filePickerRef.current) {
      setUploadDisabled(true)
      filePickerRef.current.click()
      setTimeout(() => setUploadDisabled(false), 500)
    }
  }

  const onFileChanged = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const done = (): void => {
      progress.setPct(100)
      setUploadProgress(null)
    }

    if (evt.target.files === null || evt.target.files.length < 1) {
      return
    }
    
    const numFiles = evt.target.files.length

    const onProgress = (pct: number, current: number, total: number) => {
      const establishedProgress = Math.round(((current - 1) / total) * 100)
      const globalProgress = establishedProgress + Math.round(pct / total)

      progress.setPct(globalProgress)
      setUploadProgress(globalProgress)
    }

    // clear progress
    setUploadProgress(0)

    let uploadProgressFileCurrentNumber = 1
    let uploadProgressFileTotalNumber = numFiles

    // allow a re-render
    await (new Promise(resolve => requestAnimationFrame(resolve)))

    for (const file of evt.target.files) {
      try {
        const { fileId, url } = await postApi('/GetS3URL', {
          targetType,
          targetId,
          file: {
            name: file.name,
            size: file.size,
            type: file.type
          }
        })

        const response = await uploadApi(
          url,
          { targetType, targetId },
          file,
          // eslint-disable-next-line
          (pct: number) => onProgress(
            pct,
            uploadProgressFileCurrentNumber,
            uploadProgressFileTotalNumber
          ) 
        )

        if (response.status !== 200) {
          notifications.error({
            message: 'Hmm, that upload failed. Try again in a bit.'
          })
          
          return done()
        }

        // now confirm the upload
        await postApi('/ConfirmS3Upload', { id: fileId })
      } catch (e) {
        console.log(e)

        notifications.error({
          message: "Something broke that wasn't supposed to break. Try again in a bit."
        })
        
        return done()
      }

      uploadProgressFileCurrentNumber++
      await (new Promise(resolve => requestAnimationFrame(resolve)))
    }

    notifications.success({
      title: 'Upload Successful!',
      message: 'Your files have been uploaded.'
    })

    setLastRefreshed(Date.now())
    setUploadProgress(null)
  }

  const renderStatus = (): ReactElement => {
    if (uploadProgress !== null) {
      if (uploadProgress === 100) {
        return (
          <span className='text-xs font-medium text-gray-500 mr-2'>
            Finalizing, hold on...
          </span> 
        ) 
      }

      return (
        <span className='text-xs font-medium text-gray-500 mr-2'>
          Uploading {uploadProgress}%...
        </span> 
      )
    }

    return <></>
  }

  return (
    <Card>
      {renderDeleteModal()}
      <CardHeader
        title='Documents'
        subtitle={getSubtitle()}
        divider={documents.length > 0}
      />
      {
        isLoading
        ? renderLoading()
        : renderDocuments()
      }
      <CardFooter>
        <div className='flex items-center justify-end'>
          <input
            type='file'
            ref={filePickerRef}
            onChange={onFileChanged}
            className='hidden'
            multiple
          />
          {renderStatus()}
          <Button
            disabled={uploadDisabled || isLoading || uploadProgress !== null}
            size='small'
            onClick={onClickUpload}
          >Upload</Button>
        </div>
      </CardFooter>
    </Card>
  )
}
