interface Props {
  limit?: number
  count?: number
  currentPage: number|string
}

const PAGE_SIZE = Symbol()
const SET_SIZE = Symbol()
const THIS_PAGE = Symbol()

export default class PageUtils {
  private [PAGE_SIZE]: number = 0
  private [SET_SIZE]: number = 0
  private [THIS_PAGE]: number = 0

  constructor(props: Props) {
    const {
      limit = 20,
      count = 0,
      currentPage
    } = props

    this[PAGE_SIZE] = limit
    this[SET_SIZE] = count
    this[THIS_PAGE] = Number(currentPage)
  }

  public limit = (): number => this[PAGE_SIZE]

  // The current page we're on
  public current = (): number => this[THIS_PAGE]

  // The last page in the set
  public last = (): number => Math.ceil(this[SET_SIZE] / this[PAGE_SIZE])

  public count = (size?: number): number => {
    if (typeof size === 'number') {
      this[SET_SIZE] = size
    }
    
    return this[SET_SIZE]
  }

  public offset = (): number => {
    return Math.max(0, (this[THIS_PAGE] - 1) * this[PAGE_SIZE])
  }

  public firstResult = (): number => this.offset() + 1
  public lastResult = (): number => Math.min(this[SET_SIZE], this.offset() + this.limit())

  public range = (): string => {
    const begin = this.firstResult()
    const end = this.lastResult()
    
    return begin !== end
      ? `${begin}-${end}`
      : `${begin}`
  }
}
