import classNames from 'classnames'
import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

const styles = {
  item: 'flex items-center px-3 py-2 text-sm font-medium rounded-md',
  itemIcon: 'flex-shrink-0 mr-3 h-6 w-6',
  itemBadge: 'ml-auto inline-block py-0.5 px-3 text-xs rounded-full'
}

const currentStyles = {
  base: classNames(
    'bg-gray-200 text-gray-900',
    styles.item
  ),
  icon: classNames('text-gray-500', styles.itemIcon),
  badge: classNames('bg-gray-50', styles.itemBadge)
}

const defaultStyles = {
  base: classNames(
    'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
    styles.item
  ),
  icon: classNames('text-gray-400', styles.itemIcon),
  badge: classNames('bg-gray-200 text-gray-600', styles.itemBadge)
}

interface Item {
  id: string
  label: string
  badge?: string
  icon: ReactElement
  href: string
}

interface Props {
  active?: string|string[]
  className?: string
  items: Item[]
}

export default function VerticalNav(props: Props): JSX.Element {
  const {
    active = '',
    className = '',
    items = []
  } = props 

  const renderItem = (item: Item): JSX.Element => {
    const {
      id,
      icon,
      label,
      badge = '',
      href
    } = item

    let itemStyles = defaultStyles

    if (
      (typeof active === 'string' && active === id) ||
      (Array.isArray(active) && active.includes(id))
    ) {
      itemStyles = currentStyles
    }
    
    const renderBadge = (): JSX.Element => {
      return (
        <span className={itemStyles.badge}>
          {badge}
        </span>
      )
    }

    const IconElement = React.cloneElement(icon, {
      className: itemStyles.icon
    }) 

    return (
      <Link key={id} to={href} className={itemStyles.base}>
        {IconElement}
        <span className='truncate'>
          {label}
        </span>
        {
          badge !== ''
          ? renderBadge()
          : null
        }
      </Link>
    )
  }

  return (
    <nav className={classNames('space-y-1', className)}>
      {items.map(renderItem)}
    </nav>
  )
}
