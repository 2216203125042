import { ExtendedAsset } from '@shared/schemas/asset'
import { ExtendedLease } from '@shared/schemas/lease'
import { ExtendedPerson } from '@shared/schemas/person'
import classNames from 'classnames'
import { Card, CardBody, CardFooter, CardHeader } from 'components/elements/Card'
import DescriptionList, { DLItemList } from 'components/elements/DescriptionList'
import Link from 'components/elements/Link'
import LeaseStatusBadge from 'components/utils/LeaseStatusBadge'
import moment from 'moment'
import { FaUser } from 'react-icons/fa'
import { RiQuestionFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { assetTypeToReadable } from 'utils/assetTypes'

interface Props {
  title?: string
  subtitle?: string
  asset: ExtendedAsset
  parentAsset?: ExtendedAsset|null
  lease: ExtendedLease
  people: ExtendedPerson[]
}

export default function LeaseCard(props: Props): JSX.Element {
  const {
    title = 'Lease',
    subtitle = '',
    lease,
    people,
    asset,
    parentAsset = null
  } = props

  const navigate = useNavigate()

  const renderPeople = (): JSX.Element => {
    const items = people.map(person => {
      return {
        icon: <FaUser />,
        label: [ person.firstName, person.lastName ].join(' '),
        action: () => navigate(`/p/${person.propertyId}/person/${person.id}`)
      }
    })

    return <DLItemList items={items} />
  }

  const renderDetails = (): JSX.Element => {
    const {
      termStart,
      termEnd,
      status
    } = lease

    const term: string = [
      moment(termStart).format('l'),
      moment(termEnd).format('l')
    ].join(' - ')

    const items = [
      { label: 'Term', value: term },
      { label: 'Status', value: <LeaseStatusBadge status={status} /> },
      { label: 'People', value: renderPeople(), wide: true }
    ]

    return (
      <DescriptionList
        inline
        twoColumn
        data={items}
      />
    )
  }

  const renderSubtitle = () => {
    if (subtitle !== '') return subtitle
    if (parentAsset === null || parentAsset.id === asset.id) return ''
    
    const notParentText: string = [
      'Showing lease for ',
      assetTypeToReadable(parentAsset.type),
      parentAsset.displayId
    ].join(' ')

    const styles: string = classNames(
      'ml-1 group inline-flex items-start text-sm space-x-2 text-gray-400',
      'hover:text-gray-600'
    )

    return (
      <span className='flex items-center'>
        <span>{notParentText}</span>
        <button
          type='button'
          className={styles}>
          <RiQuestionFill size={16} />
        </button>
      </span>
    )
  }

  return (
    <Card>
      <CardHeader title={title} subtitle={renderSubtitle()} />
      <CardBody type='table'>
        {renderDetails()}
      </CardBody>
      <CardFooter>
        <Link
          to={`/lease/${lease.id}`}
          relative='property'
          className='block text-sm font-medium text-gray-500 text-center hover:text-gray-700 sm:rounded-b-lg'
        >
          View full lease details
        </Link>
      </CardFooter>
    </Card>
  )
}
