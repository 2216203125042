import { ExtendedPerson } from '@shared/schemas/person'
import HeaderAvatar from 'components/app/HeaderAvatar'
import Page from 'components/app/Page'
import Shell from 'components/app/Shell'
import Dropdown from 'components/elements/Dropdown'
import DeleteModal from 'components/elements/modals/DeleteModal'
import MultiContext from 'components/utils/MultiContext'
import { PersonContext } from 'contexts/person'
import { postApi } from 'modules/api'
import notifications from 'modules/notifications'
import progress from 'modules/progress'
import { ReactElement, useState } from 'react'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { IoPerson } from 'react-icons/io5'
import { LoaderFunction, LoaderFunctionArgs, Outlet, RouteObject, useLoaderData, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { getPerson } from 'stores/person'
import getParams from 'utils/getParams'
import { useProperty } from '../_root'
import PersonNav from './_nav'

type LoaderData = {
  person: ExtendedPerson
}

const PersonRootComponent = (): ReactElement => {
  const { person } = useLoaderData() as LoaderData
  const property = useProperty()
  const navigate = useNavigate()
  
  const [ visibleModal, setVisibleModal ] = useState('')
  const [ isDeleting, setIsDeleting ] = useState(false)

  const fullName = [ person.firstName, person.lastName ].join(' ')

  const deletePerson = async (): Promise<void> => {
    setIsDeleting(true)
    progress.start('delete person')

    const result = await postApi('/DeletePerson', { personId: person.id })

    progress.done('delete person')

    if (result._.statusCode !== 200) {
      setIsDeleting(false)
      setVisibleModal('')

      notifications.error({ message: result.message })
      return
    }
    
    navigate(`/p/${property.id}/people`)

    notifications.success({
      message: (
        <span>
          <span className='font-medium'>{fullName}</span> has been deleted.
        </span>
      )
    })
  }

  const renderHeader = (): JSX.Element => {
    const menu = [
      [
        {
          label: 'Edit',
          icon: <FaEdit />,
          action: () => navigate('edit')
        }
      ],
      [
        { 
          label: 'Delete',
          icon: <FaTrashAlt />,
          action: () => setVisibleModal('deletePerson')
        }
      ]
    ]
  
    const actions = [
      <Dropdown label='Options' menu={menu} />
    ]

    return (
      <HeaderAvatar
        title={fullName}
        actions={actions}
        avatarIcon={<IoPerson />}
      />
    )
  }

  const crumbs = [
    {
      label: property.displayName, 
      href: `/p/${property.id}`
    },
    {
      label: 'People',
      href: `/p/${property.id}/people`
    },
    {
      label: fullName,
      href: `/p/${property.id}/person/${person.id}`
    }
  ]
  const contexts = [
    { context: PersonContext, value: person }
  ]

  return (
    <MultiContext contexts={contexts}>
      <Shell active='PEOPLE' breadcrumbs={crumbs}>
        <DeleteModal
          isDeleting={isDeleting}
          visible={visibleModal === 'deletePerson'}
          action={deletePerson}
          close={() => setVisibleModal('')}
        />
        {renderHeader()}
        <Page>
          <div className='grid grid-cols-3 gap-6'>
            <div className='col-span-2'>
              <Outlet />
            </div>

            <div className='col-span-1'>
              <PersonNav />
            </div>
          </div>
        </Page>
      </Shell>
    </MultiContext>
  )
}

const loader: LoaderFunction = async (args: LoaderFunctionArgs): Promise<any> => {
  const { personId } = getParams(args)
  const person = await getPerson({ personId })

  return { person }
}

const route: RouteObject = {
  id: 'person',
  path: 'person/:personId',
  element: <PersonRootComponent />,
  loader
}

export const usePerson = (): ExtendedPerson => {
  const { person } = useRouteLoaderData('person') as LoaderData
  return person
}

export default route
