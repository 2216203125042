import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { PersonStub } from '@shared/schemas/person'
import { Breadcrumb } from 'components/app/Breadcrumbs'
import HeaderAvatar from 'components/app/HeaderAvatar'
import Page from 'components/app/Page'
import Shell from 'components/app/Shell'
import Badge from 'components/elements/Badge'
import Button from 'components/elements/Button'
import { Card, CardBody, CardFooter } from 'components/elements/Card'
import Empty from 'components/elements/Empty'
import Pagination from 'components/elements/Pagination'
import Table from 'components/elements/Table'
import React, { ReactElement } from 'react'
import { IoPeople } from 'react-icons/io5'
import { LoaderFunctionArgs, useLoaderData, useNavigate } from 'react-router-dom'
import { getPeople } from 'stores/person'
import getParams from 'utils/getParams'
import { getSearchParams } from 'utils/getQuery'
import PageUtils from 'utils/pages'
import { useProperty } from './_root'

interface LoaderData {
  people: PersonStub[]
  page: PageUtils
}

const PeoplePageComponent = (): ReactElement => {
  const navigate = useNavigate()
  const property = useProperty()
  const {
    people = [],
    page
  } = useLoaderData() as LoaderData

  const title: string = 'People'
  const subtitle: string = page.count() !== 0
    ? `Viewing ${page.range()} of ${page.count()} people.`
    : 'Nothing here yet.'

  const columns = [
    { key: 'name', label: 'Name', type: 'primary' },
    { key: 'address', label: 'Address' },
    { key: 'status', label: '', rowClasses: 'text-right' }
  ]

  const rows = people.map(person => {
    const {
      id,
      firstName,
      lastName,
      address = null
    } = person
    
    const config = {
      onClick: () => navigate(`/p/${property.id}/person/${id}`)
    }

    const displayAddress = address !== null
      ? `${address.street1} ${address.street2}`
      : ''

    return {
      name: `${firstName} ${lastName}`,
      address: displayAddress,
      status: <Badge label='Active' color='green' />,
      config
    }
  })

  const actions = [
    <Button type='secondary' onClick={() => navigate(`/p/${property.id}/people/create`)}>
      <span>Person</span><PlusCircleIcon className='w-4 ml-2' />
    </Button>
  ]

  const breadcrumbs: Breadcrumb[] = [
    { label: property.displayName, href: `/p/${property.id}` },
    { label: 'People', href: `/p/${property.id}/people` }
  ]

  const renderPages = (): JSX.Element => {
    if (page.last() === 1) {
      return <React.Fragment />
    }

    return (
      <CardFooter>
        <Pagination
          page={page}
          getPageHref={(page: number) => `/p/${property.id}/people?p=${page}`}
        />
      </CardFooter>
    )
  }

  const renderPeople = people.length !== 0
    ? <Card>
        <CardBody type='table'>
          <Table columns={columns} rows={rows} inline />
        </CardBody>
        {renderPages()}
      </Card>
    : <Card>
        <Empty
          icon={<IoPeople />}
          message='Nobody has been added yet.'
          height='200px'
        />
      </Card>

  return (
    <Shell active='PEOPLE' breadcrumbs={breadcrumbs}>
      <HeaderAvatar
        title={title}
        subtitle={subtitle}
        actions={actions}
        avatarIcon={<IoPeople />}
      />
      <Page>
        {renderPeople}
      </Page>
    </Shell>
  )
}

const loader = async (args: LoaderFunctionArgs): Promise<LoaderData> => {
  const { propertyId } = getParams(args)
  const { p: pageStr = '1' } = getSearchParams(args)

  const pageUtil = new PageUtils({
    currentPage: Number(pageStr)
  })

  const data = await getPeople({
    propertyId,
    offset: pageUtil.offset(),
    limit: pageUtil.limit()
  })
  

  const { people, page } = data
  pageUtil.count(page.count)

  return {
    people,
    page: pageUtil
  }
}

const route = {
  path: 'people',
  element: <PeoplePageComponent />,
  loader
}

export default route
