import React, { ReactElement } from 'react'

interface Props {
  children: ReactElement|ReactElement[]
}

export default function Page(props: Props): JSX.Element {
  const { children } = props

  return (
    <main>
      <div className='max-w-7xl mx-auto py-6 sm:px-6 lg:px-8'>
        {children}
      </div>
    </main>
  )
}
