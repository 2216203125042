import { useFormikContext } from 'formik'
import {
  getNamespacedErrors, getWithinNamespace, withNamespace
} from '../../../utils/forms'
import Badge from '../Badge'
import AttributesInput from './inputs/Attributes'
import DateInput from './inputs/Date'
import PeopleSelect from './inputs/People'
import Section from './inputs/Section'
import TextInput from './inputs/Text'

interface Props {
  propertyId: string
  visible: boolean
  index?: number
  namespace?: string
  title?: string
  subtitle?: string
}

export default function LeaseForm(props: Props): JSX.Element {
  const {
    visible,
    index = 0,
    propertyId,
    namespace: ns = null,
    title = 'Lease Details',
    subtitle = ''
  } = props

  const formik = useFormikContext()
  const namespace = withNamespace(ns)
  const formikValues = getWithinNamespace(formik.values, namespace)
  const formikErrors = getNamespacedErrors(formik, namespace)

  const tenants = formikValues('tenants')

  function handleSelectTenant(tenant: any) {
    const exists = tenants
      .filter((item: any) => item.id === tenant.id)
      .length > 0

    // Do not add the item it's already in the list
    if (exists) return

    formik.setFieldValue(namespace('tenants'), [ ...tenants, tenant ])
  }

  function renderTenant(tenant: any): JSX.Element {
    const {
      id,
      firstName,
      lastName
    } = tenant

    const onRemove = () => {
      formik.setFieldValue(
        namespace('tenants'),
        tenants.filter((item: any) => item.id !== id)
      )
    }

    return (
      <span key={id} className='mt-0.5 mr-1'>
        <Badge
          label={`${firstName} ${lastName}`}
          onRemove={onRemove}
          size='large'
          color='indigo'
        />
      </span>
    )
  }

  function renderTenants(tenants: any[]): JSX.Element {
    return (
      <div className='col-span-6 sm:col-span-3 flex flex-col'>
        <label className='block text-sm leading-5 font-medium text-gray-700'>
          &nbsp;
        </label>
        <div className='flex flex-1 flex-wrap mt-2'>
          {tenants.map(renderTenant)}
        </div>
      </div>
    )
  }
  
  return (
    <>
      <Section title={title} subtitle={subtitle} index={index} visible={visible}>
        <PeopleSelect
          label='Add a person'
          propertyId={propertyId}
          onSelect={handleSelectTenant}
          error={formikErrors('tenants')}
        />

        {renderTenants(tenants)}

        <DateInput
          label='Start date'
          className='col-span-6 sm:col-span-2'
          name={namespace('termStart')}
        />

        <DateInput
          label='End date'
          className='col-span-6 sm:col-span-2'
          name={namespace('termEnd')}
        />

        <div className='col-span-6 sm:col-span-2'>
        </div>

        <TextInput
          label='Due day'
          help='The day of the month that lease payments are due.'
          className='col-span-6 sm:col-span-2'
          placeholder='1'
          name={namespace('dueDay')}
        />

        <TextInput
          label='Late after (in days)'
          name={namespace('lateAfterDays')}
          help='Payments will be considered late if received this many days after the due day.'
          className='col-span-6 sm:col-span-2'
          placeholder='7'
        />
      </Section>
      <Section
        title='Extra Charges & Fees'
        subtitle='These are items that will be added to the statements for this lease on a recurring basis.'
        index={1}
      >
        <AttributesInput
          name='lineItems'
          mode='props'
          label='Item'
          buttonLabel='Add item'
          placeholder='Pet Rent'
          allowCustom
          allowDuplicates
          customAttrType='number'
          customAttrIdIsUUID
        />
      </Section>
    </>
  )
}
