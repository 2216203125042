import { Property } from '@shared/schemas/property'
import { User } from '@shared/schemas/user'
import MultiContext from 'components/utils/MultiContext'
import { PropertyContext } from 'contexts/property'
import { UserContext } from 'contexts/user'
import { ReactElement } from 'react'
import { LoaderFunctionArgs, Outlet, RouteObject, useLoaderData, useRouteLoaderData } from 'react-router-dom'
import { getProperty } from 'stores/property'
import { getSelfUser } from 'stores/user'
import getParams from 'utils/getParams'

type LoaderData = {
  property: Property
  user: User
}

const PropertyPageComponent = (): ReactElement => {
  const { property, user } = useLoaderData() as LoaderData
  const contexts = [
    { context: PropertyContext, value: property },
    { context: UserContext, value: user }
  ]
  
  return (
    <MultiContext contexts={contexts}>
      <Outlet />
    </MultiContext>
  )
}

const loader = async (args: LoaderFunctionArgs): Promise<LoaderData> => {
  const { propertyId } = getParams(args)

  const [
    property,
    user
  ] = await Promise.all([
    getProperty({ propertyId }),
    getSelfUser() // this needs to be moved up scope
  ])

  return { property, user }
}


const route: RouteObject = {
  id: 'property',
  path: '/p/:propertyId/',
  element: <PropertyPageComponent />,
  loader
}

export const useProperty = (): Property => {
  const { property } = useRouteLoaderData('property') as LoaderData
  return property
}

export default route
