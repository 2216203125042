import Button from 'components/elements/Button'
import ImgWithFallback from 'components/elements/ImgWithFallback'
import { useNavigate } from 'react-router-dom'

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate()

  const goBack = () => navigate(-1)

  return (
    <div className='border-2 h-screen flex items-center justify-center'>
      <div>
        <ImgWithFallback
          src='/assets/images/404.webp, /assets/images/404@2x.webp 2x'
          fallback='/assets/images/404.png'
          width={300}
          className='-ml-8'
        />
        <h2 className='mt-8 text-3xl font-bold text-gray-900'>Oops, something is missing.</h2>
        <p className='mt-2 text-xl text-gray-600 max-w-screen-sm leading-8'>
          Oh darn. This resource may have been deleted or moved, or it may have&nbsp;
          <i className='opacity-80'>never</i>&nbsp;
          <i className='opacity-60'>existed</i>&nbsp;
          <i className='opacity-40'>at</i>&nbsp;
          <i className='opacity-20'>all.</i>
        </p>
        <div className='mt-5'>
          <Button onClick={goBack}>Go Back</Button>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
