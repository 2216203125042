import schemas from '@shared/schemas'
import { User } from '@shared/schemas/user'
import { getTypedApi } from 'modules/typedApi'

interface GetUserOptions {
  userId: string
}

export async function getUser(options: GetUserOptions): Promise<User> {
  const { userId } = options

  const apiOptions: any = {
    url: '/GetUser',
    path: 'user',
    query: { userId },
    schema: schemas.user.user
  }

  if (userId === 'self') {
    apiOptions.cache = true
  }

  return await getTypedApi(apiOptions)
}

export async function getSelfUser(): Promise<User> {
  return await getUser({ userId: 'self' })
}
