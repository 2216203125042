import { AssetTypes } from '@shared/schemas/asset'
import { ReactElement } from 'react'
import { FaBox, FaBuilding, FaCar, FaCaravan, FaTree } from 'react-icons/fa'

const ASSET_ICONS: Record<AssetTypes, ReactElement> = {
  'mhp_home': <FaCaravan />,
  'mhp_lot': <FaTree />,
  'parking': <FaCar />,
  'storage_unit': <FaBox />,
  'apartment': <FaBuilding />
}

export { assetTypeToReadable } from '@shared/utils/assetTypes'

export function assetTypeIcon(type: AssetTypes): ReactElement {
  const _type = type.toLowerCase() as AssetTypes

  if (!ASSET_ICONS.hasOwnProperty(_type)) {
    throw Error('assetTypeToReadable: unknown asset type')
  }

  return ASSET_ICONS[_type]
}
