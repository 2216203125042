import React, { useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { omit } from 'lodash'

/**
 * *-active classes represent which styles you want to animate to, so it's 
 * important to add transition declaration only to them, otherwise transitions
 * might not behave as intended! 
 */

/**
* What's happening right now is that translate-x-0 is not occuring, the
* animation that is playing is because it is translating to x-2 when the
* enterActive classes fire
* 
* So this doesn't work it all and it has to do with CSS class specificity.
* Basically, the animation library applies enter classes, and then in the
* next tick tacks on the enterActive classes. As long as the enterActive
* classes are definied later in the CSS file than the enter classes, this
* will work, but it will NOT work if the enter classes are defined later,
* as they will not be overridden by the browser.
* 
* This is why setting opacity-100 in exit and then trying to animate to 
* opacity-0 does not work, because opacity-100 is defined later in the file
*/
export default function GenericTransition(props: any): JSX.Element {
  const nodeRef = useRef(null)

  const {
    styles,
    className = '',
    children,
    duration = 300
  } = props

  const spreadProps = omit(props, [
    'styles',
    'className',
    'children',
    'duration'
  ])

  return (
    <CSSTransition
      {...spreadProps}
      timeout={duration}
      classNames={{ ...styles }}
      nodeRef={nodeRef}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      <div ref={nodeRef} className={className}>
        {children}
      </div>
    </CSSTransition>
  )
}
