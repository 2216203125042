import classNames from 'classnames'
import { Children, ReactElement } from 'react'

const Spacer = ({ children }: { children: ReactElement|ReactElement[] }): ReactElement => {
  const renderChild = (child: ReactElement, index: number) => {
    const classes = classNames(
      'block',
      { 'mt-6': index !== 0 }
    )

    return <div className={classes}>{child}</div>
  }

  return <>{Children.map(children, renderChild)}</>
}

export default Spacer
