import {
  CalendarDaysIcon, CheckIcon, ClockIcon, CurrencyDollarIcon, ExclamationTriangleIcon
} from '@heroicons/react/24/solid'
import { BillingInvoice } from '@shared/schemas/billing'
import Badge from 'components/elements/Badge'
import Stats from 'components/elements/Stats'
import Table from 'components/elements/Table'
import Decimal from 'decimal.js'
import { DateTime } from 'luxon'
import { ReactElement } from 'react'
import { formatMoney } from 'utils/transforms'

export function renderInvoiceStatusBadge(status: string): ReactElement {
  if (status === 'paid') {
    return <Badge color='green' label='Paid' />
  }

  if (status === 'past_due') {
    return <Badge color='red' label='Overdue' />
  }

  if (status === 'upcoming') {
    return <Badge color='gray' label='Upcoming' />
  }

  return <Badge color='blue' label='Issued' />
}

interface InvoiceRenderStatsOptions {
  hideStatus?: boolean
}

export function renderStats(
  invoice: BillingInvoice,
  options: InvoiceRenderStatsOptions = {}
): ReactElement {
  const {
    hideStatus = false
  } = options

  const due = DateTime.fromJSDate(invoice.due, { zone: 'UTC' })
  const now = DateTime.now()

  const status = () => {    
    if (new Decimal(invoice.amount).equals(invoice.paid)) {
      return {
        label: 'Status',
        value: 'Paid',
        icon: <CheckIcon className='w-4' />
      }
    }

    if (now > due) {
      return {
        label: 'Status',
        value: 'Overdue',
        icon: <ExclamationTriangleIcon className='w-4' />,
        iconBg: 'bg-red-500'
      }
    } 

    return {
      label: 'Status',
      value: 'Issued',
      icon: <CurrencyDollarIcon className='w-4' />
    }
  }

  let stats = [
    {
      label: 'Amount',
      value: formatMoney(invoice.amount),
      icon: <ClockIcon className='w-4' />
    },
    {
      label: 'Due',
      value: DateTime.fromJSDate(invoice.due, { zone: 'UTC' }).toFormat('M/dd/yy'),
      icon: <CalendarDaysIcon className='w-4' />
    }
  ]

  if (!hideStatus) {
    stats = [status(), ...stats]
  }

  return <Stats data={stats} />
}

export function renderPayload(invoice: BillingInvoice): ReactElement {
  const { payload } = invoice
  const { items } = payload

  const columns = [
    { key: 'item', label: 'Item' },
    { key: 'amount', label: 'Amount' },
    { key: 'qty', label: 'QTY' },
    { key: 'total', label: 'Total', rowClasses: 'text-right', headerClasses: 'text-right' }
  ]

  const rows = items.map(item => {
    const {
      name,
      description = '',
      amount,
      qty
    } = item

    const renderItem = description === ''
      ? name
      : `${name} // ${description}`

    return {
      item: renderItem,
      amount: formatMoney(amount),
      qty,
      total: formatMoney(
        new Decimal(amount).mul(qty).toFixed(2)
      )
    }
  })

  return <Table rows={rows} columns={columns} />
}
