export function hasStyle(classNames: string, searchClass: string): boolean {
  const individualClasses = classNames.split(' ')

  if (!searchClass.endsWith('*')) {
    return individualClasses.includes(searchClass)
  }

  for (const i of individualClasses) {
    if (i.startsWith(searchClass.slice(0, -1))) {
      return true
    }
  }

  return false
}
