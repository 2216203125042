import { omit } from 'lodash'
import React from 'react'

interface Props {
  src: string|string[]
  fallback: string
  type?: string
  className?: string
  width?: number|string
}

const ImgWithFallback: React.FC<Props> = props => {
  const sources = Array.isArray(props.src)
    ? props.src
    : [ props.src ]

  const {
    type = 'image/webp',
    fallback
  } = props

  const passthrough = omit(props, [
    'src',
    'fallback',
    'type'
  ])

  // logo-480.png, logo-480-2x.png 2x
  return (
    <picture>
      {sources.map(src => <source srcSet={src} type={type} key={src} />)}
      <img src={fallback} {...passthrough} alt='' />
    </picture>
  )
}

export default ImgWithFallback
