import * as address from './address'
import * as asset from './asset'
import * as lease from './lease'
import * as person from './person'
import * as setting from './setting'
import * as property from './property'
import * as comment from './comment'
import * as user from './user'
import * as page from './page'
import * as attribute from './attribute'
import * as activity from './activity'
import * as document from './document'
import * as billing from './billing'

export default {
  address,
  asset,
  lease,
  person,
  setting,
  property,
  comment,
  user,
  page,
  attribute,
  activity,
  document,
  billing
}
