import schemas from '@shared/schemas'
import { Form } from 'components/elements/Form'
import AddressForm from 'components/elements/forms/Address'
import AttributesInput, {
  mapKeyedAttrsToListAttrs
} from 'components/elements/forms/inputs/Attributes'
import Section from 'components/elements/forms/inputs/Section'
import Submit from 'components/elements/forms/inputs/Submit'
import TextInput from 'components/elements/forms/inputs/Text'
import { AssetModificationContext } from 'contexts/assetModification'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { postApi } from 'modules/api'
import progress from 'modules/progress'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import createSchema from 'utils/createSchema'
import { withNamespace } from 'utils/forms'
import namespaces from 'utils/namespaces'
import { useAsset } from './_root'

export default function EditLotPage(): JSX.Element {
  const { asset, address } = useAsset()
  const navigate = useNavigate()
  const { assetAttributeSettings } = useContext(AssetModificationContext)
  const attributeSettings = assetAttributeSettings['mhp_lot']

  const namespace = withNamespace(namespaces.lot)

  const schema = createSchema()
    .with(namespaces.lot, schemas.asset.lot)
    .with(namespaces.address, schemas.address.address)

  const assetAttributesToFormAttributes = mapKeyedAttrsToListAttrs(
    asset.attributes,
    attributeSettings.presets,
    { allowCustom: attributeSettings.allowCustom }
  )
  
  const initialAddress = address !== null
    ? address
    : schemas.address.blanks.address

  const initialValues = schema.filter({
    [namespaces.lot]: {
      ...asset,
      attributes: assetAttributesToFormAttributes
    },
    [namespaces.address]: initialAddress
  })

  const asyncOnSubmit = async (
    values: any,
    frmk: FormikHelpers<any>
  ): Promise<void> => {
    const done = () => {
      progress.done('update asset')
      frmk.setSubmitting(false)
    }

    const data = schema.filter(values)
    progress.start('update asset')

    try {
      await postApi('/UpdateAsset', data, {
        assetId: asset.id
      })
    } catch (e) {
      done()
      throw e
    }

    progress.done('update asset')
    navigate(`/p/${asset.propertyId}/asset/${asset.id}`)
  }

  const onSubmit = (values: any, frmk: FormikHelpers<any>): void => {
    asyncOnSubmit(values, frmk)
  }

  const renderForm = (formikProps: FormikProps<typeof initialValues>): JSX.Element => {
    return (
      <Form onSubmit={formikProps.handleSubmit}>
        <Section fullWidth>
          <TextInput
            label={'Lot #'}
            className='col-span-6 sm:col-span-3'
            help='This might also be the Unit or Apt number.'
            name={namespace('displayId')}
          />

          <TextInput
            label='Base rate'
            help='The standard monthly charge for this asset.'
            className='col-span-6 sm:col-span-3'
            name={namespace('baseRate')}
          />

          <AttributesInput
            label='Attributes'
            help='Extra information about the asset.'
            name={namespace('attributes')}
            mode='settings'
            settings={attributeSettings}
          />
        </Section>

        <AddressForm
          fullWidth
          namespace={namespaces.address}
          visible
        />

        <Submit fullWidth />
      </Form>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={schema.validate}
      onSubmit={onSubmit}
    >{renderForm}</Formik>
  )
}
