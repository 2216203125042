import * as z from 'zod'
import { attributes } from './attribute'
import { zFlexCurrency, zJsonDate } from './util'

export const types = z.enum([
  'mhp_lot',
  'mhp_home',
  'parking',
  'storage_unit',
  'apartment'
])

export const displayIdPattern = /^([A-Z]{0,5})([0-9]{0,5})([A-Z]{0,5})$/i

/**
 * .baseRate should probably just be a string across the board, to prevent JS
 * floating point issues
 */
const baseAsset = z.object({
  baseRate: zFlexCurrency(),
  attributes: attributes.nullable().optional()
})

const hasDisplayId = z.object({
  displayId: z.string().regex(displayIdPattern, {
    message: 'letters and numbers only, up to five of each'
  }).nullable().optional()
})

// The server returns the saved assets as an id-keyed object, not an array
const hasExtendedAttributes = {
  attributes: z.record(
    z.union([
      z.string(),
      z.number(),
      z.boolean()
    ])
  ).nullable().optional()
}

export const extendedAsset = baseAsset
  .merge(hasDisplayId)
  .extend(hasExtendedAttributes)
  .merge(z.object({
    id: z.string().uuid(),
    addressId: z.string().uuid().nullable().optional(), // Some methods require that we pass the address ID directly
    type: types,
    propertyId: z.string().uuid(),
    parentAssetId: z.string().uuid().nullable().optional(),
    createdAt: z.union([
      z.date(),
      zJsonDate()
    ])
  }))

export const assetStub = extendedAsset
  .pick({
    id: true,
    displayId: true,
    type: true,
    propertyId: true
  })

export const extendedAssets = z.array(extendedAsset)

// TODO: the base should have a displayId
const base = baseAsset.merge(hasDisplayId)

export const home = base
export const lot = base
export const apartment = base

export type AssetTypes = z.infer<typeof types>
export type Home = z.infer<typeof home>
export type Lot = z.infer<typeof lot>
export type Apartment = z.infer<typeof apartment>
export type ExtendedAsset = z.infer<typeof extendedAsset>
export type AssetStub = z.infer<typeof assetStub>

const blankAsset: z.infer<typeof base> = {
  displayId: '',
  baseRate: '0',
  attributes: null
}

const blankExtendedAsset: ExtendedAsset = {
  ...blankAsset,
  attributes: null,
  type: 'mhp_home',
  id: '_blank',
  propertyId: '_blank',
  createdAt: new Date()
}

export const blanks = Object.freeze({
  asset: blankAsset,
  extendedAsset: blankExtendedAsset,
  home: blankAsset,
  lot: blankAsset,
  apartment: blankAsset
})
