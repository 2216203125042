import { generateApiUrl, postApi } from './api'

const TOKEN_NAME: string = 'deletype.hc:access_token'

export function getAccessToken(): string|null {
  return localStorage.getItem(TOKEN_NAME)
}

export function setAccessToken(token: string): void {
  return localStorage.setItem(TOKEN_NAME, token)
}

export async function getAuthState(): Promise<boolean> {
  const accessToken = getAccessToken()

  if (!accessToken) {
    return false
  }

  const resource = generateApiUrl('/GetUser', { userId: 'self' })
  const response = await fetch(resource, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })

  if (response.status === 200) {
    return true
  }

  return false
}

export async function clearAuth() {
  const token = getAccessToken()
  
  if (token !== null) {
    // This is a best-effort attempt to remove the access token server side
    try {
      await postApi('/DeleteAccessToken', { token })
    } catch (e) {
      // just move on
      console.warn('server may have not deleted token', e)
    }
  }

  // Empty local storage
  localStorage.clear()
}
