import { AssetTypes } from '@shared/schemas/asset'
import { Attributes } from '@shared/schemas/setting'
import HeaderAvatar from 'components/app/HeaderAvatar'
import Page from 'components/app/Page'
import Shell from 'components/app/Shell'
import CreateApartment from 'components/create_asset/CreateApartment'
import CreateHome from 'components/create_asset/CreateHome'
import CreateLot from 'components/create_asset/CreateLot'
import CenteredWideButtonsModal from 'components/elements/modals/CenteredWideButtonsModal'
import MultiContext from 'components/utils/MultiContext'
import { AssetModificationContext, IAssetModificationContext } from 'contexts/assetModification'
import { ReactElement, useRef, useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import { LoaderFunctionArgs, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import { getAssetAttributeSettings } from 'stores/asset'
import { assetTypeIcon, assetTypeToReadable } from 'utils/assetTypes'
import getParams from 'utils/getParams'
import { getSearchParams } from 'utils/getQuery'
import { useProperty } from './_root'

type LoaderData = {
  assetType: AssetTypes
  assetModificationContext: IAssetModificationContext
}

const SUBTITLES: Record<AssetTypes, string> = {
  'mhp_lot': 'Why add a little, when you can add a lot?',
  'mhp_home': 'Home is where the heart is, and you can take this one with you.',
  'parking': '',
  'storage_unit': '',
  'apartment': ''
}

const CreateAssetPageComponent = (): ReactElement => {
  const [ newAssetId, setNewAssetId ] = useState('')
  const formResetRef = useRef()
  const navigate = useNavigate()

  const {
    assetType,
    assetModificationContext
  } = useLoaderData() as LoaderData

  const property = useProperty()

  const updateParentState = (
    { newAssetId }:
    { newAssetId: string }
  ) => {
    setNewAssetId(newAssetId)
  }

  const updateParentFormResetRef = (ref: any) => {
    formResetRef.current = ref
  }

  const formRenderProps = {
    updateParentState,
    updateParentFormResetRef
  }

  const renderForm = (): JSX.Element => {
    switch (assetType) {
      case 'mhp_lot': return <CreateLot {...formRenderProps} />
      case 'mhp_home': return <CreateHome {...formRenderProps} />
      case 'apartment': return <CreateApartment {...formRenderProps} />
      default: return <div>Not found</div>
    }
  }

  const breadcrumbs = () => {    
    return [{
      label: property.displayName,
      href: `/p/${property.id}`
    }, {
      label: assetTypeToReadable(assetType, true),
      href: `/p/${property.id}/assets/${assetType}`
    }, {
      label: 'Create',
      href: null
    }]
  }

  const contexts = [
    { context: AssetModificationContext, value: assetModificationContext }
  ]

  const confirmModalButtons = {
    confirm: {
      label: `Go to ${assetTypeToReadable(assetType).toLowerCase()}`,
      action: () => {
        navigate(`/p/${property.id}/asset/${newAssetId}`)
      }
    },
    cancel: {
      label: `Add another ${assetTypeToReadable(assetType).toLowerCase()}`,
      action: () => {
        setNewAssetId('')

        if (typeof formResetRef.current === 'function') {
          const resetFunc = formResetRef.current as () => void
          resetFunc()
        }
      }
    }
  }

  return (
    <MultiContext contexts={contexts}>
      <Shell active={assetType} breadcrumbs={breadcrumbs()}>
        <CenteredWideButtonsModal
          visible={newAssetId !== ''}
          icon={<FaCheck className='text-white' />}
          iconBg='bg-green-400'
          title={`${assetTypeToReadable(assetType)} created!`}
          message='Would you like to view it now or add another one?'
          buttons={confirmModalButtons}
        />

        <HeaderAvatar
          avatarIcon={assetTypeIcon(assetType)}
          title={`Add a ${assetTypeToReadable(assetType).toLowerCase()}`}
          subtitle={SUBTITLES[assetType]}
        />
        <Page>
          {renderForm()}
        </Page>
      </Shell>
    </MultiContext>
  )
}

const loader = async (args: LoaderFunctionArgs): Promise<LoaderData> => {
  const { propertyId } = await getParams(args)
  const { type: assetType } = getSearchParams<{ type: AssetTypes }>(args)

  const assetAttributeSettings: Record<string, Attributes> = {}
  
  assetAttributeSettings[assetType] = await getAssetAttributeSettings({
    assetType,
    propertyId
  })

  /**
   * If we are creating a lot, we can also add a home at the same time. If we
   * are doing that, we should also get the attribute settings for a home type
   */
  if (assetType === 'mhp_lot') {
    assetAttributeSettings['mhp_home'] = await getAssetAttributeSettings({
      assetType: 'mhp_home',
      propertyId
    })
  }

  const assetModificationContext: IAssetModificationContext = {
    assetAttributeSettings
  }

  return {
    assetType,
    assetModificationContext
  }
}

const route: RouteObject = {
  path: 'assets/create',
  element: <CreateAssetPageComponent />,
  loader
}

export default route
