import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { BillingInvoice } from '@shared/schemas/billing'
import Button from 'components/elements/Button'
import Table from 'components/elements/Table'
import Decimal from 'decimal.js'
import { DateTime } from 'luxon'
import { ReactElement } from 'react'
import { LoaderFunction, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import { getBillingInvoicesByLeaseId, getUpcomingBillingInvoice } from 'stores/billing'
import getParams from 'utils/getParams'
import { decimalFromMoney } from 'utils/money'
import { formatMoney } from 'utils/transforms'
import { renderInvoiceStatusBadge } from './_invoices'
import LeasePageShell from './_shell'

type LoaderData = {
  upcomingInvoice: BillingInvoice
  invoices: BillingInvoice[]
}

const LeaseInvoicesPage: React.FC = () => {
  const { invoices, upcomingInvoice } = useLoaderData() as LoaderData
  const navigate = useNavigate()

  const renderInvoices = (invoices: BillingInvoice[]): ReactElement => {
    const rows = [ upcomingInvoice, ...invoices ].map((invoice, idx) => {
      const due = DateTime.fromJSDate(invoice.due, { zone: 'UTC' })
      const configOverrides: any = {}

      if (idx === 0) {
        // Upcoming invoice
        configOverrides.className = 'opacity-30'
        configOverrides.onClick = () => navigate('../upcoming-invoice')
      }
      
      const status = (): string => {
        if (idx === 0) return 'upcoming'
        if (new Decimal(invoice.amount).equals(invoice.paid)) return 'paid'
        if (invoice.late) return 'overdue'
        return ''
      }

      const balance = decimalFromMoney(invoice.amount).sub(
        decimalFromMoney(invoice.paid)
      ).toFixed(2)

      return {
        due: due.toLocaleString(DateTime.DATE_SHORT),
        amount: formatMoney(invoice.amount),
        balance: formatMoney(balance),
        status: renderInvoiceStatusBadge(status()),
        config: {
          onClick: () => navigate(`../invoice/${invoice.id}`),
          ...configOverrides
        }
      }
    })

    const columns = [
      { key: 'due', label: 'Due', type: 'primary' },
      { key: 'amount', label: 'Amount' },
      { key: 'balance', label: 'Balance' },
      { key: 'status', label: '', rowClasses: 'text-right' }
    ]

    return <Table rows={rows} columns={columns} />
  }

  const actions = (): ReactElement[] => {
    return [
      <Button type='secondary' onClick={() => navigate(`../invoice/new/edit`)}>
        <span>Invoice</span><PlusCircleIcon className='w-4 ml-2' />
      </Button>
    ]
  }

  return (
    <LeasePageShell title='Invoices' actions={actions()}>
      {renderInvoices(invoices)}
    </LeasePageShell>
  )
}

const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { leaseId } = getParams(args)

  const [
    invoices,
    upcomingInvoice
  ] = await Promise.all([
    getBillingInvoicesByLeaseId({ leaseId }),
    getUpcomingBillingInvoice({ leaseId })
  ])

  return {
    invoices,
    upcomingInvoice
  }
}

const route: RouteObject = {
  path: 'invoices',
  element: <LeaseInvoicesPage />,
  loader
}

export default route
