import { CheckIcon, DocumentMinusIcon, DocumentTextIcon } from '@heroicons/react/24/solid'
import { ExtendedAsset } from '@shared/schemas/asset'
import { BillingInvoice } from '@shared/schemas/billing'
import { ExtendedPerson, PersonStub } from '@shared/schemas/person'
import HeaderAvatar from 'components/app/HeaderAvatar'
import Page from 'components/app/Page'
import Shell from 'components/app/Shell'
import { Card, CardBody, CardFooter } from 'components/elements/Card'
import Dropdown from 'components/elements/Dropdown'
import Empty from 'components/elements/Empty'
import Link from 'components/elements/Link'
import Pagination from 'components/elements/Pagination'
import Table from 'components/elements/Table'
import Decimal from 'decimal.js'
import { flatten } from 'lodash'
import { DateTime } from 'luxon'
import { Fragment, ReactElement } from 'react'
import { LoaderFunction, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import { getAssets } from 'stores/asset'
import { getBillingInvoicesByPropertyId } from 'stores/billing'
import { getLease } from 'stores/lease'
import { getLeasePeople } from 'stores/person'
import { assetTypeToReadable } from 'utils/assetTypes'
import getParams from 'utils/getParams'
import { getSearchParams } from 'utils/getQuery'
import PageUtils from 'utils/pages'
import { formatMoney } from 'utils/transforms'
import { renderInvoiceStatusBadge } from './lease/_invoices'
import { useProperty } from './_root'

type LoaderData = {
  invoices: BillingInvoice[]
  page: PageUtils
  assetsById: Record<string, ExtendedAsset|null>
  peopleByLeaseId: Record<string, ExtendedPerson[]>
  leaseIdToAssetId: Record<string, string>
  filters: {
    status: string
  }
}

const InvoicesPage: React.FC = () => {
  const property = useProperty()
  const {
    invoices,
    page,
    peopleByLeaseId,
    assetsById,
    leaseIdToAssetId,
    filters
  } = useLoaderData() as LoaderData
  const navigate = useNavigate()

  const crumbs = [
    { label: property.displayName, href: `/p/${property.id}` },
    { label: 'Invoices', href: `/p/${property.id}/invoices` }
  ]

  const renderTable = (): ReactElement => {
    const columns = [
      { key: 'due', label: 'Due' },
      { key: 'amount', label: 'Amount' },
      { key: 'tenants', label: 'Tenants' },
      { key: 'asset', label: 'Asset' },
      { key: 'status', label: '', headerClasses: 'text-right', rowClasses: 'text-right' }
    ]

    const status = (invoice: BillingInvoice): string => {
      const due = DateTime.fromJSDate(invoice.due, { zone: 'UTC' })
      const now = DateTime.now()

      if (new Decimal(invoice.amount).equals(invoice.paid)) return 'paid'
      if (now > due) return 'past_due'
      return ''
    }


    const renderTenants = (leaseId: string): ReactElement => { 
      const people = peopleByLeaseId.hasOwnProperty(leaseId)
        ? peopleByLeaseId[leaseId]
        : []
     
      const elements = people.map((person: PersonStub, i: number) => {
        const name = [
          person.firstName,
          person.lastName
        ].join(' ')

        return (
          <span key={person.id}>
            <Link to={`/person/${person.id}`} relative='property'>{name}</Link>
            <span color=''></span>
            { i < people.length - 1 && <span>, </span> }
          </span>
        )
      })

      return <>{elements}</>
    }

    const renderAsset = (leaseId: string): ReactElement => {
      const assetId = leaseIdToAssetId.hasOwnProperty(leaseId)
        ? leaseIdToAssetId[leaseId]
        : null

      if (assetId === null) return <span>Unknown</span>

      const asset = assetsById.hasOwnProperty(assetId)
        ? assetsById[assetId]
        : null

      if (asset === null) return <span>Unknown</span>

      return (
        <Link to={`/asset/${asset.id}`} relative='property'>
          {assetTypeToReadable(asset.type)} {asset.displayId}
        </Link>
      )
    }

    const rows = invoices.map((invoice: BillingInvoice) => {
      const due = DateTime
        .fromJSDate(invoice.due, { zone: 'UTC' })
        .toLocaleString(DateTime.DATE_SHORT)

      const onClick = () => navigate(
        `../lease/${invoice.leaseId}/invoice/${invoice.id}`
      )
      

      return {
        due,
        amount: formatMoney(invoice.amount),
        tenants: renderTenants(invoice.leaseId),
        asset: renderAsset(invoice.leaseId),
        status: renderInvoiceStatusBadge(status(invoice)),
        config: {
          onClick
        }
      }
    })

    return (
      <Table
        columns={columns}
        rows={rows}
        inline
        empty={
          <Empty
            className='py-12'
            icon={<DocumentMinusIcon className='w-14' />}
            message={
              filters.status === 'any'
              ? 'No invoices found.'
              : 'No matching invoices found.'
            }
          />
        }
      />      
    )
  }

  const renderPages = (): ReactElement => {
    if (page.last() <= 1) {
      return <Fragment />
    }

    return (
      <CardFooter>
        <Pagination
          page={page}
          getPageHref={(page: number) => `/p/${property.id}/invoices?p=${page}`}
        />
      </CardFooter>
    )
  }

  const renderFilterMenu = (): ReactElement => {
    const { status = 'any' } = filters

    const statusToLabel: Record<string, string> = {
      'any': 'All',
      'paid': 'Paid',
      'unpaid': 'Unpaid',
      'overdue': 'Overdue'
    }

    const items = Object.entries(statusToLabel).map(([ key, value ]) => {
      return {
        label: value,
        icon: status === key ? <CheckIcon /> : <Fragment />,
        action: () => navigate(`./?status=${key}`)
      }
    })
    
    const label = statusToLabel[status]

    return <Dropdown menu={[ items ]} label={label} />
  }

  const subtitle = page.count() !== 0
    ? `Viewing ${page.range()} of ${page.count()} invoices.`
    : 'Nothing here yet.'

  return (
    <Shell active='invoices' breadcrumbs={crumbs}>
      <HeaderAvatar
        title='Invoices'
        subtitle={subtitle}
        avatarIcon={<DocumentTextIcon className='w-6' />}
        actions={[ renderFilterMenu() ]}
      />
      <Page>
        <Card>
          <CardBody type='table'>
            {renderTable()}
          </CardBody>
          {renderPages()}
        </Card>
      </Page>
    </Shell>
  )
}

const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { propertyId } = getParams(args)
  const { p: pageStr = '1', status = 'any' } = getSearchParams(args)

  const page = new PageUtils({ currentPage: Number(pageStr) })

  const [
    { invoices, page: pageData }
  ] = await Promise.all([
    getBillingInvoicesByPropertyId({
      propertyId,
      offset: page.offset(),
      limit: page.limit(),
      status: status as any
    })
  ])

  // these are all the asset IDs we need to fetch
  const assetsById: Record<string, null|ExtendedAsset> = {}
  const leaseIdToAssetId: Record<string, string> = {}
  const peopleByLeaseId: Record<string, ExtendedPerson[]> = {}

  const getAssetId = async (leaseId: string): Promise<void> => {
    if (leaseIdToAssetId.hasOwnProperty(leaseId)) return
    const lease = await getLease({ leaseId })
    
    if (lease !== null) {
      assetsById[lease.assetId] = null
      leaseIdToAssetId[leaseId] = lease.assetId
    }
  }

  const getPeople = async (leaseId: string): Promise<void> => {
    if (peopleByLeaseId.hasOwnProperty(leaseId)) return
    peopleByLeaseId[leaseId] = await getLeasePeople({ leaseId })
  }

  await Promise.all(
    flatten(
      invoices.map(invoice => {
        return [ getAssetId(invoice.leaseId), getPeople(invoice.leaseId) ]
      })
    )
  )

  const assets = await getAssets({ assetIds: Object.keys(assetsById) })

  for (const asset of assets) {
    assetsById[asset.id] = asset
  }

  page.count(pageData.count)

  return {
    invoices,
    assetsById,
    peopleByLeaseId,
    leaseIdToAssetId,
    page,
    filters: {
      status
    }
  }
}

const route: RouteObject = {
  path: '/p/:propertyId/invoices',
  loader,
  element: <InvoicesPage />,
}

export default route
