import { ReactElement } from 'react'
import { FaPizzaSlice } from 'react-icons/fa'
import { buttonStyles } from '../Button'
import CenteredWideButtonsModal from './CenteredWideButtonsModal'
import TextInput from '../forms/inputs/Text'

interface Props {
  visible: boolean
  action: any
  close: any
  isLoading?: boolean
  label?: string
  title?: string
  message?: ReactElement|string
  inputValue: string
  inputOnChange: any
  placeholder?: string
  icon?: ReactElement
  iconBg?: string
}

export default function TextInputModal(props: Props): JSX.Element {
  const {
    isLoading = false,
    visible,
    action,
    close,
    label = 'Submit',
    title = 'Text Input',
    message = 'Please type your data:',
    inputValue,
    inputOnChange,
    placeholder = '',
    icon = <FaPizzaSlice className='text-white' />,
    iconBg = 'bg-indigo-600'
  } = props

  const cancel = {
    label: 'Cancel',
    action: close,
    className: buttonStyles({ color: 'white', disabled: isLoading }),
    disabled: isLoading
  }

  const confirm = {
    label: isLoading ? 'Please wait...' : label,
    action,
    className: buttonStyles({ color: 'indigo', disabled: isLoading }),
    disabled: isLoading
  }

  const buttons = { cancel, confirm }

  const renderMessage = (): JSX.Element => {
    return (
      <div className='-mb-3'>
        <p className='text-sm leading-5 text-gray-500'>{message}</p>
        <div className='w-full mt-2'>
          <TextInput
            className='w-full mt-1'
            placeholder={placeholder}
            value={inputValue}
            onChange={(evt) => inputOnChange(evt.target.value)}
          />
        </div>
      </div>
    )
  }

  return (
    <CenteredWideButtonsModal
      visible={visible}
      icon={icon}
      iconBg={iconBg}
      title={title}
      message={renderMessage()}
      buttons={buttons}
    />
  )
}
