import Button from 'components/elements/Button'
import TextInput from 'components/elements/forms/inputs/Text'
import { postApi } from 'modules/api'
import { setAccessToken } from 'modules/auth'
import notifications from 'modules/notifications'
import progress from 'modules/progress'
import { ReactElement, useState } from 'react'

/**
 * Auth page is mounted by App.tsx, it is NOT a RoutableFC, just a regular
 * component.
 */
export default function AuthPage(): ReactElement {
  const [ username, setUsername ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)

  const doAuth = async () => {
    setIsLoading(true)
    progress.start('login')

    try {
      const data = await postApi('/GetAccessTokenWithPassword', {
        username,
        password
      })

      if (data._.statusCode !== 200) {
        throw new Error(data.message)
      }

      setAccessToken(data.token)
      window.location.href = '/'
      return
    } catch (e: any) {
      progress.done('login')

      notifications.error({
        message: 'Check your username and password, and try again.'
      })

      setIsLoading(false)
      return
    }
  }

  return (
    <div className='flex' style={{height: '100vh'}}>
        <div style={{width: '500px'}} className='bg-white shadow-md flex items-center'>
          <div className='w-full px-24'>
            <img className='h-14 w-14 -ml-1 mb-2' src='/assets/logo.svg' alt='Deletype logo' />
            <span className='text-black font-medium text-sm uppercase block'>
              Deletype
            </span>
            <span className='text-gray-500 font-normal text-xs block mb-4'>
              Property Management
            </span>

            <TextInput
              className='pb-2'
              placeholder='email address'
              onChange={evt => setUsername(evt.target.value)}
              value={username}
              disabled={isLoading}
            />

            <TextInput
              className='pb-2'
              placeholder='password'
              type='password'
              onChange={evt => setPassword(evt.target.value)}
              value={password}
              disabled={isLoading}
            />

            <div className='flex justify-end'>
              <Button
                type='primary'
                onClick={doAuth}
                disabled={isLoading}
              >{
                  isLoading
                  ? 'Please wait...'
                  : 'Sign In'
              }</Button>
            </div>
          </div>
        </div>
    </div>
  )
}
