import progress from 'modules/progress'
import { LoaderFunction, LoaderFunctionArgs, RouteObject } from 'react-router-dom'

export function withProgress(
  routes: RouteObject[],
  namespace: string = ''
): RouteObject[] {
  return routes.map((route: RouteObject) => {
    const { loader, path = '/' } = route
    
    const wrappedLoader: LoaderFunction = async (args: LoaderFunctionArgs) => {
      if (loader) {
        progress.start('loader ' + namespace + path)
        const data = await loader(args)
        progress.done('loader ' + namespace + path)
        return data
      }
    }

    const newRoute: RouteObject = loader
      ? { ...route, loader: wrappedLoader }
      : { ...route }

    return newRoute
  })
}

export function withProgressDeep(routes: RouteObject[], namespace = ''): RouteObject[] {
  for (const route of routes) {
    if (route.children) {
      route.children = withProgressDeep(route.children, namespace + route.path)
    }
  }

  return withProgress(routes, namespace)
}
