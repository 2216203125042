import { PencilIcon } from '@heroicons/react/24/solid'
import { BillingInvoice } from '@shared/schemas/billing'
import Alert from 'components/elements/Alert'
import Button from 'components/elements/Button'
import CenteredWideButtonsModal from 'components/elements/modals/CenteredWideButtonsModal'
import Spacer from 'components/elements/Spacer'
import progress from 'modules/progress'
import { ReactElement, useState } from 'react'
import { LoaderFunction, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import { createUpcomingBillingInvoice, getUpcomingBillingInvoice } from 'stores/billing'
import getParams from 'utils/getParams'
import { renderPayload, renderStats } from './_invoices'
import { useLease } from './_root'
import LeasePageShell from './_shell'

type LoaderData = {
  invoice: BillingInvoice
}

const LeaseUpcomingInvoicePage: React.FC = () => {
  const [ visibleModal, setVisibleModal ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const { invoice } = useLoaderData() as LoaderData
  const { lease } = useLease()
  const navigate = useNavigate()

  const actions = (): ReactElement[] => {
    const editOnClick = () => {
      setVisibleModal('editUpcoming')
    }

    return [
      <Button type='secondary' onClick={editOnClick}>
        <PencilIcon className='w-4 mr-2' /><span>Edit</span>
      </Button>
    ]
  }

  const renderConfimModal = (): ReactElement => {
    const confirm = async () => {
      progress.start('create invoice')

      setLoading(true)
      
      const newInvoiceId = await createUpcomingBillingInvoice({
        leaseId: lease.id
      })
      
      navigate(
        `../invoice/${newInvoiceId}/edit`,
        { replace: true }
      )

      progress.done('create invoice')
    }

    const cancel = () => {
      setVisibleModal('')
    }

    const buttons = {
      confirm: {
        label: loading ? 'Please wait...' : 'Edit invoice',
        action: confirm,
        disabled: loading
      },
      cancel: {
        label: 'Cancel',
        action: cancel,
        disabled: loading
      }
    }

    return (
      <CenteredWideButtonsModal
        title='Edit upcoming invoice?'
        message='By doing so, it will be issued immediately and become visible to the tenant. It will still be due on the date you set.'
        buttons={buttons}
        visible={visibleModal === 'editUpcoming'}
        icon={<PencilIcon className='w-6 h-6 text-indigo-700' />}
        iconBg='bg-indigo-100'
        
      />
    )
  }

  return (
    <LeasePageShell title='Upcoming invoice' active='invoices' actions={actions()}>
      {renderConfimModal()}
      <Spacer>
        <Alert
          border={true}
          type='info'
          message='You are previewing an invoice that has not been issued yet. This invoice is not visible to the tenant.'
        />
        {renderStats(invoice, { hideStatus: true })}
        {renderPayload(invoice)}
      </Spacer>
    </LeasePageShell>
  )
}

const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { leaseId } = getParams(args)
  const invoice = await getUpcomingBillingInvoice({ leaseId })

  return { invoice }
}

const route: RouteObject = {
  path: 'upcoming-invoice',
  loader,
  element: <LeaseUpcomingInvoicePage />
}

export default route
