import NodeCache from 'node-cache'

const DEFAULT_TTL = 60

const cache = new NodeCache({
  useClones: true
})

interface CacheKeyParams {
  key: string
}

/**
 * Put an item into the cache
 * @param key 
 * @param value 
 * @param ttl Optional, in seconds
 */
interface CacheSetParams extends CacheKeyParams {
  value: any
  ttl?: number
}

function set(options: CacheSetParams): void {
  const {
    key,
    value,
    ttl = DEFAULT_TTL
  } = options

  cache.set(key, value, ttl)
}

function get(options: CacheKeyParams): any {
  const { key } = options
  return cache.get(key)
}

const _ = { set, get}

export default _
