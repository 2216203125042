import classNames from 'classnames'
import { useField } from 'formik'
import { omit } from 'lodash'
import { InputHTMLAttributes } from 'react'
import { FaExclamationCircle } from 'react-icons/fa'
import { defaultInputStyles } from './Defaults'
import Label from './Label'

interface BaseProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  help?: string
  error?: string
  clearOnFocus?: string
  displayType?: 'inline'|'standard'
  inputClassName?: string
}

interface BasePropsRequiresName extends BaseProps {
  name: string
}

interface AllProps extends BaseProps {
  formik?: any
}

const removeCustomProps = (props: any): any => {
  return omit(props, [
    'label',
    'help',
    'mask',
    'className',
    'children',
    'formik',
    'clearOnFocus',
    'displayType',
    'inputClassName'
  ])
}

interface TextInputRendererProps {
  className: string
  inputClassName?: string
  inputProps: any
  error: string
  help: string
  label: string
}

function TextInputInlineLabel(props: TextInputRendererProps): JSX.Element {
  const {
    label = '',
    error = '',
    help = '',
    inputClassName = '',
    inputProps
  } = props

  const inputClasses = classNames({
    'block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6': true,
    [inputClassName]: inputClassName !== '',
    'sm:text-sm': !inputClassName.includes('text-')
  })

  return (
    <div className=''>
      <div className='bg-white rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600'>
        <label className='block text-xs font-medium text-gray-600'>
          {label}
        </label>
        <input
          className={inputClasses}
          {...inputProps}
        />
        {
          help !== ''
          ? <p className='text-xs text-gray-500'>{help}</p>
          : null
        }
        {
          error !== ''
          ? <p className='text-xs text-red-600'>
              {error}
            </p>
          : null
        }
      </div>
    </div>
  )
}

function TextInputStandard(props: TextInputRendererProps): JSX.Element {
  const {
    className,
    error,
    help,
    label,
    inputProps,
    inputClassName = ''
  } = props

  const renderError: boolean = error !== ''
  const renderLabel: boolean = label !== ''
  const renderHelp: boolean = help !== '' && !renderError

  const inputClasses = defaultInputStyles({ error: renderError }) + ` ${inputClassName}`

  const inputContainerClasses = classNames(
    'relative',
    { 'mt-1': renderLabel }
  )

  const errorIcon: JSX.Element = (
    <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
      <FaExclamationCircle className='text-red-500' />
    </div>
  )

  return (
    <div className={className}>
      { renderLabel ? <Label label={label} /> : null }
      <div className={inputContainerClasses}>
        <input className={inputClasses} {...inputProps} />
        {renderError ? errorIcon : null}
      </div>
      {
        renderHelp
        ? <p className='mt-2 text-sm text-gray-500'>{help}</p>
        : null
      }
      {
        renderError
        ? <p className='mt-2 text-sm text-red-600'>
            {error !== '' && error}
          </p>
        : null
      }
    </div>
  )
}

function StandardTextInput(props: AllProps): JSX.Element {
  const {
    name = null,
    label = '',
    className = '',
    help = '',
    formik = [],
    error = '',
    clearOnFocus = '',
    displayType = 'standard',
    inputClassName = ''
  } = props

  const [
    field = {},
    meta = {},
    helpers = {}
  ] = formik

  const clearOnFocusProps = clearOnFocus !== ''
  ? { onFocus: (e: any) => field.value === clearOnFocus ? helpers.setValue('') : null }
  : {}

  const inputProps = {
    ...removeCustomProps(props),
    ...clearOnFocusProps,
    type: props.type || 'text'
  }

  const inputPropsField: any = name !== null 
    ? { ...inputProps, ...field }
    : { ...inputProps }

  const _error: string = meta.touched && !!meta.error
    ? meta.error
    : error

  if (displayType === 'inline') {
    return (
      <TextInputInlineLabel
        className={className}
        inputProps={inputPropsField}
        error={_error}
        help={help}
        label={label}
        inputClassName={inputClassName}
      />
    )
  }

  return <TextInputStandard 
    className={className}
    inputProps={inputPropsField}
    error={_error}
    help={help}
    label={label}
    inputClassName={inputClassName}
  />

}

function FormikTextInput(props: BasePropsRequiresName): JSX.Element {
  const formik = useField(props)
  return <StandardTextInput {...props} formik={formik} />
}

export default function TextInput(props: BaseProps): JSX.Element {
  if (typeof props.name !== 'undefined' && props.name !== '') {
    return <FormikTextInput {...props as BasePropsRequiresName} />
  }

  return <StandardTextInput {...props} />
}

