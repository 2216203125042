import { withNamespace } from '../../../utils/forms'
import Transition from '../transitions/FormSection'
import Section from './inputs/Section'
import TextInput from './inputs/Text'

interface Props {
  visible?: boolean
  namespace?: string
  index?: number
  title?: string
  subtitle?: string
  fullWidth?: boolean
}

export default function AddressForm(props: Props): JSX.Element {
  const {
    visible = true,
    namespace: ns = null,
    index = 0,
    title = '',
    subtitle = '',
    fullWidth = false
  } = props

  const namespace = withNamespace(ns)

  return (
    <Transition in={visible}>
      <Section
        title={title}
        subtitle={subtitle}
        index={index}
        fullWidth={fullWidth}
      >
        <TextInput
          label='Street address'
          className='col-span-6 sm:col-span-4'
          name={namespace('street1')}
        />


        <TextInput
          label='Apt, unit, suite, etc'
          className='col-span-6 sm:col-span-2'
          name={namespace('street2')}
        />

        <TextInput
          label='City'
          className='col-span-6 sm:col-span-2'
          name={namespace('city')}
        />

        <TextInput
          label='State'
          className='col-span-6 sm:col-span-2'
          name={namespace('state')}
        />

        <TextInput
          label='ZIP'
          className='col-span-6 sm:col-span-2'
          name={namespace('zip')}
        />
      </Section>
    </Transition>
  )
}
