import CreatePayment from './create_payment'
import Edit from './edit'
import EditInvoice from './edit_invoice'
import Index from './index'
import Invoice from './invoice'
import Invoices from './invoices'
import Payment from './payment'
import Payments from './payments'
import UpcomingInvoice from './upcoming_invoice'
import UpdateStatus from './update_status'
import Root from './_root'

Root.children = [
  Index,
  Edit,
  CreatePayment,
  Payments,
  Payment,
  Invoices,
  Invoice,
  UpcomingInvoice,
  EditInvoice,
  UpdateStatus
]

export default Root
