import classNames from 'classnames'
import { MouseEvent, ReactNode } from 'react'
import { Link, useParams } from 'react-router-dom'

interface Props {
  children: ReactNode
  to: string
  relative?: 'property'|'global'
  className?: string
}

const BASE_CLASSES: any = {
  normal: 'text-indigo-700 hover:text-indigo-900 inline-block'
}

export default function L(props: Props): JSX.Element {
  const { propertyId = '' }: any = useParams() 

  const {
    to,
    children,
    relative = 'global',
    className = ''
  } = props

  const classes = classNames(
    { [BASE_CLASSES.normal]: !className.includes('text-') },
    className
  )

  if (to.startsWith('http://') || to.startsWith('https://')) {
    return (
      <a
        className={classes}
        href={to}
        target='_blank'
        rel='noreferrer'
      >{children}</a>
    )
  }

  const href: string = relative === 'property'
    ? `/p/${propertyId}${to}`
    : to

  const stopProp = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation()
  }

  return (
    <Link to={href} className={classes} onClick={stopProp}>{children}</Link>
  )
}
