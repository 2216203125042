import { InputHTMLAttributes } from 'react'
import { generateId, removeCustomProps } from 'components/elements/Form'
import classNames from 'classnames'

interface BaseProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  help?: string
  error?: string
}

export function Checkbox(props: BaseProps): JSX.Element {
  const { name, label, help, disabled = false } = props
  const id = generateId(name)
  const inputProps = {
    id,
    style: { marginTop: '2px' },
    className: classNames(
      'focus:ring-indigo-500 h-4 w-4 border-gray-300 rounded',
      { 'text-gray-400': disabled },
      { 'text-indigo-600': !disabled }
    ),
    ...removeCustomProps(props)
  }
  
  return (
    <div className='mt-4'>
      <div className='flex items-start select-none'>
        <div className='flex items-center h5'>
          <input type='checkbox' {...inputProps } />
        </div>
        <div className='ml-3 text-sm leading-5'>
          <label htmlFor={id} className='font-medium text-gray-700'>{label}</label>
          { help !== null ? <p className='text-gray-500'>{help}</p> : null }
        </div>
      </div>
    </div>
  )
}
