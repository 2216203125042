import { withProgressDeep } from 'utils/withProgress'
import Dashboard from './global/dashboard'
import CreateProperty from './global/properties/create'
import Property from './property/_routes'
import Root from './_root'

Root.children = withProgressDeep([
  Dashboard,
  CreateProperty,
  Property
])

export default Root
