import classNames from 'classnames'
import Avatar from 'components/elements/Avatar'
import FadeUpFast from 'components/elements/transitions/FadeUpFast'
import { PropertyContext } from 'contexts/property'
import { UserContext } from 'contexts/user'
import { clearAuth } from 'modules/auth'
import progress from 'modules/progress'
import { ReactElement, useContext, useRef, useState } from 'react'
import { FaLock, FaUser } from 'react-icons/fa'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import useOutsideClick from 'utils/useOutsideClick'
import Breadcrumbs, { Breadcrumb } from './Breadcrumbs'
import Search from './Search'

export interface NavProps {
  active?: string
  breadcrumbs?: Breadcrumb[]
  hideSearch?: boolean
}

interface ILink {
  label: string
  href: string
  active: string
  icon?: ReactElement
}

interface ProfileLink extends Omit<ILink, 'href'> {
  action: (() => void)|(() => Promise<void>)
}

function Nav(props: NavProps): JSX.Element {
  const { hideSearch = false } = props
  const user = useContext(UserContext)
  const property = useContext(PropertyContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { id: propertyId } = property

  const GLOBAL_LINKS: ILink[] = [
    { label: 'Dashboard', href: '/', active: 'DASHBOARD' }
  ]
  
  const PROPERTY_LINKS: ILink[] = [
    { label: 'Dashboard', href: ':property/', active: 'DASHBOARD' },
    { label: 'People', href: ':property/people', active: 'PEOPLE' }
  ]
  
  const PROFILE_LINKS: ProfileLink[] = [
    {
      label: 'Account',
      active: 'ACCOUNT',
      icon: <FaUser />,
      action: () => { navigate('/account') }
    },
    {
      label: 'Sign Out',
      active: '',
      icon: <FaLock />,
      action: async () => {
        progress.start('sign out')
        await clearAuth()
        window.location.reload()
      }
    }
  ]
 
  const [ profileMenuVisible, setProfileMenuVisible ] = useState(false)
  const profileMenuRef = useRef(null)

  const {
    breadcrumbs = []
  } = props

  useOutsideClick(profileMenuRef, () => {
    setProfileMenuVisible(false)
  })

  const activeLink = props.active
    ? props.active.toUpperCase()
    : ''

  const renderLink = (link: ILink): JSX.Element => {
    const base = 'px-3 py-2 text-sm font-medium'
    const inactive = 'text-gray-500'
    const active = 'text-black'

    const styles: string = classNames(
      base,
      { [active]: link.active === activeLink },
      { [inactive]: link.active !== activeLink }
    )

    const href = link.href.replace(':property', `/p/${propertyId}`)

    return (
      <Link key={link.label} to={href} className={styles}>{link.label}</Link>
    )
  }

  const renderLinks = (): JSX.Element => {
    let links = location.pathname.startsWith('/p/')
      ? PROPERTY_LINKS
      : GLOBAL_LINKS

    if (property.assetTypes.includes('mhp_lot')) {
      links = [
        ...links,
        { label: 'Lots', href: ':property/assets/mhp_lot', active: 'MHP_LOT' }
      ]
    }

    if (property.assetTypes.includes('mhp_home')) {
      links = [
        ...links,
        { label: 'Mobile Homes', href: ':property/assets/mhp_home', active: 'MHP_HOME' }
      ]
    }

    if (property.assetTypes.includes('apartment')) {
      links = [
        ...links,
        { label: 'Apartments', href: ':property/assets/apartment', active: 'APARTMENT' }
      ]
    }

    return (
      <div className='hidden md:block'>
        <div className='hidden lg:flex lg:items-center lg:space-x-4'>
          {links.map(renderLink)}
        </div>
      </div>
    )
  }

  const renderLogo = (): JSX.Element => {
    return (
      <div className='flex-shrink-0 flex items-center mr-6'> 
        <img className='h-8 w-8' src='/assets/logo.svg' alt='Deletype logo' />
      </div>
    )
  }

  const renderProfileMenuItem = (item: ProfileLink, index: number): JSX.Element => {
    const { label, icon, action } = item
    const classes = classNames(
      'w-full group flex items-center px-4 py-2 text-sm leading-5',
      'text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none',
      'focus:bg-gray-100 focus:text-gray-900'
    )

    return (
      <button
        key={index}
        onClick={action}
        type='button'
        className={classes}
      >
        <div className='mr-2 h-5 w-5 flex items-center text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500'>
          {icon}
        </div>

        {label}
      </button>
    )
  }

  const renderProfileMenu = (): JSX.Element => {
    const classes = classNames(
      'origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1',
      'bg-white ring-1 ring-black ring-opacity-5 z-10'
    )

    return (
      <FadeUpFast in={profileMenuVisible}>
        <div className={classes}>
          {PROFILE_LINKS.map(renderProfileMenuItem)}
        </div>
      </FadeUpFast>
    )
  }

  const renderProfile = (): JSX.Element => {
    const initials = [
      user.firstName.slice(0, 1),
      user.lastName.slice(0, 1)
    ].join('')

    return (
      <div className='hidden md:flex'>
        <div className='ml-4 flex flex-1 items-center md:ml-6'>
          <div className='relative z-10' ref={profileMenuRef}>
            <div>
              <button className='max-w-xs flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white' onClick={() => setProfileMenuVisible(!profileMenuVisible)}>
                <Avatar
                  size={8}
                  src={user.avatarUrl}
                  initials={initials}
                />
              </button>
            </div>
            {renderProfileMenu()}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <nav className='bg-white shadow'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='flex justify-between h-16'>
            <div className='flex items-center'>
              {renderLogo()}
              {renderLinks()}
            </div>

            {
              hideSearch === true
              ? <></>
              : <Search />
            }
            {renderProfile()}
          </div>

          <div className='border-t border-gray-200 py-3'>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        </div>
      </nav>
    </>
  )
}

export default Nav
