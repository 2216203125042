import schemas from '@shared/schemas'
import { AssetTypes, ExtendedAsset } from '@shared/schemas/asset'
import { Attributes } from '@shared/schemas/setting'
import { getApi } from 'modules/api'
import { getTypedApi } from 'modules/typedApi'
import { z } from 'zod'

export async function getAssets(
  { assetIds } :
  { assetIds: string[] }
): Promise<ExtendedAsset[]> {
  if (assetIds.length === 0) {
    // Do not send a request if no assets were requested
    return []
  }

  return await getTypedApi({
    url: '/GetAsset',
    schema: schemas.asset.extendedAssets,
    path: 'assets',
    query: { assetId: assetIds.join(',') }
  })
}

export async function getAsset(
  { assetId } :
  { assetId: string }
): Promise<ExtendedAsset> {
  const [ asset ] = await getAssets({ assetIds: [ assetId ] })
  return asset
}

export async function getAssetAttributeSettings(
  { assetType, propertyId } :
  { assetType: AssetTypes, propertyId: string }
): Promise<Attributes> {
  return await getTypedApi({
    url: '/GetAssetAttributeDefaults',
    schema: schemas.setting.attributes,
    query: {
      assetType: assetType,
      propertyId: propertyId
    },
    path: 'setting'
  })
}

export interface ListAssetOptions {
  propertyId: string
  type: AssetTypes
  offset?: number
  limit?: number
  vacant?: boolean
}

export async function listAssets(options: ListAssetOptions) {
  const {
    propertyId,
    type,
    offset = 0,
    limit = 50,
    vacant
  } = options

  return await getTypedApi({
    url: '/GetAssets',
    schema: z.object({
      assets: schemas.asset.extendedAssets,
      page: schemas.page.page
    }),
    query: {
      propertyId,
      type,
      offset,
      limit,
      vacant
    }
  })
}

export async function getLeaseAssets(
  options: { leaseId: string }
): Promise<ExtendedAsset[]> {
  const { leaseId } = options
  
  return await getTypedApi({
    url: '/GetLeaseAssets',
    query: { leaseId },
    schema: schemas.asset.extendedAssets,
    path: 'assets'
  })
}


type HierarchyObjectNode<T> = Record<string, T>
interface HierarchyObject extends HierarchyObjectNode<HierarchyObject> {}

interface GetAssetHierarchyResponse {
  root: string
  members: string[]
  paths: string[]
  hierarchy: HierarchyObject
}

export async function getAssetHierarchy(
  { assetId } :
  { assetId: string }
): Promise<GetAssetHierarchyResponse> {
  const {
    root,
    members,
    paths,
    hierarchy
  } = await getApi('/GetAssetHierarchy', { assetId })

  return {
    root,
    members,
    paths,
    hierarchy
  }
}
