import HeaderAvatar from 'components/app/HeaderAvatar'
import Dropdown from 'components/elements/Dropdown'
import DeleteModal from 'components/elements/modals/DeleteModal'
import { AssetContext } from 'contexts/asset'
import { PropertyContext } from 'contexts/property'
import { postApi } from 'modules/api'
import notifications from 'modules/notifications'
import progress from 'modules/progress'
import { useContext, useState } from 'react'
import { FaEdit, FaPlus, FaTrashAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { assetTypeIcon, assetTypeToReadable } from 'utils/assetTypes'

export default function AssetHeader(): JSX.Element {
  const asset = useContext(AssetContext)
  const property = useContext(PropertyContext)
  const [ isDeleting, setIsDeleting ] = useState(false)
  const [ visibleModal, setVisibleModal ] = useState('')
  const navigate = useNavigate()

  const { type, displayId } = asset
  const title = `${assetTypeToReadable(type)} ${displayId}`

  const removeAsset = async () => {
    setIsDeleting(true)
    progress.start('remove asset')

    const result = await postApi('/DeleteAsset', {
      assetId: asset.id
    })

    progress.done('remove asset')
    
    if (result._.statusCode === 200) {
      navigate(`/p/${property.id}/assets/${type}`, { replace: true })
      notifications.success({ message: 'Asset was removed successfully.' })
    } else {
      setIsDeleting(false)
      setVisibleModal('')
      notifications.warn({ message: result.message })
    }
  }

  const menu = [
    [
      {
        label: 'Edit',
        icon: <FaEdit />,
        action: () => navigate('edit')
      },
      {
        label: 'Add lease',
        icon: <FaPlus />,
        action: () => navigate([
          `/p/${property.id}`,
          `/leases/create?assetId=${asset.id}`
        ].join(''))
      }
    ],
    [
      { 
        label: 'Delete',
        icon: <FaTrashAlt color='red' />,
        action: () => setVisibleModal('delete')
      }
    ]
  ]

  const actions = [
    <Dropdown label='Options' menu={menu} />
  ]

  return (
    <>
      <HeaderAvatar
        title={title}
        avatarIcon={assetTypeIcon(type)}
        actions={actions}
      />
      <DeleteModal
        isDeleting={isDeleting}
        visible={visibleModal === 'delete'}
        action={removeAsset}
        close={() => setVisibleModal('')}
      />
    </>
  )
}
