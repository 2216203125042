import { FormikProps } from 'formik'
import { get } from 'lodash'

export function withNamespace(namespace?: string|null) {
  return (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName
  }
}

type NamespaceFunc = ReturnType<typeof withNamespace>

export function getFieldProps(
  formikProps: FormikProps<any>,
  namespace?: NamespaceFunc
) {
  return (fieldName: string): any => {
    let error = null
    let fullFieldName: string = namespace
      ? namespace(fieldName)
      : fieldName

    if (
      get(formikProps.touched, fullFieldName) &&
      get(formikProps.errors, fullFieldName)
    ) {
      error = get(formikProps.errors, fullFieldName)
    }
  
    return {
      name: fullFieldName,
      value: get(formikProps.values, fullFieldName),
      onChange: formikProps.handleChange,
      onBlur: formikProps.handleBlur,
      error
    }
  }
}

export function getWithinNamespace(
  target: any,
  namespace: NamespaceFunc
) {
  return (key: string): any => {
    const fullKey = namespace(key)
    return get(target, fullKey)
  }
}

export function getNamespacedErrors(
  target: FormikProps<any>,
  namespace: NamespaceFunc
) {
  return (key: string): string|null => {
    const fullKey = namespace(key)

    if (get(target.touched, fullKey) && get(target.errors, fullKey)) {
      return get(target.errors, fullKey) as string
    }

    return null
  }
}
