import { AssetTypes } from '../schemas/asset'

type ReadableAssetTypes = Record<AssetTypes, string[]>

const ASSET_TYPES: ReadableAssetTypes = {
  'mhp_home': [ 'Mobile Home', 'Mobile Homes' ],
  'mhp_lot': [ 'Lot', 'Lots' ],
  'parking': [ 'Parking Space', 'Parking Spaces' ],
  'storage_unit': [ 'Storage Unit', 'Storage Units' ],
  'apartment': [ 'Apartment', 'Apartments' ]
}
  
export function assetTypeToReadable(
  type: AssetTypes,
  plural: boolean = false
): string {
  const _type = type.toLowerCase() as AssetTypes

  if (!ASSET_TYPES.hasOwnProperty(_type)) {
    throw Error('assetTypeToReadable: unknown asset type')
  }

  const [ _single, _plural ] = ASSET_TYPES[_type]

  return plural
    ? _plural
    : _single
}
