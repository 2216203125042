import schemas from '@shared/schemas'
import { BillingInvoice, BillingInvoicePaymentsAdjustmentsResult, BillingPayment } from '@shared/schemas/billing'
import { Page } from '@shared/schemas/page'
import { postApi } from 'modules/api'
import { getTypedApi } from 'modules/typedApi'
import { z } from 'zod'

export async function createUpcomingBillingInvoice(
  { leaseId }: { leaseId: string }
): Promise<string> {
  const { invoiceId } = await postApi(
    '/CreateUpcomingBillingInvoice',
    { leaseId }
  )
  return invoiceId
}

export async function getBillingInvoicesByLeaseId(
  options: { leaseId: string }
) {
  const { leaseId } = options
  return await getTypedApi({
    url: '/GetBillingInvoicesByLeaseId',
    schema: z.array(schemas.billing.invoice),
    path: 'invoices',
    query: { leaseId }
  })
}

export async function getBillingInvoicesWithABalanceByLeaseId(
  options: { leaseId: string }
) {
  const { leaseId } = options
  return await getTypedApi({
    url: '/GetBillingInvoicesWithABalanceByLeaseId',
    schema: z.array(schemas.billing.invoice),
    path: 'invoices',
    query: { leaseId }
  })
}

export async function getBillingInvoice(
  options: { invoiceId: string }
): Promise<BillingInvoice> {
  const { invoiceId } = options
  return await getTypedApi({
    url: '/GetBillingInvoice',
    schema: schemas.billing.invoice,
    path: 'invoice',
    query: { id: invoiceId }
  })
}

export async function getUpcomingBillingInvoice(
  { leaseId }: { leaseId: string }
) {
  return await getTypedApi({
    url: '/GetUpcomingBillingInvoice',
    schema: schemas.billing.invoice,
    path: 'invoice',
    query: { leaseId }
  })
}

export async function getBillingInvoicePayments(
  options: { invoiceId: string }
) {
  const { invoiceId } = options
  return await getTypedApi({
    url: '/GetBillingInvoicePayments',
    schema: z.array(schemas.billing.paymentDistribution),
    path: 'payments',
    query: { invoiceId }
  })
}

const GetBillingPaymentResponse = z.object({
  payment: schemas.billing.payment,
  distributions: z.array(schemas.billing.paymentDistribution)
})

export async function getBillingPayment(
  options: { paymentId: string }
): Promise<z.infer<typeof GetBillingPaymentResponse>> {
  const { paymentId } = options
  return await getTypedApi({
    url: '/GetBillingPayment',
    schema: GetBillingPaymentResponse,
    query: { id: paymentId }
  })
}

export async function updateBillingPaymentDistribution(
  options: {
    paymentId: string
    invoiceId: string
    amount: string
  }
): Promise<void> {
  const { paymentId, invoiceId, amount } = options

  await postApi('/UpdateBillingPaymentDistribution', {
    paymentId,
    invoiceId,
    amount
  })
}

export async function deleteBillingPaymentDistribution(
  options: { paymentId: string, invoiceId: string }
): Promise<void> {
  const { paymentId, invoiceId } = options
  
  await postApi('/DeleteBillingPaymentDistribution', {
    paymentId,
    invoiceId
  })
}

export async function getBillingPaymentsByLeaseId(
  options: { leaseId: string }
): Promise<BillingPayment[]> {
  const { leaseId } = options
  return await getTypedApi({
    url: '/GetBillingPaymentsByLeaseId',
    schema: z.array(schemas.billing.payment),
    path: 'payments',
    query: { leaseId }
  })
}

export async function getBillingInvoicePaymentsAutoAdjustmentPreview(
  options: { invoiceId: string, leaseId: string, amount: string }
): Promise<BillingInvoicePaymentsAdjustmentsResult|null> {
  const { invoiceId, leaseId,  amount } = options

  return await getTypedApi({
    url: '/GetBillingInvoicePaymentsAutoAdjustmentPreview',
    schema: z.union([
      z.null(),
      schemas.billing.invoicePaymentsAdjustmentsResult
    ]),
    path: 'preview',
    query: { invoiceId, leaseId, amount }
  })
}

export async function getBillingInvoicesByPropertyId(
  { propertyId, limit, offset, status }:
  { propertyId: string, limit?: number, offset?: number, status?: 'any'|'paid'|'unpaid'|'overdue' }
): Promise<{ invoices: BillingInvoice[], page: Page }> {
  return await getTypedApi({
    url: '/GetBillingInvoicesByPropertyId',
    query: { propertyId, limit, offset, status },
    schema: z.object({
      invoices: z.array(schemas.billing.invoice),
      page: schemas.page.page
    })
  })
}
