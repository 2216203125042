import { get, set } from 'lodash'
import { z } from 'zod'

type Z = z.Schema<any>

const _schema = Symbol()

function getAllSchemaKeys(
  schema: z.ZodObject<any>,
  currentKey: string = ''
): string[] {
  const thisLevelKeys = Object.keys(schema.shape)
  const currentLevel = schema.shape

  let keys: string[] = []

  for (const key of thisLevelKeys) {
    const newKey: string = currentKey === ''
      ? key
      : `${currentKey}.${key}`

    if (!currentLevel[key].shape) {
      keys = [ ...keys, newKey ]
      continue
    }

    const lowerSchema = currentLevel[key]

    keys = [
      ...keys,
      ...getAllSchemaKeys(lowerSchema, newKey),
    ]
  }

  return keys
}

class CreateSchema {
  private [_schema] = z.object({})

  public with = (
    ns: string,
    schema: Z,
    include?: boolean
  ): CreateSchema => {
    if (include === false) {
      return this
    }

    this[_schema] = this[_schema].merge(z.object({
      [ns]: schema
    }))

    return this
  }

  public validate = (values: unknown) => {
    const errors = {}

    /**
     * For a reason I don't have the time to figure out, when I switched the
     * schemas to a shared aliased folder, it broke safeParse(), in that it 
     * won't catch it's own error messages.
     */
    try {
      this[_schema].parse(values)
    } catch (e) {
      const issues = JSON.parse((e as Error).message)

      for (const issue of issues) {
        const path = issue.path.join('.')
        set(errors, path, issue.message)
      }

      return errors
    }

    return {}
  }

  public filter = (data: any): any => {
    /**
     * We need to strip all properties from the provided `data` that are not 
     * also present on the schema object
     */
    const filtered = {}
    const keys = getAllSchemaKeys(this[_schema])

    for (const key of keys) {
      const item = get(data, key)
      
      if (typeof item !== 'undefined') {
        set(filtered, key, item)
      }
    }

    return filtered
  }
}

export default function createSchema() {
  return new CreateSchema()
}
