import { CurrencyDollarIcon, MinusCircleIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/solid'
import { BillingInvoice, BillingPayment, BillingPaymentDistribution } from '@shared/schemas/billing'
import { ExtendedPerson } from '@shared/schemas/person'
import Button from 'components/elements/Button'
import { Card, CardBody, CardHeader } from 'components/elements/Card'
import DescriptionList from 'components/elements/DescriptionList'
import DeleteModal from 'components/elements/modals/DeleteModal'
import Spacer from 'components/elements/Spacer'
import Stats from 'components/elements/Stats'
import Table from 'components/elements/Table'
import PaymentDistributionOverlay from 'components/lease/PaymentDistributionOverlay'
import Comments from 'components/modules/Comments'
import Decimal from 'decimal.js'
import { keyBy, uniq } from 'lodash'
import { DateTime } from 'luxon'
import { postApi } from 'modules/api'
import notifications from 'modules/notifications'
import progress from 'modules/progress'
import { ReactElement, useState } from 'react'
import { LoaderFunction, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import { getBillingInvoice, getBillingPayment } from 'stores/billing'
import { getPerson } from 'stores/person'
import getParams from 'utils/getParams'
import { formatMoney } from 'utils/transforms'
import { useProperty } from '../_root'
import LeasePageShell from './_shell'

type LoaderData = {
  payment: BillingPayment
  distributions: BillingPaymentDistribution[]
  person: ExtendedPerson|null
  invoices: BillingInvoice[]
}

const MODALS: Record<string, string> = {
  DELETE: 'delete'
}

const LeasePaymentPage: React.FC = () => {
  const {
    payment,
    distributions,
    person,
    invoices
  } = useLoaderData() as LoaderData
  const property = useProperty()
  
  const invoicesById = keyBy(invoices, 'id')
  
  const [
    editingDistributionInvoiceId,
    setEditingDistributionInvoiceId
  ] = useState('')

  const [
    visibleModal,
    setVisibleModal 
  ] = useState('')

  const [
    loading,
    setLoading
  ] = useState(false)

  const navigate = useNavigate()

  const deletePayment = async (): Promise<void> => {
    setLoading(true)
    progress.start('delete payment')

    const result = await postApi('/DeleteBillingPayment', {
      paymentId: payment.id
    })

    if (result._.statusCode !== 200) {
      setLoading(false)
      setVisibleModal('')
      progress.done('delete payment')
      return
    }

    notifications.success({
      message: 'The payment has been deleted.',
    })

    navigate(
      `/p/${property.id}/lease/${payment.leaseId}/payments`,
      { replace: true }
    )
    
    progress.done('delete payment')
  }
  
  const renderDetails = () => {
    const date = DateTime
      .fromJSDate(payment.paidAt, { zone: 'UTC' })
      .toLocaleString(DateTime.DATE_SHORT)
    
    const paidBy = (): ReactElement => {
      if (!person) {
        return <span className='text-gray-400'>Not specified</span>
      }

      return <span>{`${person.firstName} ${person.lastName}`}</span>
    }

    const details = [
      { label: 'Date', value: date },
      { label: 'Provider', value: payment.provider },
      { label: 'Paid by', value: paidBy() }
    ]

    return (
      <DescriptionList
        className='w-full flex flex-col mt-0'
        data={details}
      />
    )
  }

  const renderStats = (): ReactElement => {
    const stats = [
      {
        label: 'Amount',
        value: formatMoney(payment.amount),
        icon: <CurrencyDollarIcon className='w-5' />
      },
      {
        label: 'Allocated',
        value: formatMoney(payment.allocated),
        icon: <CurrencyDollarIcon className='w-5' />
      }
    ]

    return (
      <Stats data={stats} />
    )
  }

  const renderDistributions = (): ReactElement => {
    const unallocatedAmount = new Decimal(payment.amount).sub(
      distributions.reduce((acc, cur) => acc.add(cur.amount), new Decimal(0))
    )

    const columns = [
      { key: 'amount', label: 'Amount' },
      { key: 'invoice', label: 'Invoice' },
      { key: 'actions', label: '', rowClasses: 'text-right align-middle py-0' }
    ]

    const rows = distributions.map(distribution => {
      const { invoiceId, amount } = distribution
      const invoice = invoicesById[invoiceId]
      const due = DateTime
        .fromJSDate(invoice.due, { zone: 'UTC' })
        .toLocaleString(DateTime.DATE_SHORT)

      const actions = () => {
        return (
          <>
            <button
              className='text-indigo-600 hover:text-indigo-900 w-5 mt-1'
              onClick={() => setEditingDistributionInvoiceId(invoiceId)}
            >
              <PencilIcon />
            </button>
          </>
        )
      }

      return {
        amount: formatMoney(amount),
        invoice: due,
        actions: actions()
      }
    })

    const unallocatedRow = () => {
      const actions = () => {
        return (
          <div className='my-0'>
            <button
              className='text-indigo-600 hover:text-indigo-900 w-5 mt-1'
              onClick={() => setEditingDistributionInvoiceId('new')}
            >
              <PlusIcon />
            </button>
          </div>
        )
      }

      return {
        amount: formatMoney(unallocatedAmount.toString()),
        invoice: 'Unallocated',
        actions: actions()
      }
    }

    if (!unallocatedAmount.isZero()) {
      rows.push(unallocatedRow())
    }

    return (
      <Card>
        <CardHeader
          title='Distributions'
          subtitle='How this payment has been allocated to invoices on this lease.'
        />
        <CardBody type='table'>
          <Table inline rows={rows} columns={columns} />
        </CardBody>
      </Card>
    )
  }

  const actions = (): ReactElement[] => {
    const deleteOnClick = () => setVisibleModal(MODALS.DELETE)

    if (payment.provider !== 'manual') {
      // edits are only allowed on manual payments
      return []
    }

    return [
      <Button type='secondary' onClick={deleteOnClick}>
        <span>Delete</span><MinusCircleIcon className='w-4 ml-2' />
      </Button>
    ]
  }

  const renderDeleteModal = (): ReactElement => {
    return (
      <DeleteModal
        visible={visibleModal === MODALS.DELETE}
        action={deletePayment}
        close={() => setVisibleModal('')}
        title='Delete payment?'
        isDeleting={loading}
      />
    )
  }

  return (
    <LeasePageShell title='Payment' actions={actions()}>
      {renderDeleteModal()}

      <PaymentDistributionOverlay
        onClose={() => setEditingDistributionInvoiceId('')}
        visible={editingDistributionInvoiceId !== ''}
        payment={payment}
        distributions={distributions}
        preselectedInvoiceId={editingDistributionInvoiceId}
      />
      <Spacer>
        {renderStats()}
        {renderDetails()}
        {renderDistributions()}
        <Comments
          targetType='billing.payment'
          targetId={payment.id}
        />
      </Spacer>
    </LeasePageShell>
  )
}

const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { paymentId } = getParams(args)
  const { payment, distributions } = await getBillingPayment({ paymentId })

  const invoiceIds = uniq(distributions.map(d => d.invoiceId))
  const fetchInvoices = invoiceIds.map(invoiceId => {
    return getBillingInvoice({ invoiceId })
  })

  const invoices = await Promise.all(fetchInvoices)

  const person = payment.personId
    ? await getPerson({ personId: payment.personId })
    : null

  return {
    payment,
    distributions,
    person,
    invoices
  }
}

const route: RouteObject = {
  path: 'payment/:paymentId',
  loader,
  element: <LeasePaymentPage />
}

export default route
