import schemas from '@shared/schemas'
import { ExtendedAsset } from '@shared/schemas/asset'
import { ExtendedLease } from '@shared/schemas/lease'
import { ExtendedPerson } from '@shared/schemas/person'
import { Attributes } from '@shared/schemas/setting'
import Address from 'components/elements/Address'
import { Card, CardBody, CardHeader } from 'components/elements/Card'
import DescriptionList from 'components/elements/DescriptionList'
import Empty from 'components/elements/Empty'
import Spacer from 'components/elements/Spacer'
import Table from 'components/elements/Table'
import Comments from 'components/modules/Comments'
import Documents from 'components/modules/Documents'
import LeaseCard from 'components/modules/LeaseCard'
import { keyBy } from 'lodash'
import { getTypedApi } from 'modules/typedApi'
import { FaFrown } from 'react-icons/fa'
import { LoaderFunction, RouteObject, useLoaderData } from 'react-router-dom'
import { getAsset } from 'stores/asset'
import { getCurrentLease } from 'stores/lease'
import { getLeasePeople } from 'stores/person'
import getParams from 'utils/getParams'
import { formatMoney } from 'utils/transforms'
import { useAsset } from './_root'

/**
 * Note that parent asset data is necessary so that we can render information
 * about the parent asset in the lease details
 */
type LoaderData = {
  parentAsset: ExtendedAsset|null
  currentLease: ExtendedLease|null
  people: ExtendedPerson[]
  attributeSettings: Attributes
}

const AssetIndexPageComponent: React.FC = () => {
  const { asset, address } = useAsset()
  const { people, currentLease, attributeSettings, parentAsset } = useLoaderData() as LoaderData

  const renderDetails = (): JSX.Element => {
    const renderAddress = address !== null
      ? <Address address={address} />
      : 'Unknown'
      
    const details = [
      { label: 'Address', value: renderAddress },
      {
        label: 'Rate',
        value: (
          <span>
            {formatMoney(asset.baseRate)} <span className='text-gray-400'>/ monthly</span>
          </span>
        )
      }
    ]

    return (
      <DescriptionList
        title='Overview'
        twoColumn
        className='w-full flex flex-col'
        data={details}
      />
    )
  }
  
  const renderAttributes = (): JSX.Element => {
    const { presets } = attributeSettings
    const presetsById = keyBy(presets, 'id')

    const columns = [
      { key: 'key' },
      { key: 'value' }
    ]

    const genericRows = [
      {
        _known: true,
        key: <span className='text-gray-900'>Asset ID</span>,
        value: asset.id
      }
    ]

    const attributes = asset.attributes
      ? asset.attributes
      : {}

    const rows = Object.keys(attributes).map(key => {
      // We need to render booleans as "Yes" or "No"
      const value = typeof attributes[key] === 'boolean'
        ? (attributes[key] ? 'Yes' : 'No')
        : attributes[key]

      if (presetsById[key]) {
        // If we have a preset, use that display name
        return {
          _known: true,
          _key: key,
          key: <span className='text-gray-900'>{presetsById[key].name}</span>,
          value
        }
      }

      return {
        _known: false,
        _key: key,
        key,
        value
      }
    }).sort((a, b) => {
      // Rows should prioritize known keys, then alphabetical
      if (a._known && !b._known) return -1
      if (!a._known && b._known) return 1
      return a._key.toLowerCase().localeCompare(b._key.toLowerCase())
    })

    return (
      <Card>
        <CardBody type='table'>
          <Table inline columns={columns} rows={[ ...genericRows, ...rows ]} />
        </CardBody>
      </Card>
    )
  }

  const renderCurrentLease = (): JSX.Element => {
    const title: string = 'Lease'

    if (currentLease === null) {
      return (
        <Card>
          <CardHeader title={title} />
          <CardBody>
            <Empty 
              icon={<FaFrown />}
              message='No active lease at the moment.'
              height='140px'
            />
          </CardBody>
        </Card>
      )
    }

    const leaseProps = {
      asset,
      title,
      lease: currentLease,
      people,
      parentAsset
    }

    return (
      <LeaseCard {...leaseProps} />
    )
  }

  return (
    <Spacer>
      {renderDetails()}
      {renderAttributes()}
      {renderCurrentLease()}
      <Documents targetId={asset.id} targetType='asset' />
      <Comments targetId={asset.id} targetType='asset' />
    </Spacer>
  )
}

const loader: LoaderFunction = async (args): Promise<LoaderData> => {
  const { assetId } = getParams(args)

  // TODO: how can we avoid loading this again
  const asset = await getAsset({ assetId }) 
  
  const currentLease = await getCurrentLease(assetId)
  const people = currentLease !== null
    ? await getLeasePeople({ leaseId: currentLease.id })
    : []

  const attributeSettings = await getTypedApi({
    url: '/GetAssetAttributeDefaults',
    schema: schemas.setting.attributes,
    query: {
      assetType: asset.type,
      propertyId: asset.propertyId
    },
    path: 'setting'
  })

  const parentAsset = currentLease !== null && currentLease.assetId !== asset.id
    ? await getAsset({ assetId: currentLease.assetId })
    : null

  return {
    parentAsset,
    currentLease,
    people,
    attributeSettings
  }
}

const route: RouteObject = {
  path: '',
  element: <AssetIndexPageComponent />,
  loader
}

export default route
