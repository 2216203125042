import classNames from 'classnames'
import { ReactElement } from 'react'

interface Props {
  src?: string|null
  initials?: string|null
  icon?: ReactElement
  size?: number
  className?: string
}

export default function Avatar(props: Props): JSX.Element {
  const {
    src = null,
    initials = null,
    size = 10,
    className = '',
    icon
  } = props

  const sizeClasses = classNames({
    'h-1 w-1': size === 1,
    'h-2 w-2': size === 2,
    'h-3 w-3': size === 3,
    'h-4 w-4': size === 4,
    'h-5 w-5': size === 5,
    'h-6 w-6': size === 6,
    'h-7 w-7': size === 7,
    'h-8 w-8': size === 8,
    'h-9 w-9': size === 9,
    'h-10 w-10': size === 10,
    'h-11 w-11': size === 11,
    'h-12 w-12': size === 12
  })

  const sharedClasses: string = classNames(
    sizeClasses,
    className
  )

  if (src !== null) {
    const s: string = classNames(
      sharedClasses,
      'rounded-full'
    )

    return (
      <img
        className={s}
        src={src}
        alt='Avatar'
      />
    )
  }

  if (initials !== null) {
    const s: string = classNames(
      'inline-flex items-center justify-center rounded-full',
      sharedClasses,
      { 'bg-gray-500': !className.includes('bg-') },
      { 'text-white': !className.includes('text-') }
    )

    const textSize = classNames(
      { 'text-xs': size <= 6 },
      { 'text-sm': size > 6 && size <= 8 },
      { 'text-base': size > 8 && size <= 10 },
      { 'text-lg': size > 10 && size <= 12 }
    )

    return (
      <span className={s}>
        <span className={`font-medium leading-none uppercase ${textSize}`}>
          {initials}
        </span>
      </span>
    )
  }

  if (icon) {
    const s: string = classNames(
      'inline-flex items-center justify-center rounded-full',
      sharedClasses,
      { 'bg-gray-500': !className.includes('bg-') },
      { 'text-white': !className.includes('text-') }
    )

    return (
      <span className={s}>
        {icon}
      </span>
    )
  }

  throw Error('you must provide one of: src, initials, or icon')
}
