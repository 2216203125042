export default function getParams(from: any): Record<string, string> {
  if (!(typeof from === 'object')) {
    return {}
  }

  if (from === null) {
    return {}
  }

  let target = from
  
  if(from.hasOwnProperty('match')) {
    // We were provided a { match }
    target = from.match
  }

  if (target.hasOwnProperty('params') && typeof target.params === 'object') {
    return Object.freeze({ ...target.params })
  }

  return {}
}
