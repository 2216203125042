const environment: string = window.location.hostname === 'localhost'
  ? 'development'
  : 'production'
  
const config: any = {}

config.api = {}

if (environment === 'development') {
  config.api.path = `http://${window.location.hostname}:7071/api`
} else {
  config.api.path = 'https://real-estate.api.deletypelabs.net/api'
}

export default config
