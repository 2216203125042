import { ExtendedAsset } from '@shared/schemas/asset'
import { ExtendedLease } from '@shared/schemas/lease'
import Address from 'components/elements/Address'
import DescriptionList from 'components/elements/DescriptionList'
import Spacer from 'components/elements/Spacer'
import Comments from 'components/modules/Comments'
import Documents from 'components/modules/Documents'
import Leases from 'components/modules/Leases'
import { uniq } from 'lodash'
import moment from 'moment'
import { ReactElement } from 'react'
import { LoaderFunctionArgs, RouteObject, useLoaderData } from 'react-router-dom'
import { getAssets } from 'stores/asset'
import { getPersonLeases } from 'stores/lease'
import formatPhone from 'utils/formatPhone'
import getParams from 'utils/getParams'
import { useProperty } from '../_root'
import { usePerson } from './_root'

interface LoaderData {
  leases: ExtendedLease[]
  assets: ExtendedAsset[]
}

const PersonIndexPageComponent = (): ReactElement => {
  const property = useProperty()
  const person = usePerson()
  const { leases, assets } = useLoaderData() as LoaderData

  const notSpecified: JSX.Element = (
    <span className='text-gray-400'>Not specified</span>
  )

  const renderDetails = (): JSX.Element => {
    const dateOfBirth = person.birthDate !== null
      ? <span className='inline-flex items-center'>
          {moment(person.birthDate).format('MM/DD/YYYY')}
          <span className='text-gray-400 ml-2 text-xs'>
            {moment().diff(moment(person.birthDate), 'years')} years old
          </span>
        </span>
      : notSpecified

    const phoneNumbers: string[] = []
    const emailAddresses: string[] = []

    if (Array.isArray(person.contactMethods)) {
      for (const item of person.contactMethods) {
        if (item.id === 'phone') phoneNumbers.push(item.value)
        if (item.id === 'email') emailAddresses.push(item.value)
        continue
      }
    }

    const details = [
      { label: 'Mailing Address', value: <Address address={person.address} /> },
      { label: 'Date of Birth', value: dateOfBirth }
    ]

    if (emailAddresses.length > 0) {
      const value = (
        <div>
          {emailAddresses.map(email => <a
            href={`mailto:${email}`}
            className='block text-indigo-700 hover:text-indigo-900'
            key={email}>{email}</a>)}
        </div>
      )

      details.push({ label: 'Email', value })
    }

    if (phoneNumbers.length > 0) {
      const value = (
        <div>
          {phoneNumbers.map(phone => <a
            href={`tel:+1${phone}`}
            className='block text-indigo-700 hover:text-indigo-900'
            key={phone}>{formatPhone(phone)}</a>)}
        </div>
      )

      details.push({ label: 'Phone', value })
    }

    return (
      <DescriptionList
        twoColumn
        className='w-full flex flex-col'
        title='Overview'
        subtitle={`General details and contact information.`}
        data={details}
      />
    )
  }

  return (
    <Spacer>
      {renderDetails()}
      <Leases
        emptyMessage='No leases.'
        leases={leases}
        assets={assets}
        property={property}
      />
      <Documents
        targetId={person.id}
        targetType='person'
      />
      <Comments
        nouns={[ 'note', 'notes' ]}
        targetId={person.id}
        targetType='person'
      />
    </Spacer>
  )
}

const loader = async (args: LoaderFunctionArgs): Promise<LoaderData> => {
  const { personId } = getParams(args)
  const leases = await getPersonLeases({ personId })
  const assetIds = uniq(leases.map(lease => lease.assetId))
  const assets = await getAssets({ assetIds })

  return {
    leases,
    assets
  }
}

const route: RouteObject = {
  path: '',
  element: <PersonIndexPageComponent />,
  loader
}

export default route
