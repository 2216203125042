import React from 'react'
import GenericTransition from './Generic'
import backgroundStyles from './ModalBackground.module.css'
import FadeUp from './FadeUp'

export function ModalBackgroundTransition(props: any): JSX.Element {
  return (
    <GenericTransition {...props} appear={true} styles={backgroundStyles}>
      {props.children}
    </GenericTransition>
  )
}

export function ModalPanelTransition(props: any): JSX.Element {
  return FadeUp(props)
}
