import classNames from 'classnames'
import FadeUpFast from 'components/elements/transitions/FadeUpFast'
import { PropertyContext } from 'contexts/property'
import { debounce } from 'lodash'
import { postApi } from 'modules/api'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'
import useAsyncEffect from 'use-async-effect'

const ASSET_TYPE_TOKENS: Record<string, string> = {
  'lot': 'mhp_lot',
  'apt': 'apartment',
  'apartment': 'apartment',
  'storage': 'storage_unit',
  'parking': 'parking',
  'space': 'parking'
}

export default function Search(): JSX.Element {
  const [ inputText, setInputText ] = useState('')
  const [ searchText, setSearchText ] = useState('')
  const [ isFocused, setIsFocused ] = useState(false)
  const [ isSearching, setIsSearching ] = useState(false)
  const [ searchResults, setSearchResults ] = useState<any[]>([])
  const [ highlightedItemId, setHighlightedItemId ] = useState<string>('')
  const navigate = useNavigate()

  const property = useContext(PropertyContext)

  const showPopover = (): boolean => {
    if (isFocused) {
      if (searchResults.length > 0) return true
      if (
        searchResults.length === 0 &&
        searchText !== '' &&
        isSearching === false
      ) return true
    }

    return false
  }

  const search = useMemo(() => debounce(
    (query: string) => setSearchText(query), 200
  ), [])

  const detectTokens = (input: string): { token: string, query: string } => {
    const allTokens = Object.keys(ASSET_TYPE_TOKENS)
    const inputStr = input.toLowerCase().trim()

    for (const token of allTokens) {
      if (inputStr.indexOf(token) === 0) {
        return {
          token: ASSET_TYPE_TOKENS[token],
          query: inputStr.replace(token, '').trim()
        }
      }
    }

    return { token: '', query: inputStr }
  }

  useEffect(() => search(inputText), [ inputText, search ])

  useAsyncEffect(async mounted => {
    const { token, query } = detectTokens(searchText)

    if (query === '') {
      setIsSearching(false)
      setSearchResults([])
      return
    }

    setIsSearching(true)

    const body = token !== ''
      ? { token, query }
      : { query }

    const { results } = await postApi('/SearchProperty', {
      ...body,
      propertyId: property.id
    })
  
    if (!mounted()) return

    setIsSearching(false)
    setSearchResults(results)
  }, [ searchText ])

  const renderResult = (item: any, index: number): JSX.Element => {
    const { id } = item

    const isHighlighted: boolean = highlightedItemId === ''
      ? index === 0
      : itemIsPresentInResults(highlightedItemId)
        ? id === highlightedItemId
        : index === 0

    const liClasses = classNames(
      'cursor-pointer select-none relative py-2 pl-3 pr-9',
      { 'text-white bg-indigo-600': isHighlighted }, // active
      { 'text-gray-900': !isHighlighted } // inactive
    )

    const spanClasses = classNames(
      'block truncate',
      { 'font-semibold': isHighlighted }, // active
      { 'font-normal': !isHighlighted } // inactive
    )

    const go = () => navigate(item.href)

    return (
      <li className={liClasses} key={item.id} onClick={go}>
        <span className={spanClasses}>{item.label}</span>
      </li>
    )
  }

  const renderPopover = (): JSX.Element => {
    return (
      <FadeUpFast in={showPopover()} className='absolute always-top w-full mt-2'>
        <div className='rounded-md bg-white shadow-lg'>
          <ul className='max-h-60 rounded-md py-1 text-base leading-6 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm sm:leading-5'>
            {searchResults.length === 0 && <span className='inline-block py-2 w-full text-center text-gray-600 font-medium'>No results</span>}
            {searchResults.map(renderResult)}
          </ul>
        </div>
      </FadeUpFast>
    )
  }

  const renderSearching = (): JSX.Element => {
    if (!isSearching) return <React.Fragment />

    return (
      <div className='absolute inset-y-0 right-0 flex items-center pt-1 pr-3'>
        <ScaleLoader height={15} width={3} margin={1} color='#999' />
      </div>
    )
  }

  const selectHighlightedItem = () => {
    const index = getSelectedItemIndex()
    const item = searchResults[index]
    navigate(item.href)
  }

  const itemIsPresentInResults = (itemId: string): boolean => searchResults
    .filter(item => item.id === itemId)
    .length > 0

  const getSelectedItemIndex = (): number => searchResults.reduce(
    (prev, current, index) => {
      if (prev !== 0) return prev
      if (current.id === highlightedItemId) return index
      return 0
    },
  0)

  const highlightNextItem = (): void  => {
    const potentialNext = getSelectedItemIndex() + 1
    const nextIndex = potentialNext > searchResults.length 
      ? 0
      : potentialNext

    const nextItem = searchResults[nextIndex]

    if (!nextItem) return
    setHighlightedItemId(nextItem.id)
  }


  const highlightPrevItem = (): void => {
    const potentialNext = getSelectedItemIndex() - 1
    const nextIndex = potentialNext < 0 || potentialNext >= searchResults.length
      ? Math.max(searchResults.length - 1, 0)
      : potentialNext

    const nextItem = searchResults[nextIndex]

    if (!nextItem) return
    setHighlightedItemId(nextItem.id)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const ARROW_DOWN = 40
    const ARROW_UP = 38
    const ENTER = 13
    const ESC = 27

    switch (e.keyCode) {
      case ARROW_DOWN: return highlightNextItem()
      case ARROW_UP: return highlightPrevItem()
      case ENTER: return selectHighlightedItem()
      case ESC:
        setInputText('')
        e.currentTarget.blur()
        return
    }
  }

  const inputStyles = classNames(
    'block w-full pl-10 y-2 border border-gray-300 rounded-md leading-5',
    'bg-white placeholder-gray-500 focus:outline-none',
    'focus:placeholder-gray-400 focus:ring focus:ring-blue-500',
    'sm:text-sm',
    { 'pr-3': !isSearching },
    { 'pr-12': isSearching }
  )

  return (
    <div className='flex-1 px-2 flex justify-center items-center lg:ml-6 lg:justify-end'>
      <div className='max-w-lg w-full lg:max-w-xs relative'>
        <label htmlFor='search' className='sr-only'>Search</label>
        <div className='relative'>
          <div className='pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center'>
            <svg className='h-5 w-5 text-gray-400' fill='currentColor' viewBox='0 0 20 20'>
              <path fillRule='evenodd' d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z' clipRule='evenodd' />
            </svg>
          </div>

          <input
            className={inputStyles}
            placeholder='Search...'
            type='search'
            value={inputText}
            onFocus={_ => setIsFocused(true)}
            onBlur={_ => setTimeout(() => setIsFocused(false), 100)}
            onChange={e => setInputText(e.target.value)}
            onKeyDown={onKeyDown}
          />

          {renderSearching()}
        </div>

        {renderPopover()}
      </div>
    </div>
  )
}
