import schemas from '@shared/schemas'
import { Form } from 'components/elements/Form'
import HomeAssetForm from 'components/elements/forms/HomeAsset'
import { mapKeyedAttrsToListAttrs } from 'components/elements/forms/inputs/Attributes'
import Submit from 'components/elements/forms/inputs/Submit'
import { AssetModificationContext } from 'contexts/assetModification'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { postApi } from 'modules/api'
import notifications from 'modules/notifications'
import progress from 'modules/progress'
import { ReactElement, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import createSchema from 'utils/createSchema'
import namespaces from 'utils/namespaces'
import { useAsset } from './_root'

export default function EditMHPHomePage(): JSX.Element {
  const { asset } = useAsset()
  const navigate = useNavigate()
  const { assetAttributeSettings } = useContext(AssetModificationContext)
  const attributeSettings = assetAttributeSettings['mhp_home']

  const assetAttributesToFormAttributes = mapKeyedAttrsToListAttrs(
    asset.attributes,
    attributeSettings.presets,
    { allowCustom: attributeSettings.allowCustom }
  )

  const schema = createSchema()
    .with(namespaces.home, schemas.asset.home)

  const initialValues = schema.filter({
    [namespaces.home]: {
      ...asset,
      attributes: assetAttributesToFormAttributes
    }
  })

  const submit = async (values: any, frmk: FormikHelpers<any>): Promise<void> => {
    const done = () => {
      progress.done('update asset')
      frmk.setSubmitting(false)
    }

    const data = schema.filter(values)
    progress.start('update asset')

    try {
      await postApi('/UpdateAsset', data, { assetId: asset.id })
    } catch (e) {
      done()
      notifications.warn({ message: (e as Error).message })
      throw e
    }

    progress.done('update asset')
    navigate(`/p/${asset.propertyId}/asset/${asset.id}`)
  }

  const onSubmit = (values: any, frmk: FormikHelpers<any>): void => {
    submit(values, frmk)
  }

  const renderForm = (formikProps: FormikProps<typeof initialValues>): ReactElement => {
    return (
      <Form>
        <HomeAssetForm
          namespace={namespaces.home}
          visible
          propertyId={asset.propertyId}
        />

        <Submit />
      </Form>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={schema.validate}
      onSubmit={onSubmit}
    >{renderForm}</Formik>
  )
}
