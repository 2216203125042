import * as z from 'zod'
import { attributePresets }from './attribute'

export const attributes = z.object({
  allowCustom: z.boolean(),
  allowDuplicates: z.boolean(),
  presets: attributePresets
})

export type Attributes = z.infer<typeof attributes>

