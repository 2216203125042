import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { useState } from 'react'

interface ComboboxInputProps {
  className?: string
  label: string
  selected: string|null
  setSelected: (value: string) => void
  displayValue: (key: string) => string
  options: (filter: string) => any[]
  getKey: (option: any) => string
}

export default function ComboboxInput(props: ComboboxInputProps) {
  const [ query, setQuery ] = useState('')
  const {
    label,
    className = '',
    selected,
    setSelected,
    displayValue,
    options,
    getKey
  } = props

  return (
    <Combobox as='div' value={selected} onChange={setSelected} className={`bg-white rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600 ${className}`}>
      <Combobox.Label className='block text-xs font-medium text-gray-900'>{label}</Combobox.Label>
      <div className='relative'>
        <Combobox.Input
          className='w-full bg-white p-0 pr-10 text-gray-900 sm:text-sm sm:leading-6 focus:ring-0 border-0'
          onChange={(event) => setQuery(event.target.value)}
          displayValue={displayValue}
        />
        <Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md pl-2 focus:outline-none'>
          <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
        </Combobox.Button>

        {options(query).length > 0 && (
          <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {options(query).map((option) => (
              <Combobox.Option
                key={getKey(option)}
                value={getKey(option)}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{displayValue(getKey(option))}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className='h-5 w-5' aria-hidden='true' />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
