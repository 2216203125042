import schemas from '@shared/schemas'
import { ExtendedActivityItem } from '@shared/schemas/activity'
import { getTypedApi } from 'modules/typedApi'
import * as z from 'zod'

export async function getActivityByPropertyId(
  { propertyId, limit, offset }:
  { propertyId: string, limit: number, offset: number }
): Promise<ExtendedActivityItem[]> {
  return await getTypedApi({
    url: '/GetActivityByPropertyId',
    query: { propertyId, limit, offset },
    schema: z.array(schemas.activity.extendedActivityItem),
    path: 'activity'
  })
}

export interface ExtendedActivityData {
  activity: ExtendedActivityItem
  link?: string
  parentLink?: string
}

export async function getActivity(
  { id }:
  { id: string }
): Promise<ExtendedActivityData> {
  return await getTypedApi({
    url: '/GetActivity',
    query: { id },
    schema: z.object({
      activity: schemas.activity.extendedActivityItem,
      link: z.string().optional(),
      parentLink: z.string().optional()
    })
  })
}
