import React, { InputHTMLAttributes } from 'react'
import classNames from 'classnames'
import { useField } from 'formik'
import { FaExclamationCircle } from 'react-icons/fa'
import Label from './Label'
import { defaultInputStyles } from './Defaults'
import { removeCustomProps } from 'components/elements/Form'

interface BaseProps extends InputHTMLAttributes<HTMLSelectElement> {
  label?: string
  help?: string
  error?: string
}

interface BasePropsFormik extends BaseProps {
  noFormik?: false
  name: string
}

interface BasePropsStandard extends BaseProps {
  noFormik: true
}

interface AllProps extends BaseProps {
  formik?: any
  noFormik?: boolean
}

function BaseSelect(props: AllProps): JSX.Element {
  const {
    className = '',
    children,
    formik = [],
    error = '',
    label = '',
    help = ''
  } = props

  const [ field = {}, meta = {} ] = formik
  const inputContainerClasses = classNames('relative')

  const renderLabel: boolean = label !== ''
  const renderError: boolean = (meta.touched && !!meta.error) || error !== ''
  const renderHelp: boolean = help !== '' && !renderError

  const inputClasses: string = classNames(
    defaultInputStyles({ error: renderError }),
    { 'mt-1': renderLabel }
  )

  const errorIcon: JSX.Element = (
    <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
      <FaExclamationCircle className='text-red-500' />
    </div>
  )

  const inputProps = {
    ...removeCustomProps(props),
    ...field,
    className: inputClasses
  }

  return (
    <div className={className}>
      { renderLabel ? <Label label={label} /> : null }
      <div className={inputContainerClasses}>
        <select {...inputProps}>
          {children}
        </select>
        {renderError ? errorIcon : null}
      </div>
      {
        renderHelp
        ? <p className='mt-2 text-sm text-gray-500'>{help}</p>
        : null
      }
      {
        renderError
        ? <p className='mt-2 text-sm text-red-600'>
            {
              error !== ''
              ? error
              : meta.error
            }
          </p>
        : null
      }
    </div>
  )
}

function FormikSelect(props: BasePropsFormik): JSX.Element {
  const formik = useField(props)
  return <BaseSelect {...props} formik={formik} />
}


export default function Select(props: BasePropsFormik | BasePropsStandard) {
  if (typeof props.noFormik === 'undefined' || props.noFormik === false) {
    return <FormikSelect {...props as BasePropsFormik} />
  }

  return <BaseSelect {...props} />
}
