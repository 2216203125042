import VerticalNav from 'components/elements/VerticalNav'
import { ReactElement } from 'react'
import { IoCash, IoStatsChartOutline } from 'react-icons/io5'
import { VscTypeHierarchySub } from 'react-icons/vsc'
import { useMatch } from 'react-router-dom'

export default function AssetNav(): ReactElement {
  const match = useMatch('/p/:propertyId/asset/:assetId/:page')
  const active = match?.params?.page || 'overview'
    
  const dashboard = {
    id: 'overview',
    label: 'Overview',
    icon: <IoStatsChartOutline />,
    href: ''
  }

  const leases = {
    id: 'leases',
    label: 'Leases',
    icon: <IoCash />,
    href: 'leases'
  }
  
  const hierarchy = {
    id: 'hierarchy',
    label: 'Hierarchy',
    icon: <VscTypeHierarchySub />,
    href: 'hierarchy'
  }

  return (
    <VerticalNav
      items={[
        dashboard,
        leases,
        hierarchy
      ]}
      className='w-full'
      active={active}
    />
  )
}
