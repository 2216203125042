import { createContext } from 'react'
import { Attributes } from '@shared/schemas/setting'

type AssetAttrSettings = Record<string, Attributes>

export interface IAssetModificationContext {
  assetAttributeSettings: AssetAttrSettings
}

const assetAttributeSettings: AssetAttrSettings = {}

const BLANK_CONTEXT: IAssetModificationContext = {
  assetAttributeSettings
} 

export const AssetModificationContext = createContext<IAssetModificationContext>(BLANK_CONTEXT)
