import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { snakeCase, get, keyBy } from 'lodash'
import { withNamespace } from 'utils/forms'
import Select from 'components/elements/forms/inputs/Select'
import { FaTrash } from 'react-icons/fa'
import * as Attribute from '@shared/schemas/attribute'
import * as Setting from '@shared/schemas/setting'
import Button from 'components/elements/Button'
import TextInput from './Text'
import Label from './Label'
import { v4 as uuid } from 'uuid'

const EMPTY_ATTRIBUTE = {
  id: '',
  name: '',
  value: '',
  type: 'string'
}

interface IAttribute {
  id: string
  type: string
  name: string
  value?: string
}

interface BaseProps {
  name: string
  label?: string|null
  help?: string
  buttonLabel?: string
  placeholder?: string
  customAttrIdIsUUID?: boolean
}

interface PropsWithSettings extends BaseProps {
  mode: 'settings'
  settings?: Setting.Attributes
}

interface PropsWithFlags extends BaseProps {
  mode: 'props'
  presets?: Attribute.AttributePresets
  allowCustom?: boolean
  allowDuplicates?: boolean
  customAttrType?: string
}

interface MapKeyedSettings {
  allowCustom?: boolean
}

type Props = PropsWithSettings | PropsWithFlags

/**
 * The attribute list is sometimes stored on the server as a keyed object to
 * make querying easier. If that's the case, we need to transform that object
 * to an array for using with the input.
 * @param attrs 
 * @param known 
 */
export function mapKeyedAttrsToListAttrs(
  attrs: unknown,
  known: Attribute.AttributePresets,
  settings: MapKeyedSettings = {}
): IAttribute[] {
  const { allowCustom = false } = settings
  const knownById = keyBy(known, 'id')

  if (typeof attrs !== 'object' || attrs === null) {
    return []
  }

  const attributeIds = Object.keys(attrs)
  const list: IAttribute[] = []

  for (const attrId of attributeIds) {
    if (!knownById.hasOwnProperty(attrId)) {
      if (allowCustom === true) {
        list.push({
          id: attrId,
          type: 'string',
          name: attrId,
          value: String((attrs as any)[attrId])
        })
      } else {
        console.warn('unknown attribute:', attrId)
      }

      continue
    }

    list.push({
      id: attrId,
      type: knownById[attrId].type,
      name: knownById[attrId].name as string,
      value: String((attrs as any)[attrId])
    })
  }

  return list
}

export default function AttributesInput(props: Props): JSX.Element {
  const formik = useFormikContext()

  const {
    label = null,
    buttonLabel = 'Add attribute',
    name,
    help = '',
    placeholder = 'Attribute name',
    customAttrIdIsUUID = false
  } = props

  const {
    presets = [],
    allowDuplicates = false,
    allowCustom = false,
    customAttrType = 'string'
  } = props.mode === 'settings' ? props.settings
    : props.mode === 'props' ? props
    : {} as any

  const namespace = withNamespace(name)

  const { values } = formik as any
  const attributes: IAttribute[] = get(values, name) || []
  const presetsById = keyBy<Attribute.AttributePreset>(presets, 'id')

  const [ selectedAttributeId, setSelectedAttributeId ] = useState<string>('')
  const [ newAttributeName, setNewAttributeName ] = useState<string>('')

  const getAllSelectOptions = (): Attribute.AttributePresets => {
    const chooseOption: Attribute.AttributePreset = {
      id: 'choose',
      name: 'Choose...',
      type: 'string'
    }
    
    const customOption: Attribute.AttributePresets = allowCustom
      ? [ { id: 'custom', name: 'Custom...', type: customAttrType } ]
      : []

    return [
      chooseOption,
      ...presets,
      ...customOption
    ]
  }

  const addAttribute = (): void => {
    const newAttrId = customAttrIdIsUUID
      ? uuid()
      : 'custom_' + snakeCase(newAttributeName)

    /**
     * It's important that we add the `value` property to these attributes, or
     * React will complain about converting an uncontrolled element to a 
     * controlled element (this is done by merging EMPTY_ATTRIBUTE)
     */
    const customAttr = {
      ...EMPTY_ATTRIBUTE,
      type: customAttrType,
      id: newAttrId,
      name: newAttributeName,
      value: ''
    }

    const presetAttr = {
      ...EMPTY_ATTRIBUTE,
      ...get(presetsById, selectedAttributeId, null),
    }

    const newAttr = selectedAttributeId === 'custom'
      ? customAttr
      : presetAttr

    if (newAttr.type === 'boolean') {
      newAttr.value = 'true'
    }
    
    formik.setFieldValue(name, [ ...attributes, newAttr ])
    setSelectedAttributeId('')
    setNewAttributeName('')
  }

  const getAbsentAttributes = (): Attribute.AttributePresets => {
    const present = attributes.map((attr: any) => attr.id)
    const absent = getAllSelectOptions().filter((attr: any) => {
      // We always want to be able to add custom attributes
      if (attr.id === 'custom') return true
      if (allowDuplicates === true) return true
      return !present.includes(attr.id)
    })

    return absent
  }

  const renderAttributeField = (
    attribute: IAttribute,
    index: number
  ): JSX.Element => {
    const onRemove = (): void => {
      formik.setFieldValue(name, attributes.filter((val, idx) => {
        return idx !== index
      }))
    }
  
    const matchingDefault = presetsById[attribute.id]

    const renderType = matchingDefault
      ? matchingDefault.type
      : attribute.type || 'string'

    /**
     * There is an oddity to our validation in that since the schema specifies
     * a union, if the type doesn't validate, the union will throw. This causes
     * the error to be presented on [index] of the errors, not on [index].value
     */
    let error = get(formik.touched, namespace(`${index}.value`), false)
      ? get(formik.errors, namespace(`${index}`))
      : ''
      
    if (error === 'Invalid input') {
      /**
       * Right now we can't add an error message to ZodUnions, which is what
       * will throw on these inputs (normally), so we need to set the right
       * message
       */
      if (renderType === 'string') error = 'cannot be blank'
      if (renderType === 'boolean') error = 'must be true or false'
      if (renderType === 'number') error = 'must be a number'
    }

    const renderInput = (): JSX.Element => {
      const inputName: string = namespace(`${index}.value`)

      if (renderType === 'boolean') {
        return (
          <Select name={inputName} error={error}>
            <option value='true'>yes</option>
            <option value='false'>no</option>
          </Select>
        )
      }

      // strings and numbers both use a text input
      return (
        <TextInput
          name={inputName}
          className='flex-1'
          error=''
        />
      )
    }
    
    return (
      <div key={index} className='col-span-6 grid grid-cols-6 gap-6'>
        <TextInput
          name={namespace(`${index}.name`)}
          className='col-span-6 sm:col-span-2'
          disabled
        />

        <div className='col-span-6 sm:col-span-3'>
          {renderInput()}
        </div>

        <div className='col-span-1'>
          <button
            type='button'
            onClick={onRemove}
            className='mt-3 text-gray-500 hover:text-red-900'
          >
            <FaTrash />
          </button>
        </div>
      </div>
    )
  }

  const renderAttributeSelect = (): JSX.Element => {
    return (
      <Select
        onChange={evt => setSelectedAttributeId(evt.target.value)}
        value={selectedAttributeId}
        noFormik
      >
        {getAbsentAttributes().map(attr => {
          return (
            <option
              key={attr.id}
              value={attr.id}
            >{attr.name}</option>
          )
        })}
      </Select>
    )
  }

  const renderAddAttribute = (): JSX.Element => {
    return (
      <div className='col-span-6 grid grid-cols-6 gap-6'>
        <div className='col-span-2'>
          {renderAttributeSelect()}
        </div>

        {
          selectedAttributeId === 'custom'
          ? <div className='col-span-2'>
              <TextInput
                onChange={evt => setNewAttributeName(evt.target.value)}
                value={newAttributeName || ''}
                placeholder={placeholder}
                className='col-span-6 sm:col-span-3'
              />
            </div>
          : null
        }

        <div className='col-span-2'>
          <Button
            onClick={addAttribute}
            disabled={selectedAttributeId === ''}
          >{buttonLabel}</Button>
        </div>
      </div>
    )
  }

  const renderLabel = (): JSX.Element => {
    if (label === null) {
      return <React.Fragment />
    }

    const helpComponent = help !== ''
      ? <p className='text-sm text-gray-500'>{help}</p>
      : ''

    return (
      <div className='col-span-6 -mb-2'>
        <Label label={label} />
        {helpComponent}
      </div>
    )
  }



  const renderAttributeFields = (): JSX.Element => {
    const noAttributesLeftToSelect: boolean = (
      getAbsentAttributes().length === 1 &&
      getAbsentAttributes()[0].id === 'choose'
    )

    return (
      <div className='col-span-6 grid grid-cols-6 gap-6'>
        {renderLabel()}
        {attributes.map(renderAttributeField)}
        {
          !noAttributesLeftToSelect
          ? renderAddAttribute()
          : null
        }        
      </div>
    )
  }

  return renderAttributeFields()
}
