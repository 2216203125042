import nProgress from 'nprogress'

const queue = Symbol()

class Progress {  
  private [queue]: string[] = []

  constructor() {
    nProgress.configure({ trickleSpeed: 400 })
  }

  public setPct = (intPct: number): void => {
    const pct = intPct / 100
    nProgress.set(pct)
  }

  public start = (id: string): void => {
    if (this[queue].length === 0) nProgress.start()
    this[queue].push(id)
  }
  
  public inc = (): void => {
    if (nProgress.isStarted()) {
      nProgress.inc()
    }
  }
  
  public done = (id: string): void => {
    this[queue] = this[queue].filter(i => i !== id)
    if(this[queue].length === 0) nProgress.done()
  }

  public setLoading = (id: string, state: boolean): void => {
    if (state === true) return this.start(id)
    return this.done(id)
  }

  public clear = (): void => {
    this[queue] = []
    nProgress.done()
  }
}

const progress = new Progress()

export default progress
