import { CheckIcon, HomeModernIcon, ReceiptPercentIcon, UserMinusIcon, WalletIcon } from '@heroicons/react/24/solid'
import { AssetTypes } from '@shared/schemas/asset'
import HeaderAvatar from 'components/app/HeaderAvatar'
import Page from 'components/app/Page'
import Shell from 'components/app/Shell'
import Address from 'components/elements/Address'
import Badge from 'components/elements/Badge'
import DescriptionList from 'components/elements/DescriptionList'
import Stats, { StatItem } from 'components/elements/Stats'
import Activity, { ActivityProps, loadActivityItemsByPropertyId } from 'components/modules/Activity'
import { cloneElement, ReactElement } from 'react'
import { LoaderFunctionArgs, RouteObject, useLoaderData, useNavigate } from 'react-router-dom'
import { getPropertyHealth, HealthStats } from 'stores/property'
import { assetTypeIcon } from 'utils/assetTypes'
import getParams from 'utils/getParams'
import { useProperty } from './_root'

interface LoaderData {
  activity: ActivityProps
  health: HealthStats
}

const PropertyDashboardPage: React.FC = () => {
  const property = useProperty()
  const { activity, health } = useLoaderData() as LoaderData
  const navigate = useNavigate()

  const breadcrumbs = [
    { label: property.displayName, href: `/p/${property.id}`, options: {} }
  ]

  const tern = (condition: boolean, trueObj: object, falseObj: object): object => {
    return condition ? trueObj : falseObj
  }

  const renderHealthStats = (): ReactElement => {
    const allGood = {
      value: <span className='text-green-500'>All good!</span>,
      icon: <CheckIcon className='h-6 w-6' />,
      iconBg: 'bg-green-500'
    }

    const expiredLeases = { 
      label: 'Expired Leases', 
      linkLabel: 'View All',
      href: `/p/${property.id}/leases`,
      ...tern(
        health.expiredLeases > 0,
        {
          value: <span>{String(health.expiredLeases)}</span>,
          icon: <WalletIcon className='h-6 w-6' />,
          iconBg: 'bg-red-500',
          onClick: () => navigate(`/p/${property.id}/leases?status=expired`)
        },
        allGood
      )
    }

    const overdueInvoices = {
      label: 'Overdue Invoices',
      linkLabel: 'View All',
      href: `/p/${property.id}/invoices`,
      ...tern(
        health.overdueInvoices > 0,
        {
          value: <span>{String(health.overdueInvoices)}</span>,
          icon: <ReceiptPercentIcon className='h-6 w-6' />,
          iconBg: 'bg-red-500',
          onClick: () => navigate(`/p/${property.id}/invoices?status=overdue`)
        },
        allGood
      )
    }

    const allVacancies = Object.keys(health.vacantAssets).reduce((acc, key) => {
      return acc + health.vacantAssets[key as AssetTypes]
    }, 0)

    const vancancyValue = (
        <span className='flex'>
          {Object.keys(health.vacantAssets).map((key) => {
            const count = health.vacantAssets[key as AssetTypes]

            if (count === 0) {
              return null
            }

            const onClick = () => navigate(`/p/${property.id}/assets/${key}`)

            const icon = cloneElement(
              assetTypeIcon(key as AssetTypes),
              { className: 'h-5 ml-1 text-gray-400' }
            )

            return (
              <span key={key} className='mr-4 cursor-pointer' onClick={onClick}>
                  <span className='flex items-center'>
                    {count} {icon}
                  </span>
              </span>
            )
          })}
        </span>
    )

    const largestVacancy = Object
      .keys(health.vacantAssets)
      .reduce((acc, key) => {
        const count = health.vacantAssets[key as AssetTypes]
        if (acc.count > count && acc.type !== '') return acc
        return { type: key, count }
    }, { type: '', count: 0 })

    const vacantAssets = {
      label: 'Vacancies',
      linkLabel: 'View All',
      href: `/p/${property.id}/assets/${largestVacancy.type}`,
      ...tern(
        allVacancies > 0,
        {
          value: vancancyValue,
          icon: <UserMinusIcon className='h-6 w-6' />,
          iconBg: 'bg-red-500',
        },
        allGood
      )
    }

    return (
      <Stats
        data={[expiredLeases, overdueInvoices, vacantAssets] as StatItem[]}
      />
    )
  }

  const renderPropertyDetails = (): ReactElement => {
    const assetData = [
      { label: 'Status', value: <Badge label='Active' color='green' /> },
      //{ label: 'Manager', value: 'John Doe' },
      //{ label: 'Acquired', value: '01/01/2021' },
      { label: 'Address', value: <Address address={property.address} type='full' /> },
    ]

    return <DescriptionList title='Overview' smallTitle data={assetData} />
  }

  return (
    <Shell active='DASHBOARD' breadcrumbs={breadcrumbs}>
      <HeaderAvatar
        title={property.displayName}
        subtitle='Dashboard'
        avatarIcon={<HomeModernIcon className='w-8' />}
      />
      <Page>
        {renderHealthStats()}
        <div className='grid grid-cols-3 gap-x-5 mt-5'>
          <div className='col-span-2'>
            <Activity {...activity} />
          </div>
          <div className='col-span-1'>
            {renderPropertyDetails()}
          </div>
        </div>
      </Page>
    </Shell>
  )
}

const loader = async (args: LoaderFunctionArgs): Promise<LoaderData> => {
  const { propertyId } = getParams(args)

  const [
    activity,
    health
  ] = await Promise.all([
    loadActivityItemsByPropertyId({ propertyId }),
    getPropertyHealth({ propertyId })
  ])

  // TODO: this is a bandage since mobile home vacancies are not supported
  health.vacantAssets.mhp_home = 0

  return {
    activity,
    health
  }
}

const route: RouteObject = {
  path: '',
  element: <PropertyDashboardPage />,
  loader
}

export default route
