import classNames from 'classnames'
import { ReactElement } from 'react'
import Transition from '../../transitions/FormSection'

interface Props {
  index?: number
  title?: string
  subtitle?: string
  visible?: boolean
  children: ReactElement|ReactElement[]
  gridCols?: number
  fullWidth?: boolean
}

export default function Section(props: Props): JSX.Element {
  const {
    children,
    title = '',
    subtitle = '',
    index = 0,
    gridCols = 6,
    visible = true,
    fullWidth = false
  } = props

  const firstItemClasses = ''
  const otherItemClasses = 'mt-10 sm:mt-0'
  const gridProps = gridCols !== 0
    ? `grid grid-cols-${gridCols} gap-6`
    : ''

  const divider = (
    <div className='hidden sm:block'>
      <div className='py-3'>
      </div>
    </div>
  )

  const renderTitle = (): JSX.Element => {
    if (title === '' && subtitle === '') {
      return <></>
    }

    return (
      <div>
        {
          title !== ''
          ? <h3 className='text-lg leading-6 font-medium text-gray-900'>
              {title}
            </h3>
          : null
        }
    
        {
          subtitle !== ''
          ? <p className='mt-1 text-sm leading-5 text-gray-500'>
              {subtitle}
            </p>
          : null
        }
      </div>
    )
  }

  const titleClasses = classNames(
    'md:col-span-1',
    {
      'px-4 sm:px-0': title !== '',
      'flex items-center': title === ''
    }
  )

  const inlineClasses = classNames(
    'mt-5 md:mt-0',
    { 'md:col-span-2': fullWidth === false },
    { 'md:col-span-3': fullWidth === true }
  )

  return (
    <Transition in={visible}>
      <div>
        {index !== 0 ? divider : null}
        <div className={index === 0 ? firstItemClasses : otherItemClasses}>
          <div className='md:grid md:grid-cols-3 md:gap-6'>
            <div className={inlineClasses}>
              <div className='shadow sm:rounded-md bg-white '>
                <div className='px-4 py-5 sm:p-6'>
                  <div className={gridProps}>
                    {children}
                  </div>
                </div>
              </div>
            </div>            

            {
              title !== '' && subtitle !== ''
              ? <div className={titleClasses}>
                  <div>
                    {renderTitle()}
                  </div>
                </div>
              : null
            }
          </div>
        </div>
      </div>
    </Transition>
  )
}

