import React, { cloneElement, ReactElement, useLayoutEffect, useRef } from 'react'
import classNames from 'classnames'

interface Props {
  title: string|ReactElement
  subtitle?: string|ReactElement
  actions?: ReactElement[]
  avatarImg?: string
  avatarIcon?: ReactElement
  className?: string
}

export default function HeaderAvatar(props: Props): JSX.Element {
  const {
    title,
    subtitle,
    actions = [],
    avatarImg = '',
    avatarIcon = null,
    className = ''
  } = props

  const titleRef = useRef<HTMLHeadingElement>(null)

  useLayoutEffect(() => {
    if (titleRef.current !== null) {
      window.document.title = `${titleRef.current.textContent} | DELETYPE Property`
    } else {
      window.document.title = 'DELETYPE Property'
    }
  })

  const avatarSize = 14
  const iconSize = 6

  const renderAvatarImg = (): JSX.Element => {
    return (
      <div className='flex-shrink-0'>
        <div className='relative'>
          <img
            className={`h-${avatarSize} w-${avatarSize}`}
            src={avatarImg}
            alt=''
          />
          <span className='absolute inset-0 shadow-inner rounded-full' />
        </div>
      </div>
    )
  }
  
  const renderAvatarIcon = (): JSX.Element => {
    if (avatarIcon === null) throw Error('unreachable')

    const clonedElement = cloneElement(avatarIcon, {
      className: `leading-none text-white h-${iconSize} w-${iconSize}`
    })

    return (
      <div className='flex-shrink-0'>
        <span className={`inline-flex items-center justify-center h-${avatarSize} w-${avatarSize} rounded-full bg-indigo-600`}>
          {clonedElement}
        </span>
      </div>
    )
  }

  const renderAvatar = (): JSX.Element => {
    if (avatarImg !== '') return renderAvatarImg()
    if (avatarIcon !== null) return renderAvatarIcon()
    return <React.Fragment />
  }

  const renderTitles = (): JSX.Element => {
    return (
      <div>
        <h1 className='text-2xl font-bold text-gray-900' ref={titleRef}>{title}</h1>
        <p className='text-sm font-medium text-gray-500'>{subtitle}</p>
      </div>
    )
  }

  const renderAction = (action: JSX.Element, index: number): JSX.Element => {
    return (
      <span key={index}>
        {action}
      </span>
    )
  }

  const renderActions = (): JSX.Element => {
    return (
      <div className='mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3'>
        {actions.map(renderAction)}
      </div>
    )
  }

  const classes = classNames(
    'pt-6 max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center',
    'md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8',
    { [className]: className !== '' }
  )

  return (
    <div className={classes}>
      <div className='flex items-center space-x-5'>
        {renderAvatar()}
        {renderTitles()}
      </div>
      {renderActions()}
    </div>
  )
}
