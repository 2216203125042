import React, { useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import styles from './Notification.module.css'

export default function NotificationTransition(props: any): JSX.Element {
  const nodeRef = useRef(null)
  const duration = 300

  return (
    <CSSTransition
      {...props}
      timeout={duration}
      classNames={{...styles}}
      nodeRef={nodeRef}
    >
      <div ref={nodeRef} className='w-full'>
        {props.children}
      </div>
    </CSSTransition>
  )
}
