import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { cloneElement, ReactElement } from 'react'

interface AlertAction {
  title: string
  onClick: () => void|(() => Promise<void>)
}

interface Props {
  title?: ReactElement|string
  message: ReactElement|string
  icon?: ReactElement
  type: 'success'|'warn'|'error'|'info',
  display?: 'description'|'linkOnRight'
  className?: string,
  actions?: AlertAction[],
  border?: boolean
}

export default function Alert(props: Props): ReactElement {
  const {
    title = '',
    message,
    icon,
    type = 'warn',
    className = '',
    display = 'description',
    actions = [],
    border = true
  } = props

  const icons: Record<Props['type'], ReactElement> = {
    'success': <CheckCircleIcon />,
    'warn': <ExclamationTriangleIcon />,
    'error': <XCircleIcon />,
    'info': <InformationCircleIcon />
  }

  const iconColors = classNames({
    'text-green-400': type === 'success',
    'text-yellow-400': type === 'warn',
    'text-red-400': type === 'error',
    'text-blue-400': type === 'info'
  })

  const iconClasses = classNames('h-5 w-5', iconColors)

  const iconWithProps = icon
    ? cloneElement(icon, { className: iconClasses })
    : cloneElement(icons[type], { className: iconClasses })

  const borderClasses = classNames({
    'border-green-300': type === 'success',
    'border-yellow-300': type === 'warn',
    'border-red-300': type === 'error',
    'border-blue-300': type === 'info'
  })

  const bgColors = classNames({
    'bg-green-50': type === 'success',
    'bg-yellow-50': type === 'warn',
    'bg-red-50': type === 'error',
    'bg-blue-50': type === 'info'
  })

  const containerClasses = classNames(
    'rounded-md p-4', 
    bgColors,
    { [className]: className !== '' },
    { [`border ${borderClasses}`]: border }
  )

  const container = (childElement: ReactElement): ReactElement => {
    return (
      <div className={containerClasses}>
        <div className='flex'>
          <div className='flex-shrink-0'>
            {iconWithProps}
          </div>
          {childElement}
        </div>
      </div>
    )
  }

  if (display === 'linkOnRight') {
    const [ action ] = actions
    
    if (!action) {
      throw Error('action must be provided for type linkOnRight')
    }

    const messageColors = classNames({
      'text-green-700': type === 'success',
      'text-yellow-700': type === 'warn',
      'text-red-700': type === 'error',
      'text-blue-700': type === 'info'
    })

    const linkColors = classNames({
      'text-green-700 hover:text-green-600': type === 'success',
      'text-yellow-700 hover:text-yellow-600': type === 'warn',
      'text-red-700 hover:text-red-600': type === 'error',
      'text-blue-700 hover:text-blue-600': type === 'info'
    })

    return container(
      <div className='ml-3 flex-1 md:flex md:justify-between'>
        <p className={`text-sm ${messageColors}`}>{message}</p>
        <p className={`mt-3 text-sm md:mt-0 md:ml-6`}>
          <a href='/' onClick={evt => { evt.preventDefault(); action.onClick() }} className={`whitespace-nowrap font-medium ${linkColors}`}>
            {action.title}
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </p>
      </div>
    )
  }

  const h3Colors = classNames({
    'text-green-800': type === 'success',
    'text-yellow-800': type === 'warn',
    'text-red-800': type === 'error',
    'text-blue-800': type === 'info'
  })

  const divColors = classNames({
    'text-green-700': type === 'success',
    'text-yellow-700': type === 'warn',
    'text-red-700': type === 'error',
    'text-blue-700': type === 'info'
  })

  return container(
    <div className='ml-3'>
      {
        title === ''
        ? null
        : <h3 className={`text-sm font-medium ${h3Colors} mb-2`}>{title}</h3>
      }
      <div className={`text-sm ${divColors}`}>
        <p>
          {message}
        </p>
      </div>
    </div>
  )
}
