import * as z from 'zod'
import { address } from './address'
import { types } from './asset'

export const property = z.object({
  id: z.string().uuid(),
  displayName: z.string().min(1, { message: 'cannot be blank' }).max(255),
  assetTypes: z.array(types),
  address,
  tenantId: z.string().uuid()
})

export const createProperty = property.omit({
  id: true,
  address: true,
  tenantId: true
})

export const properties = z.array(property)

export type Property = z.infer<typeof property>
export type CreateProperty = z.infer<typeof createProperty>
export type Properties = z.infer<typeof properties>

export const blanks = Object.freeze({
  createProperty: {
    displayName: '',
    assetTypes: [] as string[]
  }
})
