import { z } from 'zod'
import { UnionToTuple } from '../utils/types'
import { zFlexDate } from './util'

export const activityTargetTypes = z.enum([
  'asset',
  'person',
  'lease',
  'document',
  'billing.invoice',
  'billing.payment'
])

export const activityPayloads = {
  'lease.created.v1': z.object({
    assetName: z.string()
  }),

  'lease.state_changed.v1': z.object({
    prevState: z.string(),
    currentState: z.string(),
    assetName: z.string()
  }),

  'lease.deleted.v1': z.object({
    assetName: z.string()
  }),

  'person.updated.v1': z.object({
    personName: z.string(),
    prevPersonName: z.string()
  }),

  'person.created.v1': z.object({
    personName: z.string()
  }),

  'person.deleted.v1': z.object({
    personName: z.string(),
  }),

  'document.read.v1': z.object({
    userId: z.string().uuid(),
    documentName: z.string()
  }),

  'document.created.v1': z.object({
    documentName: z.string()
  }),

  'document.deleted.v1': z.object({
    documentName: z.string()
  }),

  'billing.payment.created.v1': z.object({
    amount: z.string()
  }),

  'billing.payment.deleted.v1': z.object({
    amount: z.string()
  }),

  'billing.payment.updated.v1': z.object({
    amount: z.string(),
    prevAmount: z.string()
  }),

  'billing.invoice.created.v1': z.object({
    amount: z.string()
  }),

  'billing.invoice.deleted.v1': z.object({
    amount: z.string()
  }),

  'billing.invoice.updated.v1': z.object({
    amount: z.string(),
    prevAmount: z.string()
  }),

  'asset.created.v1': z.object({
    assetName: z.string()
  }),

  'asset.deleted.v1': z.object({
    assetName: z.string()
  }),

  'asset.updated.v1': z.object({
    assetName: z.string()
  }),

  'comment.created.v1': z.null(),
  'comment.updated.v1': z.null(),
  'comment.deleted.v1': z.null()
} as const

export type ActivityPayloadsTypes = {
  [K in keyof typeof activityPayloads]: z.infer<typeof activityPayloads[K]>;
}

export type ActivityTypes = keyof ActivityPayloadsTypes

/**
 * This is a bit sketch because the order of the object keys may not (probably
 * won't) be the same as the order of the keys in the union type.
 */
export const activityTypes = z.enum(
  Object.keys(activityPayloads) as UnionToTuple<ActivityTypes>
)

export const activityItem = z.object({
  userId: z.string().uuid().nullable().optional(), // which user is responsible for this item?
  targetType: activityTargetTypes.nullable().optional(), // which tables can be referenced by the target ID
  targetId: z.string().uuid().nullable().optional(),
  type: activityTypes,
  payload: z.any(),
  propertyId: z.string().uuid()
})

export const extendedActivityItem = activityItem.extend({
  id: z.string().uuid(),
  createdAt: zFlexDate()
})

export type ActivityTargetTypes = z.infer<typeof activityTargetTypes>
export type ActivityItem = z.infer<typeof activityItem>
export type ExtendedActivityItem = z.infer<typeof extendedActivityItem>
export type ExtendedActivityItemWithPayload = {
  [K in ActivityTypes]: {
    type: K
    payload: ActivityPayloadsTypes[K]
  } & Omit<ExtendedActivityItem, 'type'|'payload'>
}[ActivityTypes]
  