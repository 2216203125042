import { ReactElement } from 'react'
import Nav, { NavProps } from './Nav'

interface Props extends NavProps {
  hideSearch?: boolean
  children: ReactElement|ReactElement[]
}

export default function Shell(props: Props): ReactElement {
  const {
    children,
    active,
    breadcrumbs,
    hideSearch = false
  } = props

  return (
    <>
      <Nav active={active} breadcrumbs={breadcrumbs} hideSearch={hideSearch} />
      {children}
    </>
  )
}
