import { ExtendedAsset } from '@shared/schemas/asset'
import { ExtendedLease } from '@shared/schemas/lease'
import { Property } from '@shared/schemas/property'
import classNames from 'classnames'
import Badge from 'components/elements/Badge'
import { Card, CardBody, CardHeader } from 'components/elements/Card'
import Empty from 'components/elements/Empty'
import Table from 'components/elements/Table'
import { keyBy } from 'lodash'
import moment from 'moment'
import { ReactElement } from 'react'
import { FaFrown } from 'react-icons/fa'
import { RiQuestionFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { assetTypeToReadable } from 'utils/assetTypes'

interface Props {
  title?: string
  subtitle?: string
  emptyMessage?: string
  assetId?: string
  leases: ExtendedLease[]
  assets: ExtendedAsset[]
  property: Property
}

const leaseStatusBadges: Record<ExtendedLease['status'], ReactElement> = {
  active: <Badge label='Active' color='green' />,
  expired: <Badge label='Expired' color='yellow' />,
  terminated: <Badge label='Terminated' color='red' />,
  month_to_month: <Badge label='M2M' color='blue' />,
  complete: <Badge label='Complete' color='gray' />,
  any: <Badge label='Unknown' color='gray' /> // any is a status for selection, not a real status
}

export const renderLeaseStatusBadge = (status: ExtendedLease['status']): ReactElement => {
  return leaseStatusBadges[status]
}


export default function Leases(props: Props): JSX.Element {
  const {
    title = 'Leases',
    subtitle = '',
    emptyMessage = 'No leases yet.',
    assetId = '',
    leases = [],
    assets = [],
    property
  } = props

  const assetsById = keyBy(assets, 'id')
  const properAsset = assetId !== ''
    ? assetsById[assetId]
    : null

  const parentAssetId = leases.reduce((prev, lease) => {
    if (lease.assetId !== prev) return lease.assetId
    return prev
  }, assetId)

  const parentAsset = parentAssetId !== ''
    ? assetsById[parentAssetId]
    : null

  const renderSubtitle = () => {
    if (subtitle !== '') return subtitle
    if (parentAsset === null) return ''
    if (properAsset === null) return ''
    if (parentAsset.id === properAsset.id) return ''
    
    const notParentText: string = [
      'Showing leases for',
      assetTypeToReadable(parentAsset.type),
      parentAsset.displayId
    ].join(' ')

    const styles: string = classNames(
      'ml-1 group inline-flex items-start text-sm space-x-2 text-gray-400',
      'hover:text-gray-600'
    )

    return (
      <span className='flex items-center'>
        <span>{notParentText}</span>
        <button
          type='button'
          className={styles}>
          <RiQuestionFill size={18} />
        </button>
      </span>
    )
  }

  const columns = [
    { key: 'asset', label: 'Asset', type: 'primary' },
    { key: 'status', label: 'Status' },
    { key: 'term', label: 'Term' },
    { key: 'edit', label: '', rowClasses: 'text-right' }
  ]

  const rows = leases.map((lease: ExtendedLease) => {
    const {
      id: leaseId,
      assetId,
      termStart,
      termEnd
    } = lease

    const asset = assetsById[assetId]
    const { id, displayId } = asset

    const assetHref = `/p/${property.id}/asset/${id}`
    const assetLink = (
      <Link to={assetHref} className='text-indigo-700 hover:text-indigo-900'>
        {assetTypeToReadable(asset.type)} {displayId}
      </Link>
    )

    const status = renderLeaseStatusBadge(lease.status)
    
    const term: string = [
      moment(termStart).format('l'),
      moment(termEnd).format('l')
    ].join(' - ')

    const leaseHref = `/p/${property.id}/lease/${leaseId}`
    const edit = (
      <Link to={leaseHref} className='text-indigo-600 hover:text-indigo-900'>
        Manage
      </Link>
    )

    return {
      asset: assetLink,
      status,
      term,
      edit
    }
  })

  return (
    <Card>
      <CardHeader title={title} subtitle={renderSubtitle()} />
      <CardBody type='table'>
        <>
        <Table inline={true} columns={columns} rows={rows} />
        {
          leases.length === 0
          ? <Empty icon={<FaFrown />} message={emptyMessage} height='200px' />
          : null
        }
        </>
      </CardBody>
    </Card>
  )
}
