import { ExtendedAsset } from '@shared/schemas/asset'
import { ExtendedLease } from '@shared/schemas/lease'
import Leases from 'components/modules/Leases'
import { uniq } from 'lodash'
import { ReactElement } from 'react'
import { LoaderFunctionArgs, RouteObject, useLoaderData } from 'react-router-dom'
import { getAssets } from 'stores/asset'
import { getPersonLeases } from 'stores/lease'
import getParams from 'utils/getParams'
import { useProperty } from '../_root'

type LoaderData = {
  leases: ExtendedLease[]
  assets: ExtendedAsset[]
}

const PersonLeasesPage = (): ReactElement => {
  const property = useProperty()
  const { leases, assets } = useLoaderData() as LoaderData

  return (
    <Leases
      leases={leases}
      assets={assets}
      property={property}
    />
  )
}

const loader = async (args: LoaderFunctionArgs): Promise<LoaderData> => {
  const { personId } = getParams(args)

  const leases = await getPersonLeases({
    personId,
    status: 'any'
  })
  
  const assetIds = uniq(leases.map(lease => lease.assetId))
  const assets = await getAssets({ assetIds })

  return {
    leases,
    assets
  }
}

const route: RouteObject = {
  path: 'leases',
  element: <PersonLeasesPage />,
  loader
}

export default route
