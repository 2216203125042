import classNames from 'classnames'

interface DefaultStylesOptions {
  error?: boolean
  noErrorIcon?: boolean
  overrideBase?: string
  extendBase?: string
}

export function defaultInputStyles(options: DefaultStylesOptions = {}): string {
  const {
    error = false,
    noErrorIcon = false,
    overrideBase = '',
    extendBase = ''
  } = options

  const base = overrideBase !== ''
    ? overrideBase
    : `block w-full sm:text-sm rounded-md sm:leading-5 ${extendBase}`

  const normal = classNames(
    'shadow-sm border-gray-300',
    'focus:ring-indigo-500 focus:border-indigo-500'
  )

  const errored = classNames(
    'border-red-300 text-red-900 placeholder-red-300',
    'focus:outline-none focus:ring-red-500 focus:border-red-500',
    { 'pr-10': noErrorIcon === false }
  )

  const output = classNames(
    base,
    {
      [normal]: error === false,
      [errored]: error === true
    }
  )

  return output
}
