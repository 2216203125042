import { Transition } from '@headlessui/react'
import { FolderMinusIcon } from '@heroicons/react/24/outline'
import { EyeIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import { BillingInvoice, BillingPayment, BillingPaymentDistribution } from '@shared/schemas/billing'
import Button from 'components/elements/Button'
import TextInput from 'components/elements/forms/inputs/Text'
import Slideover, { SlideoverProps } from 'components/elements/modals/Slideover'
import Table from 'components/elements/Table'
import { PropertyContext } from 'contexts/property'
import Decimal from 'decimal.js'
import { DateTime } from 'luxon'
import { Fragment, ReactElement, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'
import { deleteBillingPaymentDistribution, getBillingInvoice, getBillingInvoicesWithABalanceByLeaseId, updateBillingPaymentDistribution } from 'stores/billing'
import useAsyncEffect from 'use-async-effect'
import { formatMoney } from 'utils/transforms'

interface Props extends Omit<SlideoverProps, 'children'|'title'|'subtitle'> {
  payment: BillingPayment
  distributions: BillingPaymentDistribution[]
  preselectedInvoiceId?: string
}

export default function PaymentDistributionOverlay(props: Props): ReactElement {
  const { id: propertyId } = useContext(PropertyContext)
  const navigate = useNavigate()
  
  const {
    visible,
    payment,
    distributions,
    preselectedInvoiceId = ''
  } = props
  
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ isSubmitting ] = useState<boolean>(false)
  const [ invoices, setInvoices ] = useState<BillingInvoice[]>([])
  const [ selectedInvoiceId, setSelectedInvoiceId ] = useState<string>(preselectedInvoiceId || '')
  const [ amount, setAmount ] = useState<string>('')
  const [ deleteState, setDeleteState ] = useState(0)

  const { leaseId } = payment
  const creatingOrUpdating = preselectedInvoiceId === 'new'
    ? 'creating'
    : preselectedInvoiceId !== ''
      ? 'updating'
      : 'empty'

  const unallocatedAmount = new Decimal(payment.amount).sub(
    distributions.reduce((acc, dist) => {
      if (selectedInvoiceId === dist.invoiceId) {
        // do not add the selected invoice's amount to the unallocated amount
        return acc
      }

      return acc.add(dist.amount)
    }, new Decimal(0))
  )

  useAsyncEffect(async isMounted => {
    let invoices: BillingInvoice[] = []

    if (creatingOrUpdating === 'creating') {
      invoices = await getBillingInvoicesWithABalanceByLeaseId({ leaseId: payment.leaseId })

      // Remove invoices that already have distributions from the results
      invoices = invoices.filter(invoice => {
        return !distributions.some(dist => dist.invoiceId === invoice.id)
      })
    }

    if (creatingOrUpdating === 'updating') {
      invoices = [ await getBillingInvoice({ invoiceId: preselectedInvoiceId }) ]
    }

    if (isMounted()) {
      setInvoices(invoices)
      setIsLoading(false)

      if (creatingOrUpdating === 'updating' && preselectedInvoiceId) {
        selectInvoiceId(preselectedInvoiceId)
      }
    }
  }, [ leaseId, preselectedInvoiceId ])

  useEffect(() => {
    if (!visible) {
      setIsLoading(true)
      setSelectedInvoiceId('')
      setAmount('')
      setDeleteState(0)
    }
  }, [ visible ])

  const onClose = (): void => {
    // prevent the modal from closing if the form is submitting
    if (isLoading === false && isSubmitting === false) {
      props.onClose()
    }
  }

  const onSave = async (): Promise<void> => {
    setIsLoading(true)

    await updateBillingPaymentDistribution({
      paymentId: payment.id,
      invoiceId: selectedInvoiceId,
      amount: new Decimal(amount).toFixed(2)
    })

    // we saved a payment so we need to refresh the page
    props.onClose()

    setTimeout(() => {
      navigate(0)
    }, 500)
  }

  const doDelete = async (): Promise<void> => {
    setIsLoading(true)

    await deleteBillingPaymentDistribution({
      paymentId: payment.id,
      invoiceId: selectedInvoiceId
    })

    props.onClose()

    setTimeout(() => {
      navigate(0)
    }, 500)
  }

  const onDelete = async (): Promise<void> => {
    if (deleteState === 0) {
      // Ask the user to confirm, but disable the button for a few seconds
      setTimeout(() => {
        if (visible) setDeleteState(2)
      }, 2000)

      setDeleteState(1)
      return
    }

    if (deleteState === 1) {
      // We are awaiting confirmation and the button is disabled
      return
    }

    if (deleteState === 2) {
      // The confirm button is enabled, if clicked now actually do delete it
      setDeleteState(3)
      await doDelete()
      return
    }
  }

  const renderDeleteButton = (): ReactElement => {
    if (creatingOrUpdating !== 'updating') {
      return <></>
    }

    const renderLabel = (): string => {
      switch (deleteState) {
        case 1: return 'Are you sure? (wait...)'
        case 2: return 'Are you sure?'
        case 3: return 'Deleting...'
      }

      return 'Delete'
    }


    return (
      <div className='flex-1'>
        <Button
          type='danger'
          onClick={onDelete}
          disabled={deleteState === 1 || deleteState === 3}
        >
          {renderLabel()}
        </Button>
      </div>
    )
  }

  const renderActions = (): ReactElement => {
    return (
      <>
        {renderDeleteButton()}
        <Button
          type='secondary'
          onClick={onClose}
          disabled={isSubmitting || isLoading}
        >
          Cancel
        </Button>
        <Button
          type='primary'
          className='ml-2'
          onClick={onSave}
          disabled={isSubmitting || isLoading || amount.length === 0}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </Button>
      </>
    )
  }

  const selectInvoiceId = (invoiceId: string): void => {
    if (invoiceId === selectedInvoiceId && creatingOrUpdating === 'creating') {
      /**
       * Only allow during creating, user should not be able to deselect while
       * update operations
       */
      setSelectedInvoiceId('')
      setAmount('')
      return
    }

    setSelectedInvoiceId(invoiceId)

    const existingDistribution = distributions.find(
      dist => dist.invoiceId === invoiceId
    )

    if (creatingOrUpdating === 'updating' && existingDistribution) {
      setAmount(existingDistribution.amount)
      return
    }

    const invoice = invoices.find(
      invoice => invoice.id === invoiceId
    )

    if (!invoice) {
      // this should be unreachable, since we clicked to select an invoice that
      // was rendered on screen
      throw Error('tried to select invoice we do not have data for')
    }

    const balance = new Decimal(invoice.amount)
      .sub(invoice.paid)
      .toString()

    if (new Decimal(balance).gt(unallocatedAmount)) {
      // The invoice needs more than is available to allocate
      setAmount(unallocatedAmount.toString())
      return
    }

    setAmount(balance)
  }

  const renderInvoices = (): ReactElement => {
    const columns = [
      { key: 'date', label: 'Invoice' },
      { key: 'amount', label: 'Balance' },
      { key: 'actions', label: '', rowClasses: 'text-right align-middle py-0' }
    ]

    const rows = invoices.map(invoice => {
      const invoiceDate = DateTime
        .fromJSDate(invoice.due, { zone: 'utc' })
        .toLocaleString(DateTime.DATE_SHORT)

      const balance = new Decimal(invoice.amount)
        .sub(invoice.paid)
        .toString()

      const existingDistribution = distributions.find(
        dist => dist.invoiceId === invoice.id
      )

      const className = selectedInvoiceId === invoice.id ? 'bg-indigo-100' : ''

      const go = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation()

        const url: string = [
          `/p/${propertyId}`,
          `/lease/${invoice.leaseId}`,
          `/invoice/${invoice.id}`
        ].join('')

        window.open(url, '_blank')
      }

      const actions = (
        <button className='text-indigo-500 mt-1 w-4' onClick={go}>
          <EyeIcon />
        </button>
      )

      const amt = (): ReactElement => {
        if (creatingOrUpdating === 'creating') {
          return (<div>{formatMoney(balance)}</div>)
        }

        if (creatingOrUpdating === 'updating' && existingDistribution) {
          /** 
           * We need to update the balance on screen with the changes to the 
           * amount the user makes so that it's clear what the balance is after
           */
          let renderBalance = new Decimal(balance)
            .add(existingDistribution.amount)
          
          if (!isNaN(Number(amount))) {
            renderBalance = renderBalance.sub(Number(amount))
          }
          
          return (
            <div className='flex items-center'>
              { 
                renderBalance.lessThan(0)
                ? <div className='text-red-500'>{formatMoney(renderBalance.toString())}</div>
                : <div>{formatMoney(renderBalance.toString())}</div>
              }
              
              <div className='text-green-500 ml-2'>
              ({
                !isNaN(Number(amount))
                  ? formatMoney(amount)
                  : formatMoney(0)
              })
              </div>
              <div className='ml-1 w-4 text-green-500'>
                <QuestionMarkCircleIcon />
              </div>
            </div>
          )
        }

        return <></>
      }

      return {
        date: invoiceDate,
        amount: amt(),
        actions,
        config: {
          onClick: () => selectInvoiceId(invoice.id),
          className
        }
      }
    })

    return (
      <div className='mt-4 -mx-6'>
        <Table rows={rows} columns={columns} inline />
        {
          invoices.length === 0
          ? <div className='text-center mt-6'>
              <FolderMinusIcon className='mx-auto h-12 w-12 text-gray-400' strokeWidth={1} />
              <h3 className='mt-1 text-sm font-medium text-gray-900'>No matches</h3>
              <p className='mt-1 text-sm text-gray-500'>Nothing currently unpaid or pending.</p>
            </div>
        : null  
        }
      </div>
    )
  }

  const renderSlideover = (children: ReactElement|ReactElement[]): ReactElement => {
    const title = creatingOrUpdating === 'creating'
      ? 'Add payment distribution'
      : 'Edit payment distribution'

    const subtitle = creatingOrUpdating === 'creating'
      ? 'Allocate part of the payment to an invoice.'
      : 'Change payment allocation to an invoice.'

    return (
      <Slideover
        title={title}
        subtitle={subtitle}
        onClose={onClose}
        visible={visible}
        actions={renderActions()}
      >
        {children}
      </Slideover>
    )
  }

  const renderInput = (): ReactElement => {
    let distributionAmount = new Decimal(0)
    let errorText = ''

    if (amount.length > 0) {
      if (isNaN(Number(amount))) {
        errorText = 'Amount must be a number'
      } else {
        distributionAmount = new Decimal(amount)
      }
    }

    if (distributionAmount.gt(unallocatedAmount)) {
      errorText = 'Amount must be less than or equal to $' + unallocatedAmount.toFixed(2)
    }

    if (distributionAmount.lte(0) && selectedInvoiceId !== '') {
      errorText = 'Amount must be greater than $0.'
    }

    try {
      distributionAmount = new Decimal(amount)
    } catch (e) {
      distributionAmount = new Decimal(0)
    }

    if (selectedInvoiceId !== '') {
      const selectedInvoice = invoices.find(invoice => invoice.id === selectedInvoiceId)
      
      if (selectedInvoice) {
        const existingDistribution = distributions.find(
          dist => dist.invoiceId === selectedInvoiceId
        )

        const potentialBalance = !existingDistribution
          ? new Decimal(selectedInvoice.amount)
              .sub(selectedInvoice.paid)
              .sub(distributionAmount)
          : new Decimal(selectedInvoice.amount)
              .sub(distributionAmount)
        
        if (potentialBalance.lessThan(0)) {
          errorText = 'You cannot allocate more than the invoice balance.'
        }
      }
    }

    const placeholder = selectedInvoiceId !== ''
      ? 'Enter amount to allocate'
      : 'Select an invoice first'

    const helpText = '$' + unallocatedAmount.sub(distributionAmount).toFixed(2) + ' of this payment is unallocated.'

    return (
      <div>
        <label className='block text-sm font-medium text-gray-900'>
          Amount
        </label>
        <div className='mt-1'>
          <TextInput
            placeholder={placeholder}
            disabled={selectedInvoiceId === ''}
            help={helpText}
            value={amount}
            error={errorText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target
              setAmount(value)
            }}
          />
        </div>
      </div>
    )
  }

  const renderContents = (): ReactElement => {
    return (
      <Transition
        show={isLoading === false}
        as={Fragment}
        enter="transform transition ease-in-out duration-300"
        enterFrom="opacity-0 translate-y-2"
        enterTo="translate-y-0 opacity-100"
        leave="transform transition ease-in-out duration-300"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="translate-y-10 opacity-0"
      >
        <div className='space-y-6 pt-6 pb-5'>
          <div>
            <label className='block text-sm font-medium text-gray-900'>
              Invoices
            </label>
            <span className='block mt-1 text-sm text-gray-500'>
              {
                creatingOrUpdating === 'creating'
                ? 'Listing all invoices on this lease that are not yet fully paid.'
                : 'You are changing the payment distribution to this invoice.'
              }
            </span>
            {renderInvoices()}
          </div>

          {invoices.length > 0 ? renderInput() : null}
        </div>
      </Transition>
    )
  }

  const renderLoading = (): ReactElement => {
    return (
      <div className='flex items-center justify-center mt-10'>
        <PulseLoader color='#4338CA' />
      </div>
    )
  }

  return (
    renderSlideover(
      <>
        { isLoading ? renderLoading() : null }
        {renderContents()}
      </>
    )
  )
}
