import progress from 'modules/progress'
import queryString from 'query-string'
import config from '../config'
import { getAccessToken } from './auth'
import cache from './cache'

export interface APICacheOptions {
  ttl?: number
  bucket?: string
}

export function generateApiUrl(relativePath: string, query?: Record<string, any>): string {
  if (relativePath.indexOf('/') !== 0) {
    // Our relative path must always start with a leading /
    throw Error('path must start with a leading /')
  }

  let qs: string = ''

  if (query) {
    qs = '?' + queryString.stringify(query)
  }

  return `${config.api.path}${relativePath}${qs}`
}

function generateHeaders(): Record<string, string> {
  const apiKey = getAccessToken()

  return {
    'Authorization': `Bearer ${apiKey}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
}

interface APIMetadata {
  statusCode: number
}

interface ApiResponse {
  _: APIMetadata
  [key: string]: any
}

export async function getApi(path: string, query?: any): Promise<ApiResponse> {
  const headers = generateHeaders()
  const resource: string = generateApiUrl(path, query)

  progress.inc()

  const response = await fetch(resource, {
    headers
  })

  const json = await response.json()

  json._ = {
    statusCode: response.status
  }

  return json
}

export async function postApi(path: string, body: any, query?: any): Promise<ApiResponse> {
  const headers = generateHeaders()
  const absolutePath: string = generateApiUrl(path, query)

  progress.inc()

  const response = await fetch(absolutePath, {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  })

  const json = await response.json()

  json._ = {
    statusCode: response.status
  }

  return json
}

export async function getApiCache(
  path: string,
  query?: any,
  cacheOptions?: APICacheOptions
): Promise<ApiResponse> {
  const { ttl } = cacheOptions || {}
  const key = generateApiUrl(path, query)
  const cached = cache.get({ key })

  if (typeof cached === 'undefined') {
    const json = await getApi(path, query)
    cache.set({ key, value: json, ttl })
    return json
  } else {
    //console.log('cache hit', key)
  }
  
  return cached
}

export function futch(
  url: string,
  opts?: any,
  onProgress?: (progress: number) => void
): Promise<Response> {
  opts = opts || {}

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open(opts.method || 'get', url)

    for (var k in opts.headers || {}) {
      xhr.setRequestHeader(k, opts.headers[k])
    }

    xhr.onload = (evt: any) => resolve({
      status: evt.target.status,
      json: async () => JSON.parse(evt.target.responseText),
      text: async () => evt.target.responseText
    } as any)
    
    xhr.onerror = reject

    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = (evt: ProgressEvent) => {
        onProgress(Math.round(evt.loaded / evt.total * 100))
      }
    }

    xhr.send(opts.body)
  })
}

export async function uploadApi(
  url: string,
  query: Record<string, string>,
  file: any,
  onProgress?: any
): Promise<any> {
  const response = await futch(url, {
    method: 'PUT',
    body: file
  }, onProgress)

  return response
}
