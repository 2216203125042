import classNames from 'classnames'
import { ReactElement } from 'react'
import { hasStyle } from 'utils/styles'

export interface Column {
  key: string
  label?: string
  headerClasses?: string
  rowClasses?: string
  type?: string
}

export interface RowConfig {
  onClick?: any
  className?: string
}

export interface Row {
  config?: RowConfig
  [key: string]: any
}

interface Props {
  columns: Column[]
  rows: Row[]
  inline?: boolean
  empty?: ReactElement
}

export default function Table(props: Props) {
  const {
    columns,
    rows,
    inline = false,
    empty
  } = props

  const renderHeader = (column: Column): ReactElement => {
    const { key, label = '', headerClasses = '' } = column
    const classes: string = classNames(
      'px-6 py-3 border-b border-gray-200 bg-gray-50 text-xs',
      'leading-4 font-medium text-gray-500 uppercase tracking-wider',
      {
        'text-left': !headerClasses.includes('text-'),
      },
      headerClasses
    )

    return (
      <th key={key} className={classes}>
        {label}
      </th>
    )
  }

  const renderRowItem = (item: any, index: number): ReactElement => {
    const { data, column, rowIndex } = item
    const {
      rowClasses = '',
      type = ''
    } = column

    const base = classNames(
      'whitespace-no-wrap text-sm leading-5',
      { 'px-6': !hasStyle(rowClasses, 'px-*') },
      { 'py-4': !hasStyle(rowClasses, 'py-*') }
    )
    
    const border = 'border-b border-gray-200 '
    const boldColumn: string = 'font-medium text-gray-900'
    const mutedColumn: string = 'text-gray-500'
    const isLastRow = rowIndex === rows.length - 1

    const styles = classNames(
      base,
      { [border]: !isLastRow },
      { [boldColumn]: type === 'primary' },
      { [mutedColumn]: type !== 'primary' },
      rowClasses
    )

    return (
      <td key={index} className={styles}>
        {data}
      </td>
    )
  }

  const renderRow = (row: Row, index: number): ReactElement => {
    const {
      config = {}
    } = row

    const {
      onClick = null,
      className = ''
    } = config

    const data: any[] = columns.map(column => {
      return {
        data: row[column.key] || null,
        column,
        rowIndex: index
      }
    })

    const hover = 'hover:bg-indigo-50 cursor-pointer transition duration-150 ease-in-out'
    const styles = classNames(
      className,
      { [hover]: onClick !== null }
    )

    return (
      <tr key={index} onClick={onClick} className={styles}>
        {data.map(renderRowItem)}
      </tr>
    )
  }

  const renderHead = (): ReactElement => {
    return (
      <thead>
        <tr>
          {columns.map(renderHeader)}
        </tr>
      </thead>
    )
  }

  const renderTable = (): ReactElement => {
    const noColumnLabels: boolean = columns.reduce<boolean>(
      (prev, current): boolean => {
        const { label = '' } = current
        if (prev === false) return false
        if (label !== '') return false
        return true
      },
    true)

    return (
      <table className='min-w-full'>
        {
          !noColumnLabels
          ? renderHead()
          : null
        }
        <tbody className='bg-white'>
          {rows.map(renderRow)}
        </tbody>
      </table>
    )
  }

  const renderWide = (): ReactElement => {
    return (
      <div className='flex flex-col'>
        <div className='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
          <div className='align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200'>
            {renderTable()}
            { rows.length === 0 ? empty : null }
          </div>
        </div>
      </div>
    )
  }

  const renderInline = (): ReactElement => {
    return (
      <div className='align-middle inline-block min-w-full overflow-hidden'>
        {renderTable()}
        { rows.length === 0 ? empty : null }
      </div>
    )
  }

  return inline 
    ? renderInline()
    : renderWide()
}

