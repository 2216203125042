import schemas from '@shared/schemas'
import { Form } from 'components/elements/Form'
import AddressForm from 'components/elements/forms/Address'
import AttributesInput, { mapKeyedAttrsToListAttrs } from 'components/elements/forms/inputs/Attributes'
import { Checkbox } from 'components/elements/forms/inputs/Checkbox'
import FieldSet from 'components/elements/forms/inputs/FieldSet'
import Section from 'components/elements/forms/inputs/Section'
import Submit from 'components/elements/forms/inputs/Submit'
import TextInput from 'components/elements/forms/inputs/Text'
import { AssetModificationContext } from 'contexts/assetModification'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { postApi } from 'modules/api'
import notifications from 'modules/notifications'
import progress from 'modules/progress'
import { ReactElement, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import createSchema from 'utils/createSchema'
import { withNamespace } from 'utils/forms'
import namespaces from 'utils/namespaces'
import { useAsset } from './_root'

export default function EditApartmentPage(): ReactElement {
  const { asset, address } = useAsset()
  const navigate = useNavigate()

  const [ usePropertyAddress, setUsePropertyAddress ] = useState(!address)
  const { assetAttributeSettings } = useContext(AssetModificationContext)

  const attributeSettings = assetAttributeSettings['apartment']
  const assetAttributesToFormAttributes = mapKeyedAttrsToListAttrs(
    asset.attributes,
    attributeSettings.presets,
    { allowCustom: attributeSettings.allowCustom }
  )

  const namespace = withNamespace(namespaces.apartment)

  const schema = createSchema()
    .with(namespaces.apartment, schemas.asset.apartment)
    .with(namespaces.address, schemas.address.address, !usePropertyAddress)

  const initialAddress = address !== null
    ? address
    : schemas.address.blanks.address

  const initialValues = schema.filter({
    [namespaces.apartment]: {
      ...asset,
      attributes: assetAttributesToFormAttributes
    },
    [namespaces.address]: initialAddress
  })

  const onSubmit = async (values: any, frmk: FormikHelpers<any>) => {
    progress.start('update apartment')
    const data = schema.filter(values)

    try {
      const response = await postApi('/UpdateAsset', data, { assetId: asset.id })

      if (response._.statusCode !== 200) {
        throw new Error(response.message)
      }
    } catch (e: any) {
      notifications.error({ message: e.message })
      progress.done('update apartment')
      return
    }

    progress.done('update apartment')
    navigate(`/p/${asset.propertyId}/asset/${asset.id}`)
  }

  const renderForm = (formikProps: FormikProps<typeof initialValues>): ReactElement => {
    return (
      <Form onSubmit={formikProps.handleSubmit}>
        <Section fullWidth>
          <TextInput
            label='Apartment #'
            className='col-span-6 sm:col-span-3'
            name={namespace('displayId')}
          />

          <TextInput
            label='Base rate'
            help='The standard monthly rent for this asset.'
            placeholder='50.00'
            className='col-span-6 sm:col-span-3'
            name={namespace('baseRate')}
          />

          <AttributesInput
            label='Attributes'
            name={namespace('attributes')}
            mode='settings'
            settings={attributeSettings}
          />
        </Section>

        <Section fullWidth>
          <FieldSet title='Options' className='col-span-6'>
            <Checkbox
              className='col-span-6'
              label='Use property address'
              help='This apartment has the same address as the property, with the unit number being different.'
              name='hasHome'
              onChange={evt => setUsePropertyAddress(evt.target.checked)}
              checked={usePropertyAddress}
            />
          </FieldSet>
        </Section>

        <AddressForm
          subtitle='Use the physical address when possible. Otherwise, use the address where tenants can receive mail.'
          namespace={namespaces.address}
          visible={!usePropertyAddress}
        />

        <Submit />
      </Form>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={schema.validate}
      onSubmit={onSubmit}
    >{renderForm}</Formik>
  )
}
